import { Card } from "@/components";

interface InfoItemProps {
  title?: string;
  noTitle?: boolean;
  content?: string;
  noContent?: boolean;
  children?: React.ReactNode;
}
/**
 *
 * @description dearis Controller status-checking 页面在使用, 修改注意确认
 */
export default function InfoItem({
  title,
  noTitle,
  content,
  children: status,
  noContent,
}: InfoItemProps) {
  return (
    <Card className="flex flex-col gap-[10px] px-3 py-4">
      {noTitle || (
        <div className="flex justify-between text-sm font-normal text-green-gray">
          <p>{title ?? "-"}</p>
          <div className="shrink-0">{status}</div>
        </div>
      )}
      {noContent || (
        <div className="break-all text-base font-medium">{content ?? "-"}</div>
      )}
    </Card>
  );
}
