import { lazy, useState } from "react";
import type { Control } from "react-hook-form";
import { Controller } from "react-hook-form";

import { OverlayLoading } from "@/components";
import { CameraInput, UploadInput } from "@/components/form";
import { useModal } from "@/hooks";
import { errorToast } from "@/shared";
import { parseImageToBase64 } from "@/shared/common";
import { getStorage } from "@/shared/store";

import { ImageControlImageShower } from "./ImageControlImageShower";

const CropperModal = lazy(
  () => import("@/pages/spotSetting/components/CropperModal")
);
interface ImageControlProps {
  name: string;
  control: Control<any>;
  disabled?: boolean;
}
export const ImageControl: React.FC<ImageControlProps> = ({
  name,
  control,
  disabled,
}) => {
  const { visible, toggle } = useModal();
  const [imageBase64, setImageBase64] = useState("");
  const [loading, setLoading] = useState(false);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => {
        const handleCropAndUploadEnd = (uri: string) => {
          onChange(uri);
        };
        const handleCropCancel = () => {
          onChange("");
        };
        const clearImage = () => {
          onChange("");
        };
        const handleFileInputChange = async (
          e: React.ChangeEvent<HTMLInputElement>
        ) => {
          setLoading(true);
          const file = e.target?.files?.[0];

          if (file && file?.size > 16 * 1024 * 1024) {
            errorToast("画像は最大16MBです。");
            setLoading(false);
            return;
          }
          if (
            file &&
            file.type !== "image/png" &&
            file.type !== "image/jpeg" &&
            file.type !== "image/jpg"
          ) {
            errorToast("写真のフォーマットはJPEGまたはPNGです。");
            setLoading(false);
            return;
          }

          if (file) {
            await parseImageToBase64(file)
              .then((base64String) => {
                setImageBase64(base64String);
                toggle();
              })
              .catch(() => {
                errorToast("写真のアップロードに失敗しました。");
              })
              .finally(() => setLoading(false));
          }
        };
        if (value) {
          return (
            <ImageControlImageShower
              uri={value}
              disabled={disabled}
              clearImage={clearImage}
            />
          );
        }
        return (
          <>
            <div className="flex gap-5">
              <CameraInput onChange={handleFileInputChange} />
              <UploadInput onChange={handleFileInputChange} />
            </div>
            {getStorage("deviceType") === "PATMO" && (
              <p className="mt-2 text-xs text-green-gray">
                <span className="text-error">※</span>&nbsp;
                スマートフォンのOSによっては写真撮影が出来ない場合があります。「写真を取る」を選択しても機種によってはアルバム選択が起動する場合があります。
              </p>
            )}
            <OverlayLoading visible={loading} />
            <CropperModal
              visible={visible}
              toggle={toggle}
              image={imageBase64}
              onCropAndUploadEnd={handleCropAndUploadEnd}
              onCropCancel={handleCropCancel}
            />
          </>
        );
      }}
    />
  );
};
