import { useQuery } from "@tanstack/react-query";

import { NECESSARY_INFO_LACKING_HINT } from "@/shared";
import { getDeviceTestResults } from "@/shared/requests/property";
import { getStorage } from "@/shared/store";

import { NecessaryInfoLackingError } from "./errors.type";
import { UNLOCK_QUERY_KEY } from "./queryKey";

export function useGetDeviceTestResults() {
  const device_id = getStorage("deviceId");
  const property_id = getStorage("propertyId");
  const queryFn = async () => {
    if (!device_id || !property_id) {
      throw new NecessaryInfoLackingError(NECESSARY_INFO_LACKING_HINT);
    }
    return getDeviceTestResults({ property_id, device_id });
  };
  return useQuery([UNLOCK_QUERY_KEY], queryFn, {
    enabled: !!(device_id && property_id),
  });
}
