import { cx } from "@emotion/css";
import {
  LazyLoadComponent,
  LazyLoadImage,
} from "react-lazy-load-image-component";

import { useAuthImgAuto } from "@/queries";

interface ImageProps {
  uri: string;
}

export const Image: React.FC<ImageProps> = ({ uri }) => {
  return (
    <LazyLoadComponent delayTime={1000}>
      <ImageChild uri={uri} />
    </LazyLoadComponent>
  );
};

const ImageChild: React.FC<ImageProps> = ({ uri }) => {
  const { data } = useAuthImgAuto(uri);
  return (
    <LazyLoadImage
      src={data?.data.uri}
      // delayMethod="debounce"
      // delayTime={3000}
      className={cx(
        "h-full w-full rounded-md object-contain",
        "bg-contain bg-center bg-no-repeat",
        "aspect-[16/9]"
      )}
    />
  );
};
