import { cx } from "@emotion/css";

import Step from "./Step";
import type {
  StepImageContentModal,
  StepsDataModal,
  StepSegmentModal,
} from "./step.type";
import { StepCaption } from "./step-caption";
import StepGraph from "./StepGraph";
import StepIndicator from "./StepIndicator";
import StepParagraph from "./StepParagraph";
import StepTitle from "./StepTitle";

interface StepsGeneratorProps {
  className?: string;
  stepsData: StepsDataModal;
}

const StepsGenerator: React.FC<StepsGeneratorProps> = ({
  className,
  stepsData,
}) => {
  return (
    <div className={cx("contents", className)}>
      {stepsData.index && (
        <Step>{stepsData?.index?.map((step) => generateStep(step))}</Step>
      )}
      {stepsData.steps?.map((step) => (
        <Step>{step?.map((stepSegment) => generateStep(stepSegment))}</Step>
      ))}
    </div>
  );
};

function generateStep(stepSegment: StepSegmentModal) {
  switch (stepSegment.type) {
    case "image": {
      if (Array.isArray(stepSegment.content)) {
        throw new Error("StepSegmentModal.content should not be an array");
      }
      if (typeof stepSegment.content === "object") {
        return (
          <StepGraph image={stepSegment.content?.src ?? "error"}>
            {stepSegment.content?.caption ?? "error"}
          </StepGraph>
        );
      } else {
        return <StepGraph image={stepSegment.content ?? "error"} />;
      }
    }
    case "images": {
      if (!Array.isArray(stepSegment.content)) {
        throw new Error("StepSegmentModal.content should be an array");
      }
      return (
        <div className="flex gap-7">
          {stepSegment.content?.map((img: StepImageContentModal) => (
            <div>
              <img src={img.src} className="w-full" />
              <p className="my-2 text-center">{img.caption ?? "error"}</p>
            </div>
          ))}
        </div>
      );
    }
    case "title": {
      return <StepTitle>{stepSegment.content as string}</StepTitle>;
    }
    case "text": {
      return (
        <StepParagraph>
          {stepSegment?.indicator && (
            <StepIndicator>{stepSegment?.indicator}</StepIndicator>
          )}
          {(stepSegment.content as string)
            .split("\n")
            ?.map((line, index) =>
              index ? <p>{line}</p> : <p className="inline">{line}</p>
            )}
        </StepParagraph>
      );
    }
    case "caption": {
      return <StepCaption>{stepSegment.content as string}</StepCaption>;
    }
    case "alert": {
      return (
        <StepParagraph>
          <span className="text-error">※&nbsp;</span>
          {stepSegment.content as string}
        </StepParagraph>
      );
    }
    default: {
      return <div className="text-xl">Error. Unknown Type.</div>;
    }
  }
}

export { StepsDataModal, StepsGenerator };
