import { useGetDevicesOfProperty, useGetPropertyById } from "@/queries";
import type { Building, Device, DeviceType } from "@/types";

export function useCountBuildingDevices() {
  const {
    data: propertyData,
    isLoading: isPropertyLoading,
    isError: isPropertyError,
    error: propertyError,
  } = useGetPropertyById();
  const {
    data: elevatorsData,
    isLoading: isElevatorLoading,
    isError: isElevatorError,
    error: elevatorError,
  } = useGetDevicesOfProperty("dearisElevator" as DeviceType);
  const {
    data: entrancesData,
    isLoading: isEntranceLoading,
    isError: isEntranceError,
    error: entranceError,
  } = useGetDevicesOfProperty("dearisEntrance" as DeviceType);
  const property = propertyData?.data;
  const elevators = elevatorsData?.data;
  const entrances = entrancesData?.data;
  const buildings = property?.buildings?.map((item) =>
    countEveryBuildingDevices(item, entrances, elevators)
  );
  return {
    entrances,
    elevators,
    buildings,
    propertyData,
    isLoading: isPropertyLoading || isElevatorLoading || isEntranceLoading,
    isError: isPropertyError || isEntranceError || isElevatorError,
    error: propertyError || entranceError || elevatorError,
  };
}

function countEveryBuildingDevices(
  building: Building,
  entrances: Device[] = [],
  elevators: Device[] = []
) {
  const count =
    entrances.filter((item) => item.position?.build_id === building.build_id)
      .length +
    elevators.filter((item) => item.position?.build_id === building.build_id)
      .length;
  return { ...building, deviceCount: count };
}
