import "./App.css";

import { ConfigProvider } from "@nutui/nutui-react";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Suspense } from "react";
import { RouterProvider } from "react-router-dom";

import jp from "@/locale/ja-JP";
import queryClient from "@/queries/queryClient";

import { LoadingPage } from "./components";
import { router } from "./router";

function App() {
  return (
    <ConfigProvider locale={jp}>
      <QueryClientProvider client={queryClient}>
        <div
          className="relative flex min-h-screen flex-col bg-[#f6f6f6]"
          id="appRoot"
        >
          <Suspense fallback={<LoadingPage />}>
            <RouterProvider router={router} />
          </Suspense>
        </div>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ConfigProvider>
  );
}

export default App;
