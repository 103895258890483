import ImageStepZero from "@/assets/dearis-pre-setting-step-three.png";
import ImageUploadRoom from "@/assets/service-configure-upload-room-succeed.png";
import type { StepsDataModal } from "@/components/step";

export const controllerStepsData: StepsDataModal = {
  steps: [
    // index
    [
      {
        type: "image",
        content: {
          src: ImageStepZero,
          caption: "dearisの設定ページ",
        },
      },
      {
        type: "caption",
        content:
          "画面が消灯したら、トップページに戻った場合はPabbitのボタンを押して、次画面の左上を連続して３回タッチします。",
      },
    ],
    // step 1
    [
      { type: "title", content: "操作手順" },
      {
        type: "text",
        content: "設定ページに「サービスリスト再取得」を押してください。",
        indicator: 1,
      },
    ],
    // step 2
    [
      {
        type: "text",
        content: "「部屋情報をアップロード」を押してください。",
        indicator: 2,
      },
      {
        type: "text",
        content:
          "成功したら以下の画面が表示されます。「戻る」をタップして設定ページに戻ります。",
      },
      { type: "image", content: ImageUploadRoom },
    ],
    // step image
    [{ type: "image", content: ImageStepZero }],
    // step 3
    [
      {
        type: "text",
        content:
          "このページに戻ったら、「終了」ボタンを押してホーム画面に遷移します。",
        indicator: 3,
      },
    ],
  ],
};
