import type { AxiosResponse } from "axios";

import type { Building, Device, Property } from "@/types";

import type {
  AttachDeviceData,
  BindDeviceData,
  CreateDevicePositionData,
  DeviceTestResultResponse,
  GETBuildsOfPropertyParams,
  GetDeviceOfPropertyParams,
  GETDeviceTestResult,
  GetPropertiesParams,
  GetPropertiesResponse,
  ListCourierParams,
  ListCourierResponse,
  ListOptionsResponse,
  RegisterDearisToAmazonData,
  ReportTestResultData,
  UnlockDeviceOfPropertyData,
  UpdateDevicePositionData,
  UpdateUnlockTestData,
} from "./property.type";
import server from "./server";

export const getProperties = async (params: GetPropertiesParams) => {
  return server.get<unknown, AxiosResponse<GetPropertiesResponse>>(
    "/properties",
    {
      params,
    }
  );
};

export const getPropertyById = async (property_id: string) => {
  return server.get<unknown, AxiosResponse<Property>>(
    `/properties/${property_id}`
  );
};

export const bindDeviceToProperty = async ({
  property_id,
  ...data
}: BindDeviceData) => {
  return await server.post<unknown, AxiosResponse<Device>>(
    `/properties/${property_id}/devices`,
    data
  );
};

// only for entrance and elevator
// and for patmo update position info after PATMO 1.3.0
export const attachDeviceToProperty = async ({
  property_id,
  ...data
}: AttachDeviceData) => {
  return server.post<unknown, AxiosResponse<Device>>(
    `/properties/${property_id}/device_positions`,
    data
  );
};

export const updateDevicePosition = async ({
  property_id,
  device_id,
  ...data
}: UpdateDevicePositionData) => {
  const response = await server.patch(
    `/properties/${property_id}/device_positions/${device_id}`,
    data
  );
  return response;
};

export const updateUnlockTest = async ({
  property_id,
  device_id,
  ...data
}: UpdateUnlockTestData) => {
  return await server.patch(
    `/properties/${property_id}/device_positions/${device_id}`,
    data
  );
};

export const createDevicePosition = async ({
  property_id,
  ...data
}: CreateDevicePositionData) => {
  return server.post(`/properties/${property_id}/device_positions`, data);
};

export const getDevicesOfProperty = async ({
  property_id,
  ...params
}: GetDeviceOfPropertyParams) => {
  return server.get<unknown, AxiosResponse<Device[]>>(
    `/properties/${property_id}/devices`,
    { params }
  );
};

export const unlockDeviceOfProperty = async ({
  property_id,
  device_id,
  ...data
}: UnlockDeviceOfPropertyData) => {
  return server.post(
    `/properties/${property_id}/devices/${device_id}/unlock`,
    data
  );
};

export const getBuildsOfProperty = async ({
  property_id,
}: GETBuildsOfPropertyParams) => {
  return server.get<unknown, AxiosResponse<Building[]>>(
    `/properties/${property_id}/buildings`
  );
};

export const registerDeviceToAmazon = async ({
  property_id,
}: RegisterDearisToAmazonData) => {
  return server.post(`/properties/${property_id}/devices/sync_registery`);
};

export const getDeviceTestResults = async ({
  property_id,
  device_id,
}: GETDeviceTestResult) => {
  return server.get<unknown, AxiosResponse<DeviceTestResultResponse>>(
    `/properties/${property_id}/devices/${device_id}/test`
  );
};

export const reportTestResult = ({
  property_id,
  device_id,
  ...data
}: ReportTestResultData) => {
  return server.post(
    `/properties/${property_id}/devices/${device_id}/test`,
    data
  );
};

export const getCourierList = ({
  property_id,
  service_type,
}: ListCourierParams) => {
  return server.get<ListCourierResponse>(
    `/properties/${property_id}/services/${service_type}`
  );
};

export const getOptionsList = ({
  property_id,
  service_type,
}: ListCourierParams) => {
  return server.get<ListOptionsResponse>(
    `/properties/${property_id}/services/${service_type}`
  );
};

export * from "./property.type";
