import { cx } from "@emotion/css";
import { useNavigate } from "react-router-dom";

import ReturnIcon from "@/assets/return.svg?react";
import ScanIcon from "@/assets/scan-icon.svg?react";
import { Button, Card, Footer, Header, Main } from "@/components";

export default function IMEIReplaceInfoRegister() {
  const navigate = useNavigate();
  return (
    <>
      <Header>IMEIを変更する</Header>
      <Main>
        <p className="p-4 pb-0.5 font-bold">
          <span className="text-error">*</span>&nbsp;
          {
            "通信アダプターに表示されたQRコードをスキャンしてください。するか、シリアル番号を入力してください"
          }
        </p>
        <Card className="mb-3 flex min-h-34 flex-col justify-between py-5">
          {"通信アダプターに表示されたQRコードをスキャンしてください。"}
          <div>
            <Button
              className="w-full"
              onClick={() => navigate("/imei-replace/scan")}
            >
              <div className={cx("flex items-center gap-2.5")}>
                <ScanIcon />
                <span>スキャン</span>
              </div>
            </Button>
          </div>
        </Card>

        <Card className="mb-3 flex min-h-34 flex-col justify-between py-5">
          {
            "スキャンがうまく使用できない場合は下記ボタンをタップして新しいIMEI番号を入力してください"
          }
          <div>
            <Button
              type="default"
              onClick={() => navigate("/imei-replace/manual")}
            >
              新しいIMEI番号を入力する
            </Button>
          </div>
        </Card>
        <button
          className={cx(
            "mx-auto mt-15 block",
            "border-0 bg-transparent p-0 text-sm text-primary underline"
          )}
          onClick={() => navigate(-1)}
        >
          <ReturnIcon className="mb-0.5 mr-3 inline h-4 w-4" />
          戻る
        </button>
      </Main>
      <Footer />
    </>
  );
}
