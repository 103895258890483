import { useGetPropertyById, useListOption } from "@/queries";
import { getStorage } from "@/shared/store";
import type { ProviderModel } from "@/types";

/**
 * @description 临时改变, PATMO二期LAN上线后应该需要改回 useUnlockTestData(), 此hook删除
 * @deprecated
 * @returns
 */
export function useUnlockTestDataPATMO() {
  const {
    data: propertyResponse,
    isLoading: isGetPropertyLoading,
    isError: isGetPropertyError,
  } = useGetPropertyById();
  const {
    data: couriersResponse,
    isLoading: isGetListCourierLoading,
    isError: isGetListCourierError,
  } = useListOption();
  const theBuilding = propertyResponse?.data?.buildings?.find(
    (building) => building?.rooms && building.rooms?.length > 0
  );
  const roomNumber = theBuilding?.rooms && theBuilding?.rooms[0]?.number;

  const theDelivery = couriersResponse?.data?.find(
    (item) => item.option !== "amazon" && item.testable
  );
  const delivery: ProviderModel = {
    name: theDelivery?.title || "",
    courier: theDelivery?.option || "",
  };
  const code = getStorage("serialNumber").slice(-4);
  return {
    isLoading: isGetPropertyLoading || isGetListCourierLoading,
    isError: isGetPropertyError || isGetListCourierError,
    roomNumber,
    code,
    theDelivery,
    delivery,
    isUnsatisfactory: !theDelivery || !roomNumber,
  };
}
