import { useLocation } from "react-router-dom";

import { RelayUnlock } from "./relay-unlock";
import { RoomNumberUnlockWrapper } from "./room-unlock";

const UnlockTest = () => {
  const locationState = useLocation().state;
  if (!locationState) return null;
  const { unlockTestType } = locationState;

  switch (unlockTestType) {
    case "relay":
      return <RelayUnlock />;
    case "roomNumber":
      return <RoomNumberUnlockWrapper />;
  }
};

export default UnlockTest;
