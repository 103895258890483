import { useNavigate } from "react-router-dom";

import { Button, Footer, Header, Main } from "@/components";
import {
  Step,
  StepContainer,
  StepIndicator,
  StepParagraph,
  StepsGenerator,
} from "@/components/step";

import { gateStepsData } from "./gate-steps-data";

export default function () {
  const navigate = useNavigate();
  return (
    <>
      <Header>設定を開始</Header>
      <Main>
        <StepContainer>
          <StepsGenerator stepsData={gateStepsData} />
          <Step>
            <StepParagraph>
              <StepIndicator>4</StepIndicator>
              {"「戻る」をタップして設定を続けます。"}
            </StepParagraph>
            <Button onClick={() => navigate(-1)}>戻る</Button>
          </Step>
        </StepContainer>
      </Main>
      <Footer />
    </>
  );
}
