import { useNavigate } from "react-router-dom";

import SolidCloseIcon from "@/assets/solid-color-close.svg?react";
import SolidSuccessIcon from "@/assets/solid-success.svg?react";
import {
  Button,
  Card,
  Footer,
  Header,
  Main,
  PlainSkeleton,
} from "@/components";
import { useFetchDataError } from "@/hooks";
import { useCheckLineStatus } from "@/queries";
import { getStorage } from "@/shared/store";

export const DearisReplace = () => {
  const navigate = useNavigate();
  const imei = getStorage("imei");
  const { data, isLoading, isError, error } = useCheckLineStatus();
  useFetchDataError(isError, error);
  const status = data?.data?.status;
  const cantReplace = status === "dead";
  if (isLoading) {
    return (
      <>
        <Header>IMEIを変更する</Header>
        <Main>
          <Card>
            <PlainSkeleton />
          </Card>
        </Main>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header>IMEIを変更する</Header>
      <Main>
        <Card className="relative">
          <header className="font-medium">新しいIMEI番号：</header>
          <div className="mt-6 flex justify-between">
            <p className="text-sm text-green-gray">{imei}</p>
            {cantReplace ? (
              <SolidCloseIcon className="mt-0.5 h-4 w-4" />
            ) : (
              <SolidSuccessIcon className="mt-0.5 h-4 w-4" />
            )}
          </div>
          {cantReplace && (
            <p className="absolute -bottom-5 left-0 z-20 text-xs text-error">
              このIMEI番号はすでに解約されました
            </p>
          )}
        </Card>

        <div className="mt-10 px-4">
          {cantReplace ? (
            <Button onClick={() => navigate("/imei-replace/status")}>
              情報ページに戻る
            </Button>
          ) : (
            <>
              <Button
                onClick={() => navigate("/imei-replace/register-line")}
                disabled={isError}
              >
                回線を開通
              </Button>
              <p className="py-2 text-xs text-error">
                ※
                注意：新しい通信アダプターがdearisデバイスに既に接続されていることを確認してください。
              </p>
            </>
          )}
        </div>
      </Main>
      <Footer />
    </>
  );
};
