import {
  useCheckDeviceStatus,
  useGetPropertyByDevice,
  useListEvents,
} from "@/queries";
import { convertTimeZone } from "@/shared/convertTimeZone";
import { setStorage } from "@/shared/store";
import type { LineStatus, Property } from "@/types";

type DeviceStatus = {
  bindedProperty: string; // 是否绑定物件
  positionedSpotName: number; // 是否设置了场所识别名和图片, 0: 未设置, 1: 设置场所名, 2: 设置场所名和图片
  lineActived: LineStatus; // 回线开通状态: active, suspend, inactive
  // isBuild0Exist?: boolean; // 物件下有0号楼则该物件的service设定已完成
  isBuildingUploaded?: boolean; // 物件的service设定完成判断标准更新：https://www.notion.so/pacport/register-a054d3081e864762914fc92556de0362
  isUnlockTestAllPassed?: boolean;
};

export const useGetPATMOStatus = () => {
  const {
    data: deviceData,
    isLoading: isGetDeviceLoading,
    isFetching: isGetDeviceFetching,
    isSuccess: isGetDeviceSuccess,
    isError: isGetDeviceError,
    error: getDeviceError,
  } = useCheckDeviceStatus();
  const device = deviceData?.data;
  const {
    isLoading: isGetEventsLoading,
    isFetching: isGetEventsFetching,
    data: eventData,
    isError: isGetEventError,
    isSuccess: isGetEventSuccess,
    error: getEventError,
  } = useListEvents();
  // ref: https://www.notion.so/pacport/register-a054d3081e864762914fc92556de0362
  const isBuildingUploaded = eventData?.data?.events?.findIndex(
    (e) =>
      e.payload?.property_id === device?.property_id &&
      e.event?.header.publishedAt &&
      device?.bound_at &&
      convertTimeZone(new Date(e.event?.header.publishedAt)) >
        new Date(device?.bound_at)
  );
  // console.log("isBuildingUploaded", isBuildingUploaded);
  // console.log("device", device);
  // console.log("events", eventData?.data.events);
  const {
    data: propertyData,
    isSuccess: isGetPropertySuccess,
    isError: isGetPropertyError,
    error: getPropertyError,
  } = useGetPropertyByDevice(deviceData?.data);
  const deviceStatus: DeviceStatus = {
    bindedProperty: device?.property_name || "",
    positionedSpotName:
      Number(!!device?.position?.spot) + Number(!!device?.position?.image_uri),
    lineActived: device?.line_status || "inactive",
    isBuildingUploaded:
      isGetEventSuccess &&
      isBuildingUploaded !== undefined &&
      isBuildingUploaded >= 0,
  };
  // console.log("uploaded", deviceStatus.isBuildingUploaded);
  const isSettingAllDone =
    deviceStatus.bindedProperty &&
    deviceStatus.positionedSpotName === 2 &&
    deviceStatus.isBuildingUploaded &&
    (device?.imei ? deviceStatus.lineActived : true);
  if (isGetEventSuccess && isGetDeviceSuccess) {
    setStorage("isBuildingUploaded", deviceStatus.isBuildingUploaded);
  }
  if (isGetDeviceSuccess) {
    setStorage("deviceId", device?.id || "");
    setStorage("spotName", device?.position?.spot || "");

    if (device?.property_id) {
      const property: Partial<Property> = {
        id: device?.property_id || "",
        name: device?.property_name || "",
      };
      setStorage("selectedBuildingOrProperty", property as Property);
      setStorage("propertyId", device?.property_id || "");
      setStorage("isDeviceBindedObject", true);
    } else {
      setStorage("selectedBuildingOrProperty", undefined);
      setStorage("propertyId", "");
      setStorage("isDeviceBindedObject", false);
    }
  }
  if (isGetPropertySuccess) {
    setStorage("selectedBuildingOrProperty", propertyData?.data);
  }

  return {
    device,
    deviceStatus,
    isSettingAllDone,
    isLoading: isGetDeviceLoading || isGetEventsLoading,
    isFetching: isGetDeviceFetching || isGetEventsFetching,
    isError: isGetDeviceError || isGetPropertyError || isGetEventError,
    isSuccess: isGetDeviceSuccess && isGetPropertySuccess && isGetEventSuccess,
    error: getDeviceError || getPropertyError || getEventError,
  };
};
