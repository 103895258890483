import { getStorage } from "@/shared/store";
import type { DeviceType } from "@/types";

import { ControllerDeliveries, PATMODeliveries } from "./deliveries-confirm";

export default function () {
  const device_type = getStorage("deviceType") as DeviceType;
  switch (device_type) {
    case "PATMO":
      return <PATMODeliveries />;
    case "dearisController":
      return <ControllerDeliveries />;
  }
}
