import React from "react";

// 二级序号
export default function ConsentParagraphThird({
  children,
}: Readonly<{
  children: React.ReactNode | React.ReactNode[];
}>) {
  return (
    <p className="indent-8 mb-2  text-xs text-neutral-400 font-normal">
      {children}
    </p>
  );
}
