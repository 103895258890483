import { cx } from "@emotion/css";
import React from "react";

import { Card } from "@/components";

export const ObjectEmpty = React.memo(() => {
  return (
    <Card className="my-4">
      <div className={cx("p-4", "text-center")}>
        <span className="text-center align-middle text-sm text-neutral-600">
          検索ボタンをタッチして、紐づけたい物件
          又は棟の情報を検索してください。
        </span>
      </div>
    </Card>
  );
});
