import { cx } from "@emotion/css";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Button, Card, Footer, Header, Main, PlainSkeleton } from "@/components";
import { Label } from "@/components/form";
import { useFormErrorsToast, useVisibilityToggle } from "@/hooks";
import { useUpdateLitePosition } from "@/queries";
import { clearLiteBindInfoByDeviceId, errorToast, SAVE_SPOT_NAME_FAILED_HINT } from "@/shared";
import { getStorage, setStorage } from "@/shared/store";

import { ImageControl, SpotInput, XiezhenCaption } from "../components";
import { useDeviceSpotValue } from "../hooks";

interface LiteSpotForm {
  location: string;
  customLocation: string;
  uri: string;
}
export const LiteSpot: React.FC = () => {
  const navigate = useNavigate();
  const { register, setValue, getValues, watch, handleSubmit, setFocus, reset, control } = useForm<LiteSpotForm>();
  const { isLoading: isGETDeviceLoading } = useDeviceSpotValue(reset);
  const [isAllFieldFilled] = useVisibilityToggle(
    watch("location"),
    // 如果 select 的 值为 "カスタム", 则需要填写 customLocation, 否则传 永为真 的值;
    watch("location") === "カスタム" ? watch("customLocation") : "permanent"
  );
  const { toastFormValueError } = useFormErrorsToast();
  // const { bindAndUpdatePosition, isLoading } = useLiteBindAndUpdate();
  const { mutateAsync: updateLitePosition, isLoading } = useUpdateLitePosition();

  const handleConfirmClick = (value: LiteSpotForm) => {
    if (value.location === "カスタム") {
      if (!value.customLocation.trim()) return errorToast("設置場所(識別名)は必須です。");
      setStorage("spotName", value.customLocation.trim());
    } else {
      setStorage("spotName", value.location);
    }
    setStorage("uri", value.uri);
    updateLitePosition()
      .then(() => {
        const deviceId = getStorage("deviceId");
        clearLiteBindInfoByDeviceId(deviceId);
        navigate(-1);
      })
      .catch((error) => {
        if (error?.status === 400) {
          errorToast(SAVE_SPOT_NAME_FAILED_HINT);
        } else {
          errorToast(error?.message ?? error?.toString());
        }
      });
  };

  useEffect(() => {
    if (watch("location") === "カスタム") {
      setFocus("customLocation");
    }
  }, [watch("location")]);

  if (isGETDeviceLoading) {
    return (
      <>
        <Header>設置場所（識別名）設定</Header>
        <Main>
          <Card>
            <PlainSkeleton />
          </Card>
        </Main>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header>設置場所（識別名）設定</Header>
      <Main>
        <Card>
          <Label htmlFor="" required>
            設置場所（識別名）
          </Label>
          <SpotInput watch={watch} register={register} setValue={setValue} getValues={getValues} />
          <Label htmlFor="" className="mt-4">
            写真
          </Label>
          <p className={cx("mb-3 text-xs font-normal leading-[18px] text-neutral-400")}>
            画像は最大16MBです。横向きの写真を撮ってください。
            <br />
            下部に写真の注意事項があります。
          </p>
          <ImageControl name="uri" control={control} />
          <div className="mt-5">
            <Button
              onClick={handleSubmit(handleConfirmClick, toastFormValueError)}
              disabled={!isAllFieldFilled}
              loading={isLoading}
            >
              確認して提出
            </Button>
          </div>
          <XiezhenCaption />
        </Card>
      </Main>
      <Footer />
    </>
  );
};
