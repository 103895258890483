import { useLocation, useNavigate } from "react-router-dom";

import { Button, Footer, Header, Main } from "@/components";
import {
  Step,
  StepContainer,
  StepIndicator,
  StepParagraph,
  StepsGenerator,
} from "@/components/step";
import { setStorage } from "@/shared/store";

import { dearisStepsData } from "./dearis-steps-data";

export default function () {
  const navigate = useNavigate();
  const location = useLocation();
  if (location.pathname === "/pre-setting/entrance") {
    setStorage("deviceType", "dearisEntrance");
  } else {
    setStorage("deviceType", "dearisController");
  }
  return (
    <>
      <Header>設定を開始</Header>
      <Main>
        <StepContainer>
          <StepsGenerator stepsData={dearisStepsData} />
          {/* Step Four */}
          <Step>
            <StepParagraph>
              <StepIndicator>4</StepIndicator>
              「次へ」をタップして設定を続けます。
            </StepParagraph>
            <StepParagraph>
              <Button onClick={() => navigate("/register", { replace: true })}>
                次へ
              </Button>
            </StepParagraph>
          </Step>
        </StepContainer>
      </Main>
      <Footer />
    </>
  );
}
