import { type FieldErrors, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Button, Card, Footer, Header } from "@/components";
import { Label } from "@/components/form";
import { useFormErrorsToast, useVisibilityToggle } from "@/hooks";

import { IMEIInput, LiteSNInput } from "../components";

export interface LiteRegisterFormField {
  serialNumber: string;
  imei: string;
}

const LiteManualRegister = () => {
  const navigate = useNavigate();

  const { watch, register, setValue, getValues, handleSubmit } = useForm<LiteRegisterFormField>();
  const [isAllFieldFilled] = useVisibilityToggle(watch("imei"), watch("serialNumber"));
  const { toastFormValueError } = useFormErrorsToast();

  const confirmRegister = async () => {
    navigate("/register/confirm");
  };

  return (
    <div className="contents">
      <Header>情報の登録</Header>
      <div className="m-4 flex-1 bg-[#f6f6f6]">
        <Card className="mt-4">
          <Label htmlFor="serialNumber" required>
            シリアル番号
          </Label>
          <LiteSNInput register={register} setValue={setValue} getValues={getValues} />
          <div className="h-6"></div>
          <Label htmlFor="imei" required>
            IMEI
          </Label>
          <IMEIInput register={register} setValue={setValue} getValues={getValues} />
          <div className="mt-10">
            <Button
              className="w-full"
              size="large"
              onClick={handleSubmit(confirmRegister, (errors: FieldErrors) => toastFormValueError(errors))}
              disabled={!isAllFieldFilled}
            >
              確認
            </Button>
          </div>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default LiteManualRegister;
