import { useFetchDataError } from "@/hooks";
import { useCheckDeviceStatus } from "@/queries";
import { getStorage } from "@/shared/store";

interface DearisControllerDeviceStatus {
  binded: string;
  lockTested: boolean;
}

export function useGetControllerStatus() {
  const { data, isLoading, isError, error } = useCheckDeviceStatus();
  useFetchDataError(!!(getStorage("deviceId") && isError), error);
  const device = data?.data;
  const deviceStatus: DearisControllerDeviceStatus = {
    binded: device?.bound_at || "",
    lockTested: device?.position?.test_passed ?? false,
  };
  return {
    device,
    deviceStatus,
    isLoading,
    isError,
    isStatusAllFinish: deviceStatus.binded && deviceStatus.lockTested,
  };
}
