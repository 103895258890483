import { useEffect } from "react";
import { type UseFormReset } from "react-hook-form";

// import { spotOptions } from "@/app.constant";
import { useGetDeviceByIdManual } from "@/queries";

interface DearisSpotFormField {
  buildId: number | undefined;
  gateNumber: number | undefined;
  location: string;
  customLocation: string;
  uri: string;
}
/**
 * @description 仅用于Dearis设备的场所名设置
 * @param id
 * @param reset
 * @param spotOptions
 * @returns
 */
export function useDearisSpotValue(
  id: string | undefined,
  reset: UseFormReset<any>,
  spotOptions: string[]
) {
  const { data, isLoading, isSuccess, isError, error } = useGetDeviceByIdManual(
    id || ""
  );
  let defaultValues = {} as DearisSpotFormField;

  if (isSuccess) {
    const spotOption = spotOptions.find(
      (spot) => spot === data?.data.position?.spot
    );
    defaultValues = {
      buildId: data?.data.position?.build_id,
      gateNumber: data?.data.position?.gate_number,
      location: spotOption || "カスタム",
      customLocation: spotOption || data?.data.position?.spot || "",
      uri: data?.data.position?.image_uri || "",
    };
  }

  useEffect(() => {
    if (id && isSuccess) {
      reset(defaultValues);
    }
  }, [id, isSuccess]);

  return {
    isLoading: id && isLoading,
    isError: id && isError,
    error,
  };
}
