import { useParams } from "react-router-dom";

import { useHandleDeviceUnknown } from "@/hooks";

import ControllerUnLockTest from "./controller";
import LiteUnLockTest from "./lite";
import PatmoUnLockTest from "./patmo";

export default function () {
  const { deviceType } = useParams();
  const { toastAndToSelectDevice } = useHandleDeviceUnknown();
  switch (deviceType) {
    case "patmo":
      return <PatmoUnLockTest />;
    case "lite":
      return <LiteUnLockTest />;
    case "controller":
      return <ControllerUnLockTest />;
    default: {
      toastAndToSelectDevice();
    }
  }
}
