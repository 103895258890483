import { useParams } from "react-router-dom";

import type { DeviceType } from "@/types";

import { EntranceElevatorUnlock, GateUnlock } from "./cloud-unlock";

export default function AmazonUnlock() {
  const { deviceType } = useParams() as { deviceType: DeviceType };
  switch (deviceType) {
    case "dearisGate":
      return <GateUnlock />;
    default:
      return <EntranceElevatorUnlock />;
  }
}
