import { useHandleDeviceUnknown } from "@/hooks";
import { getStorage } from "@/shared/store";
import type { DeviceType } from "@/types";

import {
  DearisControllerPreSetting,
  GatePreSetting,
  PatmoPreSetting,
} from "./pre-setting";

export default function PreSetting() {
  const deviceType = getStorage("deviceType") as DeviceType;
  const { toastAndToSelectDevice } = useHandleDeviceUnknown();
  switch (deviceType) {
    case "PATMO":
      return <PatmoPreSetting />;
    case "dearisGate":
      return <GatePreSetting />;
    case "dearisController":
    case "dearisEntrance":
      return <DearisControllerPreSetting />;
    default: {
      toastAndToSelectDevice();
    }
  }
}
