import { cx } from "@emotion/css";
import type { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";

import ErrorIcon from "@/assets/error.svg?react";
import ReturnIcon from "@/assets/return.svg?react";
import {
  Button,
  Footer,
  Header,
  Hint,
  LineStatusAction,
  LineStatusCard,
  LineStatusStatement,
  Link,
  Popup,
  PopupAction,
  PopupMain,
} from "@/components";
import { useModal } from "@/hooks";
import { getStorage } from "@/shared/store";
import type { DeviceType } from "@/types";

import { useReplaceIMEI } from "../hooks";

const RegisterFailed = ({
  setKey,
}: {
  setKey: Dispatch<SetStateAction<number>>;
}) => {
  const navigate = useNavigate();
  const { visible } = useModal(getStorage("PATMOReplaceIMEI"));
  const onReplaceSucceed = () => {
    navigate("/status");
  };
  const onCancel = () => {
    navigate("/status");
  };

  const { isReplacing, handleReplaceIMEIClick, handleCancelClick } =
    useReplaceIMEI({ onReplaceSucceed, onCancel });
  return (
    <>
      <div className="contents">
        <Header>回線開通</Header>
        <div className="flex-1 bg-[#f6f6f6] p-4">
          <LineStatusCard>
            <ErrorIcon className={cx("m-auto")} />
            <LineStatusStatement>回線開通に失敗しました</LineStatusStatement>
            <LineStatusAction>
              <Button
                className="w-full"
                type="default"
                onClick={() => setKey((prev) => prev + 1)}
              >
                もう一度行う
              </Button>
            </LineStatusAction>
          </LineStatusCard>
          <FailedHint />
        </div>
        <FailedReturn />
        <Footer />
      </div>
      <Popup visible={visible}>
        <PopupMain>
          <p>{"開通に失敗しました。"}</p>
          <br />
          <p>{"IMEI番号を変更しますか？"}</p>
        </PopupMain>
        <PopupAction className="flex gap-5">
          <div className="flex-1">
            <Button
              type="default"
              onClick={handleReplaceIMEIClick}
              loading={isReplacing}
            >
              変更
            </Button>
          </div>
          <div className="flex-1">
            <Button onClick={handleCancelClick}>変更しません</Button>
          </div>
        </PopupAction>
      </Popup>
    </>
  );
};

const FailedHint = () => {
  const deviceType = getStorage("deviceType") as DeviceType;
  switch (deviceType) {
    case "PATMO":
      return (
        <Hint className="m-2 py-2 text-xs">
          {"何度も開通に失敗した場合は、デバイス管理者に連絡してください。"}
        </Hint>
      );
    case "dearisController":
      return (
        <Hint className="m-2 py-2 text-xs">
          {
            "何度も開通に失敗した場合は、元の通信アダプターを取り付けてください。"
          }
        </Hint>
      );
  }
};

const FailedReturn = () => {
  const deviceType = getStorage("deviceType") as DeviceType;
  switch (deviceType) {
    case "PATMO":
      return (
        <Link className="m-3 block text-center" to="/">
          <ReturnIcon className="mr-1 inline" />
          トップページに戻る
        </Link>
      );
    case "dearisController":
      return (
        <Link className="m-3 block text-center" to="/imei-replace/status">
          <ReturnIcon className="mr-1 inline" />
          情報ページに戻る
        </Link>
      );
  }
};

export default RegisterFailed;
