import ImageHelpOne from "@/assets/patmo-pre-setting-help-1.png";
import ImageHelpTwo from "@/assets/patmo-pre-setting-help-2.png";
import ImageHelpThree from "@/assets/patmo-pre-setting-help-3.png";
import ImageHelpFour from "@/assets/patmo-pre-setting-help-4.png";
import ImageHelpFive from "@/assets/patmo-pre-setting-help-5.png";
import ImageHelpSix from "@/assets/patmo-pre-setting-help-6.png";
import ImageHelpSeven from "@/assets/patmo-pre-setting-help-7.png";
// import ImageHelpEight from "@/assets/patmo-pre-setting-help-8.png";
import ImageStepOne from "@/assets/patmo-pre-setting-step-1.png";
import ImageStepTwo from "@/assets/patmo-pre-setting-step-2.png";
import ImageStepThree from "@/assets/patmo-pre-setting-step-3.png";
import type { StepsDataModal } from "@/components/step";

export const patmoStepsData: StepsDataModal = {
  steps: [
    // step 1
    [
      {
        type: "text",
        content:
          "このQRコードをスマートフォンでスキャンして、設定ツールを開いてください。次に、デバイスのPabbitのボタンを押します。",
        indicator: 1,
      },
      { type: "image", content: ImageStepOne },
    ],
    // step 2
    [
      {
        type: "text",
        content: "この画面の右上を連続して３回タッチします。",
        indicator: 2,
      },
      { type: "image", content: ImageStepTwo },
    ],
    // step 3 4
    [
      {
        type: "text",
        content: "デバイスがこのページを表示することを確認します。",
        indicator: 3,
      },
      {
        type: "image",
        content: {
          src: ImageStepThree,
          caption: "PATMOαの設定ページ",
        },
      },
    ],
  ],
};
export const patmoStepsExtra: StepsDataModal = {
  steps: [
    // step 3 4
    [
      {
        type: "text",
        content: "「次へ」をタップして設定を続けます。",
        indicator: 4,
      },
    ],
  ],
};
export const patmoHelpExtra: StepsDataModal = {
  steps: [
    [
      {
        type: "text",
        content: "デバイスのホーム画面に、「部屋呼出」ボタンを押します。",
        indicator: 1,
      },
      { type: "image", content: ImageHelpOne },
    ],
  ],
};
export const patmoHelpData: StepsDataModal = {
  steps: [
    // step 1-7
    [{ type: "image", content: ImageHelpTwo }],
    [
      {
        type: "text",
        content: "管理用番号を入力してください。",
        indicator: 3,
      },
      { type: "image", content: ImageHelpThree },
    ],
    [
      {
        type: "text",
        content: "「Pabbit設定」ボタンを押してください。",
        indicator: 4,
      },
      { type: "image", content: ImageHelpFour },
    ],
    [
      {
        type: "text",
        content:
          "「Pabbitを再設定する」ボタンを押した後、次のポップアップに「はい」をタップしてください。",
        indicator: 5,
      },
      { type: "image", content: ImageHelpFive },
      { type: "image", content: ImageHelpSix },
    ],
    [
      {
        type: "text",
        content:
          "この画面が表示されます。3秒くらいを待って、トップページに戻ります。",
        indicator: 6,
      },
      { type: "image", content: ImageHelpSeven },
      { type: "image", content: ImageStepOne },
      {
        type: "text",
        content: "設定用のQRコードはデバイスの設定ページ中に表示されます。",
      },
    ],
    [
      {
        type: "text",
        content: "「戻る」をタップして設定を続けます。",
        indicator: 7,
      },
    ],
  ],
};
