import React from "react";

export const Footer = React.memo(() => {
  const handleNavigateClick = () => {
    window.open("https://www.pacport.com/", "_blank");
  };
  return (
    <footer className="mb-2 w-full self-end text-center text-sm text-neutral-400">
      © 2023 <span onClick={handleNavigateClick}>PacPort</span>. All rights
      reserved
    </footer>
  );
});
