import { useMutation } from "@tanstack/react-query";

import {
  getCurrentAuthenticatedUser,
  sendVerifyCode,
} from "@/shared/requests/auth";

export function useSendVerifyCode() {
  const mutationFn = async ({ code }: { code: string }) => {
    try {
      await sendVerifyCode({ code });
    } catch (e: any) {
      if (e.code === "NotAuthorizedException") {
        throw new VerifyCodeExpiredError(
          "このパスワードはすでに期限切れになっています。"
        );
      }
      return Promise.reject(new Error(e));
    }
    try {
      await getCurrentAuthenticatedUser();
    } catch (e: any) {
      throw new VerifyCodeNotMatchError("パスワードのエラー。");
    }
  };
  return useMutation(mutationFn);
}

export class VerifyCodeNotMatchError extends Error {}
export class VerifyCodeExpiredError extends Error {}
