import { cx } from "@emotion/css";

export default function Step({
  children,
  className,
}: Readonly<{
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
}>) {
  return <div className={cx("flex flex-col gap-5", className)}>{children}</div>;
}
