import { useNavigate } from "react-router-dom";

import { Button, Card, Footer, Header, Main, Tag } from "@/components";
import { Label } from "@/components/form";
import { useFetchDataError, useGetScannerData } from "@/hooks";
import { useCheckLineStatus } from "@/queries";
import { getStorage } from "@/shared/store";
import type { LineStatus } from "@/types";

import { DearisAdapterSkeleton } from "../components";

export default function DearisAdapterDataConfirm() {
  const imei = getStorage("imei");
  const { data, isFetching, isError, error } = useCheckLineStatus();
  useFetchDataError(isError, error);
  const lineStatus = data?.data.status || "unknown";
  useGetScannerData();

  if (isFetching) return <DearisAdapterSkeleton />;

  return (
    <>
      <Header>デバイス情報の確認</Header>
      <Main>
        <Card>
          <div className="flex justify-between text-base">
            <Label htmlFor="" required>
              IMEI
            </Label>
            <div className="mb-2 shrink-0">
              <LineOpenedTag lineStatus={lineStatus} />
            </div>
          </div>
          <p className="py-2 text-sm">{imei}</p>

          <NextButton lineStatus={lineStatus} />
        </Card>
      </Main>
      <Footer />
    </>
  );
}

const LineOpenedTag = ({ lineStatus }: { lineStatus: LineStatus }) => {
  if (lineStatus === "active")
    return (
      <Tag background="#E4FFE6" color="#52C41A">
        開通中
      </Tag>
    );
  if (lineStatus === "suspend")
    return (
      <Tag background="#FFF2EB" color="#FD8140">
        一時停止
      </Tag>
    );
  if (lineStatus === "dead") return <Tag>解約された</Tag>;
  return <Tag>未開通</Tag>;
};

const NextButton = ({ lineStatus }: { lineStatus: LineStatus }) => {
  const navigate = useNavigate();
  if (lineStatus === "active") {
    return (
      <div className="mt-10">
        <Button onClick={() => navigate("/dearis", { replace: true })}>
          開通ステップをスキップ
        </Button>
        <p
          className="mt-5 text-center text-sm text-primary"
          onClick={() => navigate("/register-line")}
        >
          再度回線を開通
        </p>
      </div>
    );
  } else {
    return (
      <Button className="mb-6 mt-10" onClick={() => navigate("/register-line")}>
        回線開通
      </Button>
    );
  }
};
