import { cx } from "@emotion/css";

import { Button, Popup, PopupAction, PopupMain } from "@/components";
import { useModal, useNavigateWithSearchParams } from "@/hooks";
import { useUpdateDearisPositionMulti } from "@/queries";
import {
  DELETE_DEVICE_FAILED_HINT,
  errorToast,
  infoToast,
  SAVE_SPOT_NAME_FAILED_HINT,
} from "@/shared";
import { setStorage } from "@/shared/store";

import { useDeleteDearisGate } from "../hooks";
import type { GateSpotFormField } from "../spot-setting";

interface DearisGateSpotDetailButtonGroupProps {
  buildIds: number[];
  formValues: GateSpotFormField;
}
export const DearisGateSpotDetailButtonGroup: React.FC<
  DearisGateSpotDetailButtonGroupProps
> = ({ buildIds, formValues }) => {
  const navigate = useNavigateWithSearchParams();
  const { visible: isDeleteVisible, toggle: deleteToggle } = useModal();
  const { visible: isUpdateVisible, toggle: updateToggle } = useModal();

  const { deleteDearisGate, isLoading: isDeleting } = useDeleteDearisGate();
  const { mutateAsync: updatePosition, isLoading: isUpdating } =
    useUpdateDearisPositionMulti();

  const handleDeleteDevice = async () => {
    const gate_number = formValues.gateNumber;
    await deleteDearisGate(gate_number)
      .then(() => navigate(-1))
      .catch((error) => {
        if (error?.status === 400) {
          errorToast(DELETE_DEVICE_FAILED_HINT);
        } else {
          errorToast(error?.message ?? error.toString());
        }
        isDeleteVisible && deleteToggle();
      });
  };

  const updateBuildingsOfGate = async () => {
    setStorage("gateNumber", formValues.gateNumber);
    const spot = formValues?.customLocation ?? formValues?.location;
    setStorage("spotName", spot);

    await updatePosition(buildIds)
      .then(() => {
        infoToast("操作に成功しました");
        navigate(0);
      })
      .catch((error) => {
        if (error?.status === 400) {
          errorToast(SAVE_SPOT_NAME_FAILED_HINT);
        } else {
          errorToast(error?.message ?? error?.toString());
        }
      });
  };

  if (buildIds?.length > 0) {
    return (
      <>
        <Button onClick={updateToggle}>更新</Button>
        <Popup visible={isUpdateVisible}>
          <PopupMain>集中集合玄関機を棟に新規登録しますか？</PopupMain>
          <PopupAction className="flex gap-2">
            <div className="flex-1">
              <Button
                className="w-full"
                type="default"
                onClick={() => {
                  isUpdating || updateToggle();
                }}
              >
                いいえ
              </Button>
            </div>
            <div className="flex-1">
              <Button
                className="w-full"
                onClick={updateBuildingsOfGate}
                loading={isUpdating}
                disabled={isUpdating}
              >
                はい
              </Button>
            </div>
          </PopupAction>
        </Popup>
      </>
    );
  }
  return (
    <>
      <Button
        onClick={() => {
          navigate("/cloud-unlock/dearisGate", undefined, {
            gate_number: formValues.gateNumber,
          });
        }}
      >
        クラウド解錠テストに進む
      </Button>
      <button
        className={cx(
          "mt-5 block w-full text-center",
          "text-sm !leading-[22px] text-error"
        )}
        onClick={deleteToggle}
      >
        デバイスを削除
      </button>
      <Popup visible={isDeleteVisible}>
        <PopupMain>このデバイスを削除してもよろしいですか？</PopupMain>
        <PopupAction className="flex gap-2">
          <div className="flex-1">
            <Button className="w-full" type="default" onClick={deleteToggle}>
              キャンセル
            </Button>
          </div>
          <div className="flex-1">
            <Button
              className="w-full"
              onClick={handleDeleteDevice}
              loading={isDeleting}
              disabled={isDeleting}
            >
              確定
            </Button>
          </div>
        </PopupAction>
      </Popup>
    </>
  );
};
