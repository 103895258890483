import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { STORE_USERNAME_KEY } from "@/app.constant";
import { Button, Footer, PabbitLogo } from "@/components";
import { SmsCaptcha } from "@/components/form";
import { useFormErrorsToast, useVisibilityToggle } from "@/hooks";
import { useGetVerifyCode, useSendVerifyCode } from "@/queries";
import { errorToast } from "@/shared";

import { ResendCode } from "./components";

const LoginWithCode = () => {
  const form = useForm();
  const navigate = useNavigate();
  const { watch, register, getValues, handleSubmit } = form;
  const { toastFormValueError } = useFormErrorsToast();

  const { mutateAsync: sendCode, isLoading: isCodeSending } =
    useSendVerifyCode();
  const { mutateAsync: getVerifyCode, isLoading: isCodeGeting } =
    useGetVerifyCode();

  const [isAllFieldFilled] = useVisibilityToggle(watch("code"));

  const handleLogin = async () => {
    const { code } = getValues();
    if (code?.trim().length !== 6) {
      errorToast("6桁のパスワードを入力してください");
      return;
    }
    sendCode({ code })
      .then(() => {
        history.replaceState(null, "", "/select-device");
        history.go(0);
      })
      .catch((error) => {
        errorToast(error.message ?? error.toString());
      });
  };

  const resend = async () => {
    const phone = window.localStorage.getItem(STORE_USERNAME_KEY) || "";
    if (!phone) {
      errorToast("ユーザー情報が見つかりません");
      navigate("/login");
      return;
    }
    await getVerifyCode({ phone });
  };

  useEffect(() => {
    return () => {
      window.localStorage.removeItem(STORE_USERNAME_KEY);
    };
  }, []);

  return (
    <FormProvider {...form}>
      <div className="contents">
        <div className="m-9 flex-1">
          <PabbitLogo />
          <div className="mt-4">
            <p className=" font-semibold text-text-black">
              ワンタイムパスワードが送信されました
            </p>
            <p className=" mt-[6px] text-text-gray">
              ワンタイムパスワードを入力してください
            </p>
            <SmsCaptcha
              register={register("code", {
                required: "6桁のパスワードを入力してください",
              })}
            />
            <ResendCode onResend={resend} />
          </div>
          <div className="mt-6"></div>
          <Button
            className="w-full"
            size="large"
            onClick={handleSubmit(handleLogin, toastFormValueError)}
            loading={isCodeSending}
            disabled={!isAllFieldFilled || isCodeGeting}
          >
            ログイン
          </Button>
        </div>
        <Footer />
      </div>
    </FormProvider>
  );
};

export default LoginWithCode;
