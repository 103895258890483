import { useMutation } from "@tanstack/react-query";

import { getVerifyCode } from "@/shared/requests/auth";

export function useGetVerifyCode() {
  const mutationFn = async ({ phone }: { phone: string }) => {
    return getVerifyCode({ phone });
  };
  return useMutation(mutationFn);
}
