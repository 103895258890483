import RestartEndImage from "@/assets/lite-restart-end.png";
import RestartIndexImage from "@/assets/lite-restart-index.png";
import type { StepsDataModal } from "@/components/step";

export const liteStepsData: StepsDataModal = {
  steps: [
    [
      { type: "image", content: RestartIndexImage },
      {
        type: "text",
        content:
          "2分程度待った後、「デバイスをリセット」ボタンを押してください。",
      },
    ],
    [
      { type: "image", content: RestartEndImage },
      {
        type: "text",
        content:
          "デバイスが再起動し、通信が正常であれば、トップページに解錠テストの完了を促すメッセージが表示されます。",
      },
      {
        type: "text",
        content:
          "通信ができない場合は、「物件情報がありません」と表示され続けています。デバイスの画面をタップして、もう一度デバイスを再起動してください。",
      },
    ],
  ],
};
