import { useMutation } from "@tanstack/react-query";

import { NECESSARY_INFO_LACKING_HINT, UNKNOWEN_ERROR_HINT } from "@/shared";
import {
  getDevices,
  registerDevice,
  updateDevice,
} from "@/shared/requests/device";
import { getStorage } from "@/shared/store";
import type { Device } from "@/types";

import { NecessaryInfoLackingError } from "./errors.type";

export function useCreateController() {
  const mutateFn = async () => {
    const imei = getStorage("imei");
    const sn = getStorage("serialNumber");
    const device_type = getStorage("deviceType");
    if (!sn || !device_type) {
      throw new NecessaryInfoLackingError(NECESSARY_INFO_LACKING_HINT);
    }
    let theDevice: Device | undefined;
    await getDevices({ device_type, sn }).then(({ data }) => {
      theDevice = data.devices.find((device) => device.sn === sn);
    });
    if (!theDevice && imei) {
      // console.log("全新设备, 本地有imei");
      return registerDevice({ imei, sn, device_type });
    } else if (!theDevice && !imei) {
      // console.log("全新设备, 本地没有imei");
      return registerDevice({ sn, device_type });
    } else if (theDevice && !theDevice.imei && imei) {
      // console.log("旧设备, 设备没有imei, 本地有imei");
      return updateDevice({ device_id: theDevice.id, imei });
    } else if (theDevice) {
      // console.log("旧设备");
      return Promise.resolve({ data: theDevice });
    } else {
      throw new Error(UNKNOWEN_ERROR_HINT);
    }
  };
  return useMutation(mutateFn);
}
