import { cx } from "@emotion/css";
import { type FC, type Key, type ReactNode, useMemo, useState } from "react";

export interface TabItem {
  key: Key;
  label: string;
  children: ReactNode;
}
interface ITabs {
  color?: string;
  items: TabItem[];
}
export const Tabs: FC<ITabs> = ({ color = "primary", items }) => {
  const [activeKey, setActiveKey] = useState(items[0]?.key);
  const accentClass = {
    borderColor: `!border-${color}`,
    bgColor: `bg-${color}`,
    color: `text-${color}`,
  };
  const activeChildren = useMemo(() => {
    return items.find((item) => item.key === activeKey)?.children;
  }, [items, activeKey]);
  return (
    <>
      <div
        className={cx(
          "flex",
          "m-4",
          "border-solid border-primary border-[1px]",
          "rounded-md",
        )}
      >
        {items.map(({ key, label }) => (
          <div
            key={key}
            className={cx(
              "flex-1",
              "p-2",
              "text-center text-[15px]",
              key === activeKey ? accentClass.bgColor : "",
              key === activeKey ? "text-white" : accentClass.color,
            )}
            onClick={() => setActiveKey(key)}
          >
            {label}
          </div>
        ))}
      </div>
      {activeChildren}
    </>
  );
};
