import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";

import {
  Button,
  Footer,
  Header,
  Main,
  Popup,
  PopupAction,
  PopupHeader,
  PopupMain,
  Skeleton,
} from "@/components";
import { useModal, useNavigateWithSearchParams } from "@/hooks";
import { errorToast, UNKNOWN_DEVICE_HINT } from "@/shared";
import { getStorage, setStorage } from "@/shared/store";
import type { DeviceType } from "@/types";

import {
  ElevatorList,
  EntranceList,
  MarkedAddDearisButton,
} from "./components";
import { useDearisBuildingDetail } from "./hooks";

export default function () {
  const navigate = useNavigateWithSearchParams();
  const dearisBuildingDetail = getStorage("dearisBuildingDetail");
  const { id } = useParams() as { id: string };
  const { visible: unlockVisible, toggle: unlockToggle } = useModal();

  const { entrances, elevators, isFetching, isDevicesExist } =
    useDearisBuildingDetail();
  const memorizedEntrances = useMemo(() => entrances, [entrances]);
  const memorizedElevators = useMemo(() => elevators, [elevators]);

  const handleAddDeviceClick = (type: DeviceType) => {
    setStorage("deviceType", type);
    if (type === "dearisElevator") {
      navigate("/spot");
    } else if (type === "dearisEntrance") {
      navigate("/pre-setting/entrance");
    } else {
      errorToast(UNKNOWN_DEVICE_HINT);
    }
  };

  const handleDetailsClick = useCallback(
    (e: React.MouseEvent<HTMLSpanElement>, type: DeviceType) => {
      const detailEle = e.target as HTMLSpanElement;
      if (detailEle && detailEle.dataset.deviceId) {
        setStorage("deviceType", type);
        setStorage("serialNumber", "");
        navigate(`/spot/${detailEle.dataset.deviceId}`);
      }
    },
    []
  );

  const handleEnterUnlockClick = () => {
    if (
      (memorizedEntrances && memorizedEntrances?.length > 0) ||
      (memorizedElevators && memorizedElevators?.length > 0)
    ) {
      navigate("/cloud-unlock", undefined, { building_id: id });
    } else {
      unlockToggle(); // 除非有设备否则按钮不可点击, 理论上这个ifelse逻辑应该不起作用了
    }
  };

  const handleEndClick = () => {
    navigate("/dearis");
  };

  if (isFetching)
    return (
      <>
        <Header canBack onClick={() => navigate("/dearis")}>
          {dearisBuildingDetail?.name}
        </Header>
        <Main>
          <MarkedAddDearisButton
            className="!my-0"
            show
            onClick={() => handleAddDeviceClick("dearisEntrance")}
          >
            集合玄関機を追加
          </MarkedAddDearisButton>
          <MarkedAddDearisButton
            show
            onClick={() => handleAddDeviceClick("dearisElevator")}
          >
            エレベーターを追加
          </MarkedAddDearisButton>
          <Skeleton />
        </Main>
        <Footer />
      </>
    );

  return (
    <>
      <Header canBack onClick={() => navigate("/dearis")}>
        {dearisBuildingDetail?.name}
      </Header>
      <Main>
        <MarkedAddDearisButton
          className="!my-0"
          show
          onClick={() => handleAddDeviceClick("dearisEntrance")}
        >
          集合玄関機を追加
        </MarkedAddDearisButton>
        <MarkedAddDearisButton
          show
          onClick={() => handleAddDeviceClick("dearisElevator")}
        >
          エレベーターを追加
        </MarkedAddDearisButton>
        <EntranceList
          show
          devices={memorizedEntrances}
          onClick={handleDetailsClick}
        />
        <ElevatorList
          show
          devices={memorizedElevators}
          onClick={handleDetailsClick}
        />
      </Main>
      <div className="my-3 px-4">
        <Button disabled={!isDevicesExist} onClick={handleEnterUnlockClick}>
          クラウド解錠テストに進む
        </Button>
      </div>
      <Footer />
      <Popup visible={unlockVisible}>
        <PopupHeader className="!text-lg !font-medium !text-neutral-600">
          制御装置の基本情報のみ登録しますか？
        </PopupHeader>
        <PopupMain>
          ※&nbsp;玄関機を登録しない場合はクラウド解錠テストが省略します。
        </PopupMain>
        <PopupAction className="flex gap-2">
          <div className="flex-1">
            <Button className="w-full" type="default" onClick={unlockToggle}>
              キャンセル
            </Button>
          </div>
          <div className="flex-1">
            <Button className="w-full" onClick={handleEndClick}>
              確定
            </Button>
          </div>
        </PopupAction>
      </Popup>
    </>
  );
}
