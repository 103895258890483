export const DEVICE_QUERY_KEY = "device";
export const EVENT_QUERY_KEY = "events";
export const LINE_QUERY_KEY = "line";
export const BUILDING_QUERY_KEY = "building";
export const PROPERTY_QUERY_KEY = "property";
export const UNLOCK_QUERY_KEY = "unlock";
export const IMAGE_QUERY_KEY = "image";
export const ENTRANCESE_QUERY_KEY = "entrance";
export const ELEVATOR_QUERY_KEY = "elevator";
export const GATE_QUERY_KEY = "gate";
export const SERVICE_QUERY_KEY = "service";
export const SERVICE_DELIVERY_QUERY_KEY = "delivery";
export const SERVICE_OPTION_QUERY_KEY = "option";
