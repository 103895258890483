import { useNavigate } from "react-router-dom";

import {
  Button,
  Footer,
  Header,
  Main,
  PlainSkeleton,
  Popup,
  PopupAction,
  PopupMain,
} from "@/components";
import {
  Step,
  StepContainer,
  StepIndicator,
  StepParagraph,
  StepTitle,
} from "@/components/step";
import { useModal } from "@/hooks";
import { useUpdateUnlockTest } from "@/queries";
import { errorToast } from "@/shared";

import { useUnlockTestData } from "../hooks";

export default function Wrapper() {
  const navigate = useNavigate();
  const { visible, toggle } = useModal();
  const { isLoading, isError, isUnsatisfactory, delivery, code } =
    useUnlockTestData();
  const {
    mutateAsync: updateControllerTestPassed,
    isLoading: isUpdateUnlockLoading,
  } = useUpdateUnlockTest();

  if (isLoading) {
    return <UnlockedSkeleton />;
  }
  if (isUnsatisfactory) {
    errorToast("宅配事業者のデータが更新されました。再度ご確認ください。");
    navigate(-1);
  }

  const handleUnlockClick = () => {
    updateControllerTestPassed()
      .then(() => {
        navigate("/status");
      })
      .catch((error) => {
        if (error?.status && error?.status === 400) {
          errorToast("設定状態が変更されました。");
        } else {
          errorToast(error?.message ?? error?.toString());
        }
      });
  };

  return (
    <>
      <Header>解錠テスト</Header>
      <Main>
        <StepContainer>
          <Step>
            <StepTitle>操作手順</StepTitle>
            <StepParagraph>
              <StepIndicator>1</StepIndicator>
              デバイスの「宅配業者の選択」ページで
              <span className="text-primary">「{delivery.name}」</span>
              を選択してください
            </StepParagraph>
          </Step>
          <Step>
            <StepParagraph>
              <StepIndicator>2</StepIndicator>
              {"実際にある部屋番号を入力して「呼出」を押してください。"}
            </StepParagraph>
          </Step>
          <Step>
            <StepParagraph>
              <StepIndicator>3</StepIndicator>
              約15秒後に「サービス番号を入力」を押せるようになりますので押してください。
            </StepParagraph>
            <StepParagraph>
              <div className="rounded-md bg-bgGray py-2 text-center text-2xl font-bold text-primary">
                「{code}」
              </div>
              <p className="mt-2">
                上記の数字を入力し「決定」を押してください。
              </p>
            </StepParagraph>
          </Step>
          <Step>
            <StepParagraph>ドアロックが解錠されましたか？</StepParagraph>
            <StepParagraph>
              <Button
                onClick={handleUnlockClick}
                loading={isUpdateUnlockLoading}
                disabled={isError || isUnsatisfactory}
              >
                解錠されました
              </Button>
              <button className="mt-2  text-primary" onClick={toggle}>
                解錠できませんでした
              </button>
            </StepParagraph>
          </Step>
        </StepContainer>
      </Main>
      <Footer />
      <Popup visible={visible}>
        <PopupMain>
          入力内容が正しいかどうか確認するか、またはデバイスの担当者に連絡してください。
        </PopupMain>
        <PopupAction>
          <Button onClick={toggle}>閉じる</Button>
        </PopupAction>
      </Popup>
    </>
  );
}

const UnlockedSkeleton = () => {
  return (
    <>
      <Header>解錠テスト</Header>
      <Main>
        <StepContainer>
          <Step>
            <PlainSkeleton />
          </Step>
          <Step>
            <PlainSkeleton />
          </Step>
          <Step>
            <PlainSkeleton />
          </Step>
          <Step>
            <PlainSkeleton />
          </Step>
        </StepContainer>
      </Main>
      <Footer />
    </>
  );
};
