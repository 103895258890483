import { Card } from "@/components";
import type { Device } from "@/types";

interface PropertyInfoCardProps {
  device: Device | undefined;
  show: boolean;
}
export function PropertyInfoCard({
  show,
  device,
}: Readonly<PropertyInfoCardProps>) {
  if (!show || !device) return null;
  return (
    <Card className="my-[10px] p-3">
      <div>
        <p className="text-sm text-green-gray">シリアル番号</p>
        <p className="break-all">{device.sn}</p>
      </div>
      <div className="mt-2">
        <p className="text-sm text-green-gray">物件</p>
        <p className="">{device?.position?.property_name}</p>
      </div>
    </Card>
  );
}
