import {
  BuildIdNotFoundError,
  DeviceAlreadyExistsError,
  SNInconsistentError,
  useGetBuildsByProperty,
  useListDevicesOfProperty,
} from "@/queries";
import { getStorage } from "@/shared/store";

export function usePreCreateEntrance() {
  const {
    mutateAsync: getBuilds,
    isLoading: isBuildsGetting,
    isError: isGetBuildsError,
  } = useGetBuildsByProperty();
  const {
    mutateAsync: getDevices,
    isLoading: isDevicesGetting,
    isError: isGetDevicesError,
  } = useListDevicesOfProperty();

  const preCreateEntrance = async ({
    buildId,
    gateNumber,
    sn,
  }: {
    buildId: number;
    gateNumber: number;
    sn: string;
  }) => {
    // 判断entrance 是否 和 controller 的 sn 一致
    const controller = getStorage("dearisController");
    if (controller?.sn !== sn) {
      throw new SNInconsistentError(
        "シリアルナンバーが制御装置と一致しません。"
      );
    }
    // 判断扫描到的buildid 是否与当前正在进行设置的楼的buildid一致
    const configuringBuildId = getStorage("configureDearisDevicesBuildId");
    if (configuringBuildId != buildId) {
      throw new Error(
        "棟番号が一致しません。デバイスが正しい建物に設定されているかどうか確認してください。"
      );
    }

    // 判断 buildId 是否存在
    const builds = await getBuilds();
    const theBuild = builds.data.find(
      (building) => building.build_id == buildId
    );
    if (!theBuild) {
      throw new BuildIdNotFoundError(
        "この棟番号は、既にアップロードされたデータの範囲内にはありません。"
      );
    }

    // 判断设备是否存在
    const property_id = getStorage("propertyId");
    const devices = await getDevices({
      property_id,
      device_type: "dearisEntrance",
    });
    const theDevice = devices.data.find(
      (device) =>
        device.position?.build_id == buildId &&
        device.position?.gate_number == gateNumber
    );
    if (theDevice) {
      throw new DeviceAlreadyExistsError("この玄関機はすでに設定されました。");
    }
  };

  return {
    preCreateEntrance,
    isPreCreating: isBuildsGetting || isDevicesGetting,
    isPreCreateError: isGetBuildsError || isGetDevicesError,
  };
}
