import { useCallback, useEffect } from "react";

import {
  Button,
  Footer,
  Header,
  Main,
  Popup,
  PopupAction,
  PopupMain,
} from "@/components";
import {
  useFetchDataError,
  useModal,
  useNavigateWithSearchParams,
} from "@/hooks";
import { getStorage, setStorage } from "@/shared/store";
import type { DeviceType } from "@/types";

import {
  AddDearisButton,
  BuildingSettingEntryCard,
  BuildingSettingTitle,
  GateCard,
  IMEIStatus,
  MarkedAddDearisButton,
  PropertyInfoCard,
} from "./components";
import { useCountBuildingDevices, useDearisMultiData } from "./hooks";

export function DearisMultiBuild() {
  setStorage("deviceType", "dearisController");
  const controller = getStorage("dearisController");
  if (controller?.sn) {
    setStorage("serialNumber", controller.sn);
  } // !important
  const navigate = useNavigateWithSearchParams();
  const { visible: unMatchVisible, toggle: unMatchToggle } = useModal();
  const { visible: registerSucceed, toggle: registerSuccessToggle } =
    useModal();

  const {
    imei,
    lineInfo,
    isControllerAdded,
    isIMEIMatch,
    device,
    dearisGates,
    isStillLocked,
    isError,
    isLoading,
    error,
    isSuccess,
  } = useDearisMultiData();
  const {
    buildings,
    isError: isBuildingsError,
    error: buildingError,
  } = useCountBuildingDevices();
  useFetchDataError(isError || isBuildingsError, error || buildingError);

  const handleDetailsClick = useCallback(
    (e: React.MouseEvent<HTMLSpanElement>, type: DeviceType) => {
      const detailEle = e.target as HTMLSpanElement;
      if (detailEle && detailEle.dataset.deviceId) {
        setStorage("deviceType", type);
        navigate(`/spot/${detailEle.dataset.deviceId}`);
      }
    },
    []
  );
  const handleAddDeviceClick = (type: DeviceType) => {
    setStorage("deviceType", type);
    navigate("/hardware-setting");
  };

  // 和 singleDearis一样, 后续优化成钩子;
  const handleUnlockComplete = () => {
    !registerSucceed && registerSuccessToggle();
  };

  useEffect(() => {
    if (isSuccess) {
      if (imei && device?.imei && device?.imei !== imei) {
        !unMatchVisible && unMatchToggle();
        setStorage("deviceId", "");
        setStorage("selectedBuildingOrProperty", undefined);
        setStorage("propertyId", "");
      }
      if (device && (isIMEIMatch || !imei)) {
        const controller = {
          sn: device.sn,
        };
        setStorage("dearisController", controller);
      } else {
        setStorage("dearisController", undefined);
      }
    }
  }, [device, lineInfo]);

  return (
    <>
      <Header>
        dearis設定リスト
        <p className="text-xs font-normal text-green-gray">
          リスト内のデバイスは一式として登録します
        </p>
      </Header>
      <Main>
        <IMEIStatus
          imei={imei}
          lineStatus={lineInfo?.data.status || "unknown"}
        />
        <AddDearisButton
          className="!my-0"
          show={!isControllerAdded}
          sn={device?.sn}
          onClick={() => handleAddDeviceClick("dearisController")}
        >
          基本情報を追加
        </AddDearisButton>
        <PropertyInfoCard
          show={isControllerAdded && (isIMEIMatch || !imei)}
          device={device}
        />
        <MarkedAddDearisButton
          show={(buildings?.length ?? 0) > 1}
          onClick={() => {
            setStorage("deviceType", "dearisGate");
            navigate("/spot");
          }}
        >
          集中集合玄関機を追加
        </MarkedAddDearisButton>
        <div onClick={(e) => handleDetailsClick(e, "dearisGate")}>
          {dearisGates?.map((item) => <GateCard key={item.id} device={item} />)}
        </div>
        <BuildingSettingTitle show={!!buildings?.length}>
          マンション名を選択して設定
        </BuildingSettingTitle>
        {buildings?.map((building) => (
          <BuildingSettingEntryCard key={building.id} building={building} />
        ))}
      </Main>
      <div className="mb-3 mt-10 px-4">
        <Button
          disabled={
            isStillLocked ||
            isBuildingsError ||
            isError ||
            isBuildingsError ||
            isLoading ||
            !isControllerAdded ||
            Boolean(imei && !isIMEIMatch)
          }
          onClick={handleUnlockComplete}
        >
          すべての設定を完了
        </Button>
      </div>
      <Footer />
      <Popup visible={unMatchVisible}>
        <PopupMain>
          このdearisデバイスにはすでにIMEIが設定されています。設定ツールを使用しての変更はできません。
        </PopupMain>
        <PopupAction>
          <Button onClick={unMatchToggle}>閉じる</Button>
        </PopupAction>
      </Popup>
      <Popup visible={registerSucceed}>
        <PopupMain>制御装置の基本情報のみ登録しますか？</PopupMain>
        <PopupAction className="flex gap-2">
          <div className="flex-1">
            <Button
              className="w-full"
              type="default"
              onClick={registerSuccessToggle}
            >
              キャンセル
            </Button>
          </div>
          <div className="flex-1">
            <Button className="w-full" onClick={() => navigate("/")}>
              確定
            </Button>
          </div>
        </PopupAction>
      </Popup>
    </>
  );
}
