import DearisInsideImg from "@/assets/dearis-inside-instructure.png";
import type { StepsDataModal } from "@/components/step";

export const dearisStepsData: StepsDataModal = {
  steps: [
    // step 1
    [
      {
        type: "text",
        content: "制御装置のディップスイッチの設定を行います。",
      },
      {
        type: "image",
        content: DearisInsideImg,
      },
      {
        type: "text",
        content:
          "設定ＳＷ２ー５\n･･･制御装置のＩＰアドレス設定\nＯＮ（ＩＰアドレスの自動取得）に変更ください。\n※ネットワーク業者よりＩＰアドレスの指定がされている場合はＯＦＦのままになります。担当の営業に問い合わせください。",
        indicator: 1,
      },
    ],
    // step 2
    [
      {
        type: "text",
        content:
          "設定ＳＷ２－６\n･･･Pabbit設定\nＯＮ(Pabbitサービス)に変更ください。\n※全ての集合玄関機にPabbitの表示がされます。\nPabbitを非表示にさせたい集合玄関機がある場合は該当する集合玄関機の施工設定にて設定してください。\n",
        indicator: 2,
      },
    ],
  ],
};
