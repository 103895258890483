import { useNavigate } from "react-router-dom";

import SuccessIcon from "@/assets/success.svg?react";
import {
  Button,
  Footer,
  Header,
  Hint,
  LineStatusAction,
  LineStatusCard,
  LineStatusStatement,
  Main,
} from "@/components";

const DearisAdapterRegisterLineSuccess = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/dearis", { replace: true });
  };
  return (
    <>
      <Header>回線開通</Header>
      <Main>
        <LineStatusCard>
          <SuccessIcon className="mx-auto" />
          <LineStatusStatement className="!font-bold !text-black/70">
            開通に成功しました
            <p className="text-xs font-medium">
              {"アダプターをオフにしてから、再起動してください"}
            </p>
            <p className="mt-4 text-xs font-normal">
              {
                "デバイスが再起動したことを確認した後、dearis玄関機の前に戻って、このページで「dearis設定を開始」ボタンをタップしてください。"
              }
            </p>
          </LineStatusStatement>

          <LineStatusAction className="mt-18">
            <Button className="w-full" onClick={handleClick}>
              dearis設定を開始
            </Button>
          </LineStatusAction>
        </LineStatusCard>
        <Hint className="p-2 text-xs">
          注意：回線を開通した後、関連するデバイスを設定してください。さもないと、システムは通信アダプターとデバイスの紐づけ関係を繋げることができません。
        </Hint>
      </Main>
      <Footer />
    </>
  );
};
export default DearisAdapterRegisterLineSuccess;
