import * as Sentry from "@sentry/react";

export function initSentry() {
  Sentry.init({
    dsn: "https://b66d443d360d6da22e7f4297fab933f4@o4506630185680896.ingest.us.sentry.io/4507127987961856",
    integrations: [
      Sentry.browserTracingIntegration({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          "localhost",
          /^https:\/\/api\.pabbit\.cloud/,
          /^https:\/\/cloud\.dev\.pacport\.dev/,
          /^https:\/\/cloud\.sandbox\.pacport\.dev/,
          /^https:\/\/configurator\.dev\.pacport\.dev/,
          /^https:\/\/configurator\.sandbox\.pacport\.dev/,
          /^https:\/\/tool\.pabbit\.cloud/,
        ],
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    environment: import.meta.env.MODE,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    ignoreErrors: [],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
