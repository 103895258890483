import { useRef, type FC, useEffect } from "react";
import { cx } from "@emotion/css";
import ExpandSquareIcon from "@/assets/expand-square.svg?react";
import CollapseSquareIcon from "@/assets/collapse-sqare.svg?react";
import { useToggle } from "react-use";
import { createPortal } from "react-dom";
import { Footer } from "..";

// interface FullscreenChildrenProps {
//   // isFullscreen: boolean;
// }
interface IToggleFullScreenProps {
  children: React.ReactNode;
}
export const ToggleFullScreen: FC<IToggleFullScreenProps> = ({ children }) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const [isFullscreen, toggle] = useToggle(false);

  useEffect(() => {
    function keyboardListener(e: KeyboardEvent) {
      if (e.code === "Escape") {
        toggle();
      }
    }
    document.addEventListener("keydown", keyboardListener);
    return () => {
      document.removeEventListener("keydown", keyboardListener);
    };
  });

  return (
    <>
      {!isFullscreen && (
        <ExpandSquareIcon
          className="absolute top-4 right-5 cursor-pointer"
          onClick={toggle}
        />
      )}
      <div
        ref={contentRef}
        className="bg-white flex items-stretch justify-center"
      >
        <div className={cx("bg-white p-2 flex-1 h-max w-max")}>{children}</div>
      </div>
      {isFullscreen &&
        createPortal(
          <div className="bg-white fixed left-0 top-0 flex  w-screen h-screen justify-center items-center z-20">
            <CollapseSquareIcon
              className="absolute top-6 right-6 cursor-pointer z-50"
              onClick={toggle}
            />
            <div
              className={cx(
                "w-full xs:w-1/3 md:w-1/5 p-28 ",
                "rotate-90 scale-[2]",
              )}
            >
              {children}
            </div>
            <div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-screen">
              <Footer />
            </div>
          </div>,
          document.body,
        )}
    </>
  );
};
