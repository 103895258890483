import { useMutation, useQuery } from "@tanstack/react-query";

import { NECESSARY_INFO_LACKING_HINT } from "@/shared";
import { getPropertyById } from "@/shared/requests/property";
import { getStorage } from "@/shared/store";
import type { Device } from "@/types";

import { NecessaryInfoLackingError } from "./errors.type";
import { PROPERTY_QUERY_KEY } from "./queryKey";

export const useGetPropertyById = () => {
  const property_id = getStorage("propertyId");
  const queryFn = () => {
    if (!property_id) {
      throw new NecessaryInfoLackingError(NECESSARY_INFO_LACKING_HINT);
    }
    return getPropertyById(property_id);
  };
  return useQuery([PROPERTY_QUERY_KEY, property_id], queryFn, {
    enabled: !!property_id,
  });
};

export const useGetPropertyMutation = () => {
  const property_id = getStorage("propertyId");
  const mutationFn = () => {
    if (!property_id)
      throw new NecessaryInfoLackingError(NECESSARY_INFO_LACKING_HINT);
    return getPropertyById(property_id);
  };
  return useMutation(mutationFn);
};

/*
 * status checking 中 property_id 依赖 device 数据的取得
 * 从 localStorage 中获取不够及时
 */
export const useGetPropertyByDevice = (device?: Device) => {
  return useQuery(
    ["property", device?.property_id],
    () => getPropertyById(device?.property_id || ""),
    { enabled: !!device?.property_id }
  );
};
