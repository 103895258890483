import React from "react";
import { useNavigate } from "react-router-dom";

import { Button, Footer, Header, Main } from "@/components";
import { useModal } from "@/hooks";
import { setStorage } from "@/shared/store";
import type { DeviceType } from "@/types";

import {
  AmazonSkeleton,
  ElevatorUnlockModal,
  EntranceUnlockModal,
  MemoElevatorList,
  MemoEntranceList,
} from "../components";
import { useDearisNotControllerData } from "../hooks";

export function EntranceElevatorUnlock() {
  const navigate = useNavigate();
  const { visible: entranceVisbile, toggle: entranceToggle } = useModal();
  const { visible: elevatorVisbile, toggle: elevatorToggle } = useModal();

  const { entrances, elevators, isLoading } = useDearisNotControllerData();
  const handleUnlockTestClick = async (
    e: React.MouseEvent<HTMLSpanElement>,
    type: DeviceType
  ) => {
    const unlockEle = e.target as HTMLSpanElement;
    if (unlockEle?.dataset.deviceId) {
      const device_id = unlockEle.dataset.deviceId;
      setStorage("tobeUnlockId", device_id);
      if (type === "dearisEntrance") {
        entranceToggle();
      } else {
        elevatorToggle();
      }
    }
  };

  // const handleUnlockComplete = () => {
  // !registerSucceed && registerSuccessToggle();
  // registerToAmazon()
  //   .then(() => {
  //     !registerSucceed && registerSuccessToggle();
  //   })
  //   .catch((error) => {
  //     if (error instanceof CloudUnlockERRNETWORKError) {
  //       !registerSucceed && registerSuccessToggle();
  //     } else if (error?.status) {
  //       !registerSucceed && registerSuccessToggle();
  //     } else {
  //       toastBottom("ネットワークタイムアウト");
  //     }
  //   });
  // };

  if (isLoading) {
    return <AmazonSkeleton />;
  }
  return (
    <>
      <Header>クラウド解錠テスト</Header>
      <Main>
        <div onClick={(e) => handleUnlockTestClick(e, "dearisEntrance")}>
          <MemoEntranceList devices={entrances} />
        </div>
        <div onClick={(e) => handleUnlockTestClick(e, "dearisElevator")}>
          <MemoElevatorList devices={elevators} />
        </div>
      </Main>
      <div className="px-4 pb-3">
        <Button onClick={() => navigate(-1)}>設定リストに戻る</Button>
      </div>
      <Footer />
      <EntranceUnlockModal visible={entranceVisbile} toggle={entranceToggle} />
      <ElevatorUnlockModal visible={elevatorVisbile} toggle={elevatorToggle} />
    </>
  );
}
