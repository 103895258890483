import { cx } from "@emotion/css";
import type { FC, ReactElement } from "react";

import HeadCountIcon from "@/assets/headcount.svg?react";
import HouseIcon from "@/assets/house.svg?react";
import NumberOfIcon from "@/assets/numberOf.svg?react";
import PlacePinIcon from "@/assets/place-pin.svg?react";
import { Card } from "@/components";
import type { Property } from "@/types";

interface IPatmoBuildingCardProps {
  className: string;
  titleLighted?: boolean;
  property: Property;
  onClick: () => void;
}
export default function PatmoBuildingCard({
  className,
  property,
  titleLighted,
  onClick,
}: Readonly<IPatmoBuildingCardProps>) {
  const addressofBuildings = property?.buildings?.filter(
    (building) =>
      "address" in building &&
      building?.address &&
      "full_address" in building.address
  ) as Array<{ address: { full_address: string } }>;

  const addressStrings = addressofBuildings?.map(
    (building) => building.address.full_address
  );
  // console.log("prop", property)
  // console.log("building", building)
  return (
    <Card className={cx("my-3", className)} onClick={onClick}>
      <p
        className={cx(
          "text-lg font-semibold text-neutral-800",
          "mb-1.5",
          titleLighted && "text-primary"
        )}
      >
        {property.name}
      </p>
      <PatmoBuildingCardItem
        attributeName="仮称名称"
        icon={<HouseIcon className="mb-[2px] mr-[10px] inline" />}
        content={property?.alias}
      />
      <PatmoBuildingCardItem
        attributeName="地番"
        icon={<NumberOfIcon className="mb-[2px] mr-[10px] inline" />}
        content={property.address?.full_address}
      />
      <PatmoBuildingCardItem
        attributeName="住所"
        icon={<PlacePinIcon className="mb-[2px] mr-[10px] inline" />}
        content={addressStrings}
      />
      <PatmoBuildingCardItem
        attributeName="戸数"
        icon={<HeadCountIcon className="mb-[2px] mr-[10px] inline" />}
        // content={property?.household}
        content={property?.buildings
          ?.map((building) =>
            building.actual_households !== undefined
              ? building.actual_households
              : 0
          )
          .reduce((a, b) => a + b, 0)}
      />
    </Card>
  );
}

interface IPatmoBuildingCardItemProps {
  attributeName: string;
  icon: ReactElement;
  content?: Array<string> | string | undefined | number;
}
const PatmoBuildingCardItem: FC<IPatmoBuildingCardItemProps> = ({
  attributeName,
  content,
  icon,
}) => {
  return (
    <div className={cx("flex items-start", "text-sm text-neutral-600")}>
      <span className="shrink-0">
        {icon}
        {attributeName}：
      </span>
      {!Array.isArray(content) && (
        <span className="hyphens-auto">{content || "-"}</span>
      )}
      {Array.isArray(content) && (
        <div>
          {content?.map((item, index) => (
            <span className="block hyphens-auto" key={index}>
              {item || "-"}
            </span>
          ))}
          {content?.length ? <></> : "-"}
        </div>
      )}
    </div>
  );
};
