import { useNavigate } from "react-router-dom";

import { OverlayLoading } from "@/components";
import { useUpdateUnlockTest } from "@/queries";
import { errorToast } from "@/shared";

export const SkipTestBTN = () => {
  const navigate = useNavigate();
  const {
    mutateAsync: updateControllerTestPassed,
    isLoading: isUpdateUnlockLoading,
  } = useUpdateUnlockTest();
  const skipTest = async () => {
    updateControllerTestPassed()
      .then(() => {
        navigate("/status");
      })
      .catch((error) => {
        if (error?.status && error?.status === 400) {
          // TODO 后端这里需要处理好
          errorToast("設定状態が変更されました。");
        } else {
          errorToast(error?.message ?? error?.toString());
        }
      });
  };
  return (
    <>
      <button className="mt-5 text-primary" onClick={skipTest}>
        テストをスキップ
      </button>
      {isUpdateUnlockLoading && <OverlayLoading visible />}
    </>
  );
};
