import { cx } from "@emotion/css";
import type { FC, PropsWithChildren } from "react";

import ReturnIcon from "@/assets/arrow-right-600.svg?react";

const Header: FC<
  PropsWithChildren & {
    canBack?: boolean;
    backText?: string;
    onClick?: () => void;
  }
> = ({ children, canBack = false, backText = "", onClick }) => {
  return (
    <div
      className={cx(
        "relative h-14",
        "flex items-center justify-between",
        "z-10 bg-white shadow-card"
      )}
    >
      {canBack && (
        <div className="absolute text-sm font-semibold" onClick={onClick}>
          <ReturnIcon className="mb-0.5 ml-4 mr-0.5 inline h-4 w-4" />
          <span>{backText}</span>
        </div>
      )}
      <span></span>
      <span className={cx("text-center text-lg font-semibold text-text-black")}>
        {children}
      </span>
      <span></span>
    </div>
  );
};

export default Header;
