import { useMutation } from "@tanstack/react-query";

import { NECESSARY_INFO_LACKING_HINT } from "@/shared";
import { getDevices, registerDevice } from "@/shared/requests/device";
import { getStorage } from "@/shared/store";

import {
  IMEIStatusError,
  NecessaryInfoLackingError,
  SNIMEINotMatchError,
} from "./errors.type";

export function useCreateLite() {
  const mutateFn = async () => {
    const sn = getStorage("serialNumber");
    const imei = getStorage("imei");
    const device_type = getStorage("deviceType");
    if (!sn || !imei || !device_type) {
      throw new NecessaryInfoLackingError(NECESSARY_INFO_LACKING_HINT);
    }
    const devices = await getDevices({ device_type, sn });

    const theDevice = devices?.data?.devices?.find(
      (device) => device.sn === sn
    );
    if (theDevice) {
      if (theDevice?.imei !== imei) {
        throw new SNIMEINotMatchError(
          "番号が一致しません。機器情報をご確認ください。"
        );
      } else {
        return theDevice;
      }
    } else {
      try {
        return (await registerDevice({ device_type, sn, imei })).data;
      } catch (e: any) {
        if ((e?.data?.msg as string).includes("failed to get line info")) {
          throw new IMEIStatusError();
        } else {
          throw e;
        }
      }
    }
  };
  return useMutation(mutateFn);
}
