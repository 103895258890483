// 随机数字
export function randomNum(m: number, n: number) {
  return Math.floor(Math.random() * (n - m + 1) + m);
}

// 随机颜色
export function randomColor() {
  return `rgb(${randomNum(0, 255)}, ${randomNum(0, 255)}, ${randomNum(
    0,
    255
  )})`;
}

export function isFunction(value: any) {
  return typeof value === "function";
}

export const originalCharacter = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];
