import { Footer, Header, Main, PlainSkeleton } from "@/components";

export function AmazonSkeleton() {
  return (
    <>
      <Header>クラウド解錠テスト</Header>
      <Main>
        <PlainSkeleton />
      </Main>
      <Footer />
    </>
  );
}
