import { useMutation, useQuery } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";

import server from "@/shared/requests/server";

import { IMAGE_QUERY_KEY } from "./queryKey";

export function useAuthImg() {
  const mutationFn = async (unAuthUri: string) => {
    return authImg(unAuthUri);
  };
  return useMutation(mutationFn);
}

export function useAuthImgAuto(unAuthUri: string) {
  const queryFn = async () => {
    return authImg(unAuthUri);
  };
  return useQuery({
    queryKey: [IMAGE_QUERY_KEY, unAuthUri],
    queryFn,
    enabled: !!unAuthUri,
    staleTime: 1000 * 60 * 20,
  });
}

const authImg = (uri: string) => {
  return server.post<unknown, AxiosResponse<{ uri: string }>>(
    "/file/pre_sign",
    { uri }
  );
};
