import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { STORE_USERNAME_KEY } from "@/app.constant";
import {
  Button,
  type canvasRefProps,
  Captcha,
  Footer,
  PabbitLogo,
} from "@/components";
import { Input, InputWithClear } from "@/components/form";
import { useFormErrorsToast, useVisibilityToggle } from "@/hooks";
import { usePreSignIn } from "@/queries";
import { errorToast } from "@/shared";

import Consent from "./components/Consent";

interface LoginForm {
  phone: string;
  captcha: string;
}

const Login = () => {
  const { watch, register, setValue, getValues, handleSubmit } =
    useForm<LoginForm>();
  const navigate = useNavigate();
  const { mutateAsync: preSignIn, isLoading: isPreSignInLoading } =
    usePreSignIn();
  const captchaRef = useRef<canvasRefProps>(null);
  const [code, setCode] = useState("");

  const { toastFormValueError } = useFormErrorsToast();
  const [isAllFieldFilled] = useVisibilityToggle(
    watch("phone"),
    watch("captcha")
  );

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cleaned = e.target.value.replace(/\D/g, "").substring(0, 11);
    setValue("phone", cleaned);
  };

  const handleVarifyCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cleaned = e.target.value.replace(/\W/g, "").substring(0, 4);
    setValue("captcha", cleaned);
  };

  const handleLogin = async (data: LoginForm) => {
    const { phone } = data;
    window.localStorage.setItem(STORE_USERNAME_KEY, phone);
    preSignIn({ phone })
      .then(() => navigate("/login-confirm"))
      .catch((error: Error) => {
        errorToast(error.message ?? error.toString());
      });
  };

  return (
    <div className="contents">
      <div className="flex flex-1 flex-col px-8 py-12">
        <PabbitLogo />
        <form className="relative mt-4 flex flex-col gap-5">
          <InputWithClear
            styleType="shadow"
            register={register("phone", {
              onChange: handlePhoneChange,
              required: "電話番号の入力が必須です",
              pattern: {
                value: /^\d{11}$/gm,
                message: "11桁の番号を入力してください",
              },
            })}
            setValue={setValue}
            getValues={getValues}
            placeholder="電話番号を入力してください"
          />
          <div className="relative">
            <Input
              styleType="shadow"
              register={register("captcha", {
                onChange: handleVarifyCodeChange,
                required: "認証文字の入力が必須です",
                validate(value) {
                  if (value.toLowerCase() !== code.toLowerCase()) {
                    return "認証文字のエラー";
                  }
                },
              })}
              helper={
                <div className="mt-2 text-[15px] text-text-gray">
                  {"右側表示されている文字を入力してください"}
                  <br />
                  {
                    "画像の数字がはっきり見えない時は、画像をタップして表示を更新します。"
                  }
                </div>
              }
              type="number"
              pattern="[0-9]*"
            />
            <Captcha
              className="absolute right-1 top-3"
              ref={captchaRef}
              charNum={4}
              onChange={setCode}
              onClick={captchaRef.current?.refresh}
            />
          </div>
        </form>
        <div className="mt-7">
          <Button
            className="w-full"
            size="large"
            loading={isPreSignInLoading}
            onClick={handleSubmit(handleLogin, toastFormValueError)}
            disabled={!isAllFieldFilled}
          >
            次へ
          </Button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default function LoginWrapper() {
  const consentFromStorage =
    window.localStorage.getItem("pabbit_configurator_user_consent_confirm") ||
    "false";
  const isConsent = consentFromStorage?.toLowerCase() !== "false";
  const [isUserConsent, setIsUserConsent] = useState(isConsent);
  if (!isUserConsent) {
    return <Consent setIsUserConsent={setIsUserConsent} />;
  } else return <Login />;
}
