import { cx } from "@emotion/css";

import RefreshIcon from "@/assets/refresh.svg?react";

interface DeliveriesCardProps {
  className?: string;
  children?: React.ReactNode[] | React.ReactNode;
  refresh: () => void;
  isFreshing: boolean;
}

export const DeliveriesCard = ({ className, children, refresh, isFreshing }: Readonly<DeliveriesCardProps>) => {
  return (
    <div
      className={cx(
        "max-h-52 overflow-auto rounded-md bg-bgGray",
        "divide-y divide-solid divide-neutral-100",
        "relative my-4 py-[14px] pl-[16.5px] pr-[23.5px] pt-4",
        "text-neutral-800",
        className
      )}
    >
      {children}
      <RefreshIcon
        className={cx("absolute right-5 top-[17px] cursor-pointer !border-0", {
          "animate-spin": isFreshing,
        })}
        onClick={() => refresh()}
      />
    </div>
  );
};

interface DeliveryItemProps {
  className?: string;
  children?: string;
  testable?: boolean;
}
export const DeliveryItem = ({ className, children, testable }: Readonly<DeliveryItemProps>) => {
  return (
    <div className={cx("border-0 pb-[9px] pt-3 text-[15px] font-medium", className)}>
      {children}
      {testable ? <></> : <span>&nbsp;&nbsp;&nbsp;&nbsp;※</span>}
    </div>
  );
};
