import { cx } from "@emotion/css";
import type { FC, InputHTMLAttributes, ReactNode } from "react";
import type { UseFormRegisterReturn } from "react-hook-form";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  register: UseFormRegisterReturn<any>;
  helper?: ReactNode;
  styleType?: "shadow" | "outline";
}
export const Input: FC<InputProps> = ({ register, className, helper, styleType = "outline", ...props }) => {
  return (
    <div className="flex flex-col">
      <input
        className={cx([
          "form-input rounded-lg  px-3 pb-[10px] pt-[13px] text-[15px] leading-[23px] outline-0",
          "placeholder:text-text-gray",
          "focus:border-transparent focus:ring-primary",
          {
            "border-none  bg-[#F2F4F5] outline-gray-500": styleType === "shadow",
            "border-solid border-[#E3E5E5]": styleType === "outline",
          },
          props.disabled || "pr-8",
          props.disabled && "bg-[#F7F9FA]",
          className,
        ])}
        style={{ boxShadow: "0 0 #0000" }}
        {...props}
        {...register}
      />

      {helper && <div className="mt-2 text-[15px] text-text-gray">{helper}</div>}
    </div>
  );
};
