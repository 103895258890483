import { useEffect, useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { prefectureOptions } from "@/app.constant";
import { Button, Card, Hint } from "@/components";
import { InputWithClear, SelectInput } from "@/components/form";
import { errorToast } from "@/shared";
import { getStorage, setStorage } from "@/shared/store";

interface AddressForm {
  prefecture_name: string;
  city_name: string;
  rest_address: string;
}
export const SearchByAddress = () => {
  const { watch, register, handleSubmit, setValue, getValues } =
    useForm<AddressForm>({
      defaultValues: {
        prefecture_name: "",
        city_name: "",
        rest_address: "",
      },
    });
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(true);

  const search = async (values: AddressForm) => {
    const { prefecture_name, city_name, rest_address } = values;
    if (!prefecture_name && !city_name && !rest_address) {
      errorToast("内容を選択または入力してください");
      return;
    }

    setStorage("searchKeywordByAddressObj", {
      isfilled: true,
      prefecture_name,
      city_name,
      rest_address,
    });

    navigate(
      `/configure/results?prefecture=${prefecture_name}&city=${city_name}&address=${rest_address}`
    );
  };

  useLayoutEffect(() => {
    const searchKeywordByAddressObj = getStorage(
      "searchKeywordByAddressObj"
    ) as {
      isfilled: boolean;
      prefecture_name: string;
      city_name: string;
      rest_address: string;
    };
    if (searchKeywordByAddressObj?.isfilled) {
      const { city_name, prefecture_name, rest_address } =
        searchKeywordByAddressObj;
      setValue("city_name", city_name);
      setValue("prefecture_name", prefecture_name);
      setValue("rest_address", rest_address);
    }
  }, []);

  useEffect(() => {
    const { city_name, prefecture_name, rest_address } = getValues();
    if (city_name || prefecture_name || rest_address) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [watch("city_name"), watch("prefecture_name"), watch("rest_address")]);

  return (
    <form onSubmit={handleSubmit(search)}>
      <Card className="flex flex-col gap-4">
        <SelectInput
          register={register("prefecture_name")}
          setValue={setValue}
          placeholder="都道府県"
          options={prefectureOptions}
        />
        <InputWithClear
          register={register("city_name")}
          setValue={setValue}
          getValues={getValues}
          placeholder="市区町村"
        />
        <InputWithClear
          register={register("rest_address")}
          setValue={setValue}
          getValues={getValues}
          placeholder="丁目番地号"
        />
        <div className="mb-1">
          <Button className="w-full" nativeType="submit" disabled={isDisabled}>
            検索
          </Button>
        </div>
        <Hint className="text-xs leading-5">
          丁目番地号のおすすめの入力形式：一丁目2-2
          <br />
          地番情報も検索できます
        </Hint>
      </Card>
    </form>
  );
};
