import Logo from "@/assets/normal_u643.png";

export function PabbitLogo() {
  return (
    <div className="flex items-center">
      <img src={Logo} className="h-8 w-8 mr-4" />
      <div className="text-sm font-bold">
        <p className="">Pabbit Entrance</p>
        <p className="">設定ツール</p>
      </div>
    </div>
  );
}
