import { cx } from "@emotion/css";

import EntranceSpotExampleImg from "@/assets/entrance-example.png";
import EntranceNgEmOne from "@/assets/entrance-ng-shadow-1.png";
import EntranceNgEmTwo from "@/assets/entrance-ng-shadow-2.png";
import EntranceNgEmThree from "@/assets/entrance-ng-tool-1.png";
import EntranceNgEmFour from "@/assets/entrance-ng-tool-2.png";

export const XiezhenCaption = () => {
  return (
    <>
      <p className="mb-5 mt-10 text-center font-semibold">入口の写真について</p>
      <div className="flex flex-col gap-5 text-sm leading-6">
        <p>
          できるだけ正面から撮影してください。手ブレや
          人の映り込みに注意してください
        </p>
        <div>
          <TinyBar />
          OK例：正面から撮影し、どのドアかがわかりやすい
          <Image src={EntranceSpotExampleImg} alt="" className="mt-[10px]" />
        </div>

        <div>
          <TinyBar />
          NG例：ぶれている、人が映り込んでいる
          <div className="mt-2 flex gap-2">
            <Image src={EntranceNgEmOne} alt="" />
            <Image src={EntranceNgEmTwo} alt="" />
          </div>
        </div>

        <div>
          <TinyBar />
          NG例：脚立や工具が映り込んでいる、ドアが開いている
          <div className="mt-2 flex gap-2">
            <Image src={EntranceNgEmThree} alt="" />
            <Image src={EntranceNgEmFour} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

const TinyBar = () => (
  <span className="margin-y-auto mr-1 inline-block h-3 w-[3px] bg-primary"></span>
);

interface ImageProps {
  className?: string;
}
const Image = ({
  className,
  ...props
}: ImageProps & React.ImgHTMLAttributes<HTMLImageElement>) => {
  return (
    <div className="flex-1">
      <img {...props} className={cx("left-0 top-0 w-full", className)} />
    </div>
  );
};
