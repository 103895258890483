import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import { Button, Card, Footer, Header, Main } from "@/components";
import { useGetScannerData } from "@/hooks";
import { useCreateController } from "@/queries";
import { errorToast, REGISTER_DEVICE_FAILED_HINT } from "@/shared";
import { getStorage, setStorage } from "@/shared/store";

import { DataItem, Warning } from "../components";

export default function () {
  const isIMEIReplace = getStorage("DearisReplaceIMEI");
  if (isIMEIReplace) {
    return <Navigate to="/imei-replace/status" />;
  } else {
    return <DearisControllerDateConfirm />;
  }
}

export const DearisControllerDateConfirm = () => {
  const navigate = useNavigate();
  const [serialNumber, setSerialNumber] = useState("");
  const [buildId, setBuildId] = useState<number>();
  const [gateNumber, setGateNumber] = useState<number>();

  const { mutateAsync: addController, isLoading } = useCreateController();

  const hanldeDataConfirmClick = () => {
    addController()
      .then((data) => {
        setStorage("deviceId", data.data.id);
        navigate("/status");
      })
      .catch((e) => {
        if (e?.status === 400) {
          errorToast(REGISTER_DEVICE_FAILED_HINT);
        } else {
          errorToast(e?.message ?? e.toString());
        }
      });
  };

  useGetScannerData();
  useEffect(() => {
    setSerialNumber(getStorage("serialNumber"));
    setBuildId(getStorage("buildIdController"));
    setGateNumber(getStorage("gateNumberController"));
    return () => {
      setStorage("buildIdController", undefined);
      setStorage("gateNumberController", undefined);
    };
  }, []);

  return (
    <>
      <Header>デバイス情報の確認</Header>
      <Main>
        <Card>
          <Warning>
            ここに表示されている情報がデバイス側の情報と一致していることを確認した後、確認を押して次のステップに進んでください。
          </Warning>
          <DataItem
            label="シリアル番号"
            content={serialNumber}
            className="mt-10"
          />
          {buildId !== undefined && (
            <DataItem label="棟番号" content={buildId} className="mt-5" />
          )}
          {gateNumber !== undefined && (
            <DataItem
              label="ゲート番号"
              content={gateNumber}
              className="mt-5"
            />
          )}
          <div className="mb-6 mt-10">
            <Button onClick={hanldeDataConfirmClick} loading={isLoading}>
              確認
            </Button>
          </div>
        </Card>
      </Main>
      <Footer />
    </>
  );
};
