import { cx } from "@emotion/css";
import React, { useEffect } from "react";

import { Button, Popup, PopupAction, PopupMain } from "@/components";
import { useAmazonUnlockTest, useUpdateCloudUnlock } from "@/queries";
import { errorToast } from "@/shared";
import { getStorage, setStorage } from "@/shared/store";

export const GateUnlockModal = React.memo(function ({
  visible,
  toggle,
}: {
  visible: boolean;
  toggle: () => void;
}) {
  setStorage("roomNumber", undefined);
  const deviceId = getStorage("tobeUnlockId");
  const [isUnlockSended, setIsUnlockSended] = React.useState(false);

  const { mutateAsync: unlockDevice, isLoading: isUnlocking } =
    useAmazonUnlockTest();
  const { mutateAsync: updateUnlockTest, isLoading } =
    useUpdateCloudUnlock("dearisGate");
  const handleUnlockClick = async () => {
    await unlockDevice(deviceId)
      .then(() => {
        setIsUnlockSended(true);
      })
      .catch((e) => {
        if (e?.status === 400 || e?.status === 410) {
          errorToast("エラーが返されました、解錠できません。");
        } else {
          errorToast(e?.message ?? e.toString());
        }
      });
  };

  const handleComfirmUnlock = () => {
    setStorage("cloudUnlockTest", true);
    updateUnlockTest(deviceId)
      .then(() => {
        toggle();
      })
      .catch((error) => {
        if (error?.status === 400) {
          errorToast("ロック解錠に失敗しました。");
        } else {
          errorToast(error?.message ?? error.toString());
        }
      });
  };

  const handleUnlockFailedClick = () => {
    setStorage("cloudUnlockTest", false);
    updateUnlockTest(deviceId)
      .then(() => {
        toggle();
      })
      .catch(
        (e) => e.status === 400 && errorToast("ロック解錠に失敗しました。")
      );
  };

  useEffect(() => {
    return () => {
      !visible && setIsUnlockSended(false);
    };
  }, [visible]);

  if (isUnlockSended) {
    return (
      <Popup
        visible={visible}
        closeable
        onCloseIconClick={() => {
          visible && toggle();
        }}
      >
        <PopupMain className="text-base">
          ドアロックが解錠されましたか？
        </PopupMain>
        <PopupAction className={cx(isLoading && "opacity-20")}>
          <Button onClick={handleComfirmUnlock}>解錠されました</Button>
          <div
            className="mt-2 text-center text-sm text-primary"
            onClick={handleUnlockFailedClick}
          >
            解錠できませんでした
          </div>
        </PopupAction>
      </Popup>
    );
  }

  return (
    <Popup
      visible={visible}
      closeable
      onCloseIconClick={() => {
        visible && toggle();
      }}
    >
      <PopupMain className="text-base">解錠してもよろしいですか？</PopupMain>
      <PopupAction>
        <div className="flex gap-2">
          <div className="flex-1">
            <Button onClick={toggle} type="default">
              いいえ
            </Button>
          </div>
          <div className="flex-1">
            <Button onClick={handleUnlockClick} loading={isUnlocking}>
              はい
            </Button>
          </div>
        </div>
      </PopupAction>
    </Popup>
  );
});
