import { useLayoutEffect } from "react";
import { type FieldErrors, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Button, Card } from "@/components";
import { InputWithClear } from "@/components/form";
import { useFormErrorsToast, useVisibilityToggle } from "@/hooks";
import { getStorage, setStorage } from "@/shared/store";

export const SearchByName = () => {
  const { watch, register, setValue, getValues, handleSubmit } = useForm();
  const navigate = useNavigate();
  const [isFieldFilled] = useVisibilityToggle(watch("objectName"));
  const { toastFormValueError } = useFormErrorsToast();

  const handleSearchKeywordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const cleaned = e.target.value;
    setValue("objectName", cleaned);
  };

  useLayoutEffect(() => {
    const searchKeywordByName = getStorage("searchKeywordByName");
    searchKeywordByName && setValue("objectName", searchKeywordByName);
  }, []);

  const search = async () => {
    const objectName = getValues("objectName").trim();
    setStorage("searchKeywordByName", objectName);
    navigate(`/configure/results?name=${objectName}`);
  };

  const handleValueInvaild = (errors: FieldErrors) => {
    toastFormValueError(errors);
  };

  return (
    <>
      <Card className="flex flex-col gap-4">
        <InputWithClear
          register={register("objectName", {
            required: "物件名の入力が必須です",
            onChange: handleSearchKeywordChange,
          })}
          setValue={setValue}
          getValues={getValues}
          placeholder="物件名を入力してください"
        />

        <div className="mt-4">
          <Button
            className="w-full"
            onClick={handleSubmit(search, handleValueInvaild)}
            disabled={!isFieldFilled}
          >
            検索
          </Button>
        </div>
      </Card>
      <p className="mt-3 text-xs text-[#909899]">
        <span className="text-error">※</span>
        該当する物件が検索されない場合はオーナー又は管理会社にてPabbitの利用申込が行われておりません。
        <br />
        Pabbitをご利用する場合はオーナー又は管理会社にPabbitの利用申込を行ってもらってください。
      </p>
    </>
  );
};
