import "./button.css";

import { cx } from "@emotion/css";
import { Button as NutuiButton } from "@nutui/nutui-react";
import type { FC, PropsWithChildren } from "react";
import React, { useMemo } from "react";

import type { ButtonProps  } from "./Button";


/**
 * Primary UI component for user interaction
 */
export const PopupButton: FC<PropsWithChildren<ButtonProps>> = ({
  size = "normal",
  type = "primary",
  className,
  children,
  disabled,
  shape = "square",
  ...props
}) => {
  const buttonColor = useMemo(() => {
    switch (type) {
      case "primary":
        return "#00AFCC";
      default:
        return "";
    }
  }, [type]);
  return (
    <NutuiButton
      size={size}
      shape={shape}
      className={cx("!rounded-md w-[124px]", className)}
      type={disabled ? "info" : type}
      disabled={disabled}
      color={buttonColor}
      {...props}
    >
      {children}
    </NutuiButton>
  );
};
