import { useHandleDeviceUnknown } from "@/hooks";
import { getStorage } from "@/shared/store";
import type { DeviceType } from "@/types";

import {
  DearisControllerStatus,
  LiteStatus,
  PATMOStatus,
} from "./status-checking";

export default function () {
  const device_type = getStorage("deviceType") as DeviceType;
  const { toastAndToSelectDevice } = useHandleDeviceUnknown();
  switch (device_type) {
    case "PabbitLite":
      return <LiteStatus />;
    case "PATMO":
      return <PATMOStatus />;
    case "dearisController":
      return <DearisControllerStatus />;
    default: {
      toastAndToSelectDevice();
    }
  }
}
