import { useMutation, useQueryClient } from "@tanstack/react-query";

import { NECESSARY_INFO_LACKING_HINT } from "@/shared";
import {
  updateUnlockTest,
  type UpdateUnlockTestData,
} from "@/shared/requests/property";
import { getStorage } from "@/shared/store";
import type { DeviceType } from "@/types";

import { NecessaryInfoLackingError } from "./errors.type";
import { ELEVATOR_QUERY_KEY, ENTRANCESE_QUERY_KEY } from "./queryKey";

/**
 * @param device_type
 * 这个参数只在给 entrance 和 elevator 解锁的时候会用, 因为此时存在store的type是controller, 技术负债问题
 * @returns
 */
export function useUpdateUnlockTest(
  device_type: DeviceType = getStorage("deviceType") as DeviceType
) {
  const queryClient = useQueryClient();
  /**
   * @param device_id
   * 同上
   * @returns
   */
  const mutateFn = (device_id = getStorage("deviceId")) => {
    const property_id = getStorage("propertyId");
    if (!device_id || !property_id)
      throw new NecessaryInfoLackingError(NECESSARY_INFO_LACKING_HINT);
    const data: UpdateUnlockTestData = {
      device_id,
      property_id,
      test_passed: true,
    };
    return updateUnlockTest(data);
  };
  return useMutation(mutateFn, {
    onSuccess: () => {
      device_type === "dearisEntrance" &&
        queryClient.invalidateQueries({ queryKey: [ENTRANCESE_QUERY_KEY] });
      device_type === "dearisElevator" &&
        queryClient.invalidateQueries({ queryKey: [ELEVATOR_QUERY_KEY] });
    },
  });
}
