import type { FC } from "react";
import { useNavigate } from "react-router-dom";

import { BuildingCard, Button, Footer, Header, Main } from "@/components";
import type { Building, DeviceType, Property } from "@/types";

interface Props {
  type: DeviceType;
  buildingOrProperty: Building | Property;
}

export const BusinessObjectResultWrapper: FC<Props> = ({
  type,
  buildingOrProperty,
}) => {
  return (
    <BindedObjectResult type={type} buildingOrProperty={buildingOrProperty} />
  );
};

const BindedObjectResult: FC<Props> = ({ type, buildingOrProperty }) => {
  const navigate = useNavigate();
  return (
    <>
      <Header>物件設定</Header>
      <Main>
        <BuildingCard type={type} buildingOrProperty={buildingOrProperty} />
        <div className="mt-5 px-4">
          <Button onClick={() => navigate(-1)} className="w-full">
            戻る
          </Button>
        </div>
      </Main>
      <Footer />
    </>
  );
};
