import { useMutation } from "@tanstack/react-query";

import { signUp } from "@/shared/requests/auth";

export function usePreSignIn() {
  const mutationFn = async ({ phone }: { phone: string }) => {
    try {
      await signUp({ phone });
    } catch (error: any) {
      if (error?.status === 400 || error?.status === 500) {
        return Promise.resolve();
      } else {
        return Promise.reject(error);
      }
    }
  };
  return useMutation(mutationFn);
}
