import { useNavigate } from "react-router-dom";

import { Button, Card, Footer, Header, Main, PlainSkeleton } from "@/components";
import { useFetchDataError } from "@/hooks";
import { useCheckLineStatusByDeviceId } from "@/queries";
import { getStorage } from "@/shared/store";

import { StatusTag } from "../components";

export const LiteOpen = () => {
  const navigate = useNavigate();
  const imei = getStorage("imei");
  const { data, isLoading, isError, error } = useCheckLineStatusByDeviceId();
  useFetchDataError(isError, error);
  const status = data?.data?.status;
  const cantReplace = status === "dead";

  if (isLoading) {
    return (
      <>
        <Header>登録した情報</Header>
        <Main>
          <Card>
            <PlainSkeleton />
          </Card>
        </Main>
        <Footer />
      </>
    );
  }
  return (
    <>
      <Header>登録した情報</Header>
      <Main>
        <Card>
          <header className="text-neutral-600">IMEI番号</header>
          <div className="flex justify-between">
            <span>{imei}</span>
            <StatusTag status={status} />
          </div>
        </Card>
        {cantReplace && <p className="mt-2  text-xs text-error">※&nbsp;このIMEI番号はすでに解約されました</p>}
        {cantReplace ? (
          <div className="mt-10 px-4">
            <Button onClick={() => navigate("/status")}>戻る</Button>
          </div>
        ) : (
          <div>
            <div className="mt-8 px-4">
              <Button onClick={() => navigate("/register-line")} disabled={isError}>
                回線を開通
              </Button>
            </div>
          </div>
        )}
      </Main>
      <Footer />
    </>
  );
};
