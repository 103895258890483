import { type Dispatch, type SetStateAction, useState } from "react";
import { useEffectOnce } from "react-use";

import { useLineOpen } from "@/queries";
import { successToast } from "@/shared";
import { getStorage } from "@/shared/store";
import type { DeviceType } from "@/types";

import RegisterFailed from "./RegisterFailed";
import RegisterProgressing from "./RegisterProgressing";
import RegisterLineSuccessWrapper from "./success";
// import RegisterWarning from "./RegisterWarning";

export const RegisterLine = ({
  setKey,
}: {
  setKey: Dispatch<SetStateAction<number>>;
}) => {
  const {
    mutate: openLine,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useLineOpen();
  useEffectOnce(() => {
    if (!isLoading) {
      openLine();
    }
  });

  if (isLoading) {
    return <RegisterProgressing />;
  } else if (isError) {
    if (error) {
      // TODO situation (if the device is unbound, show the warning), not completed on the backend, now every deviceId gets a success response.
      // return <RegisterWarning />;
    }
    return <RegisterFailed setKey={setKey} />;
  } else if (isSuccess) {
    const deviceType = getStorage("deviceType") as DeviceType;
    // TODO 检查表现否正确
    deviceType === "PATMO" && successToast("開通に成功しました");
    return <RegisterLineSuccessWrapper />;
  }
  return null;
};

const RegisterLineWrapper = () => {
  const [key, setKey] = useState(0);
  return <RegisterLine key={key} setKey={setKey} />;
};

export default RegisterLineWrapper;
