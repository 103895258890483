import { useMutation, useQueryClient } from "@tanstack/react-query";

import { NECESSARY_INFO_LACKING_HINT } from "@/shared";
import { attachDeviceToProperty } from "@/shared/requests/property";
import type { AttachDeviceData } from "@/shared/requests/property.type";
import { getStorage } from "@/shared/store";

import { NecessaryInfoLackingError } from "./errors.type";
import { DEVICE_QUERY_KEY, PROPERTY_QUERY_KEY } from "./queryKey";

export function useUpdateDearisPositionMulti() {
  const queryClient = useQueryClient();

  const mutateFn = async (buildings: number[] = []) => {
    // 获取devices信息
    const property_id = getStorage("propertyId");
    const gate_number = getStorage("gateNumber");

    if (!property_id || !gate_number) {
      throw new NecessaryInfoLackingError(NECESSARY_INFO_LACKING_HINT);
    }

    const sn = getStorage("serialNumber");
    const spot = getStorage("spotName");
    const image_url = getStorage("uri") || import.meta.env.VITE_XIEZHEN_DEFAULT_URI;
    if (!property_id || !sn || !spot || !image_url) {
      throw new NecessaryInfoLackingError(NECESSARY_INFO_LACKING_HINT);
    }
    const data: AttachDeviceData = {
      property_id,
      sn,
      device_type: "dearisGate",
      positions: [
        ...(buildings?.map((item) => ({
          build_id: item,
          gate_number,
          image_url,
          spot,
        })) ?? []),
      ],
      setup_by: "manual",
    };
    return attachDeviceToProperty(data);
  };
  return useMutation(mutateFn, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [DEVICE_QUERY_KEY, PROPERTY_QUERY_KEY],
      });
    },
  });
}
