import { useMutation } from "@tanstack/react-query";

import { NECESSARY_INFO_LACKING_HINT } from "@/shared";
import { attachDeviceToProperty } from "@/shared/requests/property";
import { getStorage } from "@/shared/store";
import type { DeviceType } from "@/types";

import { NecessaryInfoLackingError } from "./errors.type";

export function useCreateDearis() {
  const mutationFn = async () => {
    const device_type = getStorage("deviceType") as DeviceType;
    const xiezhenDefaultUri =
      device_type === "dearisElevator"
        ? import.meta.env.VITE_XIEZHEN_DEFAULT_URI_ELEVATOR
        : import.meta.env.VITE_XIEZHEN_DEFAULT_URI;
    const sn = getStorage("serialNumber");
    const spot = getStorage("spotName");
    const build_id = getStorage("buildId");
    const gate_number = getStorage("gateNumber");
    const image_url = getStorage("uri") || xiezhenDefaultUri;
    const property_id = getStorage("propertyId");
    if (!device_type || !sn || build_id === undefined || gate_number === undefined || !image_url || !property_id) {
      throw new NecessaryInfoLackingError(NECESSARY_INFO_LACKING_HINT);
    }

    return attachDeviceToProperty({
      property_id,
      sn,
      device_type,
      positions: [
        {
          build_id,
          gate_number: parseInt(gate_number, 10),
          image_url,
          spot,
        },
      ],
      setup_by: "manual",
    });
  };
  return useMutation(mutationFn);
}
