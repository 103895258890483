import { getStorage } from "@/shared/store";
import type { DeviceType } from "@/types";

import {
  // AdapterManualRegister,
  DearisAdapterManualRegister,
  DearisControllerManualRegister,
  DearisEntranceManualRegister,
  LiteManualRegister,
  PATMOManualRegister,
} from "./manual-register";

export default function ManualRegisterWrapper() {
  const deviceType = getStorage("deviceType") as DeviceType;
  switch (deviceType) {
    case "PabbitLite":
      return <LiteManualRegister />;
    case "PATMO":
      return <PATMOManualRegister />;
    // case "adapter":
    //   return <AdapterManualRegister />;
    case "DearisAdapter":
      return <DearisAdapterManualRegister />;
    case "dearisController":
      return <DearisControllerManualRegister />;
    case "dearisEntrance":
      return <DearisEntranceManualRegister />;
  }
}
