import { useNavigate } from "react-router-dom";

import { Button, Footer, Header, Main } from "@/components";
import {
  Step,
  StepContainer,
  StepIndicator,
  StepParagraph,
  StepsGenerator,
} from "@/components/step";

import { dearisStepsData } from "./dearis-steps-data";

export const DearisHardware = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header>制御装置の設定</Header>
      <Main>
        <StepContainer>
          <StepsGenerator stepsData={dearisStepsData} />
          <Step>
            <StepParagraph>
              <StepIndicator>3</StepIndicator>
              {"リセットボタンを押してください。"}
            </StepParagraph>
            <Button onClick={() => navigate("/pre-setting")}>次へ</Button>
          </Step>
        </StepContainer>
      </Main>
      <Footer />
    </>
  );
};
