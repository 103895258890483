import { useHandleDeviceUnknown } from "@/hooks";
import { getStorage } from "@/shared/store";
import type { DeviceType } from "@/types";

import {
  DearisAdapterDataConfirm,
  DearisControllerDataConfirm,
  // AdapterDataConfirm,
  DearisDataConfirm,
  EntranceDataConfirm,
  LiteDataConfirm,
  PatmoDataConfirm,
} from "./data-confirm";

const DataConfirmWrapper = () => {
  const deviceType = getStorage("deviceType") as DeviceType;
  const { toastAndToSelectDevice } = useHandleDeviceUnknown();
  switch (deviceType) {
    // case "adapter":
    //   return <AdapterDataConfirm />;
    case "PATMO":
      return <PatmoDataConfirm />;
    case "PabbitLite":
      return <LiteDataConfirm />;
    case "dearis": // 需要更详细; Entrance 还是 Elevator
      return <DearisDataConfirm />;
    case "DearisAdapter":
      return <DearisAdapterDataConfirm />;
    case "dearisController":
      return <DearisControllerDataConfirm />;
    case "dearisEntrance":
      return <EntranceDataConfirm />;
    default: {
      return toastAndToSelectDevice();
    }
  }
};

export default DataConfirmWrapper;
