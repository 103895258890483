import type { InputHTMLAttributes } from "react";
import type {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";

import { InputWithClear } from "@/components/form";
import { OnlyNumberRegExp, removeUnexpectedChar } from "@/shared/regexps";
import { setStorage } from "@/shared/store";

interface BuildIdInputProps {
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
}
export function BuildIdInput({
  register,
  setValue,
  getValues,
  ...props
}: BuildIdInputProps & InputHTMLAttributes<HTMLInputElement>) {
  const handleInputBuildId = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cleaned = removeUnexpectedChar(e.target.value, OnlyNumberRegExp)
      .toString()
      .substring(0, 2);
    const numberValue = parseInt(cleaned, 10);
    setStorage("buildId", numberValue);
    setValue("buildId", numberValue);
  };
  return (
    <InputWithClear
      id="buildId"
      register={register("buildId", {
        onChange: handleInputBuildId,
        pattern: {
          value: /^[0-9]{1,2}$/gm,
          message: "棟番号の形式が正しくありません",
        },
      })}
      setValue={setValue}
      getValues={getValues}
      placeholder="棟番号を入力してください"
      type="number"
      pattern="[0-9]*"
      onWheel={(event) => event.currentTarget.blur()}
      {...props}
    />
  );
}
