import { useEffect, useState } from "react";

import { useFetchDataError } from "@/hooks";
import { useCheckDeviceStatus, useGetPropertyByDevice } from "@/queries";
import { errorToast, getLiteBindInfo } from "@/shared";
import { getStorage, setStorage } from "@/shared/store";
import type { Building, Device, DeviceMode, LineStatus } from "@/types";

type LiteStatus = {
  bindedProperty: string; // 是否绑定物件
  lineActived: LineStatus; // 回线开通状态: active, suspend, inactive
  positionedSpotName: number; // 是否设置了场所识别名和图片, 0: 未设置, 1: 设置场所名, 2: 设置场所名和图片
  isUnlockTestAllPassed?: boolean;
  mode?: DeviceMode;
  isSettingAllDone: boolean;
};

export function useGetLiteStatus() {
  const [deviceStatus, setDeviceStatus] = useState<LiteStatus>();
  const {
    data: deviceData,
    isLoading: isGetDeviceLoading,
    isSuccess: isGetDeviceSuccess,
    isError: isGetDeviceError,
    error: deviceError,
  } = useCheckDeviceStatus();
  useFetchDataError(isGetDeviceError, deviceError);

  const device = deviceData?.data;
  const {
    data: propertyData,
    isSuccess: isGetPropertySuccess,
    isError: isGetPropertyError,
  } = useGetPropertyByDevice(device);

  if (isGetPropertyError) {
    errorToast("物件情報の更新に失敗しました。");
  }

  useEffect(() => {
    if (isGetDeviceSuccess) {
      setDeviceStatus((prev) => {
        return {
          ...prev,
          bindedProperty: device?.property_name ?? "",
          lineActived: device?.line_status ?? "inactive",
          positionedSpotName:
            Number(!!device?.position?.spot) +
            Number(!!device?.position?.image_uri),
          isUnlockTestAllPassed: device?.position?.test_passed,
          mode: device?.mode,
          isSettingAllDone: calculateSettingAllDone(device),
        };
      });
      if (!device?.property_id) {
        setStorage("isDeviceBindedObject", false);
        setStorage("selectedBuildingOrProperty", undefined);
        setStorage("propertyId", "");
      }
    }
  }, [device]);

  useEffect(() => {
    if (isGetPropertySuccess && isGetDeviceSuccess) {
      if (device?.property_id) {
        const deviceId = getStorage("deviceId");
        const build_id =
          device?.position?.build_id ?? getLiteBindInfo(deviceId)?.build_id;
        const building = propertyData?.data?.buildings?.find(
          (bd) => bd.build_id === build_id
        );
        setStorage("isDeviceBindedObject", true);
        setStorage("selectedBuildingOrProperty", {
          ...building,
          property_name: propertyData.data.name,
          property_alias: propertyData.data.alias,
        } as Building);
        setStorage("propertyId", propertyData.data.id);
        setDeviceStatus((prev) => {
          return {
            ...prev,
            bindedProperty: propertyData.data.name ?? "",
          } as LiteStatus;
        });
      } else {
        setStorage("isDeviceBindedObject", false);
        setStorage("selectedBuildingOrProperty", undefined);
        setStorage("propertyId", "");
      }
    }
  }, [propertyData, deviceData]);

  return {
    deviceStatus,
    isLoading: isGetDeviceLoading,
    isSuccess: isGetDeviceSuccess || isGetPropertySuccess,
    isError: isGetDeviceError || isGetPropertyError,
  };
}

function calculateSettingAllDone(device?: Device): boolean {
  return (
    !!device?.property_id &&
    device.line_status === "active" &&
    !!device.position?.spot &&
    !!device.position?.image_uri &&
    !!device.position?.test_passed &&
    device.mode === "operational"
  );
}
