import { memo, useEffect, useState } from "react";

import { Button, Popup, PopupAction, PopupMain } from "@/components";
import { useAmazonUnlockTest, useReportTest } from "@/queries";
import {
  errorToast,
  UNKNOWN_DEVICE_HINT,
  UPDATE_UNLOCK_STATE_FAILED,
} from "@/shared";
import { getStorage } from "@/shared/store";
import type { DeviceType, ReportTestOption } from "@/types";

interface CloudUnlockTestModalProps {
  visible: boolean;
  toggle: () => void;
}
/**
 * @description Lite PATMO 使用这个组件进行解锁;
 */
export const CloudUnlockTestModal = memo(function ({
  visible,
  toggle,
}: CloudUnlockTestModalProps) {
  const deviceId = getStorage("tobeUnlockId");
  const [isUnlockSended, setIsUnlockSended] = useState(false);
  const { mutateAsync: unlockDevice, isLoading: isUnlocking } =
    useAmazonUnlockTest();
  const { mutateAsync: updatePatmoTestPassed } = useReportTest();
  const handleUnlockClick = async () => {
    await unlockDevice(deviceId)
      .then(() => {
        setIsUnlockSended(true);
      })
      .catch((error) => {
        if (error?.status === 400 || error?.status === 410) {
          errorToast("エラーが返されました、解錠できません。");
        } else {
          errorToast(error?.message ?? error.toString());
        }
      });
  };

  const handleUnlockSucceedClick = () => {
    const deviceType = getStorage("deviceType") as DeviceType;
    if (deviceType !== "PabbitLite" && deviceType !== "PATMO")
      throw new Error(UNKNOWN_DEVICE_HINT);
    const deviceStepMapper: Record<"PabbitLite" | "PATMO", ReportTestOption> = {
      PabbitLite: "3",
      PATMO: "2",
    };
    const step = deviceStepMapper[deviceType];
    updatePatmoTestPassed({ step })
      .catch((error) => {
        if (error.status === 400) {
          errorToast(UPDATE_UNLOCK_STATE_FAILED);
        } else {
          errorToast(error?.message ?? error.toString());
        }
      })
      .finally(() => {
        toggle();
      });
  };

  const handleUnlockFailedClick = () => {
    const deviceType = getStorage("deviceType") as DeviceType;
    if (deviceType !== "PabbitLite" && deviceType !== "PATMO")
      throw new Error(UNKNOWN_DEVICE_HINT);
    const deviceStepMapper: Record<"PabbitLite" | "PATMO", ReportTestOption> = {
      PabbitLite: "3",
      PATMO: "2",
    };
    const step = deviceStepMapper[deviceType];
    updatePatmoTestPassed({ step, failed: true })
      .catch((error) => {
        if (error.status === 400) {
          errorToast(UPDATE_UNLOCK_STATE_FAILED);
        } else {
          errorToast(error?.message ?? error.toString());
        }
      })
      .finally(() => {
        toggle();
      });
  };

  useEffect(() => {
    return () => {
      !visible && setIsUnlockSended(false);
    };
  }, [visible]);

  if (isUnlockSended) {
    return (
      <Popup
        visible={visible}
        closeable
        onCloseIconClick={() => {
          visible && toggle();
        }}
      >
        <PopupMain className="text-base">
          ドアロックが解錠されましたか？
        </PopupMain>
        <PopupAction>
          <Button onClick={handleUnlockSucceedClick}>解錠されました</Button>
          <div
            className="mt-2 text-center text-sm text-primary"
            onClick={handleUnlockFailedClick}
          >
            解錠できませんでした
          </div>
        </PopupAction>
      </Popup>
    );
  }

  return (
    <Popup
      visible={visible}
      closeable
      onCloseIconClick={() => {
        visible && toggle();
      }}
    >
      <PopupMain className="text-base">解錠してもよろしいですか？</PopupMain>
      <PopupAction>
        <div className="flex gap-2">
          <div className="flex-1">
            <Button onClick={toggle} type="default">
              いいえ
            </Button>
          </div>
          <div className="flex-1">
            <Button onClick={handleUnlockClick} loading={isUnlocking}>
              はい
            </Button>
          </div>
        </div>
      </PopupAction>
    </Popup>
  );
});
