import { useHandleDeviceUnknown } from "@/hooks";
import { getStorage } from "@/shared/store";
import type { DeviceType } from "@/types";

import { LiteOpen, PATMOOpen } from "./imei-open";

export default function IMEIOpenWrapper() {
  const deviceType = getStorage("deviceType") as DeviceType;
  const { toastAndToSelectDevice } = useHandleDeviceUnknown();

  switch (deviceType) {
    case "PabbitLite":
      return <LiteOpen />;
    case "PATMO":
      return <PATMOOpen />;
    default: {
      toastAndToSelectDevice();
    }
  }
}
