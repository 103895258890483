import { Toast } from "@nutui/nutui-react";
import type { CSSProperties } from "react";

import SuccessIcon from "@/assets/solid-success.svg?react";
import ErrorIcon from "@/assets/toast-icon-error.svg?react";

const commonToastStyle = {
  "--nutui-toast-inner-top": "10%",
} as CSSProperties;

const commonContentStyle = {
  width: "100%",
  lineHeight: "1.5rem",
} as CSSProperties;

const generateToast =
  (type: "error" | "success" | "warning" | "info") => (message: string) => {
    switch (type) {
      case "error": {
        Toast.show({
          icon: <ErrorIcon className="mb-1 mr-2 inline" />,
          content: message,
          style: {
            ...commonToastStyle,
          },
          contentStyle: {
            ...commonContentStyle,
          },
        });
        break;
      }
      case "success": {
        Toast.show({
          icon: <SuccessIcon className="mb-1 mr-5 inline h-4 w-4" />,
          content: <span className="text-sm font-medium">{message}</span>,
          style: {
            ...commonToastStyle,
            "--nutui-overlay-bg-color": "rgba(0, 0, 0, 0.4)",
            "--nutui-toast-inner-bg-color": "white",
            "--nutui-toast-inner-border-radius": "12px",
            "--nutui-toast-font-color": "#333333",
            "--nutui-toast-inner-padding": "16px",
          } as CSSProperties,
          contentStyle: {
            ...commonContentStyle,
            textWrap: "nowrap",
          } as CSSProperties,
          closeOnOverlayClick: false,
          duration: 1,
        });
        break;
      }
      default: {
        Toast.show({
          content: message,
          style: {
            ...commonToastStyle,
          },
          contentStyle: {
            ...commonContentStyle,
          },
        });
      }
    }
  };

export const errorToast = generateToast("error");
export const successToast = generateToast("success");
export const warnToast = generateToast("warning");
export const infoToast = generateToast("info");
