import type { InputHTMLAttributes } from "react";
import type {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";

import { InputWithClear } from "@/components/form";
import { OnlyNumberRegExp, removeUnexpectedChar } from "@/shared/regexps";
import { setStorage } from "@/shared/store";

interface GateNumberInputProps {
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
}
export function GateNumberInput({
  register,
  setValue,
  getValues,
  ...props
}: GateNumberInputProps & InputHTMLAttributes<HTMLInputElement>) {
  const handleInputGateNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cleaned = removeUnexpectedChar(e.target.value, OnlyNumberRegExp)
      .toString()
      .substring(0, 2);
    const numberValue = parseInt(cleaned, 10);
    setStorage(
      "gateNumber",
      Number.isNaN(numberValue) ? undefined : numberValue
    );
    setValue("gateNumber", Number.isNaN(numberValue) ? undefined : numberValue);
  };
  return (
    <InputWithClear
      id="gateNumber"
      register={register("gateNumber", {
        onChange: handleInputGateNumber,
        pattern: {
          value: /^[0-9]{1,2}$/gm,
          message: "ゲート番号の形式が正しくありません",
        },
        max: {
          value: 99,
          message: "ゲート番号の形式が正しくありません",
        },
        min: {
          value: 1,
          message: "ゲート番号の形式が正しくありません",
        },
      })}
      setValue={setValue}
      getValues={getValues}
      placeholder="ゲート番号を入力してください"
      type="number"
      pattern="[0-9]*"
      onWheel={(event) => event.currentTarget.blur()}
      {...props}
    />
  );
}
