import StepParagraph from "./StepParagraph";

export function StepCaption({ children }: { children: string }) {
  return (
    <StepParagraph className="text-green-gray">
      <span className="text-error">※&nbsp;</span>
      {children}
    </StepParagraph>
  );
}
