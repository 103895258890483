import { useHandleDeviceUnknown } from "@/hooks";
import { getStorage } from "@/shared/store";
import type { DeviceType } from "@/types";

import { DearisReplace, PATMOReplace } from "./imei-replace";

export default function IMEIReplaceWrapper() {
  const deviceType = getStorage("deviceType") as DeviceType;
  const { toastAndToSelectDevice } = useHandleDeviceUnknown();
  switch (deviceType) {
    case "PATMO":
      return <PATMOReplace />;
    case "dearisController":
      return <DearisReplace />;
    default: {
      toastAndToSelectDevice();
    }
  }
}
