import { useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";

import dearisDevice from "@/assets/dearis-device.png";
import pabbitDevice from "@/assets/pabbit-lite-device.png";
import patmoDevice from "@/assets/patmo-device.png";
import { Main } from "@/components";
import { Footer } from "@/components/footer";
import { FetchPermissionError, useAuthGrantPermission } from "@/queries";
import { errorToast } from "@/shared";
import { Unauthorized401Error } from "@/shared/requests/error.type";
import { clearStorage, setStorage } from "@/shared/store";
import type { DeviceType } from "@/types";

import { DeviceCard } from "./components";

const SelectDevice = () => {
  const navigate = useNavigate();
  clearStorage();

  // const toDeviceInstruction = () => {
  //   navigate("/setting-methods");
  // };

  const configureDevice = (type: DeviceType) => () => {
    setStorage("deviceType", type);

    const navigationPaths: Partial<Record<DeviceType, string>> = {
      PabbitLite: "/register",
      PATMO: "/pre-setting",
      dearis: "dearis",
    };
    const defaultPath = "";
    navigate(navigationPaths[type] || defaultPath);
  };

  return (
    <>
      <div className="mx-4 my-6 flex-1">
        <p className="text-center text-lg font-medium">
          デバイスタイプを選択してください
        </p>
        <div className="my-5 grid grid-cols-2 gap-4">
          <DeviceCard
            image={<img className="w-[46px] flex-none" src={pabbitDevice} />}
            title="Pabbit Lite"
            onClick={configureDevice("PabbitLite")}
          />
          <DeviceCard
            image={<img className="w-[64px] flex-none" src={patmoDevice} />}
            title="PATMOα"
            onClick={configureDevice("PATMO")}
          />
          <DeviceCard
            image={<img className="w-[62px] flex-none" src={dearisDevice} />}
            title="dearis"
            onClick={configureDevice("dearis")}
          />
        </div>
        {/* <div className="mt-10 px-4">
          <Button
            fill="outline"
            className="w-full"
            onClick={toDeviceInstruction}
          >
            設定方法はこちら
          </Button>
        </div> */}
      </div>
      <Footer />
    </>
  );
};

const SelectDeviceWrapper = () => {
  const { mutateAsync: authGetPermission, isLoading } =
    useAuthGrantPermission();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    authGetPermission().catch((error) => {
      if (error instanceof Unauthorized401Error) {
        navigate("/login");
      } else if (error instanceof FetchPermissionError) {
        console.error("grant permission error", error);
      } else {
        errorToast(error.message ?? error.toString());
      }
    });
  }, []);

  if (isLoading) return <Main />;
  return <SelectDevice />;
};

export default SelectDeviceWrapper;
