import { cx } from "@emotion/css";
import { InfiniteLoading } from "@nutui/nutui-react";
import type { UseInfiniteQueryResult } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";

import BackIcon from "@/assets/back-to-prev.svg?react";
import { BuildingCard, Footer, Header, Loading } from "@/components";
import { useFetchDataError } from "@/hooks";
import { getStorage, setStorage } from "@/shared/store";
import type { Building, DeviceType, PaginationParams, Property } from "@/types";

import { SearchObjectEmpty } from "../components";

export const PatmoSearchResults = ({
  deviceType,
  infiniteQuery,
  dataKey,
}: Readonly<{
  deviceType: DeviceType;
  infiniteQuery: (
    _params: PaginationParams
  ) => UseInfiniteQueryResult<AxiosResponse>;
  dataKey: string;
}>) => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const searchName = searchParams.get("name") ?? "";
  const searchPrefecture = searchParams.get("prefecture") ?? "";
  const searchCity = searchParams.get("city") ?? "";
  const searchAddress = searchParams.get("address") ?? "";
  const fullAddress = `${searchPrefecture}${searchCity}${searchAddress}`;
  const searchKeyWord = searchName || fullAddress;

  const requestParams: PaginationParams = {
    page: 1,
    page_size: 10,
  };
  searchName && (requestParams.property_name = searchName);
  fullAddress && (requestParams.address = fullAddress);
  const { data, fetchNextPage, hasNextPage, isLoading, isError, error } =
    infiniteQuery(requestParams);
  useFetchDataError(isError, error);
  // console.log("data",data);
  const count = data?.pages?.at(0)?.data?.total;

  const loadMore = async (done: () => void) => {
    await fetchNextPage();
    done();
  };

  const onConfirm = (buildingOrProperty: Building | Property) => () => {
    setStorage("selectedBuildingOrProperty", buildingOrProperty);
    const deviceType = getStorage("deviceType") as DeviceType;
    if (deviceType === "PabbitLite") {
      setStorage("propertyId", (buildingOrProperty as Building).property_id);
    } else {
      setStorage("propertyId", (buildingOrProperty as Property).id);
    }
    navigate("/configure");
  };

  const handleBack = () => {
    navigate("/configure/search", { replace: true });
  };
  const handleBackHome = () => {
    navigate("/select-device", { replace: true });
  };

  if (isLoading) {
    return (
      <SearchContainer searchKeyWord={searchKeyWord} count={count} footChildren={null}>
        <div className="mt-4 text-center text-sm text-neutral-600 ">
          <Loading />
          <p>ローディング</p>
        </div>
      </SearchContainer>
    );
  }

  if (!count && !isLoading) {
    return (
      <SearchContainer searchKeyWord={searchKeyWord} count={count} footChildren={
        <>
          <p className="break-keep text-center text-xs text-[#909899]">
            <span className="pr-1 text-[#EE421D]">※</span>該当する物件が無い場合は設定ツールを閉じてください
          </p>
          <span className="flex items-center mt-4 justify-center gap-[6px] hover:cursor-pointer">
            <BackIcon className="" />
            <a className="text-sm my-0 pt-1" onClick={handleBackHome}>
            ホームページに戻る
            </a>
          </span>
        </>
      }>
        <>
          <SearchObjectEmpty />
          <p
            className={cx(
              "mt-3 break-keep text-center text-xs text-[#909899]"
            )}
          >
            下記の場合は物件名は表示されません：
            <br />
            オーナー又は管理会社から
            <br />
            利用許諾申請が行われていない
            <br />
            又は物件情報が登録されていない
          </p>
        </>
      </SearchContainer>
    );
  }

  return (
    <SearchContainer searchKeyWord={searchKeyWord} count={count} footChildren={
      <span className="flex items-center justify-center gap-[6px] hover:cursor-pointer">
        <BackIcon />
        <a className="text-sm my-0 pt-1" onClick={handleBack}>
          検索に戻る
        </a>
      </span>
    }>
      <InfiniteLoading
        hasMore={hasNextPage}
        onLoadMore={loadMore}
        loadMoreText={" "}
      >
        {data?.pages.map((page) => {
          return page?.data?.[dataKey]?.map((item: Building | Property) => (
            <BuildingCard
              key={item.id}
              type={deviceType}
              className="my-3"
              titleLighted
              buildingOrProperty={item}
              onClick={onConfirm(item)}
            />
          ));
        })}
      </InfiniteLoading>
      <div className="mx-4 my-3 flex flex-col justify-center">
        <p className="mb-3 text-center text-[13px] text-neutral-400">
          検索結果が要件を満たしていない場合は、​「検索に戻る」​をタッチして再度検索してください。
        </p>
      </div>
    </SearchContainer>
  );
};

const SearchContainer = ({
  // searchKeyWord,
  count,
  children,
  footChildren,
}: {
  searchKeyWord: string;
  count: number;
  children?: React.ReactNode | React.ReactNode;
  footChildren?: React.ReactNode | React.ReactNode;
}) => {
  return (
    <>
      <Header>検索結果の確認</Header>
      <div className="flex-1 bg-[#f6f6f6] p-4">
        <div className={cx("text-sm leading-6 text-neutral-600")}>
          {/* <p>
            条件：<span className="text-primary">{searchKeyWord}</span>
          </p> */}
          <p>
            検索結果：<span className="text-primary">{count}</span>
          </p>
        </div>
        {children}
      </div>
      <FootContainer children={footChildren} />
    </>
  );
};
const FootContainer = ({
  children,
}: {
  children?: React.ReactNode | React.ReactNode;
}) => {
  return (
    <>
      <div className="mb-10">
        {children}
      </div>
      <Footer />
    </>
  );
};

