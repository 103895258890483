import { useMutation } from "@tanstack/react-query";

import { NECESSARY_INFO_LACKING_HINT } from "@/shared";
import { openLineByIMEI } from "@/shared/requests/line";
import { getStorage } from "@/shared/store";

import { NecessaryInfoLackingError } from "./errors.type";

export const useLineOpenIMEIReplace = () => {
  const mutationFn = () => {
    const imei = getStorage("imei");
    if (!imei) throw new NecessaryInfoLackingError(NECESSARY_INFO_LACKING_HINT);
    return openLineByIMEI({ imei });
  };
  return useMutation(mutationFn);
};
