import { useState } from "react";

import {
  useDeleteBunchDevices,
  useGetDevicesOfPropertyMutation,
} from "@/queries";

export function useDeleteDearisGate() {
  const [isLoading, setIsLoading] = useState(false);
  const { mutateAsync: getDevicesOfProperty } =
    useGetDevicesOfPropertyMutation("dearisGate");
  const { mutateAsync: deleteBunchDevices } = useDeleteBunchDevices();
  async function deleteDearisGate(gateNumber: number) {
    setIsLoading(true);
    try {
      const devices = await getDevicesOfProperty();
      const dearisGatesByGateNumber = devices?.data?.filter(
        (device) => device?.position?.gate_number == gateNumber
      );
      await deleteBunchDevices(
        dearisGatesByGateNumber?.map((item) => item?.id)
      );
    } catch (e) {
      setIsLoading(false);
      throw e;
    }
  }
  return { deleteDearisGate, isLoading };
}
