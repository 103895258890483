import { Tag } from "@/components";
import type { LineStatus } from "@/types";

export const BindedTag = ({ binded }: { binded: string }) => {
  if (binded)
    return (
      <Tag background="#E6FFFF" color="#00AFCC">
        設定済み
      </Tag>
    );
  return <Tag>未設定</Tag>;
};

export const LineOpenedTag = ({ lineOpened }: { lineOpened: LineStatus }) => {
  if (lineOpened === "active")
    return (
      <Tag background="#E4FFE6" color="#52C41A">
        開通中
      </Tag>
    );
  if (lineOpened === "suspend")
    return (
      <Tag background="#FFF2EB" color="#FD8140">
        一時停止
      </Tag>
    );
  if (lineOpened === "dead") return <Tag>解約された</Tag>;
  return <Tag>未開通</Tag>;
};

export const PositionedTag = ({ positioned }: { positioned: string }) => {
  if (positioned) {
    return (
      <Tag background="#E6FFFF" color="#00AFCC">
        設定済み
      </Tag>
    );
  } else return <Tag>未設定</Tag>;
};

export const UnlockTestedTag = ({ lockTested }: { lockTested: boolean }) => {
  if (lockTested) {
    return (
      <Tag background="#E6FFFF" color="#00AFCC">
        テスト完了
      </Tag>
    );
  } else return <Tag>未完了</Tag>;
};
