import { cx } from "@emotion/css";

import SandglassIcon from "@/assets/sandglass.svg?react";
import {
  Footer,
  Header,
  LineStatusCard,
  LineStatusStatement,
} from "@/components";

const RegisterProgressing = () => {
  return (
    <div className="contents">
      <Header>回線開通</Header>
      <div className="flex-1 bg-[#f6f6f6] p-4">
        <LineStatusCard className="!min-h-56">
          <SandglassIcon
            className={cx("mx-auto", "origin-[center_32px]")}
            style={{ animation: "flipHorizontal 1.5s ease-in-out infinite" }}
          />
          <LineStatusStatement>
            <p className={cx()}>回線開通中</p>
            <p className="mt-6 text-sm font-normal text-neutral-600">
              しばらくお待ちください
            </p>
          </LineStatusStatement>
        </LineStatusCard>
      </div>
      <Footer />
    </div>
  );
};

export default RegisterProgressing;
