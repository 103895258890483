import { cx } from "@emotion/css";
import { InfiniteLoading } from "@nutui/nutui-react";
import type { UseInfiniteQueryResult } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";

import { BuildingCard, Button, Footer, Header, Loading } from "@/components";
import { useFetchDataError } from "@/hooks";
import { getStorage, setStorage } from "@/shared/store";
import type { Building, DeviceType, PaginationParams, Property } from "@/types";

import { SearchObjectEmpty } from "../components";

export const LiteSearchResults = ({
  deviceType,
  infiniteQuery,
  dataKey,
}: Readonly<{
  deviceType: DeviceType;
  infiniteQuery: (
    _params: PaginationParams
  ) => UseInfiniteQueryResult<AxiosResponse>;
  dataKey: string;
}>) => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const searchName = searchParams.get("name") ?? "";
  const searchPrefecture = searchParams.get("prefecture") ?? "";
  const searchCity = searchParams.get("city") ?? "";
  const searchAddress = searchParams.get("address") ?? "";
  const fullAddress = `${searchPrefecture}${searchCity}${searchAddress}`;
  const searchKeyWord = searchName || fullAddress;

  const requestParams: PaginationParams = {
    page: 1,
    page_size: 10,
  };
  searchName && (requestParams.property_name = searchName);
  fullAddress && (requestParams.address = fullAddress);
  const { data, fetchNextPage, hasNextPage, isLoading, isError, error } =
    infiniteQuery(requestParams);
  useFetchDataError(isError, error);

  const count = data?.pages?.at(0)?.data?.total;

  const loadMore = async (done: () => void) => {
    await fetchNextPage();
    done();
  };

  const onConfirm = (buildingOrProperty: Building | Property) => () => {
    setStorage("selectedBuildingOrProperty", buildingOrProperty);
    const deviceType = getStorage("deviceType") as DeviceType;
    if (deviceType === "PabbitLite") {
      setStorage("propertyId", (buildingOrProperty as Building).property_id);
    } else {
      setStorage("propertyId", (buildingOrProperty as Property).id);
    }
    navigate("/configure");
  };

  const handleBack = () => {
    navigate("/configure/search", { replace: true });
  };

  if (isLoading) {
    return (
      <Container searchKeyWord={searchKeyWord} count={count}>
        <div className="mt-4 text-center text-sm text-neutral-600 ">
          <Loading />
          <p>ローディング</p>
        </div>
      </Container>
    );
  }

  if (!count && !isLoading) {
    return (
      <Container searchKeyWord={searchKeyWord} count={count}>
        <>
          <SearchObjectEmpty />
          <p
            className={cx(
              "mt-3 break-keep text-center text-xs text-neutral-400"
            )}
          >
            下記の場合は物件名は表示されません：
            <br />
            オーナー又は管理会社から
            <br />
            利用許諾申請が行われていない
            <br />
            又は物件情報が登録されていない
          </p>
        </>
      </Container>
    );
  }

  return (
    <Container searchKeyWord={searchKeyWord} count={count}>
      <InfiniteLoading
        hasMore={hasNextPage}
        onLoadMore={loadMore}
        loadMoreText={" "}
      >
        {data?.pages.map((page) => {
          return page?.data?.[dataKey]?.map((item: Building | Property) => (
            <BuildingCard
              key={item.id}
              type={deviceType}
              className="my-3"
              titleLighted
              buildingOrProperty={item}
              onClick={onConfirm(item)}
            />
          ));
        })}
      </InfiniteLoading>
      <div className="mx-4 my-3">
        <p className="mb-3 text-center text-[13px] text-neutral-400">
          検索結果が要件を満たしていない場合は、​「戻る」​をタッチして再度検索してください。
        </p>
        <Button fill="outline" onClick={handleBack}>
          検索に戻る
        </Button>
      </div>
    </Container>
  );
};

const Container = ({
  // searchKeyWord,
  count,
  children,
}: {
  searchKeyWord: string;
  count: number;
  children?: React.ReactNode | React.ReactNode;
}) => {
  return (
    <>
      <Header>検索結果の確認</Header>
      <div className="flex-1 bg-[#f6f6f6] p-4">
        <div className={cx("text-sm leading-6 text-neutral-600")}>
          {/* <p>
            条件：<span className="text-primary">{searchKeyWord}</span>
          </p> */}
          <p>
            検索結果：<span className="text-primary">{count}</span>
          </p>
        </div>
        {children}
      </div>
      <Footer />
    </>
  );
};
