import { useMemo } from "react";

import { useListOption } from "@/queries";
import type { ProviderModel } from "@/types";

export function useOptions() {
  const { data, isLoading, isSuccess, isError, refetch } = useListOption();

  const willShowProviders = useMemo(() => {
    const options = data?.data ?? [];
    const filteredProviders: Array<ProviderModel> = options.map((option) => ({
      courier: option.option,
      name: option.title,
      testable: option.testable,
    }));
    return filteredProviders;
  }, [data]);

  const isProvidersAllUnavailable = !willShowProviders?.filter(
    (provider) => provider.testable && provider.courier !== "amazon"
  ).length;
  // const onlyAmazon =
  //   willShowProviders.length === 1 && willShowProviders[0].courier === "amazon";

  return {
    willShowProviders,
    isProvidersAllUnavailable, // 一个都没有, 包括amazon
    // onlyAmazon,
    isLoading,
    isError,
    isSuccess,
    refetch,
  };
}
