import { useMutation } from "@tanstack/react-query";

import { NECESSARY_INFO_LACKING_HINT, UNKNOWN_DEVICE_HINT } from "@/shared";
import server from "@/shared/requests/server";
import { getStorage } from "@/shared/store";
import type { DeviceType } from "@/types";

import { NecessaryInfoLackingError } from "./errors.type";

interface OpenLineParams {
  imei: string;
}
const openLine = ({ imei }: OpenLineParams) => {
  return server.post(`/devices/line/${imei}/line`);
};

interface OpenLineByIdParams {
  device_id: string;
}
const openLineById = ({ device_id }: OpenLineByIdParams) => {
  return server.post(`/devices/${device_id}/line`);
};

const getDeviceIdAndOpen = () => {
  const device_id = getStorage("deviceId");
  if (!device_id)
    throw new NecessaryInfoLackingError(NECESSARY_INFO_LACKING_HINT);
  return openLineById({ device_id });
};

const getIMEIAndOpen = () => {
  const imei = getStorage("imei");
  if (!imei) throw new NecessaryInfoLackingError(NECESSARY_INFO_LACKING_HINT);
  return openLine({ imei });
};

export const useLineOpen = () => {
  const mutationFn = () => {
    const device_type = getStorage("deviceType") as DeviceType;
    switch (device_type) {
      case "PabbitLite":
        return getDeviceIdAndOpen();
      case "PATMO":
        return getDeviceIdAndOpen();
      // case "adapter":
      //   return getIMEIAndOpen();
      case "DearisAdapter":
        return getIMEIAndOpen();
      case "dearisController":
        return getIMEIAndOpen();
      default:
        throw new Error(UNKNOWN_DEVICE_HINT);
    }
  };
  return useMutation(mutationFn);
};
