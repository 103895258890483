import { useEffect } from "react";
import { type FieldErrors, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Card,
  Footer,
  Header,
  Main,
  Popup,
  PopupAction,
  PopupMain,
} from "@/components";
import { Label } from "@/components/form";
import {
  useFormErrorsToast,
  useGetScannerData,
  useModal,
  useVisibilityToggle,
} from "@/hooks";
import { useCheckLineExistMutation } from "@/queries";
import { errorToast } from "@/shared";
import { getStorage } from "@/shared/store";

import { IMEIInput } from "../components";

interface AdapterRegisterFormField {
  imei: string;
}
const DearisAdapterManualRegister = () => {
  const navigate = useNavigate();
  const { watch, register, setValue, getValues, handleSubmit } =
    useForm<AdapterRegisterFormField>();
  const [isAllFieldFilled] = useVisibilityToggle(watch("imei"));
  const { toastFormValueError } = useFormErrorsToast();
  const { mutateAsync, isLoading } = useCheckLineExistMutation();
  const { visible, toggle } = useModal();

  const confirmRegister = async () => {
    mutateAsync()
      .then(() => navigate("/register/confirm", { replace: true }))
      .catch((error) => {
        if (error.status === 400) {
          toggle();
        } else {
          errorToast(error?.message ?? error.toString());
        }
      });
  };
  const handleIMEINotExistClick = () => {
    toggle();
    navigate("/register");
  };

  useGetScannerData();

  useEffect(() => {
    const imei = getStorage("imei");
    setValue("imei", imei);
  }, []);

  return (
    <>
      <Header>情報の登録</Header>
      <Main>
        <Card className="mt-4">
          <Label htmlFor="imei" required>
            IMEI
          </Label>
          <IMEIInput
            register={register}
            setValue={setValue}
            getValues={getValues}
          ></IMEIInput>
          <div className="mt-10">
            <Button
              className="w-full"
              size="large"
              onClick={handleSubmit(confirmRegister, (errors: FieldErrors) =>
                toastFormValueError(errors)
              )}
              disabled={!isAllFieldFilled}
              loading={isLoading}
            >
              確認
            </Button>
          </div>
        </Card>
      </Main>
      <Footer />
      <Popup visible={visible}>
        <PopupMain>
          このIMEIは存在しません。再度スキャンするか、入力してください。
        </PopupMain>
        <PopupAction>
          <Button onClick={handleIMEINotExistClick}>閉じる</Button>
        </PopupAction>
      </Popup>
    </>
  );
};

export default DearisAdapterManualRegister;
