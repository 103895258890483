import { useNavigate } from "react-router-dom";

import { Button, Footer, Header, Link, Main } from "@/components";
import {
  Step,
  StepContainer,
  StepIndicator,
  StepParagraph,
  StepTitle,
} from "@/components/step";
import { useReportTest } from "@/queries";
import { errorToast } from "@/shared";
import type { ProviderModel } from "@/types";

import { useUnlockTestDataPATMO } from "../hooks";
import { UnlockedSkeleton } from "./components";
import { useUpdatePatmoTest } from "./hooks";

interface PatmoUnLockTestProps {
  code: string;
  delivery: ProviderModel;
}

export function PatmoUnLockTest({
  code,
  delivery,
}: Readonly<PatmoUnLockTestProps>) {
  const navigate = useNavigate();

  const { updatePatmoTests, isLoading } = useUpdatePatmoTest();
  const { mutateAsync: updatePatmoTestPassed, isLoading: isReportTestLoading } =
    useReportTest();
  const handleUnlockSucceedClick = async () => {
    await updatePatmoTests()
      .catch((error) => {
        if (error?.status && error?.status === 400) {
          errorToast("設定状態が変更されました。");
        } else {
          errorToast(error?.message ?? error.toString());
        }
      })
      .finally(() => {
        navigate("/unlock-overview", { replace: true });
      });
  };

  const handleUnlockFailedClick = async () => {
    await updatePatmoTestPassed({ step: "1", failed: true })
      .catch((error) => {
        if (error?.status && error?.status === 400) {
          errorToast("テストの状態更新に失敗しました");
        } else {
          errorToast(error?.message ?? error.toString());
        }
      })
      .finally(() => {
        navigate("/unlock-overview", { replace: true });
      });
  };

  return (
    <>
      <Header>解錠テスト</Header>
      <Main>
        <StepContainer>
          {/* Step One */}
          <Step>
            <StepTitle>操作手順</StepTitle>
            <StepParagraph>
              <StepIndicator>1</StepIndicator>
              デバイスの「宅配業者の選択」ページで
              <span className="font-semibold text-primary">
                「{delivery.name}」
              </span>
              を選択してください
            </StepParagraph>
          </Step>
          {/* Step Two */}
          <Step>
            <StepParagraph>
              <StepIndicator>2</StepIndicator>
              {"実際にある部屋番号を入力して「呼出」を押してください。"}
            </StepParagraph>
          </Step>
          {/* Step Three */}
          <Step>
            <StepParagraph>
              <StepIndicator>3</StepIndicator>
              約15秒後に「サービス番号を入力」を押せるようになりますので押してください。
            </StepParagraph>
            <StepParagraph>
              <div className="rounded-md bg-bgGray py-2 text-center text-2xl font-bold text-primary">
                「{code}」
              </div>
            </StepParagraph>
            <StepParagraph>
              上記の数字を入力し「決定」を押してください。
            </StepParagraph>
          </Step>
          {/* Step End */}
          <Step className="mt-5">
            <StepParagraph>ドアロックが解錠されましたか？</StepParagraph>

            <StepParagraph>
              <Button loading={isLoading} onClick={handleUnlockSucceedClick}>
                解錠されました
              </Button>
            </StepParagraph>
            <StepParagraph>
              <Button
                type="default"
                onClick={handleUnlockFailedClick}
                loading={isReportTestLoading}
              >
                解錠できませんでした
              </Button>
            </StepParagraph>
            <StepParagraph className="text-green-gray">
              {
                "注意：解錠に失敗する場合は、配線などを調整して再度テストしてください。"
              }
            </StepParagraph>
          </Step>
        </StepContainer>
      </Main>
      <Footer />
    </>
  );
}

const UnlockTestError = () => {
  return (
    <>
      <Header>解錠テスト</Header>
      <Main>
        <StepContainer>
          <Step>
            <StepTitle className="!mt-0">エラーが発生しました</StepTitle>
            <StepParagraph>
              すべてのテスト情報を取得しないため、後続の解錠テストを完了することはできません。
            </StepParagraph>
          </Step>
          <Step className="mb-2">
            <StepParagraph>
              <Link to="/" className="block text-center">
                戻る
              </Link>
            </StepParagraph>
          </Step>
        </StepContainer>
      </Main>
      <Footer />
    </>
  );
};

export default function PatmoUnLockTestWrapper() {
  const { isLoading, isError, delivery, theDelivery, code, isUnsatisfactory } =
    useUnlockTestDataPATMO();

  if (isLoading) {
    return <UnlockedSkeleton />;
  }

  if (isUnsatisfactory) {
    errorToast("宅配事業者のデータが更新されました。再度ご確認ください。");
  }

  if (isError || !theDelivery || !code) {
    return <UnlockTestError />;
  } else {
    return <PatmoUnLockTest code={code} delivery={delivery} />;
  }
}
