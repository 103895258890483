import type { FC } from "react";

import ObjectActivated from "./ObjectActivated";
import ObjectNotActivated from "./ObjectNotActivated";

interface IObjectConfigureOk {
  isActivated?: boolean;
}
const ObjectConfigureOk: FC<IObjectConfigureOk> = ({ isActivated = false }) => {
  // TODO 添加接口接收设备是否已经开通
  if (isActivated) {
    return <ObjectActivated />;
  }
  return <ObjectNotActivated />;
};

export default ObjectConfigureOk;
