import { useLocation, useNavigate } from "react-router-dom";

import { Button, Footer, Header, Main } from "@/components";
import {
  Step,
  StepContainer,
  StepParagraph,
  StepsGenerator,
} from "@/components/step";

import { ServiceConfigDoneBTN } from "../components";
import {
  patmoStepsData,
  patmoStepsData2,
  patmoStepsData3,
} from "./patmo-steps-data";

export default function ServiceConfigure() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const hasImei = state?.hasImei;
  return (
    <>
      <Header>サービス設定</Header>
      <Main>
        <StepContainer>
          <StepsGenerator stepsData={patmoStepsData} />
          {hasImei ? (
            <>
              <StepParagraph>
                <span className="mr-1 text-primary">❸</span>
                このページに戻ったら、「スキャナー接続テスト」を押してください。
              </StepParagraph>
              <Step>
                <Button onClick={() => navigate("/scanner-test")}>
                  スキャナーテストを開始する
                </Button>
              </Step>
              <StepsGenerator stepsData={patmoStepsData2} />
            </>
          ) : (
            <>
              <StepsGenerator stepsData={patmoStepsData3} />
            </>
          )}

          <ServiceConfigDoneBTN />
        </StepContainer>
      </Main>
      <Footer />
    </>
  );
}
