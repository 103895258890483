import { useEffect, useState } from "react";

import { useIssueCredential } from "@/queries";
import { errorToast } from "@/shared";

export function useGenerateIssueCode(service: string | undefined) {
  const [code, setCode] = useState("");
  const { mutateAsync } = useIssueCredential();
  useEffect(() => {
    if (!service) return;
    mutateAsync(service)
      .then((res) => {
        setCode(res.data);
      })
      .catch((e) => {
        if (e.status === 400) {
          errorToast("バーコードの取得に失敗しました。");
        } else {
          errorToast(e?.message ?? e.toString());
        }
      });
  }, [service]);
  return { code };
}
