import { useQuery } from "@tanstack/react-query";

import { NECESSARY_INFO_LACKING_HINT } from "@/shared";
import { getDeviceByID } from "@/shared/requests/device";
import { getStorage } from "@/shared/store";

import { NecessaryInfoLackingError } from "./errors.type";
import { DEVICE_QUERY_KEY } from "./queryKey";

export function useGetDeviceById() {
  const device_id = getStorage("deviceId");
  const device_type = getStorage("deviceType");

  const queryFn = () => {
    if (!device_id || !device_type) {
      throw new NecessaryInfoLackingError(NECESSARY_INFO_LACKING_HINT);
    }
    return getDeviceByID({ device_id, device_type });
  };

  return useQuery([DEVICE_QUERY_KEY, device_id], queryFn, {
    enabled: !!device_id,
  });
}

export function useGetDeviceByIdManual(id: string) {
  const queryFn = () => {
    const device_type = getStorage("deviceType");
    if (!device_type) {
      throw new NecessaryInfoLackingError(NECESSARY_INFO_LACKING_HINT);
    }
    return getDeviceByID({ device_id: id, device_type });
  };

  return useQuery([DEVICE_QUERY_KEY, id], queryFn, {
    enabled: !!id,
  });
}
