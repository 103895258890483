import { cx } from "@emotion/css";
import {
  ConfigProvider,
  Tag as NutTag,
  type TagProps as NutTagProps,
} from "@nutui/nutui-react";

interface TagProps {
  children?: React.ReactNode;
  className?: string;
  padding?: string;
}
export default function Tag(props: TagProps & Partial<NutTagProps>) {
  return (
    <ConfigProvider
      theme={{
        nutuiTagPadding: props.padding || "0 12px",
      }}
    >
      <NutTag
        className={cx("text-xs leading-5", props.className)}
        type="primary"
        round
        background="#FFE6E8"
        color="#F51E1E"
        {...props}
      >
        {props.children}
      </NutTag>
    </ConfigProvider>
  );
}
