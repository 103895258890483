import { useNavigate } from "react-router-dom";

import {
  Button,
  Footer,
  Header,
  Main,
  Popup,
  PopupAction,
  PopupMain,
} from "@/components";
import { Step, StepContainer, StepsGenerator } from "@/components/step";
import { useModal } from "@/hooks";
import { errorToast } from "@/shared";

import { usePropertyData } from "../hooks";
import { controllerStepsData } from "./controller-steps-data";

export default function () {
  const navigate = useNavigate();
  const { visible, toggle } = useModal();
  const { checkBuildingExists, isLoading, isError } = usePropertyData();

  const handleNextClick = async () => {
    checkBuildingExists()
      .then((buildingLength) => {
        if (buildingLength) {
          navigate("/deliveries");
        } else {
          toggle();
        }
      })
      .catch((error: any) => {
        errorToast(error?.message ?? error.toString());
      });
  };
  if (isError) {
    errorToast("部屋情報の取得に失敗しました。");
  }
  return (
    <>
      <Header>サービス設定</Header>
      <Main>
        <StepContainer>
          <StepsGenerator stepsData={controllerStepsData} />
          <Step>
            <Button onClick={handleNextClick} loading={isLoading}>
              次へ
            </Button>
          </Step>
        </StepContainer>
      </Main>
      <Footer />
      <Popup visible={visible}>
        <PopupMain>
          棟情報がありません。「部屋情報アップロード」を押してください。
        </PopupMain>
        <PopupAction>
          <Button onClick={toggle}>閉じる</Button>
        </PopupAction>
      </Popup>
    </>
  );
}
