import { cx } from "@emotion/css";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { gateSpotOptions } from "@/app.constant";
import { Button, Card, Footer, Header, Main } from "@/components";
import { Label } from "@/components/form";
import {
  useFormErrorsToast,
  useNavigateWithSearchParams,
  useVisibilityToggle,
} from "@/hooks";
import { GateNumberInput } from "@/pages/registerDevice/components";
import { useCreateDearisPositionMulti } from "@/queries";
import { errorToast, SAVE_SPOT_NAME_FAILED_HINT } from "@/shared";
import { setStorage } from "@/shared/store";

import {
  BuildCheckboxControlGroup,
  // DearisGateResigteredBuilding,
  DearisGateSpotDetailButtonGroup,
  GateSkeleton,
  GateSpotInput,
  ImageControl,
  XiezhenCaption,
} from "../components";
import { useDearisSpotValue } from "../hooks";

export interface GateSpotFormField {
  gateNumber: number;
  location: string;
  customLocation: string;
  uri: string;
  buildIds: number[];
}
export function GateSpot() {
  const navigate = useNavigateWithSearchParams();
  const { id } = useParams() as { id: string };

  // form
  const { watch, register, control, setValue, getValues, handleSubmit, reset } =
    useForm<GateSpotFormField>();
  const { toastFormValueError } = useFormErrorsToast();
  const [isAllFieldFilled] = useVisibilityToggle(
    watch("gateNumber"),
    watch("location"),
    watch("location") === "カスタム" ? watch("customLocation") : "permanent",
    watch("buildIds")
  );

  // data
  const { isLoading: isGETDeviceLoading } = useDearisSpotValue(
    id,
    reset,
    gateSpotOptions
  );

  const { mutateAsync: updatePosition, isLoading } =
    useCreateDearisPositionMulti();

  // handler
  const handleRegisterPosition = async (values: GateSpotFormField) => {
    if (values.location === "カスタム") {
      if (!values.customLocation.trim())
        return errorToast("設置場所(識別名)は必須です。");
      setStorage("spotName", values.customLocation.trim());
    } else {
      setStorage("spotName", values.location);
    }
    setStorage("uri", values.uri);

    updatePosition(values.buildIds)
      .then(() => {
        navigate(-1);
      })
      .catch((error) => {
        if (error?.status === 400) {
          errorToast(SAVE_SPOT_NAME_FAILED_HINT);
        } else {
          errorToast(error?.message ?? error?.toString());
        }
      });
  };

  const navigateToHelpPage = () => {
    navigate("/pre-setting");
  };

  if (isGETDeviceLoading) {
    return <GateSkeleton />;
  }

  return (
    <>
      <Header canBack onClick={() => navigate(-1)}>
        {id ? "デバイス詳細" : "集中集合玄関機を追加"}
      </Header>
      <Main>
        <Card>
          <div className="flex flex-wrap justify-between">
            <Label htmlFor="gateNumber" required>
              ゲート番号
            </Label>
            {!!id || (
              <button
                className={cx(
                  "mb-2 inline w-fit",
                  "text-xs leading-6 text-[#0DB5B2]"
                )}
                onClick={navigateToHelpPage}
              >
                ゲート番号が分からない場合
              </button>
            )}
          </div>
          <GateNumberInput
            register={register}
            setValue={setValue}
            getValues={getValues}
            disabled={Boolean(id)}
          />
          <Label htmlFor="location" required className="mt-5">
            設置場所（識別名）
          </Label>
          <GateSpotInput
            watch={watch}
            register={register}
            setValue={setValue}
            getValues={getValues}
            options={gateSpotOptions}
            disabled={id}
          />
          <Label htmlFor="uri" className="mt-5">
            写真
          </Label>
          {!!id || (
            <p
              className={cx(
                "mb-3 text-xs font-normal leading-[18px] text-neutral-400"
              )}
            >
              画像は最大16MBです。横向きの写真を撮ってください。
              <br />
              下部に写真の注意事項があります。
            </p>
          )}
          <ImageControl name="uri" control={control} disabled={Boolean(id)} />
          <Label htmlFor="" required className="mt-5">
            棟に登録
          </Label>
          <p
            className={cx(
              "text-xs font-normal leading-[18px] text-neutral-400"
            )}
          >
            このエントランスを通過しない棟がある場合はチェックを外してください。
          </p>
          <BuildCheckboxControlGroup
            name="buildIds"
            control={control}
            gateNumber={getValues("gateNumber")}
            update={!!id}
          />
          {!id ? (
            <CreateButtonGroup
              onClick={handleSubmit(
                handleRegisterPosition,
                toastFormValueError
              )}
              loading={isLoading}
              disabled={!isAllFieldFilled}
            />
          ) : (
            <DearisGateSpotDetailButtonGroup
              buildIds={watch("buildIds")}
              formValues={getValues()}
            />
          )}
        </Card>
      </Main>
      <Footer />
    </>
  );
}

interface CreateButtonGroupProps {
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
}

export const CreateButtonGroup: React.FC<CreateButtonGroupProps> = ({
  ...props
}) => {
  return (
    <>
      <div className="mt-5">
        <Button {...props}>確認して提出</Button>
      </div>
      <XiezhenCaption />
    </>
  );
};
