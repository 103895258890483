import { cx } from "@emotion/css";

import SolidAddIcon from "@/assets/solid-plus.svg?react";
import { Card } from "@/components";

interface AddDearisButtonProps {
  children: React.ReactNode;
  className?: string;
  show: boolean;
  [key: string]: any;
}

export function AddDearisButton({
  children,
  className,
  show,
  ...props
}: Readonly<AddDearisButtonProps>) {
  if (!show) return null;
  return (
    <Card
      {...props}
      className={cx(
        "my-4 !py-3",
        "text-sm font-medium leading-[22px] text-primary",
        className
      )}
    >
      <SolidAddIcon className="mb-[2px] mr-3 inline h-4 w-4" />
      {children}
    </Card>
  );
}

export const MarkedAddDearisButton = ({
  children,
  show,
  className,
  ...props
}: AddDearisButtonProps) => {
  return (
    <AddDearisButton
      {...props}
      show={show}
      className={cx(
        "my-[10px] !rounded-[10px] !border-[1px] !border-solid !border-primary !bg-[#C7EFF5]/30",
        className
      )}
    >
      {children}
    </AddDearisButton>
  );
};
