import { useQuery } from "@tanstack/react-query";

import { NECESSARY_INFO_LACKING_HINT } from "@/shared";
import { getOptionsList } from "@/shared/requests/property";
import { getStorage } from "@/shared/store";

import { NecessaryInfoLackingError } from "./errors.type";
import { SERVICE_OPTION_QUERY_KEY, SERVICE_QUERY_KEY } from "./queryKey";

export function useListOption() {
  const property_id = getStorage("propertyId");
  const queryFn = async () => {
    if (!property_id)
      throw new NecessaryInfoLackingError(NECESSARY_INFO_LACKING_HINT);
    return getOptionsList({ property_id, service_type: "options" });
  };
  return useQuery({
    queryKey: [SERVICE_QUERY_KEY, SERVICE_OPTION_QUERY_KEY, property_id],
    queryFn,
    enabled: !!property_id,
  });
}
