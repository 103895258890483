import { useNavigate } from "react-router-dom";

import { Button, Footer, Header, Main } from "@/components";
import {
  Step,
  StepContainer,
  StepParagraph,
  StepsGenerator,
} from "@/components/step";

import { patmoStepsData } from "./patmo-steps-data";

export function PATMORestart() {
  const navigate = useNavigate();
  return (
    <>
      <Header>通信アダプターリセット</Header>
      <Main>
        <StepContainer>
          <StepsGenerator stepsData={patmoStepsData} />
          {/* Step End */}
          <Step>
            <StepParagraph>「次へ」をタップして設定を続けます。</StepParagraph>
            <Button onClick={() => navigate("/status")}>リセットが完了</Button>
          </Step>
        </StepContainer>
      </Main>
      <Footer />
    </>
  );
}
