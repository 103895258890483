import type { AxiosResponse } from "axios";

import type {
  GetLineInfoDeviceIdParams,
  GetLineInfoDeviceIdResponse,
  GetLineInfoIMEIParams,
  GetLineInfoIMEIResponse,
  OpenLineDeviceIdParams,
  OpenLineIMEIParams,
} from "./line.type";
import server from "./server";

export const getLineInfoByIMEI = ({ imei }: GetLineInfoIMEIParams) => {
  return server.get<unknown, AxiosResponse<GetLineInfoIMEIResponse>>(
    `/devices/line/${imei}/line`
  );
};

export const getLineInfoByDeviceId = ({
  device_id,
}: GetLineInfoDeviceIdParams) => {
  return server.get<unknown, AxiosResponse<GetLineInfoDeviceIdResponse>>(
    `/devices/${device_id}/line`
  );
};

export const openLineByIMEI = ({ imei }: OpenLineIMEIParams) => {
  return server.post(`/devices/line/${imei}/line`);
};

export const openLineByDeviceId = ({ device_id }: OpenLineDeviceIdParams) => {
  return server.post(`/devices/${device_id}/line`);
};
