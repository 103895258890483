import { useHandleDeviceUnknown } from "@/hooks";
import { getStorage } from "@/shared/store";
import type { DeviceType } from "@/types";

import LiteUnlockOverview from "./lite";
import PATMOUnlockOverview from "./patmo";

export default function () {
  const deviceType = getStorage("deviceType") as DeviceType;
  const { toastAndToSelectDevice } = useHandleDeviceUnknown();
  switch (deviceType) {
    case "PabbitLite":
      return <LiteUnlockOverview />;
    case "PATMO":
      return <PATMOUnlockOverview />;
    default: {
      toastAndToSelectDevice();
    }
  }
}
