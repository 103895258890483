import { useNavigate } from "react-router-dom";

import { Button, Card, Footer, Header, Main } from "@/components";
import { useGetScannerData } from "@/hooks";

import { DataItem, Warning } from "../components";

export default function DearisDataConfirm() {
  const navigate = useNavigate();
  useGetScannerData();
  return (
    <>
      <Header>デバイス情報の確認</Header>
      <Main>
        <Card>
          <Warning>
            ここに表示されている情報がデバイス側の情報と一致していることを確認した後、確認を押して次のステップに進んでください。
          </Warning>
          <DataItem
            label="シリアル番号"
            content="VMXSTDO1234567"
            className="mt-10"
          />
          <DataItem label="棟番号" content="0" className="mt-5" />
          <DataItem label="ゲート番号" content="1" className="mt-5" />
          <div className="mb-6 mt-10">
            <Button onClick={() => navigate("/configure")}>確認</Button>
          </div>
        </Card>
      </Main>
      <Footer />
    </>
  );
}
