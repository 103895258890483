import { cx } from "@emotion/css";
import type { FC, InputHTMLAttributes, ReactNode } from "react";
import type { UseFormGetValues, UseFormRegisterReturn, UseFormSetValue } from "react-hook-form";

import CloseIcon from "@/assets/close.svg?react";

import { PlainLoading } from "..";

interface InputWithClearProps extends InputHTMLAttributes<HTMLInputElement> {
  register: UseFormRegisterReturn<any>;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  helper?: ReactNode;
  styleType?: "shadow" | "outline";
  isLoading?: boolean;
}
export const InputWithClear: FC<InputWithClearProps> = ({
  register,
  setValue,
  getValues,
  className,
  helper,
  styleType = "outline",
  isLoading,
  ...props
}) => {
  return (
    <div className={cx("relative flex flex-col", className)}>
      <input
        className={cx([
          "form-input rounded-lg p-3 text-[14px] leading-[22px] outline-0",
          "placeholder:text-text-gray",
          "focus:border-transparent focus:ring-primary",
          "rounded-md",
          {
            "border-none  bg-[#F2F4F5] outline-gray-500": styleType === "shadow",
            "border-solid border-[#E3E5E5]": styleType === "outline",
          },
          props.disabled || "pr-8",
          props.disabled && "bg-[#F7F9FA]",
        ])}
        style={{ boxShadow: "0 0 #0000" }}
        {...props}
        {...register}
      />
      <span
        className={cx(
          "absolute right-0 flex h-4 items-center p-4",
          getValues(register.name) || getValues(register.name) === 0 || "hidden",
          props.disabled && "hidden"
        )}
        onClick={() => setValue(register.name, "")}
      >
        <CloseIcon className={cx("h-4 w-4")} />
      </span>
      {isLoading && <PlainLoading className="absolute right-0 h-6 p-4" />}
      {helper && <div className="mt-2 text-[15px] text-text-gray">{helper}</div>}
    </div>
  );
};
