import DearisRestartIndexImage from "@/assets/dearis-restart-index.png";
import type { StepsDataModal } from "@/components/step";

export const dearisStepsData: StepsDataModal = {
  steps: [
    // step index
    [
      {
        type: "image",
        content: {
          src: DearisRestartIndexImage,
          caption: "dearisの設定ページ",
        },
      },
      {
        type: "caption",
        content:
          "画面が消灯したら、トップページに戻った場合はPabbitのボタンを押して、次画面の左上を連続して３回タッチします。",
      },
    ],
    // step 1
    [
      { type: "title", content: "操作手順" },
      {
        type: "text",
        content: "設定ページに「サービスリストを再取得」を押してください。",
        indicator: 1,
      },
    ],
    // step 2
    [
      {
        type: "text",
        content: "成功したら、「終了」を押してホームページに戻ります。",
        indicator: 2,
      },
    ],
    // step end
    [
      {
        type: "text",
        content:
          "受信に失敗した場合、デバイスはエラーメッセージを表示します。通信アダプターを再起動するか、他のデバイスに交換してください。",
      },
    ],
  ],
};
