import { cx } from "@emotion/css";

import { useAuthImgAuto } from "@/queries";
import { errorToast } from "@/shared";

export const Image = ({
  url,
  className,
}: {
  url: string;
  className?: string;
}) => {
  const { data, isError } = useAuthImgAuto(url);
  if (isError) {
    errorToast("画像の読み込みに失敗しました。");
  }
  return (
    <img
      src={data?.data.uri}
      className={cx(
        "aspect-video w-full rounded-md bg-[#F7F9FA] bg-xiezhen-empty bg-center bg-no-repeat",
        className
      )}
    />
  );
};
