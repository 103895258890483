import { cx } from "@emotion/css";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { prefectureOptions } from "@/app.constant";
import { Button, Card, Footer, Header, Main } from "@/components";
import {
  FormControl,
  InputWithClear,
  Label,
  SelectInput,
} from "@/components/form";
import { useFormErrorsToast, useVisibilityToggle } from "@/hooks";

interface ObjectSearchForm {
  objectName: string;
  prefecture_name: string;
  city_name: string;
  rest_address: string;
}
export const LiteSearch = () => {
  const navigate = useNavigate();
  const { watch, register, setValue, getValues, handleSubmit } =
    useForm<ObjectSearchForm>();
  const { toastFormValueError } = useFormErrorsToast();
  const [isAllFieldFilled] = useVisibilityToggle(
    watch("objectName"),
    watch("prefecture_name"),
    watch("city_name")
  );
  watch("rest_address");

  const search = (values: ObjectSearchForm) => {
    const { objectName, prefecture_name, city_name, rest_address } = values;
    navigate(
      `/configure/results?prefecture=${prefecture_name}&city=${city_name}&address=${rest_address}&name=${objectName}`
    );
  };
  return (
    <>
      <Header>物件検索</Header>
      <Main>
        <Card>
          <FormControl>
            <Label htmlFor="" required="（必須）">
              物件名(仮称名称)
            </Label>
            <InputWithClear
              register={register("objectName", {
                required: "必須内容を選択または入力してください",
              })}
              setValue={setValue}
              getValues={getValues}
              placeholder="物件名を入力"
            />
          </FormControl>
          <FormControl className="mt-4">
            <Label htmlFor="" required="（必須）">
              都道府県
            </Label>
            <SelectInput
              register={register("prefecture_name", {
                required: "必須内容を選択または入力してください",
              })}
              setValue={setValue}
              placeholder="都道府県を選択"
              options={prefectureOptions}
            />
          </FormControl>
          <FormControl className="mt-4">
            <Label htmlFor="" required="（必須）">
              市区町村
            </Label>
            <InputWithClear
              register={register("city_name", {
                required: "必須内容を選択または入力してください",
              })}
              setValue={setValue}
              getValues={getValues}
              placeholder="市区町村を入力"
            />
          </FormControl>
          <FormControl className="mt-4">
            <Label htmlFor="">丁目番地号</Label>
            <InputWithClear
              register={register("rest_address")}
              setValue={setValue}
              getValues={getValues}
              placeholder="丁目番地号を入力"
            />
          </FormControl>
          <div className={cx("mt-2", "text-xs leading-6 text-[#909899]")}>
            <p>例： 一丁目2-2</p>
            <p>地番情報も検索できます。</p>
          </div>
          <div className="mt-5">
            <Button
              onClick={handleSubmit(search, toastFormValueError)}
              disabled={!isAllFieldFilled}
            >
              検索
            </Button>
          </div>
        </Card>
        <div className="py-2 text-xs text-[#909899]">
          <span className="leading-5 text-[#F51E1E]">※</span>&nbsp;
          該当する物件が検索されない場合はオーナー又は管理会社にてPabbitの利用申込が行われておりません。
          <br />
          Pabbitをご利用する場合はオーナー又は管理会社にPabbitの利用申込を行ってもらってください。
        </div>
      </Main>
      <Footer />
    </>
  );
};
