import { cx } from "@emotion/css";
import { type FunctionComponent, memo, type SVGProps } from "react";

import DoorIcon from "@/assets/door.svg?react";
import ElevatorIcon from "@/assets/elevator.svg?react";
import GateIcon from "@/assets/gate-login.svg?react";
import { Card, Tag } from "@/components";
import type { Device } from "@/types";

import { Image } from ".";

interface BasicCardProps {
  device: Device;
  Icon: FunctionComponent<SVGProps<SVGSVGElement & { className: string }>>;
}
function BasicCard({ device, Icon }: Readonly<BasicCardProps>) {
  const unlocked = device.position?.test_passed;
  return (
    <Card className="my-2 px-3 py-4">
      <div className="flex items-start justify-between gap-6">
        <div className="flex">
          <Icon className="my-[3px] mr-2 inline h-4 w-4 shrink-0" />
          <p className="text-sm font-medium leading-[22px]">
            {device.position?.spot}
          </p>
        </div>

        <div className="relative shrink-0 basis-18">
          <Tag color="#FFFFFF" background="#00AFCC" padding="0 20px">
            解錠
            <span
              className="absolute left-0 top-0 z-20 h-full w-full leading-6"
              data-device-id={device.id}
            ></span>
          </Tag>
          <span
            className={cx(
              "block text-center text-[10px] leading-[14px]",
              unlocked || "text-error"
            )}
          >
            {unlocked || "未完了"}
          </span>
        </div>
      </div>
      <div className="flex gap-10 text-sm text-green-gray">
        <p>
          <span>棟番号：</span>
          <span>{device.position?.build_id}</span>
        </p>
        <p>
          <span>ゲート番号：</span>
          <span>{device.position?.gate_number}</span>
        </p>
      </div>
      <div className="mt-1">
        <Image uri={device.position?.image_uri || ""} />
      </div>
    </Card>
  );
}

const EntranceCard = ({ device }: { device: Device }) => {
  return <BasicCard device={device} Icon={DoorIcon} />;
};
const ElevatorCard = ({ device }: { device: Device }) => {
  return <BasicCard device={device} Icon={ElevatorIcon} />;
};

const GateCard = ({ device }: { device: Device }) => {
  return <BasicCard device={device} Icon={GateIcon} />;
};

interface DearisDeviceListProps {
  devices: Device[] | undefined;
}

const MemoEntranceList = memo(function ({ devices }: DearisDeviceListProps) {
  return (
    <>
      {devices?.map((entrance) => (
        <EntranceCard key={entrance.id} device={entrance} />
      ))}
    </>
  );
});

const MemoElevatorList = memo(function ({ devices }: DearisDeviceListProps) {
  return (
    <>
      {devices?.map((elevator) => (
        <ElevatorCard key={elevator.id} device={elevator} />
      ))}
    </>
  );
});

export { GateCard, MemoElevatorList, MemoEntranceList };
