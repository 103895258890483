import ConsentParagraph from "./ConsentParagraph";
import ConsentParagraphSecond from "./ConsentParagraphSecond";
import ConsentParagraphThird from "./ConsentParagraphThird";
import ConsentTitle from "./ConsentTitle";

const ConsentContent = () => {
  return (
    <div className="text-neutral-600">
      <h2 className="text-xl text-center">Pabbit Entrance設定ツール利用規約</h2>
      <ConsentTitle>第 1 条（目的）</ConsentTitle>
      <ConsentParagraphSecond>
        1. 本 Pabbit Entrance
        設定ツール利用規約（以下、「本利用規約」といいます。）は、株式会社
        PacPort（以下、「当社」といいます。）がアイホン株式会社と共同して提供するPabbit
        Entrance 設定
        ツール（以下、「本ツール」といいます。）を利用する施工会社及びそのご担当者（以下、「利用者」と
        いいます。なお、本ツールは、Pabbit Entrance
        設定を行う設定者向けに提供するものであり、その他の
        者に提供するものではありません。）に適用されます。利用者は、本利用規約に同意の上、Pabbit
        Entrance 設定の目的に限り、本ツールを利用するものとします。
      </ConsentParagraphSecond>
      <ConsentParagraphSecond>
        2.{" "}
        本利用規約は、本ツールの利用条件を定めています。本ツールの利用者は、本利用規約の定める条件に
        従って本ツールを利用するものとします。
      </ConsentParagraphSecond>
      <ConsentParagraphSecond>
        3.{" "}
        利用者が本利用規約に同意することにより、当社との間に本契約が成立します。
      </ConsentParagraphSecond>

      <ConsentTitle>第 2 条（定義）</ConsentTitle>
      <ConsentParagraph>
        本利用規約において使用する以下の用語は、以下の各号に定める意味を有します。
      </ConsentParagraph>
      <ConsentParagraphThird>
        (1)「本契約」：本利用規約を契約条件として当社及び利用者との間で締結される、本ツールの利用契約
        を指します。
      </ConsentParagraphThird>
      <ConsentParagraphThird>
        (2)「Pabbit Entrance 設定」：集合玄関機を用いて、Pabbit
        クラウドに登録されている物件情報と設
        定対象となる集合玄関機を構成するインターホンシステムの紐づけを行う設定を指します。
      </ConsentParagraphThird>
      <ConsentParagraphThird>
        (3)「パスワード」：本ツールを用いたPabbit Entrance
        設定を完了させる上で必要な情報を指します。
        利用者は、本ツール上に携帯電話番号を登録し、SMS
        にてパスワードを取得します。
      </ConsentParagraphThird>

      <ConsentTitle>第 3 条（Pabbit Entrance設定）</ConsentTitle>
      <ConsentParagraphSecond>
        1.{" "}
        本ツールの利用者は、本利用規約の内容に同意の上、当社が定める手続によりPabbit
        Entrance 設定を 行います。
      </ConsentParagraphSecond>
      <ConsentParagraphSecond>
        2. 当社は、当社の裁量により、本ツールの利用やPabbit Entrance
        設定を拒否する場合があります。
      </ConsentParagraphSecond>

      <ConsentTitle>第 4 条（通信に関する管理）</ConsentTitle>
      <ConsentParagraphSecond>
        1. 利用者は、本ツールの提供を受けるため及びPabbit Entrance
        設定を完了させるために必要な通信手段
        を全て自らの費用と責任で備えるものとします。また、本ツールの利用にあたり必要となる通信費用は、
        全て利用者の負担とします。
      </ConsentParagraphSecond>
      <ConsentParagraphSecond>
        2.{" "}
        利用者は、パスワード及び通信手段の管理責任を負います。パスワード及び通信手段の管理不十分、使用
        上の過誤、第三者の使用等による損害の責任は利用者が負い、当社は当社に故意又は重過失のない限り一
        切の責任を負いません。
      </ConsentParagraphSecond>

      <ConsentTitle>第 5 条（本ツールの停止・変更・廃止等）</ConsentTitle>
      <ConsentParagraphSecond>
        １．
        当社は、当社が必要と認めるときは、メンテナンス等のために、利用者に通知することなく、本ツール
        の利用の停止又は本ツールの内容の変更を行うことができるものとします。
      </ConsentParagraphSecond>
      <ConsentParagraphSecond>
        ２．
        当社は、当社が本ツールの提供を廃止すべきと合理的に判断した場合、本ツールの提供を廃止できるも
        のとします。
      </ConsentParagraphSecond>
      <ConsentParagraphSecond>
        ３．
        本ツールの利用の停止、本ツールの内容の変更又は本ツールの提供の廃止により利用者に損害が生じた
        場合であっても、当社は一切の責任を負いません。
      </ConsentParagraphSecond>

      <ConsentTitle>第 6 条（知的財産権等）</ConsentTitle>
      <ConsentParagraphSecond>
        1.{" "}
        本ツールに関する著作権、特許権、実用新案権、商標権、意匠権その他一切の知的財産権及びこれらの権
        利の登録を受ける権利（以下総称して「知的財産権」といいます。）並びに本ツールに関するその他一切
        の権利は、当社又は当社がライセンスを受けているライセンサーに帰属し、利用者には帰属しません。ま
        た、利用者は、知的財産権の存否にかかわらず、本ツールについて、複製、配布、転載、転送、公衆送信、
        改変、翻案その他の二次利用等を行ってはなりません。
      </ConsentParagraphSecond>
      <ConsentParagraphSecond>
        2.{" "}
        利用者が本条の規定に違反して問題が発生した場合、利用者は、自己の費用と責任において当該問題を解
        決するとともに、当社に何らの不利益、負担又は損害を与えないよう適切な措置を講じなければならない
        ものとします。
      </ConsentParagraphSecond>

      <ConsentTitle>第 7 条（禁止事項）</ConsentTitle>
      <ConsentParagraphSecond>
        1.{" "}
        当社は、利用者による本ツールの利用に際して、以下の各号に定める行為を禁止します。
      </ConsentParagraphSecond>
      <ConsentParagraphThird>(1)本利用規約に違反する行為</ConsentParagraphThird>
      <ConsentParagraphThird>
        (2)Pabbit Entrance 設定以外の目的で本ツールを利用する行為
      </ConsentParagraphThird>
      <ConsentParagraphThird>
        (3)Pabbit Entrance 設定の対象となる物件以外の物件とPabbit Entrance
        設定を試みる又は実施する 行為
      </ConsentParagraphThird>
      <ConsentParagraphThird>
        (4)当社、当社がライセンスを受けているライセンサーその他第三者の知的財産権、肖像権等の財産的又
        は人格的な権利を侵害する行為又はこれらを侵害するおそれのある行為
      </ConsentParagraphThird>
      <ConsentParagraphThird>
        (5)当社又は第三者に不利益若しくは損害を与える行為又はそのおそれのある行為
      </ConsentParagraphThird>
      <ConsentParagraphThird>
        (6)不当に他人の名誉や権利、信用を傷つける行為又はそのおそれのある行為
      </ConsentParagraphThird>
      <ConsentParagraphThird>
        (7)法令又は条例等に違反する行為
      </ConsentParagraphThird>
      <ConsentParagraphThird>
        (8)公序良俗に反する行為若しくはそのおそれのある行為
      </ConsentParagraphThird>
      <ConsentParagraphThird>
        (9)犯罪行為、犯罪行為に結びつく行為若しくはこれを助長する行為又はそのおそれのある行為
      </ConsentParagraphThird>
      <ConsentParagraphThird>
        (10)当社のシステムへの不正アクセス、それに伴うプログラムコードの改ざん、クラウドに登録している
        情報の改ざん、故意に虚偽、通信機器の仕様その他アプリケーションを利用してのチート行為、コン
        ピューターウィルスの頒布その他本ツールの正常な運営を妨げる行為又はそのおそれのある行為
      </ConsentParagraphThird>
      <ConsentParagraphThird>
        (11)本ツールの信用を損なう行為又はそのおそれのある行為
      </ConsentParagraphThird>
      <ConsentParagraphThird>
        (12)第三者になりすまして本ツールを利用する行為
      </ConsentParagraphThird>
      <ConsentParagraphThird>
        (13)その他当社が不適当と判断する行為
      </ConsentParagraphThird>

      <ConsentParagraphSecond>
        2. 当社は、利用者の行為が、第1
        項各号のいずれかに該当すると判断した場合、事前に通知することなく、以下の各号のいずれか又は全ての措置を講じることができます。
      </ConsentParagraphSecond>
      <ConsentParagraphThird>(1)本ツールの利用制限</ConsentParagraphThird>
      <ConsentParagraphThird>
        (2)その他当社が必要と合理的に判断する行為
      </ConsentParagraphThird>

      <ConsentTitle>第 8 条（非保証・免責）</ConsentTitle>
      <ConsentParagraphSecond>
        1.{" "}
        当社は、本ツールに瑕疵のないこと、本ツールが特定の目的に適合すること、本ツールが利用者及び第三
        者に損害を与えないこと、本ツールの完全性、正確性、有用性、適法性及び有効性等について、一切の保
        証をしません。また、当社は、本ツールに中断、中止その他の障害が生じないことを保証しません。さら
        に、当社は、本ツールに起因して生じた損害について一切の責任を負いません。
      </ConsentParagraphSecond>
      <ConsentParagraphSecond>
        2.{" "}
        利用者が本ツールを利用するにあたり、本ツールから本ツールに関わる当社又は第三者が運営する他の
        ツール（以下「外部ツール」といいます。）に遷移する場合があります。その場合、利用者は、自らの責
        任と負担で外部ツールの利用規約等に同意の上、本ツール及び外部ツールを利用します。なお、当社は、
        当社が提供する外部ツールを除き、外部ツールに瑕疵のないこと、外部ツールが特定の目的に適合するこ
        と、外部ツールが利用者及び第三者に損害を与えないこと、外部ツールの完全性、正確性、有用性、適法
        性及び有効性等について、一切の保証をしません。また、当社は、外部ツールに起因して生じた損害につ
        いて一切の責任を負いません。
      </ConsentParagraphSecond>
      <ConsentParagraphSecond>
        3.{" "}
        利用者は、法令の範囲内で本ツールをご利用ください。本ツールの利用に関連して利用者が日本又は外国
        の法令に触れた場合でも、当社は一切の責任を負いません。
      </ConsentParagraphSecond>
      <ConsentParagraphSecond>
        4.{" "}
        予期しない不正アクセス等の行為によって利用者に関する情報を盗取された場合でも、それによって生じ
        る利用者の損害等に対して、当社は一切の責任を負いません。
      </ConsentParagraphSecond>
      <ConsentParagraphSecond>
        5.{" "}
        当社は、天災、地変、火災、ストライキ、通商停止、戦争、暴動、内乱、感染症の流行、通信回線の障害
        その他の不可抗力により本契約の全部又は一部に不履行が発生した場合、一切の責任を負いません。
      </ConsentParagraphSecond>
      <ConsentParagraphSecond>
        6.{" "}
        本ツールの利用に関し、利用者が他の利用者との間でトラブル（本ツール内外を問いません。）になった
        場合でも、当社は一切の責任を負わず、利用者間のトラブルは、当該利用者が自らの費用と負担において
        解決するものとします。
      </ConsentParagraphSecond>

      <ConsentTitle>第 9 条（損害賠償）</ConsentTitle>
      <ConsentParagraph>
        利用者は、本利用規約の違反又は本ツールの利用に関連して当社に損害を与えた場合、当社に発生した損
        害（逸失利益及び弁護士費用を含みます。）を賠償しなければならないものとします。
      </ConsentParagraph>
      <ConsentTitle>第 10 条（秘密保持）</ConsentTitle>
      <ConsentParagraphSecond>
        1.{" "}
        利用者は、本ツールの利用に関連して知得した当社の秘密情報（本ツールに関するノウハウ、当社のシス
        テムに関する情報、技術上又は営業上の一切の秘密情報を含みます。）を、厳重かつ適正に管理するもの
        とし、当社の事前の書面による同意なく第三者に開示、提供及び漏洩し、又は本ツールの提供若しくは利
        用の目的以外に使用してはならないものとします。
      </ConsentParagraphSecond>
      <ConsentParagraphSecond>
        2. 次の各号の情報は、秘密情報に該当しないものとします。
      </ConsentParagraphSecond>
      <ConsentParagraphThird>
        (1) 開示を受けた時、既に保有していた情報
      </ConsentParagraphThird>
      <ConsentParagraphThird>
        (2)
        開示を受けた時、既に公知であった情報又はその後利用者の責に帰さない事由により公知となった情
        報
      </ConsentParagraphThird>
      <ConsentParagraphThird>
        (3) 開示を受けた後に、第三者から合法的に取得した情報
      </ConsentParagraphThird>
      <ConsentParagraphThird>
        (4) 開示された秘密情報によらず利用者が独自に開発し又は創作した情報
      </ConsentParagraphThird>
      <ConsentParagraphSecond>
        3.利用者は、当社の指示があった場合又は本契約が終了した場合は、当社の指示に従い速やかに秘密情報を、
        原状に回復した上で返却又は廃棄し、以後使用しないものとします。
      </ConsentParagraphSecond>

      <ConsentTitle>第 11 条（反社会的勢力の排除）</ConsentTitle>
      <ConsentParagraphSecond>
        1. 利用者は、現在、暴力団、暴力団員、暴力団員でなくなった時から5
        年を経過しない者、暴力団準構成員、
        暴力団関係企業、総会屋等、社会運動等標ぼうゴロ又は特殊知能暴力集団等、その他これらに準ずる者
        （以下総称して「暴力団員等」といいます。）に該当しないこと、及び次の各号のいずれにも該当しない
        ことを表明し、かつ将来にわたっても該当しないことを保証します。
      </ConsentParagraphSecond>
      <ConsentParagraphThird>
        (1)暴力団員等が経営を支配していると認められる関係を有すること。
      </ConsentParagraphThird>
      <ConsentParagraphThird>
        (2)暴力団員等が経営に実質的に関与していると認められる関係を有すること。
      </ConsentParagraphThird>
      <ConsentParagraphThird>
        (3)自己、自社若しくは第三者の不正の利益を図る目的又は第三者に損害を加える目的をもってする等、
        不当に暴力団員等を利用していると認められる関係を有すること。
      </ConsentParagraphThird>
      <ConsentParagraphThird>
        (4)暴力団員等に対して資金等を提供し、又は便宜を供与する等の関与をしていると認められる関係を有
        すること。
      </ConsentParagraphThird>
      <ConsentParagraphThird>
        (5)役員又は経営に実質的に関与している者が暴力団員等と社会的に非難されるべき関係を有すること。
      </ConsentParagraphThird>

      <ConsentParagraphSecond>
        2.{" "}
        利用者は、自ら又は第三者を利用して次の各号のいずれかに該当する行為を行わないことを確約します。
      </ConsentParagraphSecond>
      <ConsentParagraphThird>(1)暴力的な要求行為</ConsentParagraphThird>
      <ConsentParagraphThird>
        (2)法的な責任を超えた不当な要求行為
      </ConsentParagraphThird>
      <ConsentParagraphThird>
        (3)取引に関して、脅迫的な言動をし、又は暴力を用いる行為
      </ConsentParagraphThird>
      <ConsentParagraphThird>
        (4)風説を流布し、偽計を用い又は威力を用いて相手方の信用を毀損し、又は相手方の業務を妨害する行
        為
      </ConsentParagraphThird>
      <ConsentParagraphThird>(5)その他前各号に準ずる行為</ConsentParagraphThird>
      <ConsentParagraphSecond>
        3. 当社は、利用者が、暴力団員等若しくは第1
        項各号のいずれかに該当し、若しくは前項各号のいずれかに
        該当する行為をし、又は第1
        項の規定にもとづく表明・保証に関して虚偽の申告をしたことが判明した場
        合には、自己の責に帰すべき事由の有無を問わず、相手方に対して何らの催告をすることなく本契約を解
        除することができます。
      </ConsentParagraphSecond>
      <ConsentParagraphSecond>
        4.{" "}
        当社は、前項により本契約を解除した場合、利用者に損害が生じたとしてもこれを一切賠償する責任を負
        いません。
      </ConsentParagraphSecond>
      <ConsentTitle>第 12 条（地位の譲渡等）</ConsentTitle>
      <ConsentParagraph>
        利用者は、当社の書面による事前の承諾なく、本契約上の地位又は本利用規約に基づく権利若しくは義務の
        全部又は一部につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。
      </ConsentParagraph>
      <ConsentTitle>第 13 条（個人情報の取り扱い）</ConsentTitle>
      <ConsentParagraph>
        本ツールにおける個人情報の取り扱いに関しては、当社が定める「個人情報の取扱いに関する通知（本利用
        規約の同意後、表示されます。）」及び「プライバシーポリシー（https://pacport.com/privacy/）」に基
        づき取扱います。
      </ConsentParagraph>
      <ConsentTitle>第 14 条（分離可能性）</ConsentTitle>
      <ConsentParagraphSecond>
        1.{" "}
        本利用規約の規定の一部が法令に基づいて無効と判断されても、本利用規約の他の規定は有効とします。
      </ConsentParagraphSecond>
      <ConsentParagraphSecond>
        2.{" "}
        本利用規約の規定の一部がある利用者との関係で無効又は取消となった場合でも、本利用規約は他の利用
        者との関係では有効とします。
      </ConsentParagraphSecond>
      <ConsentTitle>第 15 条（本契約の有効期間）</ConsentTitle>
      <ConsentParagraph>
        本契約の有効期間は、本契約成立時から利用者がPabbit Entrance
        設定を完了するまでの間とします。なお、 第4 条第2 項、第5 条第3 項、第6
        条、第８条、第９条、第10 条、本条、第16 条及び第17 条の規定は、
        本契約の終了後も有効に存続するものとします。
      </ConsentParagraph>
      <ConsentTitle>第 16 条（準拠法）</ConsentTitle>
      <ConsentParagraph>
        本利用規約は、日本国の法令に準拠し、これに従って解釈されるものとします。
      </ConsentParagraph>
      <ConsentTitle>第 17 条（合意管轄）</ConsentTitle>
      <ConsentParagraph>
        利用者と当社との間における一切の訴訟は、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
      </ConsentParagraph>
      <ConsentTitle>第 18 条（お問い合わせ）</ConsentTitle>
      <ConsentParagraphSecond>
        1.{" "}
        当社は、本ツールに関する利用者からのお問い合わせに対して回答するよう努めますが、法令又は本利用
        規約上、当社に義務又は責任が発生する場合を除き、回答の義務を負いません。
      </ConsentParagraphSecond>
      <ConsentParagraphSecond>
        2. 本ツールに関するお問い合わせ先は下記になります。
        <br />
        アイホン株式会社（Pabbit に関する当社の共同事業者）
        <br />住 所：〒460-0004
        愛知県名古屋市中区新栄町一丁目１番　明治安田生命名古屋ビル10 階
        <br /> T E L：052-228-8181
      </ConsentParagraphSecond>
      <ConsentTitle>第 19 条（その他）</ConsentTitle>
      <ConsentParagraphSecond>
        1.{" "}
        利用者は、本利用規約に定めのない事項について、当社が細目等を別途定めた場合、これに従います。こ
        の場合、当該細目等は、本利用規約と一体をなします。
      </ConsentParagraphSecond>
      <ConsentParagraphSecond>
        2. 細目等は、当社所定の箇所に掲載した時点より効力を生じます。
      </ConsentParagraphSecond>
      <ConsentParagraphSecond>
        3. 細目等と本利用規約の内容に矛盾抵触がある場合、本利用規約が優先します
      </ConsentParagraphSecond>
      <footer className="text-xs font-normal text-neutral-400">
        以上 付則 ２０２３年１０月２３日：制定・施行
      </footer>

      <h2 className="text-xl text-center">個人情報の取扱いに関する通知</h2>
      <ConsentParagraph>
        株式会社PacPort（以下、「当社」といいます。）は、当社がアイホン株式会社と共同して提供する
        Pabbit Entrance
        設定ツール（以下「設定ツール」といいます。）を利用する施工会社のご担当者（以
        下「利用者」といいます。）からご提供頂いた個人情報（当社が設定ツールを通じて取得する情報で個
        人情報に該当し得るものは利用者の電話番号のみです。）について、以下の取扱いを致します。なお、
        本通知は当社が提供する設定ツールのみに適用されるものとします。
      </ConsentParagraph>
      <ConsentTitle>第 1 条（利用目的）</ConsentTitle>
      <ConsentParagraph>
        当社は、以下の目的に必要な範囲で、利用者の個人情報を取得し、取得した個人情報を利用させてい
        ただきます。以下の目的の範囲を超えて個人情報を利用する場合には、事前に適切な方法で利用者か
        らの同意を得るものとします。
      </ConsentParagraph>
      <ConsentParagraphThird>
        (1)
        設定ツールの不正利用を防止することを目的として、設定ツールのログインに使用するパス
        ワードを利用者に送付するため
      </ConsentParagraphThird>
      <ConsentParagraphThird>
        (2)
        設定ツールの登録完了後、利用者に対し設定ツールに関する連絡を行う場合があるため
      </ConsentParagraphThird>
      <ConsentTitle>第 2 条（共同利用）</ConsentTitle>
      <ConsentParagraph>
        当社は、設定ツールで取得した個人情報について、以下のとおり、共同利用いたします。
      </ConsentParagraph>
      <ConsentParagraphThird>
        (1) 共同して利用される個人情報の項目
        <div className="mt-2">・電話番号</div>
      </ConsentParagraphThird>
      <ConsentParagraphThird>
        (2) 共同して利用する者の利用目的
        <div className="mt-2">
          ・設定ツールの登録完了後、利用者に対し設定ツールに関する連絡を行う場合があるため
        </div>
      </ConsentParagraphThird>
      <ConsentParagraphThird>
        (3) 共同して利用する者の範囲
        <p>アイホン株式会社</p>
        <p>
          住 所：〒460-0004
          愛知県名古屋市中区新栄町一丁目１番　明治安田生命名古屋ビル10 階
        </p>
        <p>T E L：052-228-8181</p>
        <p>
          （
          土・日・祝日など弊社休業日のお問い合わせは、翌営業日以降の回答となります。）
        </p>
        <p>※ 代表者名：鈴木　富雄</p>
      </ConsentParagraphThird>
      <ConsentParagraphThird>
        (4) 共同利用される個人情報の管理について責任を有する者
        <p>株式会社 PacPort</p>
        <p>
          住 所：〒100-0004　東京都千代田区大手町二丁目 7 番 1 号 TOKIWABRIDGE
          13F
        </p>
        <p>xTECH</p>
        <p>WEB 問合せ：https://pacport.co.jp/contact</p>
        <p>
          （
          土・日・祝日など弊社休業日のお問い合わせは、翌営業日以降の回答となります。）
        </p>
        <p>※ 代表者名:沈 燁</p>
      </ConsentParagraphThird>
      <ConsentTitle>第 3 条（その他）</ConsentTitle>
      <ConsentParagraph>
        当社は、本通知に定めるもののほか、当社が定めた個人情報保護方針に従い、適切に個人情報を取
        扱うものとします。
        <p>株式会社PacPort：http://pacport.com/privacy/</p>
        <p>
          なお、アイホン株式会社の個人情報保護方針については下記ウェブページをご参照ください。
        </p>
        <p>アイホン株式会社：https://www.aiphone.co.jp/privacy/ 以上</p>
      </ConsentParagraph>
      <footer className="text-xs text-neutral-400 font-normal">
        以上 ２０２３年１０月２３日制定・施行
      </footer>
    </div>
  );
};

export default ConsentContent;
