import { useEffect } from "react";
import { type FieldErrors, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Card,
  Footer,
  Header,
  Main,
  Popup,
  PopupAction,
  PopupMain,
} from "@/components";
import { Label } from "@/components/form";
import {
  useFormErrorsToast,
  useGetScannerData,
  useModal,
  useVisibilityToggle,
} from "@/hooks";
import { IMEIInput } from "@/pages/registerDevice/components";
import { useCheckLineExistMutation } from "@/queries";
import { errorToast } from "@/shared";
import { getStorage, setStorage } from "@/shared/store";

interface IMEIReplaceForm {
  imei: string;
}

export default function IMEIReplaceManualRegister() {
  const navigate = useNavigate();
  const { watch, register, setValue, getValues, handleSubmit } =
    useForm<IMEIReplaceForm>();
  const [isAllFilled] = useVisibilityToggle(watch("imei"));
  const { toastFormValueError } = useFormErrorsToast();
  const { visible, toggle } = useModal();

  const { mutateAsync: checkIMEI, isLoading } = useCheckLineExistMutation();

  const confirmRegister = (data: IMEIReplaceForm) => {
    setStorage("imei", data.imei);
    checkIMEI()
      .then(() => {
        navigate("/imei-replace");
      })
      .catch((error) => {
        if (error?.status === 400) {
          toggle();
        } else {
          errorToast(error?.message ?? error.toString());
        }
      });
  };

  useGetScannerData();
  useEffect(() => {
    const imei = getStorage("imei");
    setValue("imei", imei);
  }, []);

  return (
    <>
      <Header>情報の登録</Header>
      <Main>
        <Card className="mt-4">
          <Label htmlFor="imei" required>
            IMEI
          </Label>
          <IMEIInput
            register={register}
            setValue={setValue}
            getValues={getValues}
          />
          <div className="mt-10">
            <Button
              className="w-full"
              size="large"
              onClick={handleSubmit(confirmRegister, (errors: FieldErrors) =>
                toastFormValueError(errors)
              )}
              disabled={!isAllFilled}
              loading={isLoading}
            >
              確認
            </Button>
          </div>
        </Card>
      </Main>
      <Footer />
      <Popup visible={visible}>
        <PopupMain>
          このIMEIは存在しません。再度スキャンするか、入力してください。
        </PopupMain>
        <PopupAction>
          <Button onClick={toggle}>閉じる</Button>
        </PopupAction>
      </Popup>
    </>
  );
}
