import { useMutation } from "@tanstack/react-query";

import { NECESSARY_INFO_LACKING_HINT } from "@/shared";
import {
  getDevices,
  registerDevice,
  updateDevice,
} from "@/shared/requests/device";
import { getStorage, setStorage } from "@/shared/store";

import {
  GateNumberNotMatchError,
  NecessaryInfoLackingError,
  SNIMEINotMatchError,
} from "./errors.type";

export function useCreatePatmo() {
  const mutateFn = async () => {
    const imei = getStorage("imei");
    const sn = getStorage("serialNumber");
    const device_type = getStorage("deviceType");
    const gateNumber = getStorage("gateNumberPATMO");
    if (!sn || !device_type) {
      throw new NecessaryInfoLackingError(NECESSARY_INFO_LACKING_HINT);
    }
    const devices = await getDevices({ device_type, sn });

    const theDevice = devices?.data?.devices?.find(
      (device) => device.sn === sn
    );
    // console.log("theDevice", theDevice);
    // device exists
    if (theDevice) {
      setStorage("deviceId", theDevice.id);
      if (theDevice.imei && imei && theDevice.imei !== imei) {
        throw new SNIMEINotMatchError(
          "IMEIとSNが一致しません。機器情報をご確認ください。"
        );
      } else if (
        (theDevice.imei?.length === 0 && imei.length !== 0) ||
        (theDevice.imei === undefined && imei.length !== 0)
      ) {
        if (
          theDevice?.position?.gate_number &&
          theDevice?.position?.gate_number !== Number(gateNumber)
        ) {
          throw new GateNumberNotMatchError(
            "番号が一致しません。機器情報をご確認ください。"
          );
        }
        return updateDevice({ device_id: theDevice.id, imei });
      } else if (
        theDevice?.position?.gate_number &&
        theDevice?.position?.gate_number !== Number(gateNumber)
      ) {
        throw new GateNumberNotMatchError(
          "番号が一致しません。機器情報をご確認ください。"
        );
      } else {
        return Promise.resolve({ data: theDevice });
      }
      // device doesn't exist then create
    } else if (imei) {
      return registerDevice({ imei, sn, device_type });
    } else {
      return registerDevice({ sn, device_type });
    }
  };
  return useMutation(mutateFn);
}
