import { useNavigate } from "react-router-dom";

import { Button, Card, Footer, Header, Main, Popup } from "@/components";
import { useModal } from "@/hooks";
import { clearStorage, setStorage } from "@/shared/store";

export default function SelectDearisDevice() {
  const navigate = useNavigate();
  const { visible, toggle } = useModal();
  const handleDearisSet = () => {
    clearStorage();
    setStorage("deviceType", "dearis");
    navigate("/dearis");
  };
  const handleAdapterSet = () => {
    clearStorage();
    setStorage("deviceType", "DearisAdapter");
    navigate("/register");
  };

  const handleChangeIMEI = () => {
    setStorage("DearisReplaceIMEI", true);
    setStorage("deviceType", "dearisController");
    navigate("/pre-setting");
  };

  return (
    <>
      <Header>タイプを選択してください</Header>
      <Main>
        <Card className="flex flex-col gap-6">
          <Button onClick={handleDearisSet}>
            共用部インターネット回線を使用
          </Button>
          <Button onClick={toggle}>専用通信アダプタ(VMW-COMAD)を使用</Button>
        </Card>
      </Main>
      <Footer />
      <Popup visible={visible}>
        <div>
          <Button onClick={handleAdapterSet}>新規</Button>
        </div>
        <div className="mt-2">
          <Button onClick={handleChangeIMEI}>交換</Button>
        </div>
      </Popup>
    </>
  );
}
