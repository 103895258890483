import { useNavigate } from "react-router-dom";

import {
  Button,
  Footer,
  Header,
  Main,
  Popup,
  PopupAction,
  PopupMain,
} from "@/components";
import {
  Step,
  StepContainer,
  StepParagraph,
  StepsGenerator,
} from "@/components/step";
import { useModal } from "@/hooks";
import { getStorage } from "@/shared/store";

import { useReplaceIMEI } from "../hooks";
import { patmoStepsData } from "./patmo-steps-data";

export const PATMOIMEIReplaceRestart = () => {
  const navigate = useNavigate();
  const { visible, toggle } = useModal(getStorage("PATMOReplaceIMEI"));

  const onReplaceSucceed = () => {
    toggle();
  };
  const onCancel = () => {
    navigate("/status");
  };
  const { isReplacing, handleCancelClick, handleReplaceIMEIClick } =
    useReplaceIMEI({
      onReplaceSucceed,
      onCancel,
    });
  return (
    <>
      <Header>通信アダプターリセット</Header>
      <Main>
        <StepContainer>
          <StepsGenerator stepsData={patmoStepsData} />
          {/* Step End */}
          <Step>
            <StepParagraph>
              「リセットが完了」をタップして設定を続けます。
            </StepParagraph>
            <Button onClick={() => navigate("/status")}>リセットが完了</Button>
          </Step>
        </StepContainer>
      </Main>
      <Footer />
      <Popup visible={visible}>
        <PopupMain>
          <p>{"開通に成功しました。"}</p>
          <br />
          <p>{"IMEI番号を変更しますか？"}</p>
        </PopupMain>
        <PopupAction className="flex gap-5">
          <div className="flex-1">
            <Button type="default" onClick={handleCancelClick}>
              変更しません
            </Button>
          </div>
          <div className="flex-1">
            <Button onClick={handleReplaceIMEIClick} loading={isReplacing}>
              変更
            </Button>
          </div>
        </PopupAction>
      </Popup>
    </>
  );
};
