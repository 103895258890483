import { cx } from "@emotion/css";
import {
  ConfigProvider,
  Loading as NutLoading,
  Overlay,
} from "@nutui/nutui-react";

export function Loading({
  size = "24px",
  direction = "horizontal",
  className,
  children,
  color = ""
}: Readonly<{
  direction?: "horizontal" | "vertical";
  size?: string;
  className?: string;
  children?: string;
  color?:string;
}>) {
  return (
    <ConfigProvider
      theme={{
        nutuiLoadingIconSize: size,
        nutuiLoadingTextSize: "1rem",
        nutuiLoadingTextColor: "#333333",
        nutuiLoadingIconColor: color,
      }}
    >
      <NutLoading
        type="spinner"
        className={cx("m-auto", className)}
        direction={direction}
        color={color}
      >
        {children}
      </NutLoading>
    </ConfigProvider>
  );
}

export const PlainLoading = ({className}:{className?: string}) =>{
  return  <NutLoading
    type="spinner"
    className={cx("m-auto", className)}
  ></NutLoading>;
};

export const OverlayLoading = ({ visible }: { visible: boolean }) => {
  return (
    <Overlay closeOnOverlayClick={false} visible={visible}>
      <div className="flex h-full justify-center items-center">
        <Loading color="#FFFFFF" />
      </div>
    </Overlay>
  );
};
