import { cx } from "@emotion/css";

interface FormControlProps {
  className?: string;
  children: React.ReactNode | React.ReactNode[];
}
export const FormControl: React.FC<FormControlProps> = ({
  className,
  children,
}) => {
  return <div className={cx("", className)}>{children}</div>;
};
