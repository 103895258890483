import { useHandleDeviceUnknown } from "@/hooks";
import { getStorage } from "@/shared/store";
import type { DeviceType } from "@/types";

import { LiteRestart, PATMORestart } from "./restart-adapter";

export default function RestartAdapter() {
  const deviceType = getStorage("deviceType") as DeviceType;
  const { toastAndToSelectDevice } = useHandleDeviceUnknown();
  switch (deviceType) {
    case "PabbitLite":
      return <LiteRestart />;
    case "PATMO":
      return <PATMORestart />;
    default: {
      toastAndToSelectDevice();
    }
  }
}
