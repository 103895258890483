import { Amplify, Auth } from "aws-amplify";

import type {
  RequestSignInParams,
  SendVerifyCodeParams,
  SignUpParams,
} from "./auth.type";
import server from "./server";

let cognitoUser: any = null;
const COUNTY_CODE = import.meta.env.VITE_COUNTRY_CODE;
Amplify.configure({
  region: import.meta.env.VITE_USER_POOL_REGION,
  userPoolId: import.meta.env.VITE_USER_POOL_ID,
  userPoolWebClientId: import.meta.env.VITE_USER_POOL_APP_CLIENT_ID,
});

export async function signUp({ phone }: SignUpParams) {
  return server.post("/users", {
    phone_number: COUNTY_CODE + phone,
  });
}

export async function getVerifyCode({ phone }: RequestSignInParams) {
  return (cognitoUser = await Auth.signIn(COUNTY_CODE + phone));
}

export async function sendVerifyCode({ code }: SendVerifyCodeParams) {
  return Auth.sendCustomChallengeAnswer(cognitoUser, code);
}

export async function getCurrentAuthenticatedUser() {
  return Auth.currentAuthenticatedUser();
}

export async function getCurrentSession() {
  return Auth.currentSession();
}

export async function auth() {
  return server.get("/auth");
}

export async function permission(user_id: string) {
  return server.post(`/users/${user_id}/roles`, {
    role: "EntranceInstaller",
    tenant: "Aiphone",
  });
}

export async function checkTokenExpiration() {
  try {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken();
    const exp = idToken.getExpiration(); // 获取令牌的过期时间，单位是秒

    const currentTime = Math.floor(new Date().getTime() / 1000); // 获取当前时间，单位是秒

    if (currentTime > exp) {
      return await refreshAuth();
    } else {
      return true;
    }
  } catch (error) {
    throw new Error("Error getting current user session");
  }
}

export const refreshAuth = async () => {
  return Auth.currentSession();
};

export * from "./auth.type";
