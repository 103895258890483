import { useHandleDeviceUnknown } from "@/hooks";
import { getStorage } from "@/shared/store";
import type { DeviceType } from "@/types";

import {
  ControllerServiceConfigure,
  PATMOServiceConfigure,
} from "./service-configure";

export default function () {
  const device_type = getStorage("deviceType") as DeviceType;
  const { toastAndToSelectDevice } = useHandleDeviceUnknown();
  switch (device_type) {
    case "PATMO":
      return <PATMOServiceConfigure />;
    case "dearisController":
      return <ControllerServiceConfigure />;
    default: {
      toastAndToSelectDevice();
    }
  }
}

// ❶❷❸❹❺❻❼❽❾❿
