import { Card, Footer, Header, Main, PlainSkeleton } from "@/components";

export function EntranceSkeleton() {
  return (
    <>
      <Header>設置場所（識別名）設定</Header>
      <Main>
        <Card>
          <PlainSkeleton />
        </Card>
      </Main>
      <Footer />
    </>
  );
}
