import React from "react";

export default function Hint({ children }: { children: React.ReactNode }) {
  return (
    <p className="p-4 pt-0 font-bold">
      <span className="text-error">*&nbsp;</span>
      {children}
    </p>
  );
}
