import { cx } from "@emotion/css";
import { useNavigate } from "react-router-dom";

import ArrowIcon from "@/assets/arrow-right.svg?react";
import { Card } from "@/components";
import { setStorage } from "@/shared/store";
import type { Building } from "@/types";

interface BuildingSettingEntryCardProps {
  building: Building & { deviceCount: number };
  className?: string;
}
export const BuildingSettingEntryCard: React.FC<
  BuildingSettingEntryCardProps
> = ({ building, className }) => {
  const navigate = useNavigate();
  const handleBuildingClick = () => {
    setStorage("configureDearisDevicesBuildId", building.build_id);
    setStorage("dearisBuildingDetail", {
      id: building.id,
      name: building.name,
    });
    navigate(`/dearis-building/${building.id}`);
  };
  return (
    <Card className={cx("my-[10px]", className)} onClick={handleBuildingClick}>
      <div className={cx("text-black/85 text-sm font-medium leading-[22px]")}>
        {building?.name ?? "-"}
      </div>
      <div
        className={cx(
          "mt-2 flex justify-between gap-14",
          "text-xs font-normal leading-[18px] text-green-gray"
        )}
      >
        <div className={cx("flex flex-grow justify-between")}>
          <span>棟番号：{building?.build_id ?? "-"}</span>
          <span>デバイス：{building?.deviceCount ?? "-"}</span>
        </div>
        <ArrowIcon className={cx("mt-[2px] h-4 w-4 text-[#C4CBCC]")} />
      </div>
    </Card>
  );
};
