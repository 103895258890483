import type { Building, Property } from "@/types";

interface ISearchKeywordByAddressModel {
  isfilled: boolean;
  prefecture_name: string;
  city_name: string;
  rest_address: string;
}

// 删除了实现滚动加载前,一些持久化的, 用来维护请求数据的字段, 此注释用于追踪提交记录
interface StorageValue {
  propertyId: string;
  serialNumber: string;
  imei: string;
  deviceType: string;
  deviceId: string; // dearis场景下, 三种设备只会(从一而终)存储controller的deviceId;
  selectedBuildingOrProperty?: Building | Property;
  spotName: string;
  searchKeywordByName: string; // 物件名检索词持久化
  searchKeywordByAddressObj: ISearchKeywordByAddressModel; //住所检索词持久化
  isAutoSendedVerifyCode: boolean; // 是否自动获取验证码 在app的整个会话周期中只发送一次
  isDeviceBindedObject: boolean; // 设备是否已经绑定物件或楼栋, 通常和 selectedBuildingOrProperty 同步设置
  buildId?: number;
  gateNumber?: number;
  uri: string;
  roomNumber?: string;
  buildIdController?: number; // 没什么用但是硬要显示的两个数据;
  gateNumberController?: number;
  gateNumberPATMO?: number; // PATMO 的 gateNumber 知道position前一直存在本地, 本地显示
  tobeUnlockId?: string;
  dearisController?: {
    // 用于检测新entrance和原有的controller是否sn一致;
    sn: string;
  };
  PATMOReplaceIMEI: boolean;
  DearisReplaceIMEI: boolean;
  cloudUnlockTest?: boolean; // 云端解锁测试
  configureDearisDevicesBuildId?: number; // 准备设定elevatorentrance的楼栋;
  dearisBuildingDetail?: {
    // dearis detail 页面到创建entrance之间需要维护的数据
    id: string;
    name: string;
  };
  isBuildingUploaded?: boolean;
}

type StorageKey = keyof StorageValue;

const version = import.meta.env.VITE_STORE_VERSION;
const STORE_SOURCE_NAME = `pabbit-store-${version}`;
const defaultValue = (): StorageValue => ({
  propertyId: sessionStorage.getItem("property_id") ?? "",
  serialNumber: "",
  imei: "",
  deviceType: "",
  deviceId: "",
  spotName: "",
  searchKeywordByName: "",
  searchKeywordByAddressObj: {
    isfilled: false,
    prefecture_name: "",
    city_name: "",
    rest_address: "",
  },
  isAutoSendedVerifyCode: false,
  isDeviceBindedObject: false,
  uri: "",
  DearisReplaceIMEI: false,
  PATMOReplaceIMEI: false,
});

let storage: StorageValue = defaultValue();

export const getStorage = <T extends StorageKey>(key: T): StorageValue[T] => {
  return JSON.parse(window.sessionStorage.getItem(STORE_SOURCE_NAME) || "{}")[
    key
  ];
};

export const setStorage = <T extends StorageKey>(
  key: T,
  value: StorageValue[T]
) => {
  const storage = JSON.parse(
    window.sessionStorage.getItem(STORE_SOURCE_NAME) || "{}"
  );
  Object.assign(storage, { [key]: value });
  window.sessionStorage.setItem(STORE_SOURCE_NAME, JSON.stringify(storage));
};

export const clearStorage = () => {
  storage = defaultValue();
  window.sessionStorage.setItem(STORE_SOURCE_NAME, JSON.stringify(storage));
};

const getEntryStorage = () => {
  const storage = window.sessionStorage.getItem(STORE_SOURCE_NAME);
  if (storage) {
    return JSON.parse(storage);
  }
  return {};
};

export const clearDeviceInfo = () => {
  const storage = getEntryStorage();
  const updateStorage = {
    ...storage,
    propertyId: "",
    serialNumber: "",
    imei: "",
    deviceId: "",
    selectedBuildingOrProperty: undefined,
    spotName: "",
    isDeviceBindedObject: false,
    buildId: undefined,
    gateNumber: undefined,
    uri: "",
    roomNumber: undefined,
    buildIdController: undefined, // 没什么用但是硬要显示的两个数据;
    gateNumberController: undefined,
    gateNumberPATMO: undefined, // PATMO 的 gateNumber 知道position前一直存在本地, 本地显示
    tobeUnlockId: undefined,
    dearisController: undefined,
    PATMOReplaceIMEI: false,
    DearisReplaceIMEI: false,
    cloudUnlockTest: undefined,
  };
  window.sessionStorage.setItem(
    STORE_SOURCE_NAME,
    JSON.stringify(updateStorage)
  );
};
