import { Card, Footer, Header, Main, Skeleton } from "@/components";

export default function StatusSkeleton() {
  return (
    <>
      <Header>登録した情報</Header>
      <Main className="flex flex-col gap-3">
        <Card>
          <Skeleton />
        </Card>
        <Card>
          <Skeleton />
        </Card>
        <Card>
          <Skeleton />
        </Card>
        <Card>
          <Skeleton />
        </Card>
        <Card>
          <Skeleton />
        </Card>
      </Main>
      <Footer />
    </>
  );
}
