import type {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";

import { gateNumberOptions } from "@/app.constant";
import { SelectInput } from "@/components/form";

interface GateNumberSelectInputProps {
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  disabled?: boolean;
  defaultValue?: number;
}
export function GateNumberSelectInput({
  register,
  setValue,
  ...props
}: Readonly<GateNumberSelectInputProps>) {
  return (
    <SelectInput
      register={register("gateNumber", {
        required: true,
      })}
      setValue={setValue}
      placeholder="ゲート番号を選んでください"
      options={gateNumberOptions}
      {...props}
    />
  );
}
