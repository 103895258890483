import { useMutation } from "@tanstack/react-query";

import { auth, permission } from "@/shared/requests/auth";
import { Unauthorized401Error } from "@/shared/requests/error.type";

export function useAuthGrantPermission() {
  const mutationFn = async () => {
    try {
      const data = await auth();
      const user = data?.data?.user;
      if (user?.roles?.length === 0) {
        await permission(user.id);
      }
    } catch (e) {
      if (e instanceof Unauthorized401Error) {
        throw e;
      }
      throw new FetchPermissionError("用户権限の取得に失敗しました。");
    }
  };
  return useMutation(mutationFn);
}

export class FetchPermissionError extends Error {}
