import { useMutation } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";

import { NECESSARY_INFO_LACKING_HINT } from "@/shared";
import server from "@/shared/requests/server";
import { getStorage } from "@/shared/store";
import type { LineStatus } from "@/types";

import { NecessaryInfoLackingError } from "./errors.type";

interface GETLineParams {
  imei: string;
}
interface GETLineResponse {
  status: LineStatus;
}
const getLineInfo = ({ imei }: GETLineParams) => {
  return server.get<unknown, AxiosResponse<GETLineResponse>>(
    `/devices/line/${imei}/line`
  );
};

const getIMEIAndCheck = ({ imei }: { imei: string }) => {
  return getLineInfo({ imei });
};

export function useCheckLineExistMutation() {
  const mutationFn = () => {
    const imei = getStorage("imei");
    if (!imei) throw new NecessaryInfoLackingError(NECESSARY_INFO_LACKING_HINT);
    return getIMEIAndCheck({ imei });
  };
  return useMutation(mutationFn);
}
