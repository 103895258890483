import { Card } from "@/components";
import { cx } from "@emotion/css";
export default function LineStatusCard({
  children,
  className,
}: Readonly<{
  children: React.ReactNode[] | React.ReactNode;
  className?: string;
}>) {
  return (
    <Card
      className={cx(
        "pt-12 px-4 min-h-[273px] text-center",
        "flex flex-col",
        className,
      )}
    >
      {children}
    </Card>
  );
}
