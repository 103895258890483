import type { FieldErrors } from "react-hook-form";

import { errorToast } from "@/shared";

export function useFormErrorsToast() {
  const toastFormValueError = (errors: FieldErrors) => {
    const firstError = Object.values(errors).map((error) => error?.message)[0];
    if (firstError && typeof firstError === "string") {
      errorToast(firstError);
    }
  };
  return { toastFormValueError };
}
