import { cx } from "@emotion/css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Popup, PopupAction, PopupMain } from "@/components";
import { FetchDataError, useModal } from "@/hooks";
import { convertTimeZone } from "@/shared/convertTimeZone";
import { getDeviceByID } from "@/shared/requests/device";
import { getEvents } from "@/shared/requests/events";
import { getStorage } from "@/shared/store";

interface ServiceConfigDoneBTNProps {
  className?: string;
}

export const ServiceConfigDoneBTN: React.FC<ServiceConfigDoneBTNProps> = ({
  className,
}) => {
  const { visible: noBuildingVisible, toggle: noBuildingToggle } = useModal();
  const [isPending, setIsPending] = useState(false);
  const navigate = useNavigate();
  const device_id = getStorage("deviceId");

  const handleSetSpotClick = async () => {
    setIsPending(true);
    await Promise.all([
      getEvents({
        event_name: "BuildingUploaded",
        identity: device_id,
        limit: 10,
      }),
      getDeviceByID({ device_type: "PATMO", device_id }),
    ])
      .then((data) => {
        setIsPending(false);
        const events = data[0].data.events;
        const device = data[1].data;
        const uploadedEventIndex = events.findIndex(
          (e) =>
            e.payload?.property_id === device?.property_id &&
            e.event?.header.publishedAt &&
            device?.bound_at &&
            convertTimeZone(new Date(e.event?.header.publishedAt)) >
              new Date(device?.bound_at)
        );
        const isBuildingUploaded =
          uploadedEventIndex !== undefined && uploadedEventIndex >= 0;
        if (isBuildingUploaded) {
          navigate("/status");
        } else if (!isBuildingUploaded) {
          noBuildingToggle();
        }
      })
      .catch((e) => {
        FetchDataError(true, e);
      });
  };
  return (
    <>
      <div className={cx(className)}>
        <Button onClick={handleSetSpotClick} loading={isPending}>
          完了
        </Button>
      </div>
      <Popup visible={noBuildingVisible}>
        <PopupMain className="!text-base">
          {"棟情報がありません。「部屋情報アップロード」を押してください。"}
        </PopupMain>
        <PopupAction className="!mt-[13px] flex justify-center">
          <Button
            className="w-[124px]"
            size="normal"
            onClick={noBuildingToggle}
          >
            閉じる
          </Button>
        </PopupAction>
      </Popup>
    </>
  );
};
