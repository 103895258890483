import type {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";

import { InputWithClear } from "@/components/form";
import { OnlyNumberRegExp, removeUnexpectedChar } from "@/shared/regexps";
import { setStorage } from "@/shared/store";

interface IMEIInputProps {
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
}
export default function IMEIInput({
  register,
  setValue,
  getValues,
}: Readonly<IMEIInputProps>) {
  const handleInputIMEI = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cleaned = removeUnexpectedChar(e.target.value, OnlyNumberRegExp)
      .toString()
      .substring(0, 50);
    setStorage("imei", cleaned);
    setValue("imei", cleaned);
  };

  return (
    <InputWithClear
      id="imei"
      register={register("imei", {
        onChange: handleInputIMEI,
        required: true,
        pattern: {
          value: /^[0-9]{8,50}$/gm,
          message: "IMEIの形式が正しくありません",
        },
      })}
      setValue={setValue}
      getValues={getValues}
      placeholder="IMEI番号を入力してください"
      type="number"
      pattern="[0-9]*"
      onWheel={(event) => event.currentTarget.blur()}
    />
  );
}
