import { useGetPropertyById, useListOption } from "@/queries";
import { getStorage } from "@/shared/store";
import type { ProviderModel } from "@/types";

export function useUnlockTestData() {
  const {
    data: propertyResponse,
    isLoading: isGetPropertyLoading,
    isError: isGetPropertyError,
  } = useGetPropertyById();
  const {
    data: couriersResponse,
    isLoading: isGetListCourierLoading,
    isError: isGetListCourierError,
  } = useListOption();
  const numberOfBuildings = propertyResponse?.data?.buildings?.length ?? 0;
  // const roomNumber = theBuilding?.rooms && theBuilding?.rooms[0]?.number;

  const theDelivery = couriersResponse?.data?.find(
    (item) => item.option !== "amazon" && item.testable
  );
  const delivery: ProviderModel = {
    name: theDelivery?.title || "",
    courier: theDelivery?.option || "",
  };
  const code = getStorage("serialNumber").slice(-4);
  return {
    isLoading: isGetPropertyLoading || isGetListCourierLoading,
    isError: isGetPropertyError || isGetListCourierError,
    code,
    theDelivery,
    delivery,
    isUnsatisfactory: !theDelivery || numberOfBuildings <= 0,
  };
}
