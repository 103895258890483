import { useNavigate } from "react-router-dom";

import { Button } from "@/components";

export default function PatmoManualButton({
  children,
}: {
  children: React.ReactNode;
}) {
  const navigate = useNavigate();
  return (
    <Button fill="outline" onClick={() => navigate("/register/options")}>
      {children}
    </Button>
  );
}
