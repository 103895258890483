import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ReturnIcon from "@/assets/return.svg?react";
import {
  Button,
  Card,
  Footer,
  Header,
  Link,
  Main,
  PlainSkeleton,
} from "@/components";
import { useFetchDataError, useGetScannerData } from "@/hooks";
import { DearisControllerNotExist, useListDearisBySN } from "@/queries";
import { getStorage, setStorage } from "@/shared/store";

import { LineOpenedTag } from "../components";

export default function () {
  const navigate = useNavigate();
  const [sn, setSn] = useState("");
  const { data, isLoading, error, isError } = useListDearisBySN();
  useFetchDataError(
    isError && !(error instanceof DearisControllerNotExist),
    error
  );
  const imei = data?.imei;
  const unRegisteredDevice = error instanceof DearisControllerNotExist;
  const imeiExist = !!imei;
  const handleConfirmClick = () => {
    data?.id && setStorage("deviceId", data?.id);
    navigate("/imei-replace/register");
  };

  useGetScannerData();
  useEffect(() => {
    setSn(getStorage("serialNumber"));
  }, []);

  if (isLoading)
    return (
      <>
        <Header>デバイス情報の確認</Header>
        <Main>
          <PlainSkeleton />
        </Main>
      </>
    );

  return (
    <>
      <Header>デバイス情報の確認</Header>
      <Main>
        <Card>
          <p className="font-medium">シリアル番号：</p>
          <div className="mt-6 text-sm text-green-gray">{sn}</div>
        </Card>
        <Card className="mt-3">
          <div className="flex justify-between">
            <p>IMEI：</p>
            {imeiExist && <LineOpenedTag lineOpened={data.line_status} />}
          </div>
          <div className="mt-6 text-sm text-green-gray">{imei || "無し"}</div>
        </Card>

        <NextStepHint
          unRegisteredDevice={unRegisteredDevice}
          imeiExist={imeiExist}
        />
        {unRegisteredDevice ? (
          <ReturnButton />
        ) : (
          <div className="mt-5">
            <Button onClick={handleConfirmClick} disabled={isError}>
              確認
            </Button>
          </div>
        )}
      </Main>
      <Link className="m-3 mb-10 block text-center" to="/">
        <ReturnIcon className="mr-1 inline h-4 w-4" />
        トップページに戻る
      </Link>
      <Footer />
    </>
  );
}

const UnResigterHint = () => (
  <p className="py-2 text-xs text-error">
    {"このシリアル番号は登録されていません。新規登録を行ってください。"}
  </p>
);

const KeinIMEIHint = () => (
  <p className="py-2 text-xs text-green-gray">
    <span className="text-error">※</span>
    {
      " デバイスが元々IMEIにバインドされていない場合、これからの操作は新しいIMEIとのバインドとみなされます。"
    }
  </p>
);

const ReturnButton = () => {
  const navigate = useNavigate();
  return (
    <div className="mt-10">
      <Button onClick={() => navigate("/select-device/dearis")}>戻る</Button>
    </div>
  );
};

export const NextStepHint = ({
  unRegisteredDevice,
  imeiExist,
}: {
  unRegisteredDevice: boolean;
  imeiExist: boolean;
}) => {
  if (unRegisteredDevice) return <UnResigterHint />;
  if (!imeiExist) return <KeinIMEIHint />;
  return (
    <p className="py-2 text-xs text-green-gray">
      <span className="text-error">※</span>
      {" 「確認」をタップして変更プロセスを開始します。"}
    </p>
  );
};
