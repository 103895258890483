import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Card, Footer, Header, Main } from "@/components";
import { useGetScannerData } from "@/hooks";
import {
  BuildIdNotFoundError,
  DeviceAlreadyExistsError,
  SNInconsistentError,
} from "@/queries";
import { errorToast } from "@/shared";
import { getStorage, setStorage } from "@/shared/store";

import { DataItem, Warning } from "../components";
import { usePreCreateEntrance } from "../hooks";

export function EntranceDataConfirm() {
  const [serialNumber, setSerialNumber] = useState("");
  const [buildId, setBuildId] = useState<number>();
  const [gateNumber, setGateNumber] = useState<number>();
  const { preCreateEntrance, isPreCreating } = usePreCreateEntrance();

  const navigate = useNavigate();

  const hanldeDataConfirmClick = async () => {
    if (buildId !== undefined && gateNumber !== undefined && serialNumber) {
      preCreateEntrance({ buildId, gateNumber, sn: serialNumber })
        .then(() => {
          navigate("/spot");
        })
        .catch((e) => {
          errorToast(e.message ?? e.toString());
          setStorage("buildId", undefined);
          setStorage("gateNumber", undefined);
          setStorage("serialNumber", "");
          if (
            e instanceof SNInconsistentError ||
            e instanceof BuildIdNotFoundError
          ) {
            navigate(-1);
          } else if (e instanceof DeviceAlreadyExistsError) {
            const building_id = getStorage("dearisBuildingDetail")?.id;
            navigate(`/dearis-building/${building_id}`);
          } else {
            errorToast(e?.message ?? e.toString());
          }
        });
    }
  };

  useGetScannerData();
  useEffect(() => {
    setSerialNumber(getStorage("serialNumber"));
    setBuildId(getStorage("buildId"));
    setGateNumber(getStorage("gateNumber"));
  }, []);
  return (
    <>
      <Header>デバイス情報の確認</Header>
      <Main>
        <Card>
          <Warning>
            ここに表示されている情報がデバイス側の情報と一致していることを確認した後、確認を押して次のステップに進んでください。
          </Warning>
          <DataItem
            label="シリアル番号"
            content={serialNumber}
            className="mt-10"
          />
          {buildId !== undefined && (
            <DataItem label="棟番号" content={buildId} className="mt-5" />
          )}
          {gateNumber !== undefined && (
            <DataItem
              label="ゲート番号"
              content={gateNumber}
              className="mt-5"
            />
          )}
          <div className="mb-6 mt-10">
            <Button onClick={hanldeDataConfirmClick} loading={isPreCreating}>
              確認
            </Button>
          </div>
        </Card>
      </Main>
      <Footer />
    </>
  );
}
