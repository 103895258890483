import { cx } from "@emotion/css";
import { useNavigate } from "react-router-dom";

import DoorIcon from "@/assets/door.svg?react";
import { Button, Card, Footer, Header, Main, Popup, PopupAction, PopupMain } from "@/components";
import { ConfigPanelContainer, ConfigPanelItem } from "@/components/config-panel";
import { useFetchDataError, useModal } from "@/hooks";
import { useGetDeviceById, useGetDeviceTestResults } from "@/queries";
import { errorToast, FETCH_DATA_ERROR_HINT } from "@/shared";
import { setStorage } from "@/shared/store";

import { CloudUnlockTestModal, Image } from "../components";

export default function LiteUnlockOverview() {
  const navigate = useNavigate();
  const { visible: unlockVisible, toggle: unlockToggle } = useModal();
  const { visible: noSpotVisible, toggle: noSpotToggle } = useModal();

  const { data, isError: isDeviceError, error: deviceError } = useGetDeviceById();
  const device = data?.data;
  const isDeviceOperational = device?.mode === "operational";
  const { data: testResultData, isError: isTestResultError, error: testResultError } = useGetDeviceTestResults();
  const testResult = testResultData?.data;
  const isAllTestPassed = !!testResult?.[1] && !!testResult?.[2] && !!testResult?.[3];
  useFetchDataError(isDeviceError || isTestResultError, deviceError || testResultError);

  const checkSpotStatus = (callback: () => void) =>
    function () {
      if (!device?.position?.spot || !device?.position?.image_uri) {
        noSpotToggle();
        return;
      }
      callback();
    };

  const handleRelayUnlockTest = checkSpotStatus(() => {
    navigate("/unlockTest/lite", {
      state: { unlockTestType: "relay" },
    });
  });

  const handleRoomUnlockTest = checkSpotStatus(() => {
    if (device?.position?.build_id !== undefined) {
      // 需要这个信息来进行房间号解锁
      navigate("/unlockTest/lite", {
        state: {
          unlockTestType: "roomNumber",
          build_id: device.position.build_id,
          device_id: device.id,
        },
      });
    } else {
      errorToast(FETCH_DATA_ERROR_HINT);
    }
  });

  const handleCloudUnlockTest = checkSpotStatus(() => {
    if (device?.id) {
      setStorage("tobeUnlockId", device.id);
      unlockToggle();
    }
  });

  return (
    <>
      <Header canBack onClick={() => navigate(-1)}>
        解錠テスト
      </Header>
      <Main>
        <Card>
          {device?.position?.spot ? (
            <p className="font-medium">
              <DoorIcon className="mr-1 inline" />
              <span>{device.position.spot}</span>
            </p>
          ) : (
            <p className="font-medium text-[#C4CBCC]">未設定</p>
          )}

          <div className="mt-5 text-sm text-green-gray">
            <div>
              <span>棟番号：</span>
              <span>{device?.position?.build_id ?? "-"}</span>
            </div>
          </div>
          <Image url={device?.position?.image_uri || ""} className="mt-1" />
        </Card>
        <Card className="mt-4 !p-3">
          <ConfigPanelContainer>
            <p className={cx("rounded-md bg-[#F6F8F8] p-2", "text-xs leading-[18px] text-[#6D7174] ")}>
              {"テスト結果の更新には遅延が生じることがありますので、手動でページを更新する必要があります。"}
            </p>
            <ConfigPanelItem
              disabled={isDeviceOperational}
              className="!border-t-0"
              optionName="リレー解錠テスト"
              content={testResult?.["1"] ? <>&nbsp;</> : "保留中"}
              onClick={handleRelayUnlockTest}
            />
            <ConfigPanelItem
              disabled={isDeviceOperational}
              optionName="番号入力解錠テスト"
              content={testResult?.["2"] ? <>&nbsp;</> : "保留中"}
              onClick={handleRoomUnlockTest}
            />
            <ConfigPanelItem
              disabled={isDeviceOperational}
              optionName="クラウド解錠テスト"
              content={testResult?.["3"] ? <>&nbsp;</> : "保留中"}
              onClick={handleCloudUnlockTest}
            />
          </ConfigPanelContainer>
        </Card>
        <div className="mb-4 mt-8 px-4">
          <Button disabled={!isAllTestPassed} onClick={() => navigate(-1)}>
            すべてのテストが完了
          </Button>
        </div>
      </Main>
      <Footer />
      <CloudUnlockTestModal visible={unlockVisible} toggle={unlockToggle} />
      <Popup visible={noSpotVisible}>
        <PopupMain>
          <p className="mb-2 text-lg font-semibold">注意：</p>
          「設置場所」と「写真」の情報がありません。クラウド解錠テストを完了することができません。
        </PopupMain>
        <PopupAction>
          <Button onClick={noSpotToggle}>閉じる</Button>
        </PopupAction>
      </Popup>
    </>
  );
}
