import { Card } from "@/components";

export default function RegisterMethodCard({
  children,
}: {
  children: React.ReactNode[];
}) {
  return (
    <Card className="mb-3 flex min-h-34 flex-col justify-between py-5">
      {children}
    </Card>
  );
}
