import { useSearchParams } from "react-router-dom";

import { useListDearis } from "@/queries";

export function useDearisNotControllerData() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("building_id");
  const [entrances, elevators] = useListDearis(id);
  const isStillLocked =
    entrances.data?.data?.some(
      (entrance) => !entrance?.position?.test_passed
    ) ||
    elevators.data?.data?.some((elevator) => !elevator?.position?.test_passed);

  return {
    entrances: entrances.data?.data,
    elevators: elevators.data?.data,
    isLoading: entrances.isLoading || elevators.isLoading,
    isError: entrances.isError || elevators.isError,
    isStillLocked,
    error: entrances.error || elevators.error,
  };
}
