import type { LineStatus } from "@/types";

export const StatusTag = ({ status }: { status?: LineStatus }) => {
  switch (status) {
    case "active":
      return <span className="text-[#52C41A]">開通中</span>;
    case "suspend":
      return <span className="text-[#FAAD14]">一時停止</span>;
    case "dead":
      return <span className="text-error">解約された</span>;
    default:
      return <span className="text-error">未開通</span>;
  }
};
