import AlertIcon from "@/assets/alert.svg?react";

const Warning = ({ children }: Readonly<{ children: string }>) => {
  return (
    <div className="mt-1">
      <p className="text-sm font-semibold leading-[22px] text-primary">
        <AlertIcon className="my-auto mb-1 inline align-middle  text-alert" />{" "}
        ヒント
      </p>
      <p className="mt-2 text-xs leading-[18px] text-green-gray">{children}</p>
    </div>
  );
};
export default Warning;
