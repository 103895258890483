import { useEffect, useState } from "react";

// 接受 string 或者 number 类型的数组，所有值均不为空返回true, 其余情况通通返回false;
// 如果传入的是react-hook-form register 的一个 field, 记得使用 watch()包围
export function useVisibilityToggle(...thisArgs: Array<any>) {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // 找到为空为假的值
    const findIndex = thisArgs.findIndex((item) => {
      if (typeof item === "object") return Object.keys(item).length === 0;
      else if (typeof item === "number") return false;
      else return !item;
    });
    if (findIndex === -1) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [...thisArgs]);
  return [isVisible];
}
