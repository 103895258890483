import { cx } from "@emotion/css";
import type { FC } from "react";
import { useNavigate } from "react-router-dom";

import SearchIcon from "@/assets/magnifier.svg?react";

interface IObjectSearchButton {}
export const ObjectSearchButton: FC<IObjectSearchButton> = () => {
  const navigate = useNavigate();
  const searchObject = () => {
    navigate("/configure/search", { replace: true });
  };

  return (
    <div
      className={cx(
        "flex items-center",
        "my-4 p-4",
        "bg-white",
        "rounded-md border border-solid border-neutral-100",
        "text-[15px] text-neutral-400"
      )}
      onClick={searchObject}
    >
      <SearchIcon className="mr-3" />
      <span className="leading-none">物件検索</span>
    </div>
  );
};
