import { Checkbox } from "@nutui/nutui-react";
import React from "react";
import { type Control, Controller } from "react-hook-form";

import { useFetchDataError } from "@/hooks";
import { useGetDevicesOfProperty, useGetPropertyById } from "@/queries";

import { BuildCheckbox } from "./BuildCheckbox";

interface BuildCheckboxGroupControlProps {
  name: string;
  control: Control<any>;
  update?: boolean; // 创建gate默认全选栋, 更新gate默认不选
  gateNumber: number;
}
export const BuildCheckboxControlGroup: React.FC<
  BuildCheckboxGroupControlProps
> = ({ name, control, update, gateNumber }) => {
  //registered building
  const {
    data,
    isError: isDoPError,
    error: doPError,
  } = useGetDevicesOfProperty("dearisGate", update);
  const filterData = data?.data?.filter(
    (item) => item?.position?.gate_number === gateNumber
  );
  const registeredBuildIds = filterData?.map(
    (item) => item?.position?.build_id
  );

  // options
  const { data: propertyData, isError, error } = useGetPropertyById();
  const options = propertyData?.data.buildings;
  const defaultValue = update ? [] : options?.map((item) => item.build_id);

  useFetchDataError(isError || isDoPError, error || doPError);
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { value, onChange } }) => {
        return (
          <Checkbox.Group onChange={onChange} defaultValue={defaultValue}>
            {options?.map((item) => (
              <BuildCheckbox
                key={item?.id}
                building={item}
                defaultChecked={value?.includes(item.build_id)}
                checked={value?.includes(item.build_id)}
                disabled={registeredBuildIds?.includes(item.build_id)}
              />
            ))}
          </Checkbox.Group>
        );
      }}
    />
  );
};
