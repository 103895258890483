import React from "react";

// 一级序号
export default function ConsentParagraphSecond({
  children,
}: Readonly<{
  children: React.ReactNode | React.ReactNode[];
}>) {
  return (
    <p className="indent-4 mt-3 mb-1  text-xs text-neutral-400 font-normal">
      {children}
    </p>
  );
}
