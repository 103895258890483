import type { LineStatus } from "@/types";

export const IMEIStatus = ({ status }: { status: LineStatus }) => {
  switch (status) {
    case "active":
      return <p className="text-[#52C41A]">開通中</p>;
    case "suspend":
      return <p className="text-[#FD8140]">一時停止</p>;
    case "dead":
      return <p className="text-error">解約された</p>;
    default:
      return <p className="text-error">未開通</p>;
  }
};
