import { cx } from "@emotion/css";
import { useNavigate } from "react-router-dom";

import SuccessIcon from "@/assets/success.svg?react";
import SuccessOutlineIcon from "@/assets/success-ouline.svg?react";
import { Button, Card, Footer, Header } from "@/components";
import { useLineOpen } from "@/queries";
/**
 * @deprecated
 * 历史遗留, 这部分代码一直没用上
 */
const ObjectActivated = () => {
  const { mutateAsync: openLineAsync, isLoading } = useLineOpen();
  const navigate = useNavigate();

  const handleOpenLine = async () => {
    await openLineAsync();
    navigate("/register-line");
  };
  return (
    <div className="contents">
      <Header>物件設定</Header>
      <div className="flex-1 bg-[#f6f6f6] p-4">
        <Card
          className={cx("p-8", "flex flex-col items-center justify-center")}
        >
          <SuccessIcon />
          <p className={cx("mb-6", "text-lg font-medium text-neutral-800")}>
            物件設定が完了
          </p>
          <p className={cx("text-[15px] text-neutral-800")}>
            デバイス上では通知ページが表示されます。「次へ」ボタンを押した後、下に「解錠テスト」をタップしてください。
          </p>
          <div
            className={cx(
              "mb-8 mt-4",
              "h-[356px] w-[200px]",
              "flex flex-col",
              "bg-[#2E3233]"
            )}
          >
            <p
              className={cx(
                "bg-[#3B4040]",
                "py-1",
                "text-center text-[8px] text-white"
              )}
            >
              設定モード
            </p>
            <div
              className={cx(
                "flex-1 rounded bg-white",
                "m-1.5 p-3.5",
                "flex flex-col items-center justify-center",
                "text-[#00161A]"
              )}
            >
              <SuccessOutlineIcon />
              <p className={cx("my-2.5", "text-sm font-medium")}>
                物件設定に成功しました
              </p>
              <p className={cx("text-center text-[10px]")}>
                「 次へ 」ボタンをタップしてください
              </p>
            </div>
            <div
              className={cx(
                "m-1.5 mt-0 py-[9px]",
                "rounded bg-primary",
                "text-center text-[11px] text-white"
              )}
            >
              次へ
            </div>
          </div>
          <Button
            className="w-full"
            loading={isLoading}
            onClick={handleOpenLine}
          >
            解錠テスト
          </Button>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default ObjectActivated;
