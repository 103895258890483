import { useQuery } from "@tanstack/react-query";

import { getEvents } from "@/shared/requests/events";
import { getStorage } from "@/shared/store";

import { EVENT_QUERY_KEY } from "./queryKey";

export function useListEvents() {
  const device_id = getStorage("deviceId");
  // const device_id = "3232a7a0df7b8d4358d97d521a331d46";
  const event_name = "BuildingUploaded";
  const queryFn = async () => {
    return getEvents({ event_name, identity: device_id, limit: 10 });
  };
  return useQuery([EVENT_QUERY_KEY], queryFn);
}
