import { useParams } from "react-router-dom";

import { useListDearis } from "@/queries";

export function useDearisBuildingDetail() {
  const { id } = useParams() as { id: string };
  const [entrances, elevators] = useListDearis(id);
  const isDevicesExist =
    !!entrances?.data?.data?.length || !!elevators?.data?.data?.length;
  return {
    entrances: entrances.data?.data,
    elevators: elevators.data?.data,
    isFetching: entrances.isFetching || elevators.isFetching,
    isDevicesExist,
  };
}
