import { useMutation } from "@tanstack/react-query";

import { NECESSARY_INFO_LACKING_HINT } from "@/shared";
import { getDeviceByID } from "@/shared/requests/device";
import {
  type AttachDeviceData,
  attachDeviceToProperty,
  updateDevicePosition,
  type UpdateDevicePositionData,
} from "@/shared/requests/property";
import { getStorage } from "@/shared/store";
import type { Property } from "@/types";

import { NecessaryInfoLackingError } from "./errors.type";

export function useUpdatePatmoPosition() {
  const mutateFn = async () => {
    const device_id = getStorage("deviceId");
    if (!device_id) throw new NecessaryInfoLackingError(NECESSARY_INFO_LACKING_HINT);
    const device = await getDeviceByID({ device_id, device_type: "PATMO" });

    if (device?.data.position) {
      const image_url = getStorage("uri") || import.meta.env.VITE_XIEZHEN_DEFAULT_URI;
      const spot = getStorage("spotName");
      const { id: property_id } = getStorage("selectedBuildingOrProperty") as Property;
      if (!spot || !image_url || !property_id) throw new NecessaryInfoLackingError(NECESSARY_INFO_LACKING_HINT);

      const data: UpdateDevicePositionData = {
        property_id,
        device_id,
        position: {
          spot,
          image_url,
        },
      };
      return updateDevicePosition(data);
    } else {
      // 获取基本信息
      const { id: property_id } = getStorage("selectedBuildingOrProperty") as Property;
      const sn = getStorage("serialNumber");
      const device_type = getStorage("deviceType");
      const spot = getStorage("spotName");
      const image_url = getStorage("uri") || import.meta.env.VITE_XIEZHEN_DEFAULT_URI;
      const gate_number = getStorage("gateNumberPATMO");
      if (!property_id || !sn || !device_type || !spot || !image_url || !gate_number)
        throw new NecessaryInfoLackingError();

      const positionData: AttachDeviceData = {
        sn,
        device_type,
        property_id,
        positions: [
          {
            spot,
            build_id: 0,
            gate_number: Number(gate_number),
            image_url,
          },
        ],
        setup_by: "manual",
      };
      return attachDeviceToProperty(positionData);
    }
  };
  return useMutation(mutateFn);
}
