// scanner 处理数据用的方法;

type DeviceDataType = number | string | boolean | undefined;
/**
 * 检查变量的类型是否符合要求
 * @param variable - 要检查的变量
 * @param types - 允许的类型
 */
export function checkVariableType(variable: any, ...types: DeviceDataType[]) {
  if (!types.includes(typeof variable)) {
    throw Error("类型不匹配");
  }
}

type DeviceDataKey =
  | "sn"
  | "aipSN"
  | "ifcSN"
  | "imei"
  | "gateNo"
  | "gateKind"
  | "buildId";
/**
 * 检查对象是否包含特定键
 * @param obj - 要检查的对象
 * @param key - 要检查的键
 * @returns 如果对象包含该键，则返回 true，否则返回 false
 */
export function hasKey(obj: object, key: DeviceDataKey) {
  if (!Object.prototype.hasOwnProperty.call(obj, key)) {
    throw new UnExistKeyError(key.toString() + "不存在该键");
  }
  return true;
}

/**
 * 检查字符串或数字是否符合指定的正则表达式
 * @param value - 要检查的字符串或数字
 * @param pattern - 正则表达式模式
 * @returns 如果符合正则表达式，则返回 true，否则返回 false
 */
export function matchesPattern(value: string | number, pattern: RegExp) {
  if (!value.toString().match(pattern)) {
    throw new UnMatchPatternError(value + " 不符合正则表达式");
  }
}

export class UnMactchTypeError extends Error {}
export class UnExistKeyError extends Error {}
export class UnMatchPatternError extends Error {}
