import { cx } from "@emotion/css";
import { useNavigate } from "react-router-dom";

import ScanIcon from "@/assets/scan-icon.svg?react";
import { Button } from "@/components";

export default function ScanButton() {
  const navigate = useNavigate();
  return (
    <Button className="w-full" onClick={() => navigate("/scan")}>
      <div className={cx("flex items-center gap-2.5")}>
        <ScanIcon />
        <span>スキャン</span>
      </div>
    </Button>
  );
}
