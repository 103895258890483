import { getStorage } from "@/shared/store";
import type { DeviceType } from "@/types";

import { DefaultSearch, LiteSearch } from "./object-search";

const ObjectSearch = () => {
  const deviceType = getStorage("deviceType") as DeviceType;
  switch (deviceType) {
    case "PabbitLite":
      return <LiteSearch />;
    case "PATMO":
      return <LiteSearch />;
    default:
      return <DefaultSearch />;
  }
};

export default ObjectSearch;
