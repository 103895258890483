import React from "react";
import { useNavigate } from "react-router-dom";

import { Button, Card, Footer, Header, Main } from "@/components";

const ManualRegisterOptions = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header>タイプを選択してください</Header>
      <Main>
        <Card className="flex flex-col gap-5">
          {/* <Button
            onClick={() =>
              navigate("/register/manual", { state: { hasImei: false } })
            }
          >
            共用部インターネット回線を使用
          </Button> */}
          <Button
            onClick={() =>
              navigate("/register/manual", { state: { hasImei: true } })
            }
          >
            専用通信アダプター(GBW-COMAD)を使用
          </Button>
        </Card>
      </Main>
      <Footer />
    </>
  );
};
export default ManualRegisterOptions;
