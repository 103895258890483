import { cx } from "@emotion/css";
import type { HTMLAttributes } from "react";

import XieZhenIcon from "@/assets/xiezhen.svg?react";

interface UploadInputProps {}
export function UploadInput({
  ...props
}: Readonly<UploadInputProps & HTMLAttributes<HTMLInputElement>>) {
  return (
    <div
      className={cx(
        "relative",
        "h-22 w-22 text-neutral-400",
        "border border-dashed border-neutral-400",
        "flex flex-col items-center justify-center gap-1"
      )}
    >
      <XieZhenIcon />
      <p className="text-center text-[10px] leading-3">
        アルバムか
        <br />
        らアップロード
      </p>
      <input
        className={cx("absolute left-0 top-0 h-full w-full opacity-0")}
        type="file"
        accept="image/.jpg, .jpeg, .png"
        {...props}
      />
    </div>
  );
}
