import { cx } from "@emotion/css";
import type {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

import { Hint } from "@/components";
import { InputWithClear, SelectInput } from "@/components/form";

interface ElevatorSpotInputProps {
  watch: UseFormWatch<any>;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  options: string[];
  [key: string]: any;
}

export const ElevatorSpotInput: React.FC<ElevatorSpotInputProps> = ({
  watch,
  register,
  setValue,
  getValues,
  options,
  ...props
}) => {
  const handleCustomLocationChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const cleaned = e.target.value.substring(0, 41);
    setValue("customLocation", cleaned);
  };
  return (
    <>
      <SelectInput
        register={register("location", {
          required: true,
        })}
        setValue={setValue}
        options={options}
        placeholder="該当する設置場所を選んでください"
        {...props}
      ></SelectInput>
      <InputWithClear
        className={cx(
          watch("location") === "カスタム" ? "block" : "hidden",
          "mt-4"
        )}
        register={register("customLocation", {
          onChange: handleCustomLocationChange,
        })}
        setValue={setValue}
        getValues={getValues}
        placeholder="識別名を入力してください"
        {...props}
      />
      <SpotNameHint
        hidden={Boolean(props.disabled)}
        location={watch("location") !== "カスタム"}
      />
    </>
  );
};

const SpotNameHint = ({
  hidden,
  location,
}: {
  hidden: boolean;
  location: boolean;
}) => {
  if (hidden) return null;
  return (
    <div>
      {location ? (
        <Hint className="my-2 text-xs">
          もし適切な選択肢がない場合は「カスタム」を選んで自由に名前を付けてください。
        </Hint>
      ) : (
        <p className="my-2 text-xs text-green-gray">
          複数エレベーターを登録する場合は「〇〇１～〇〇３号室用エレベーター」のように名付けてください。
        </p>
      )}
    </div>
  );
};
