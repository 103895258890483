import { cx } from "@emotion/css";
import {
  Popup as NutPopup,
  type PopupProps as NuiPopupProps,
} from "@nutui/nutui-react";
import React from "react";
import ReactDOM from "react-dom";

import AlertIcon from "@/assets/alert.svg?react";
import ErrorIcon from "@/assets/toast-icon-error.svg?react";

interface PopupProps {
  visible: boolean;
  children?: React.ReactNode[] | string;
  closeable?: boolean;
  className?: string;
}
export function Popup({
  visible,
  children,
  closeable = false,
  className,
  ...props
}: PopupProps & Partial<NuiPopupProps>) {
  return ReactDOM.createPortal(
    <NutPopup
      {...props}
      visible={visible}
      className={cx(
        "min-w-24 w-2/3 max-w-72 rounded-md p-6",
        closeable && "!pt-10",
        className
      )}
      overlayStyle={{ backgroundColor: "rgba(0,0,0,0.4)" }}
      closeOnOverlayClick={false}
      style={{
        background: "linear-gradient(to bottom, #EDFDFF 0, #FFFFFF 50%)",
      }}
      closeIconPosition="top-right"
      closeable={closeable}
    >
      {children}
    </NutPopup>,
    document.body
  );
}

export function PopupHeader({
  children,
  className,
  alert,
}: Readonly<{
  children: React.ReactNode;
  className?: string;
  alert?: boolean;
}>) {
  return (
    <header
      className={cx(
        "align-middle text-sm font-semibold text-primary",
        className
      )}
    >
      {alert && <AlertIcon className="mb-[2px] mr-1 inline text-alert" />}
      {children}
    </header>
  );
}

export function PopupMain({
  children,
  className,
}: Readonly<{
  children: React.ReactNode;
  className?: string;
}>) {
  return (
    <main className={cx("text-base leading-5 text-neutral-600", className)}>
      {children}
    </main>
  );
}

export function PopupAction({
  children,
  className,
}: Readonly<{ children: React.ReactNode; className?: string }>) {
  return <div className={cx("mt-5", className)}>{children}</div>;
}

export function ErrorPopup({
  visible,
  children,
  closeable = false,
  className,
  onClick,
  ...props
}: PopupProps & Partial<NuiPopupProps>) {
  return ReactDOM.createPortal(
    <NutPopup
      {...props}
      visible={visible}
      className={cx(
        "min-w-24 flex w-2/3 max-w-72 items-center rounded-md p-4 py-[11px] text-sm",
        className
      )}
      overlayStyle={{ backgroundColor: "rgba(0,0,0,.7)" }}
      closeOnOverlayClick={false}
      style={{
        background: "#555555",
        color: "#FFFFFF",
      }}
      closeIconPosition="top-right"
      closeable={closeable}
    >
      <ErrorIcon
        className="mr-2 inline w-10 hover:cursor-pointer"
        onClick={onClick}
      />
      {children}
    </NutPopup>,
    document.body
  );
}
