import { type FieldErrors, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Button, Card, Footer, Header, Main } from "@/components";
import { Label } from "@/components/form";
import { useFormErrorsToast, useVisibilityToggle } from "@/hooks";

import { PatmoSNInput } from "../components";

interface DearisControllerFormField {
  serialNumber: string;
}
export default function () {
  const navigate = useNavigate();
  const { watch, register, setValue, getValues, handleSubmit } =
    useForm<DearisControllerFormField>();
  const [isAllFieldFilled] = useVisibilityToggle(watch("serialNumber"));
  const { toastFormValueError } = useFormErrorsToast();

  const confirmRegister = () => {
    navigate("/register/confirm");
  };

  return (
    <>
      <Header>情報の登録</Header>
      <Main>
        <Card className="mt-4">
          <Label htmlFor="serialNumber" required>
            シリアル番号
          </Label>
          <PatmoSNInput
            register={register}
            setValue={setValue}
            getValues={getValues}
          />
          <div className="mb-6 mt-10">
            <Button
              className="w-full"
              size="large"
              onClick={handleSubmit(confirmRegister, (errors: FieldErrors) =>
                toastFormValueError(errors)
              )}
              disabled={!isAllFieldFilled}
            >
              確認
            </Button>
          </div>
        </Card>
      </Main>
      <Footer />
    </>
  );
}
