import { useQuery } from "@tanstack/react-query";

import { NECESSARY_INFO_LACKING_HINT } from "@/shared";
import {
  getLineInfoByDeviceId,
  getLineInfoByIMEI,
} from "@/shared/requests/line";
import { getStorage } from "@/shared/store";

import { NecessaryInfoLackingError } from "./errors.type";
import { LINE_QUERY_KEY } from "./queryKey";

export function useCheckLineStatus(enabled = true) {
  const imei = getStorage("imei");
  const queryFn = () => {
    if (!imei) throw new NecessaryInfoLackingError(NECESSARY_INFO_LACKING_HINT);
    return getLineInfoByIMEI({ imei });
  };
  return useQuery([LINE_QUERY_KEY, imei], queryFn, { enabled });
}

export function useCheckLineStatusByDeviceId() {
  const device_id = getStorage("deviceId");
  const queryFn = () => {
    if (!device_id)
      throw new NecessaryInfoLackingError(NECESSARY_INFO_LACKING_HINT);
    return getLineInfoByDeviceId({ device_id });
  };
  return useQuery([LINE_QUERY_KEY, device_id], queryFn);
}
