import {
  BuildIdNotFoundError,
  DeviceAlreadyExistsError,
  useCreateDearis,
  useGetBuildsByProperty,
  useListDevicesOfProperty,
} from "@/queries";
import { getStorage } from "@/shared/store";

export function useCreateElevator() {
  const {
    mutateAsync: getBuilds,
    isLoading: isBuildsGetting,
    isError: isGetBuildsError,
  } = useGetBuildsByProperty();
  const {
    mutateAsync: createDearis,
    isLoading: isCreating,
    isError: isCreateError,
  } = useCreateDearis();
  const {
    mutateAsync: getDevices,
    isLoading: isDevicesGetting,
    isError: isGetDevicesError,
  } = useListDevicesOfProperty();

  const createElevator = async ({
    buildId,
    gateNumber,
  }: {
    buildId: number;
    gateNumber: number;
  }) => {
    // 判断buildId是否存在
    const builds = await getBuilds();
    const theBuild = builds.data.find(
      (building) => building.build_id == buildId
    );
    if (!theBuild) {
      throw new BuildIdNotFoundError(
        "この棟番号は、既にアップロードされたデータの範囲内にはありません。"
      );
    }

    // 判断棟番号和ゲート番号是否重复
    const property_id = getStorage("propertyId");
    const devices = await getDevices({
      property_id,
      device_type: "dearisElevator",
    });
    const theDevice = devices.data.find(
      (device) =>
        device.position?.build_id == buildId &&
        device.position?.gate_number == gateNumber
    );
    if (theDevice) {
      throw new DeviceAlreadyExistsError(
        "棟番号とゲート番号が重複しています。"
      );
    }
    return createDearis();
  };

  return {
    createElevator,
    isCreating: isCreating || isBuildsGetting || isDevicesGetting,
    isCreateError: isGetBuildsError || isCreateError || isGetDevicesError,
  };
}
