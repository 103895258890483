import { useGetPropertyMutation } from "@/queries";

export function usePropertyData() {
  const { mutateAsync, isLoading, isError } = useGetPropertyMutation();

  const checkBuildingExists = async () => {
    const property = await mutateAsync();
    return property?.data?.buildings?.length;
  };

  return {
    checkBuildingExists,
    isLoading,
    isError,
  };
}
