import { cx } from "@emotion/css";

export default function StepTitle({
  children,
  className,
}: Readonly<{
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
}>) {
  return (
    <p className={cx("mx-auto font-bold text-lg", className)}>{children}</p>
  );
}
