interface LiteBindPayload {
  property_id?: string;
  build_id?: number;
}

const LITE_BIND_INFO_STORAGE = "lite-bind";
const LITE_BIND_INFO_STORAGE_VERSION = "1.0";
const LITE_BIND_INFO_STORAGE_VERSIONED_KEY = `${LITE_BIND_INFO_STORAGE}-${LITE_BIND_INFO_STORAGE_VERSION}`;

function getLiteBindInfoStorage(): Record<string, LiteBindPayload> {
  return JSON.parse(
    window.localStorage.getItem(LITE_BIND_INFO_STORAGE_VERSIONED_KEY) || "{}"
  );
}

function setLiteBindInfoStorage(storage: Record<string, LiteBindPayload>) {
  window.localStorage.setItem(
    LITE_BIND_INFO_STORAGE_VERSIONED_KEY,
    JSON.stringify(storage)
  );
}

export function setLiteBindInfo(deviceId: string, payload: LiteBindPayload) {
  const storage = getLiteBindInfoStorage();
  const old = getLiteBindInfo(deviceId);
  Object.assign(storage, { [deviceId]: { ...old, ...payload } });
  setLiteBindInfoStorage(storage);
}

export function getLiteBindInfo(deviceId: string): LiteBindPayload {
  return getLiteBindInfoStorage()[deviceId];
}

export function clearLiteBindInfoByDeviceId(deviceId: string) {
  const storage = getLiteBindInfoStorage();
  delete storage[deviceId];
  setLiteBindInfoStorage(storage);
}
