import { useEffect } from "react";
import type { UseFormReset } from "react-hook-form";
import { useParams } from "react-router-dom";

import { spotOptions } from "@/app.constant";
import { useFetchDataError } from "@/hooks";
import { useGetDeviceByIdManual } from "@/queries";

interface DeviceSpotFormField {
  location: string;
  customLocation: string;
  uri: string;
}
/**
 * @description 仅用于 Lite、 PATMO 设备的场所名设置
 * @param reset
 * @returns
 */
export function useDeviceSpotValue(reset: UseFormReset<any>) {
  const { id } = useParams();
  const { data, isLoading, isSuccess, isError, error } = useGetDeviceByIdManual(
    id || ""
  );
  useFetchDataError(isError, error);
  let defaultValues = {} as DeviceSpotFormField;

  if (isSuccess) {
    const spotOption = spotOptions.find(
      (spot) => spot === data?.data.position?.spot
    );
    defaultValues = {
      location: spotOption || "カスタム",
      customLocation: spotOption || data?.data.position?.spot || "",
      uri: data?.data.position?.image_uri || "",
    };
  }

  useEffect(() => {
    if (id && isSuccess) {
      reset(defaultValues);
    }
  }, [id, isSuccess]);

  // 当设备第一次设置场所名
  return {
    isLoading: id && isLoading,
    isError: id && isError,
    error,
  };
}
