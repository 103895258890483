import {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";

import { Button, Footer, PabbitLogo } from "@/components";

import ConsentContent from "./ConsentContent";

export default function Consent({
  setIsUserConsent,
}: {
  setIsUserConsent: Dispatch<SetStateAction<boolean>>;
}) {
  const consentRef = useRef<HTMLDivElement>(null);
  const [isDisabled, setIsDisabled] = useState(true);

  const handleUserConsent = () => {
    setIsUserConsent(true);
    window.localStorage.setItem(
      "pabbit_configurator_user_consent_confirm",
      "true"
    );
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsDisabled(false);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );
    if (consentRef.current) {
      observer.observe(consentRef.current);
    }

    return () => {
      if (consentRef.current) {
        observer.unobserve(consentRef.current);
      }
    };
  }, []);

  return (
    <div className="box-border flex h-screen flex-col px-8 pt-12">
      <PabbitLogo />
      <p className="my-5 text-center text-neutral-600"></p>
      {/* プライバシーポリシー */}
      <div className="bg-consent-gray flex-1 overflow-auto">
        <ConsentContent />
        <div ref={consentRef} className="h-4"></div>
      </div>
      <div className="my-4">
        <Button disabled={isDisabled} onClick={handleUserConsent}>
          承諾
        </Button>
      </div>
      <p className="mb-9 text-center text-neutral-400">
        利用規約に同意しない場合は、
        <br />
        このページを閉じてください。
      </p>
      <Footer />
    </div>
  );
}
