import { cx } from "@emotion/css";
import React from "react";

import ArrowIcon from "@/assets/arrow-right.svg?react";

interface ConfigPanelContainerProps {
  className?: string;
  children: React.ReactNode;
}

export const ConfigPanelContainer: React.FC<ConfigPanelContainerProps> = ({ className, children }) => {
  return <section className={cx("divide-x-0 divide-y divide-solid divide-[#E9EDEE]", className)}>{children}</section>;
};

interface ConfigPanelItemProps {
  step?: number;
  iconLighted?: boolean;
  className?: string;
  optionName: string;
  content?: string | React.ReactNode;
  placeholder?: string;
  disabled?: boolean;
  onClick?: () => void;
}
export const ConfigPanelItem: React.FC<ConfigPanelItemProps> = ({
  step,
  iconLighted = false,
  className,
  optionName,
  content,
  placeholder,
  disabled,
  onClick,
}) => {
  return (
    <div
      className={cx("px-0 py-[10px]", "text-left disabled:text-black", className)}
      onClick={() => {
        disabled || onClick?.();
      }}
    >
      <section className={cx("flex justify-between")}>
        <main className="flex items-start">
          <span>
            {step && (
              <BlueCircleIndicator className={cx(iconLighted ? "!bg-primary" : "", "m-1 mr-[10px]")}>
                {step}
              </BlueCircleIndicator>
            )}
          </span>
          <div className="mb-[3px] mt-[2px]">
            <p className="mb-1 text-[13px]">{optionName}</p>
            <p className={cx("min-h-4 text-xs", content ? "text-green-gray" : "text-error")}>
              {content || placeholder}
            </p>
          </div>
        </main>
        <aside>
          {disabled || <ArrowIcon className={cx("h-4 w-4 text-[#C4CBCC]", iconLighted && "!text-primary", "mt-1")} />}
        </aside>
      </section>
    </div>
  );
};

const BlueCircleIndicator = ({ className, children }: { className?: string; children?: number }) => {
  return (
    <span
      className={cx(
        "inline-block h-4 w-4 rounded-full",
        "bg-[#C4CBCC] text-center text-xs font-medium text-white",
        className
      )}
    >
      {children}
    </span>
  );
};
