import { useNavigate } from "react-router-dom";

import { OverlayLoading } from "@/components";
import { useUpdatePatmoTest } from "@/pages/unlockTest/patmo/hooks";
import { errorToast } from "@/shared";

export const SkipTestBTNPATMO = () => {
  const navigate = useNavigate();
  const { updatePatmoTests, isLoading } = useUpdatePatmoTest();

  const skipTest = async () => {
    await updatePatmoTests()
      .catch((error) => {
        if (error?.status && error?.status === 400) {
          errorToast("設定状態が変更されました。");
        } else {
          errorToast(error?.message ?? error.toString());
        }
      })
      .finally(() => {
        navigate("/unlock-overview", { replace: true });
      });
  };
  return (
    <>
      <button className="mt-5 text-primary" onClick={skipTest}>
        テストをスキップ
      </button>
      {isLoading && <OverlayLoading visible />}
    </>
  );
};
