import { cx } from "@emotion/css";
import { useNavigate } from "react-router-dom";

import { Button, Card } from "@/components";

export const SearchObjectEmpty = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/configure/search", { replace: true });
  };

  return (
    <Card className="my-3">
      <p className={cx("mb-8", "text-[15px] text-neutral-800")}>
        該当する物件情報がありません
      </p>
      <Button onClick={handleBack} fill="outline">
        物件検索に戻る
      </Button>
    </Card>
  );
};
