import { cx } from "@emotion/css";

export const Label = ({
  htmlFor,
  children,
  required,
  className,
}: {
  htmlFor: string;
  children: string;
  required?: boolean | string;
  className?: string;
}) => {
  return (
    <label htmlFor={htmlFor} className={cx("block", className)}>
      <p className={cx("mb-2 text-[15px] font-normal leading-[22.5px]")}>
        {children}&nbsp;
        {required && <span className="text-danger">{typeof required === "string" ? required : "*"}</span>}
      </p>
    </label>
  );
};
