import { CompressImageError, ParseCanvasToBlobError } from "./common.type";

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export async function parseImageToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

export async function parseDataUrlToImageData(
  dataUrl: string
): Promise<ImageData> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const context = canvas.getContext("2d");
      context?.drawImage(img, 0, 0, canvas.width, canvas.height);
      const imageData = context?.getImageData(
        0,
        0,
        canvas.width,
        canvas.height
      );
      if (imageData) {
        resolve(imageData);
      } else {
        reject(
          new Error(
            "画像の処理に失敗しました。再度写真を撮るか、手動で入力してください。"
          )
        );
      }
    };
    img.onerror = () =>
      reject(
        new Error(
          "画像の処理に失敗しました。再度写真を撮るか、手動で入力してください。"
        )
      );
    img.src = dataUrl;
  });
}

export async function parseFileToImageData(file: File): Promise<ImageData> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const context = canvas.getContext("2d");
        context?.drawImage(img, 0, 0, canvas.width, canvas.height);
        const imageData = context?.getImageData(
          0,
          0,
          canvas.width,
          canvas.height
        );
        if (imageData) {
          resolve(imageData);
        } else {
          reject("faild to parse file to image data");
        }
      };
      img.onerror = () =>
        reject(
          new Error(
            "画像の処理に失敗しました。再度写真を撮るか、手動で入力してください。"
          )
        );
      img.src = e.target?.result as string;
    };
    reader.onerror = () =>
      reject(
        new Error(
          "画像の処理に失敗しました。再度写真を撮るか、手動で入力してください。"
        )
      );
    reader.readAsDataURL(file);
  });
}

export async function parseCanvasToBlob(
  canvas: HTMLCanvasElement
): Promise<Blob> {
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new ParseCanvasToBlobError("failed to parse canvas to blob"));
        }
      },
      undefined,
      0.1
    );
  });
}

export const compressImage = async (
  blob: Blob,
  limit = 2,
  compressRatio = 0.5,
  quality = 1
): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = function (e) {
      const image = new Image();
      if (e.target && typeof e.target.result === "string") {
        image.src = e.target.result;
        image.onload = function () {
          const { width, height } = image;
          const maxResolution = 16000000;
          if (width * compressRatio * height * compressRatio > maxResolution) {
            compressImage(blob, limit, compressRatio * compressRatio, quality);
          }
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.setAttribute("width", "" + width * compressRatio);
          canvas.setAttribute("height", "" + height * compressRatio);
          ctx?.drawImage(
            image,
            0,
            0,
            width * compressRatio,
            height * compressRatio
          );
          canvas.toBlob(
            (result) => {
              if (result) {
                return result.size / 1024 ** 2 > limit
                  ? resolve(
                    compressImage(result, limit, compressRatio, quality)
                  )
                  : resolve(result);
              } else {
                reject(new CompressImageError("failed to compress image"));
              }
            },
            blob.type,
            quality
          );
          canvas.remove();
        };
      }
    };
  });
};

export { CompressImageError, ParseCanvasToBlobError } from "./common.type";
