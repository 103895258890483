import { useHandleDeviceUnknown } from "@/hooks";
import { getStorage } from "@/shared/store";
import type { DeviceType } from "@/types";

import {
  ElevatorSpot,
  EntranceSpot,
  GateSpot,
  LiteSpot,
  PATMOSpot,
} from "./spot-setting";

export default function SpotSetting() {
  const device_type = getStorage("deviceType") as DeviceType;
  const { toastAndToSelectDevice } = useHandleDeviceUnknown();
  switch (device_type) {
    case "PabbitLite":
      return <LiteSpot />;
    case "PATMO":
      return <PATMOSpot />;
    case "dearisGate":
      return <GateSpot />;
    case "dearisEntrance":
      return <EntranceSpot />;
    case "dearisElevator":
      return <ElevatorSpot />;
    default: {
      toastAndToSelectDevice();
    }
  }
}
