import { useQuery } from "@tanstack/react-query";

import { NECESSARY_INFO_LACKING_HINT } from "@/shared";
import { getDeviceByID } from "@/shared/requests/device";
import { getStorage } from "@/shared/store";

import { NecessaryInfoLackingError } from "./errors.type";
import { DEVICE_QUERY_KEY } from "./queryKey";

export function useCheckDeviceStatus() {
  const device_id = getStorage("deviceId");
  const queryFn = () => {
    const device_type = getStorage("deviceType");
    if (!device_id || !device_type)
      throw new NecessaryInfoLackingError(NECESSARY_INFO_LACKING_HINT);
    return getDeviceByID({ device_type, device_id });
  };

  return useQuery([DEVICE_QUERY_KEY, device_id], queryFn, {
    enabled: !!device_id,
  });
}
