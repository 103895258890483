import { cx } from "@emotion/css";
import { barcode } from "pure-svg-code";
import { useEffect, useState } from "react";

import { ToggleFullScreen } from "@/components";

export const LiteUnlockBarcode = ({ code }: { code: string }) => {
  const [barCodeString, setBarCodeString] = useState("");
  useEffect(() => {
    if (code) {
      const barCodeString = barcode(code, "codabar", {
        bgColor: "white",
        barHeight: 40,
        width: 142,
      });
      setBarCodeString(barCodeString);
    }
  }, [code]);
  return (
    <div className={cx("rounded-md bg-bgGray", "px-4 py-11", "relative")}>
      <ToggleFullScreen>
        {
          <>
            {barCodeString && (
              <div dangerouslySetInnerHTML={{ __html: barCodeString }}></div>
            )}
            <p className={cx("text-center font-black")}>{code}</p>
          </>
        }
      </ToggleFullScreen>
    </div>
  );
};
