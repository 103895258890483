import { cx } from "@emotion/css";
import type { HTMLAttributes } from "react";

import CameraIcon from "@/assets/camera.svg?react";

interface CameraInputProps {}

export function CameraInput({
  ...props
}: Readonly<CameraInputProps & HTMLAttributes<HTMLInputElement>>) {
  return (
    <div
      className={cx(
        "relative",
        "h-22 w-22 text-neutral-400",
        "border border-dashed border-neutral-400",
        "flex flex-col items-center justify-center gap-1"
      )}
    >
      <CameraIcon />
      <p className="text-[10px] leading-3">写真を撮る</p>
      <input
        className="absolute left-0 top-0 h-full w-full opacity-0"
        type="file"
        capture
        accept="image/.jpg, .jpeg, .png "
        {...props}
      />
    </div>
  );
}
