import { cx } from "@emotion/css";
import React, { type FC } from "react";

import { Footer } from "@/components";
import { infoToast } from "@/shared";
import type { DeviceType } from "@/types";

import {
  ArrowRight,
  DearisDevicePic,
  PabbitLiteDevicePic,
  PatmoDevicePic,
} from "./asserts";

interface IDeviceCard {
  imgUrl: string;
  title: string;
  color?: string;
  type: DeviceType;
  onClick: (_t: DeviceType) => void;
}

const DeviceCard: FC<IDeviceCard> = ({
  imgUrl,
  type,
  title,
  color,
  onClick,
}) => {
  return (
    <div
      className={cx(
        "relative",
        "overflow-clip",
        "flex h-[72px] items-center justify-between",
        "rounded-md bg-bgGray px-5"
      )}
      onClick={() => onClick(type)}
    >
      <div
        style={{ borderLeftColor: color }}
        className={cx(
          "absolute left-0",
          "h-full w-2",
          "border-0 border-l-2 border-solid"
        )}
      />
      <div className="flex items-center gap-5">
        <img src={imgUrl} alt="" className="h-10 w-10 object-contain" />
        <span>{title}</span>
      </div>
      <ArrowRight />
    </div>
  );
};

const SettingMethods = React.memo(() => {
  // const navigate = useNavigate();

  const handleDeviceClick = (type: DeviceType) => {
    infoToast("準備中");
    console.log(type);
    // navigate(`/setting-methods?type=${type}`);
  };

  return (
    <>
      <div className="flex-1 px-4 py-6">
        <p className="text-center text-lg font-semibold">設定方法について</p>
        <div className={cx(" my-4 space-y-3")}>
          <DeviceCard
            imgUrl={PabbitLiteDevicePic}
            title="Pabbit Liteの設定方法"
            color="#00AFCC"
            type="PabbitLite"
            onClick={handleDeviceClick}
          />
          <DeviceCard
            imgUrl={PatmoDevicePic}
            title="PATMOの設定方法"
            color="#F57357"
            type="PATMO"
            onClick={handleDeviceClick}
          />
          <DeviceCard
            imgUrl={DearisDevicePic}
            title="dearisの設定方法"
            color="#F5B657"
            type="dearis"
            onClick={handleDeviceClick}
          />

          {/* <DeviceCard
            imgUrl={UnknownDevicePic}
            title="アダプタの設定方法"
            color="#6AB3F5"
            type="adapter"
            onClick={handleDeviceClick}
          /> */}
        </div>
      </div>
      <Footer />
    </>
  );
});

export default SettingMethods;
