import type { StepIndicatorArabic } from "./step.type";

const indicatorMap: Record<StepIndicatorArabic, string> = {
  1: "❶",
  2: "❷",
  3: "❸",
  4: "❹",
  5: "❺",
  6: "❻",
  7: "❼",
  8: "❽",
  9: "❾",
};

export default function StepIndicator({
  children,
}: Readonly<{ children: string }>) {
  const formattedChildren = parseInt(children, 10);
  return (
    <span className="text-primary">
      {indicatorMap[formattedChildren as StepIndicatorArabic]}&nbsp;
    </span>
  );
}
