import ImageCompleted from "@/assets/service-configure-four-btn.png";
import ImageStepZero from "@/assets/service-configure-patmo-with-signal.png";
import ImageSucceedGetDeliveries from "@/assets/service-configure-step-1.png";
import ImageStepTwo from "@/assets/service-configure-step-2.png";
import ImageUploadRoomSucceed from "@/assets/service-configure-upload-room-succeed.png";
import type { StepsDataModal } from "@/components/step";

export const patmoStepsData: StepsDataModal = {
  steps: [
    // step index
    [
      {
        type: "image",
        content: {
          src: ImageStepZero,
          caption: "PATMOαの設定ページ",
        },
      },
      {
        type: "caption",
        content:
          "画面が消灯したら、トップページに戻った場合はPabbitのボタンを押して、次画面の右上を連続して３回タッチします。",
      },
    ],
    // step 1
    [
      {
        type: "title",
        content: "操作手順",
      },
      {
        type: "text",
        content: "設定ページに「サービスリスト再取得」を押してください。",
        indicator: 1,
      },
      { type: "image", content: ImageSucceedGetDeliveries },
      {
        type: "text",
        content:
          "成功すると、この画面が表示されます。「戻る」をタップして設定ページに戻ります。",
      },
    ],
    // step 2 3
    [
      {
        type: "text",
        content:
          "設定ページに戻ったら、「部屋情報リストメニュー」を押してください。",
        indicator: 2,
      },
      { type: "image", content: ImageStepTwo },
      {
        type: "text",
        content:
          "このページに「部屋情報リスト作成」を押してください。リスト作成後、「部屋情報アップロード」を押してください。",
      },

      { type: "image", content: ImageUploadRoomSucceed },
      {
        type: "text",
        content:
          "成功すると、この画面が表示されます。「戻る」をタップしてください。",
      },
      { type: "image", content: ImageStepZero },
    ],
  ],
};

export const patmoStepsData2: StepsDataModal = {
  steps: [
    [
      { type: "title", content: "スキャナーがない場合" },
      {
        type: "image",
        content: {
          src: ImageCompleted,
          caption: "PATMOαの設定ページ",
        },
      },
      { type: "text", content: "設定ページの終了ボタンを押してください。" },
      { type: "text", content: "ここで「完了」をタップしてください。" },
    ],
  ],
};
export const patmoStepsData3: StepsDataModal = {
  steps: [
    [
      {
        type: "text",
        content: "このページに戻ったら、終了ボタンを押してください。",
        indicator: 3,
      },
      { type: "text", content: "ここで「完了」をタップしてください。" },
    ],
  ],
};
