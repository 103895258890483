import { type FieldErrors, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Button, Card, Footer, Header, Main } from "@/components";
import { Label } from "@/components/form";
import { useFormErrorsToast, useVisibilityToggle } from "@/hooks";
import { getStorage, setStorage } from "@/shared/store";

import { BuildIdInput, GateNumberInput, PatmoSNInput } from "../components";

interface DearisEntranceFormField {
  serialNumber: string;
  buildId: number;
  gateNumber: number;
}

export function DearisEntranceManualRegister() {
  const navigate = useNavigate();
  const snSource = getStorage("dearisController")?.sn ?? "";
  const { watch, register, setValue, getValues, handleSubmit } =
    useForm<DearisEntranceFormField>({
      defaultValues: {
        buildId: getStorage("configureDearisDevicesBuildId") ?? 0,
        serialNumber: snSource,
      },
    });

  const [isAllFieldFilled] = useVisibilityToggle(watch("gateNumber"));
  const { toastFormValueError } = useFormErrorsToast();

  const confirmRegister = (values: DearisEntranceFormField) => {
    setStorage("serialNumber", snSource);
    setStorage("buildId", values.buildId);
    setStorage("gateNumber", values.gateNumber);
    navigate("/register/confirm");
  };

  return (
    <>
      <Header>情報の登録</Header>
      <Main>
        <Card className="mt-4">
          <Label htmlFor="serialNumber" required>
            シリアル番号
          </Label>
          <PatmoSNInput
            register={register}
            setValue={setValue}
            getValues={getValues}
            disabled
          />
          <div className="h-6"></div>

          <Label htmlFor="buildId" required>
            棟番号
          </Label>
          <BuildIdInput
            register={register}
            setValue={setValue}
            getValues={getValues}
            disabled
          />
          <div className="h-6"></div>
          <Label htmlFor="gateNumber" required>
            ゲート番号
          </Label>
          <GateNumberInput
            register={register}
            setValue={setValue}
            getValues={getValues}
          />
          <div className="mt-10">
            <Button
              className="w-full"
              size="large"
              onClick={handleSubmit(confirmRegister, (errors: FieldErrors) =>
                toastFormValueError(errors)
              )}
              disabled={!isAllFieldFilled}
            >
              確認
            </Button>
          </div>
        </Card>
      </Main>
      <Footer />
    </>
  );
}
