import Captcha from "rc-captcha-input";
import type { FC } from "react";
import { useState } from "react";
import { useFormContext, type UseFormRegisterReturn } from "react-hook-form";

interface ISmsCaptcha {
  register: UseFormRegisterReturn<any>;
}
export const SmsCaptcha: FC<ISmsCaptcha> = ({ register }) => {
  const { setValue } = useFormContext();
  const [captchaCode, setCaptchaCode] = useState("");
  const onCaptchaChange = (val: string) => {
    setCaptchaCode(val);
    setValue(register.name, val);
  };
  return (
    <>
      <Captcha value={captchaCode} onChange={onCaptchaChange} length={6} />
    </>
  );
};
