import { useNavigate } from "react-router-dom";

import RectangleIcon from "@/assets/rectangle.svg?react";
import {
  Button,
  Card,
  Footer,
  Header,
  Main,
  Popup,
  PopupAction,
  PopupButton,
  PopupMain,
} from "@/components";
import {
  ConfigPanelContainer,
  ConfigPanelItem,
} from "@/components/config-panel";
import { useFetchDataError, useModal } from "@/hooks";
import { getStorage, setStorage } from "@/shared/store";

import {
  BasicInfoCard,
  BasicInfoItem,
  IMEIStatus,
  StatusSkeleton,
} from "../components";
import { useGetPATMOStatus } from "../hooks";

export default function () {
  const navigate = useNavigate();

  const { visible: objectSettingVisible, toggle: objectSettingToggle } =
    useModal();
  const { visible: lineNotOpenedVisible, toggle: lineNotOpenedToggle } =
    useModal();
  const { visible: serviceSettingVisible, toggle: serviceSettingToggle } =
    useModal();
  const { visible: allDoneVisible, toggle: allDoneToggle } = useModal();
  const { visible: spotSettingVisible, toggle: spotSettingToggle } = useModal();

  const { device, deviceStatus, isLoading, isError, error, isSettingAllDone } =
    useGetPATMOStatus();
  useFetchDataError(isError, error);
  // console.log("device", device);
  const hasImei = device?.imei?.length !== 0 && device?.imei !== undefined;
  // console.log("hasImei", hasImei);
  // const { mutateAsync: registerToAmazon } = useRegisterDeviceToAmazon();

  const sn = getStorage("serialNumber");
  const gateNumber =
    device?.position?.gate_number?.toString() ||
    getStorage("gateNumberPATMO")?.toString() ||
    "-";

  if (isLoading) {
    return <StatusSkeleton />;
  }

  const handleLineOpenedClick = () => {
    if (!deviceStatus.bindedProperty) {
      objectSettingToggle();
      return;
    }
    setStorage("imei", device?.imei || "");
    navigate("/line-open");
  };
  const checkBindAndOpenStatus = (callback: () => void) =>
    function () {
      if (!deviceStatus.bindedProperty) {
        objectSettingToggle();
        return;
      }
      if (device?.imei && deviceStatus.lineActived !== "active") {
        lineNotOpenedToggle();
        return;
      }
      callback();
    };

  const handleServiceConfigureClick = checkBindAndOpenStatus(() => {
    navigate("/service-configure", { state: { hasImei: hasImei } });
  });
  const checkServiceStatus = (callback: () => void) =>
    function () {
      if (!deviceStatus.isBuildingUploaded) {
        serviceSettingToggle();
        return;
      }
      callback();
    };

  const handleSetSpotNameClick = checkBindAndOpenStatus(
    checkServiceStatus(() => {
      if (!deviceStatus.positionedSpotName) {
        navigate("/spot");
      } else {
        navigate(`/spot/${device?.id}`);
      }
    })
  );

  const checkSpotStatus = (callback: () => void) =>
    function () {
      if (deviceStatus.positionedSpotName !== 2) {
        spotSettingToggle();
        return;
      }
      callback();
    };
  const handleUnlockClick = checkBindAndOpenStatus(
    checkServiceStatus(
      checkSpotStatus(() => {
        navigate("/unlock-overview");
      })
    )
  );

  const handleNextClick = () => {
    !allDoneVisible && allDoneToggle();
    // registerToAmazon()
    //   .then(() => {
    //     !allDoneVisible && allDoneToggle();
    //   })
    //   .catch((error) => {
    //     if (error instanceof CloudUnlockERRNETWORKError) {
    //       toastBottom("ネットワークタイムアウト");
    //     } else {
    //       !allDoneVisible && allDoneToggle();
    //     }
    //   });
  };

  return (
    <>
      <Header>登録した情報</Header>
      <Main className="flex flex-col gap-3">
        <BasicInfoCard>
          <BasicInfoItem title="シリアル番号" content={sn} />
          {device?.imei && (
            <BasicInfoItem title="IMEI" content={device?.imei} />
          )}
          <BasicInfoItem title="ゲート番号" content={gateNumber} />
        </BasicInfoCard>
        <p className="mt-2 font-semibold">
          <RectangleIcon className="mb-[2px] inline h-4 w-4" />
          <span>設定手順</span>
        </p>
        <Card className="px-4 py-2">
          <ConfigPanelContainer>
            <ConfigPanelItem
              step={1}
              iconLighted={!!deviceStatus.bindedProperty}
              optionName="物件設定"
              content={deviceStatus.bindedProperty}
              placeholder="未設定"
              onClick={() => navigate("/configure")}
            />
            {device?.imei ? (
              <>
                <ConfigPanelItem
                  step={2}
                  iconLighted={deviceStatus.lineActived === "active"}
                  optionName="回線開通（IMEI）"
                  content={<IMEIStatus status={deviceStatus.lineActived} />}
                  onClick={handleLineOpenedClick}
                />
                <ConfigPanelItem
                  step={3}
                  iconLighted={!!deviceStatus.isBuildingUploaded}
                  optionName="サービス設定"
                  content={deviceStatus?.isBuildingUploaded && "設定済み"}
                  placeholder="未設定"
                  onClick={handleServiceConfigureClick}
                />
                <ConfigPanelItem
                  step={4}
                  iconLighted={deviceStatus.positionedSpotName === 2}
                  optionName="設置場所と写真"
                  content={deviceStatus.positionedSpotName === 2 && "設定済み"}
                  placeholder="未設定"
                  onClick={handleSetSpotNameClick}
                />
                <ConfigPanelItem
                  step={5}
                  iconLighted={deviceStatus.isUnlockTestAllPassed}
                  optionName="解錠テスト"
                  content={
                    <>
                      <p>番号入力解錠テスト</p>
                      <p>クラウド解錠テスト</p>
                    </>
                  }
                  onClick={handleUnlockClick}
                />
              </>
            ) : (
              <>
                <ConfigPanelItem
                  step={2}
                  iconLighted={!!deviceStatus.isBuildingUploaded}
                  optionName="サービス設定"
                  content={deviceStatus?.isBuildingUploaded && "設定済み"}
                  placeholder="未設定"
                  onClick={handleServiceConfigureClick}
                />
                <ConfigPanelItem
                  step={3}
                  iconLighted={deviceStatus.positionedSpotName === 2}
                  optionName="設置場所と写真"
                  content={deviceStatus.positionedSpotName === 2 && "設定済み"}
                  placeholder="未設定"
                  onClick={handleSetSpotNameClick}
                />
                <ConfigPanelItem
                  step={4}
                  iconLighted={deviceStatus.isUnlockTestAllPassed}
                  optionName="解錠テスト"
                  content={
                    <>
                      <p>番号入力解錠テスト</p>
                      <p>クラウド解錠テスト</p>
                    </>
                  }
                  onClick={handleUnlockClick}
                />
              </>
            )}
          </ConfigPanelContainer>
        </Card>
        <div className="mb-2 mt-8 text-center">
          <Button
            onClick={handleNextClick}
            disabled={isError || !isSettingAllDone}
          >
            設定を完了
          </Button>
        </div>
      </Main>
      <Footer />
      <Popup visible={objectSettingVisible}>
        <PopupMain>{"物件の設定を先に完了してください。"}</PopupMain>
        <PopupAction className="!mt-10 flex justify-center">
          <PopupButton
            size="normal"
            className="w-[124px]"
            onClick={objectSettingToggle}
          >
            閉じる
          </PopupButton>
        </PopupAction>
      </Popup>
      <Popup visible={lineNotOpenedVisible}>
        <PopupMain>
          {
            "回線が開通していないため、後続の操作ができません。先に回線を開通してください。"
          }
        </PopupMain>
        <PopupAction className="flex justify-center">
          <PopupButton onClick={lineNotOpenedToggle}>閉じる</PopupButton>
        </PopupAction>
      </Popup>
      <Popup visible={serviceSettingVisible}>
        <PopupMain>{"サービス設定を完了してください。"}</PopupMain>
        <PopupAction className="flex justify-center">
          <PopupButton onClick={serviceSettingToggle}>閉じる</PopupButton>
        </PopupAction>
      </Popup>
      <Popup visible={spotSettingVisible}>
        <PopupMain>
          <p className="mb-2 text-lg font-semibold">注意：</p>
          「設置場所」と「写真」の情報がありません。クラウド解錠テストを完了することができません。
        </PopupMain>
        <PopupAction className="flex justify-center">
          <PopupButton onClick={spotSettingToggle}>閉じる</PopupButton>
        </PopupAction>
      </Popup>
      <Popup visible={allDoneVisible}>
        <PopupMain>{"設定が完了しました。"}</PopupMain>
        <PopupAction className="flex justify-center">
          <PopupButton onClick={() => navigate("/")}>確定</PopupButton>
        </PopupAction>
      </Popup>
    </>
  );
}
