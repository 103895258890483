import { useMutation } from "@tanstack/react-query";

import { NECESSARY_INFO_LACKING_HINT } from "@/shared";
import { bindDeviceToProperty } from "@/shared/requests/property";
import { getStorage } from "@/shared/store";
import type { DeviceType } from "@/types";

import { NecessaryInfoLackingError } from "./errors.type";

export function useBindDevice() {
  const mutateFn = async () => {
    const device_type = getStorage("deviceType") as DeviceType;
    const sn = getStorage("serialNumber");
    const property_id = getStorage("propertyId");

    if (!device_type || !sn || !property_id) {
      throw new NecessaryInfoLackingError(NECESSARY_INFO_LACKING_HINT);
    }
    return bindDeviceToProperty({ property_id, sn, device_type });
  };
  return useMutation(mutateFn);
}
