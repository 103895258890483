import { useInfiniteQuery } from "@tanstack/react-query";

import { getBuildings } from "@/shared/requests/building";

import { BUILDING_QUERY_KEY } from "./queryKey";

export function useInfiniteListBuildings(params: {
  page?: number;
  page_size?: number;
  property_name?: string;
  address?: string;
}) {
  return useInfiniteQuery(
    [BUILDING_QUERY_KEY, params],
    ({ pageParam = params }) => getBuildings(pageParam),
    {
      getNextPageParam: (lastPage, allPage) => {
        const totalPage = lastPage.data.total_pages;
        const currentPage = allPage.length;
        if (currentPage >= totalPage) return false;
        const newParams = { ...params, page: currentPage + 1 };
        return newParams;
      },
    }
  );
}
