import LiteBusinessIMG from "@/assets/lite-mode-business.png";
import LiteDebugIMG from "@/assets/lite-mode-debug.png";
import LiteToggleSucceed from "@/assets/lite-mode-toggle-success.png";
import type { StepsDataModal } from "@/components/step";

export const liteStepsData: StepsDataModal = {
  steps: [
    [
      {
        type: "images",
        content: [
          { src: LiteDebugIMG, caption: "" },
          { src: LiteBusinessIMG, caption: "" },
        ],
      },
      {
        type: "text",
        content:
          "デバイス上で、丸いボタンを押しながら右にスライドしてください（図2参照）。「運用モード」と表示されたら、「確認」をタップしてください。",
      },
    ],
    [
      { type: "image", content: LiteToggleSucceed },
      {
        type: "text",
        content:
          "成功の画面が表示されれば、すべての設定が完了です。「モード切替を完了」をタップしてトップページに戻ります。",
      },
    ],
  ],
};
