import { cx } from "@emotion/css";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Card,
  Footer,
  Header,
  Main,
  Popup,
  PopupAction,
  PopupMain,
} from "@/components";
import { useGetScannerData, useModal } from "@/hooks";
import { GateNumberSelectInput } from "@/pages/registerDevice/components";
import { useDeleteIMEI } from "@/queries";
import {
  DELETE_IMEI_FAILED_HINT,
  errorToast,
  REGISTER_DEVICE_FAILED_HINT,
} from "@/shared";
import { getStorage, setStorage } from "@/shared/store";

import { Warning } from "../components";
import {
  GateNumberNotMatchError,
  IMEIStatusError,
  SNIMEINotMatchError,
  useCheckAndCreatePatmo,
} from "../hooks";

interface PatmoDataConfirmFields {
  gateNumber: number;
}

export default function PatmoDataConfirm() {
  const navigate = useNavigate();
  const { register, setValue, getValues, handleSubmit } =
    useForm<PatmoDataConfirmFields>();

  const [imei, setImei] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [gateNumber, setGateNumber] = useState("");
  const { mutateAsync: deleteIMEI, isLoading: isDeleting } = useDeleteIMEI();
  const { visible: imeiNotFoundVisible, toggle: imeiNotFoundToggle } =
    useModal();
  const { visible: useLanVisible, toggle: LanToggle } = useModal();
  const { visible: notMatchVisible, toggle: notMatchToggle } = useModal();
  const {
    visible: gateNumberNotMatchVisible,
    toggle: gateNumberNotMatchToggle,
  } = useModal();

  const { checkAndCreatePatmo, isLoading } = useCheckAndCreatePatmo();

  const handleDataConfirmClick = (values: PatmoDataConfirmFields) => {
    setStorage("gateNumberPATMO", Number(gateNumber) || values.gateNumber);
    checkAndCreatePatmo()
      .then(({ data }) => {
        setStorage("deviceId", data.id || "");
        // console.log("dataImei", data.imei)
        // console.log("imei", imei)
        if (
          imei?.length === 0 &&
          data.imei !== undefined &&
          imei !== data.imei
        ) {
          LanToggle();
        } else {
          navigate("/status");
        }
      })
      .catch((e) => {
        if (e instanceof IMEIStatusError) {
          imeiNotFoundToggle();
        } else if (e instanceof GateNumberNotMatchError) {
          gateNumberNotMatchToggle();
          setStorage("gateNumberPATMO", undefined);
          setGateNumber("");
        } else if (e instanceof SNIMEINotMatchError) {
          notMatchToggle();
        } else if (e.status === 400) {
          errorToast(REGISTER_DEVICE_FAILED_HINT);
        } else {
          errorToast(e.message ?? e.toString());
        }
      });
  };

  const handleIMEINotExistClick = () => {
    imeiNotFoundToggle();
    navigate("/register");
  };

  const handleChangeIMEIClick = () => {
    setStorage("PATMOReplaceIMEI", true);
    navigate("/imei-replace");
  };

  const handleGateNoNotMatchClose = () => {
    gateNumberNotMatchToggle();
    navigate("/register");
  };
  const handleBack = () => {
    LanToggle();
  };
  const handleUseLan = () => {
    deleteIMEI()
      .then(() => navigate("/status"))
      .catch((error) => {
        if (error?.status === 400) {
          errorToast(DELETE_IMEI_FAILED_HINT);
        } else {
          errorToast(error?.message ?? error.toString());
        }
      });
  };

  useGetScannerData();
  useEffect(() => {
    setImei(getStorage("imei"));
    setSerialNumber(getStorage("serialNumber"));
    setGateNumber(getStorage("gateNumberPATMO")?.toString() || "");
  }, []);

  return (
    <>
      <Header>デバイス情報の確認</Header>
      <Main>
        <Card className="shadow-sm">
          <Warning>
            {
              "ここに表示されている情報がデバイス側の情報と一致していることを確認してください。"
            }
          </Warning>
          <UniItem label="シリアル番号" content={serialNumber} />
          <UniItem label="IMEI" content={imei} />
          {gateNumber ? (
            <UniItem label="ゲート番号" content={gateNumber} />
          ) : (
            <UniItem
              label="ゲート番号"
              content={
                <GateNumberSelectInput
                  register={register}
                  setValue={setValue}
                  getValues={getValues}
                  defaultValue={parseInt(gateNumber || "1")}
                />
              }
            />
          )}
          <div className="mb-6 mt-10">
            <Button
              onClick={handleSubmit(handleDataConfirmClick)}
              loading={isLoading}
            >
              確認
            </Button>
          </div>
        </Card>
        <p className="mt-3 px-4 text-xs leading-[18px] text-green-gray">
          <span className="text-error">※</span>
          {
            " 注意:QRコードにゲート番号が含まれていない場合は、手動で選択してから「確認」ボタンを押してください。"
          }
        </p>
      </Main>
      <Footer />
      <Popup visible={imeiNotFoundVisible} closeOnOverlayClick={false}>
        <PopupMain>
          {"このIMEIは存在しません。再度スキャンするか、入力してください。"}
        </PopupMain>
        <PopupAction>
          <Button onClick={handleIMEINotExistClick}> 閉じる</Button>
        </PopupAction>
      </Popup>
      <Popup visible={notMatchVisible} closeOnOverlayClick={false}>
        <PopupMain>
          {
            "このIMEI番号と登録したデバイスの情報が一致していません。\nIMEIを変更された場合は、「IMEI変更」をタップしてください。"
          }
        </PopupMain>
        <PopupAction className="flex gap-5">
          <div className="flex-1">
            <Button onClick={notMatchToggle} type="default">
              キャンセル
            </Button>
          </div>
          <div className="flex-1">
            <Button onClick={handleChangeIMEIClick}>IMEI変更</Button>
          </div>
        </PopupAction>
      </Popup>
      <Popup visible={gateNumberNotMatchVisible}>
        <PopupMain>
          {"ゲート番号と登録したデバイスの情報が一致していません。"}
        </PopupMain>
        <PopupAction>
          <Button onClick={handleGateNoNotMatchClose}>閉じる</Button>
        </PopupAction>
      </Popup>
      <Popup visible={useLanVisible}>
        <PopupMain>{"IMEI情報無しでデバイスを登録しますか？"}</PopupMain>
        <PopupAction className="flex gap-5">
          <div className="flex-1">
            <Button type="default" onClick={handleBack}>
              いいえ
            </Button>
          </div>
          <div className="flex-1">
            <Button loading={isDeleting} onClick={handleUseLan}>
              はい
            </Button>
          </div>
        </PopupAction>
      </Popup>
    </>
  );
}

const UniItem = ({ label, content, className }: any) => {
  if (!content) return null;
  return (
    <div className={cx("mt-5 text-base", className)}>
      <p className="text-neutral-600">{label}</p>
      {typeof content == "string" ? (
        <p className="block break-all font-medium">{content}</p>
      ) : (
        <div className="mt-2">{content}</div>
      )}
    </div>
  );
};
