import ImageStepThree from "@/assets/dearis-pre-setting-step-three.png";
import ImageStepTwo from "@/assets/dearis-pre-setting-step-two.png";
import ImageStepOne from "@/assets/patmo-pre-setting-step-one.png";
import type { StepsDataModal } from "@/components/step";

export const gateStepsData: StepsDataModal = {
  steps: [
    [
      {
        type: "text",
        content: "デバイスのホーム画面にPabbitのボタンを押します。",
        indicator: 1,
      },
      { type: "image", content: ImageStepOne },
    ],
    [
      {
        type: "text",
        content: "この画面の左上を連続して３回タッチします。",
        indicator: 2,
      },
      { type: "image", content: ImageStepTwo },
    ],
    [
      {
        type: "text",
        content: "デバイスがこのページを表示することを確認します。",
        indicator: 3,
      },
      {
        type: "image",
        content: {
          src: ImageStepThree,
          caption: "dearisの設定ページ",
        },
      },
    ],
  ],
};
