import { cx } from "@emotion/css";
import React from "react";

import { Card } from "@/components";

export default function StepContainer({
  children,
}: Readonly<{
  children?: React.ReactNode | React.ReactNode[];
}>) {
  return (
    <Card className={cx("p-5 pr-4 pb-10 flex flex-col gap-9 shadow-card", "text-neutral-800")}>
      {children}
    </Card>
  );
}
