import { cx } from "@emotion/css";
import { type FunctionComponent, memo, type SVGProps } from "react";

import ArrowIcon from "@/assets/arrow-right.svg?react";
import DoorIcon from "@/assets/door.svg?react";
import ElevatorIcon from "@/assets/elevator.svg?react";
import GateIcon from "@/assets/gate-login.svg?react";
import { Card, Tag } from "@/components";
import type { Device, DeviceType } from "@/types";

interface BasicCardProps {
  device: Device;
  Icon: FunctionComponent<SVGProps<SVGSVGElement & { className: string }>>;
}
function BasicCard({ device, Icon }: Readonly<BasicCardProps>) {
  return (
    <Card className="relative my-2">
      <div
        data-device-id={device.id}
        className="z-100 absolute left-0 top-0 h-full w-full"
      ></div>
      <div className="flex items-start justify-between gap-6">
        <div className="flex">
          <Icon className="my-1 mr-2 inline h-4 w-4 shrink-0 " />
          <p className="text-sm font-medium leading-[22px]">
            {device.position?.spot}
          </p>
        </div>
        <div className="relative shrink-0 basis-[90px]">
          {device?.position?.test_passed || (
            <Tag color="#FD8140" background="#FFF2EB">
              テスト待ち
            </Tag>
          )}
        </div>
      </div>
      <div
        className={cx(
          "mt-4 flex justify-between gap-14",
          "text-xs leading-[18px] text-green-gray"
        )}
      >
        <div className={cx("flex flex-grow justify-between")}>
          <span>棟番号：{device.position?.build_id}</span>
          <span>ゲート番号：{device.position?.gate_number}</span>
        </div>
        <ArrowIcon className={cx("mt-[2px] h-4 w-4 text-[#C4CBCC]")} />
      </div>
    </Card>
  );
}
interface DearisListProps {
  show: boolean;
  devices: Device[] | undefined;
  onClick: (
    _e: React.MouseEvent<HTMLSpanElement>,
    _deviceType: DeviceType
  ) => void;
}

const EntranceCard = ({ device }: { device: Device }) => {
  return <BasicCard device={device} Icon={DoorIcon} />;
};

const EntranceList = memo(function ({
  show,
  devices,
  onClick,
}: DearisListProps) {
  if (!show) return null;
  return (
    <div onClick={(e) => onClick(e, "dearisEntrance")}>
      {devices?.map((device) => (
        <EntranceCard key={device.id} device={device} />
      ))}
    </div>
  );
});

const ElevatorCard = ({ device }: { device: Device }) => {
  return <BasicCard device={device} Icon={ElevatorIcon} />;
};

const ElevatorList = memo(function ({
  show,
  devices,
  onClick,
}: DearisListProps) {
  if (!show) return null;
  return (
    <div onClick={(e) => onClick(e, "dearisElevator")}>
      {devices?.map((device) => (
        <ElevatorCard key={device.id} device={device} />
      ))}
    </div>
  );
});

const GateCard = ({ device }: { device: Device }) => {
  return <BasicCard device={device} Icon={GateIcon} />;
};

export { ElevatorList, EntranceList, GateCard };
