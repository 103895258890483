import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

import { setStorage } from "@/shared/store";

export function useGetScannerData() {
  const { state } = useLocation() as {
    state: {
      pathname?: string;
      device?: {
        sn: "";
        imei: "";
        buildId: undefined | number;
        gateNumber: undefined | number;
        buildIdController: undefined | number;
        gateNumberController: undefined | number;
        gateNumberPATMO: undefined | number;
      };
    };
  };
  useLayoutEffect(() => {
    if (
      state?.pathname &&
      state.pathname.includes("/imei-replace/scan") &&
      state.device
    ) {
      state.device.imei && setStorage("imei", state.device.imei);
      return;
    }

    if (state?.pathname && state.pathname.includes("/scan") && state.device) {
      state.device.sn && setStorage("serialNumber", state.device.sn);
      state.device.imei && setStorage("imei", state.device.imei);
      // buildId, gateNumber are number and could be 0
      state.device.gateNumberPATMO !== undefined &&
        setStorage("gateNumberPATMO", state.device.gateNumberPATMO);
      state.device.buildId !== undefined &&
        setStorage("buildId", state.device.buildId);
      state.device.gateNumber !== undefined &&
        setStorage("gateNumber", state.device.gateNumber);
      state.device.buildIdController !== undefined &&
        setStorage("buildIdController", state.device.buildIdController);
      state.device.gateNumberController !== undefined &&
        setStorage("gateNumberController", state.device.gateNumberController);
    }
  }, []);
}
