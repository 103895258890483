import { useNavigate } from "react-router-dom";

import { Button, Footer, Header, Main } from "@/components";
import { Step, StepContainer, StepsGenerator } from "@/components/step";

import { dearisStepsData } from "./dearis-steps-data";

export const DearisIMEIReplaceRestart = () => {
  const navigate = useNavigate();

  return (
    <>
      <Header>通信確認</Header>
      <Main>
        <StepContainer>
          <StepsGenerator stepsData={dearisStepsData} />
          <Step>
            <Button onClick={() => navigate("/")}>変更プロセスを完了</Button>
            <Button type="default" onClick={() => navigate("/dearis")}>
              設定リストに進む
            </Button>
          </Step>
        </StepContainer>
      </Main>
      <Footer />
    </>
  );
};
