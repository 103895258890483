import { useQuery } from "@tanstack/react-query";

import { NECESSARY_INFO_LACKING_HINT } from "@/shared";
import { getDevices } from "@/shared/requests/device";
import { getStorage } from "@/shared/store";

import { NecessaryInfoLackingError } from "./errors.type";
import { DEVICE_QUERY_KEY } from "./queryKey";

export function useListDearisBySN() {
  const sn = getStorage("serialNumber");
  const queryFn = async () => {
    if (!sn) {
      throw new NecessaryInfoLackingError(NECESSARY_INFO_LACKING_HINT);
    }
    const devices = await getDevices({ sn, device_type: "dearisController" });
    const theDevice = devices.data.devices?.find((device) => device.sn === sn);
    if (theDevice) {
      return theDevice;
    } else {
      throw new DearisControllerNotExist("デバイスが見つかりません");
    }
  };
  return useQuery({ queryKey: [DEVICE_QUERY_KEY, sn], queryFn, enabled: !!sn });
}

export class DearisControllerNotExist extends Error {}
