import type { AxiosResponse } from "axios";

import type { Building } from "@/types";

import type { GetBuildingsParams, GetbuildingsResponse } from "./building.type";
import server from "./server";

export const getBuildings = async (params: GetBuildingsParams) => {
  const response = server.get<unknown, AxiosResponse<GetbuildingsResponse>>(
    "/buildings",
    { params }
  );
  return response;
};

export const getBuildingsByPropertyId = async (id: string) => {
  return server.get<unknown, AxiosResponse<Building[]>>(
    `/properties/${id}/buildings`
  );
};

export * from "./building.type";
