import { useUpdateIMEI } from "@/queries";
import { errorToast } from "@/shared";
import { setStorage } from "@/shared/store";

export function useReplaceIMEI({
  onReplaceSucceed,
  onCancel,
}: {
  onReplaceSucceed: () => void;
  onCancel: () => void;
}) {
  const { mutateAsync: updateIMEI, isLoading } = useUpdateIMEI();
  const handleReplaceIMEIClick = () => {
    updateIMEI()
      .then(() => {
        onReplaceSucceed();
        setStorage("PATMOReplaceIMEI", false);
      })
      .catch((error) => {
        if (error.status === 400) {
          errorToast("IMEIの変更に失敗しました。");
        } else {
          errorToast(error?.message ?? error.toString());
        }
      });
  };
  const handleCancelClick = () => {
    setStorage("PATMOReplaceIMEI", false);
    onCancel();
  };
  return { handleReplaceIMEIClick, handleCancelClick, isReplacing: isLoading };
}
