import { Footer, Header, type TabItem, Tabs } from "@/components";

import { SearchByAddress, SearchByName } from "../components";

const tabItems = [
  { key: "1", label: "物件名検索", children: <SearchByName /> },
  { key: "2", label: "住所検索", children: <SearchByAddress /> },
] satisfies TabItem[];

export const DefaultSearch = () => {
  return (
    <div className="contents">
      <Header>物件検索</Header>
      <div className="flex-1 bg-[#f6f6f6] p-4">
        <Tabs items={tabItems}></Tabs>
      </div>
      <Footer />
    </div>
  );
};
