import { cx } from "@emotion/css";
import { Popup as NutPopup } from "@nutui/nutui-react";
import { type FC, Fragment, type HTMLAttributes, type PropsWithChildren, type ReactNode, useState } from "react";
import ReactDOM from "react-dom";
import type { UseFormRegisterReturn, UseFormSetValue } from "react-hook-form";

type Option =
  | {
      value: string | number;
      label: NonNullable<ReactNode>;
    }
  | string;
interface SelectInputProps extends HTMLAttributes<HTMLInputElement> {
  register: UseFormRegisterReturn<string>;
  setValue: UseFormSetValue<any>;
  styleType?: "shadow" | "outline";
  options?: Option[];
}
export const SelectInput: FC<PropsWithChildren<SelectInputProps>> = ({
  className,
  register,
  styleType = "outline",
  placeholder,
  setValue,
  options,
  ...props
}) => {
  const [isPopup, setIsPopup] = useState(false);
  const handleItemClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const optionEle = e.target as HTMLParagraphElement;
    if (optionEle.dataset.optionValue) {
      setValue(register.name, optionEle.dataset.optionValue);
    }
  };
  return (
    <div className="flex flex-col">
      <input
        readOnly
        className={cx([
          "form-select rounded-lg p-3 text-[14px] leading-[22px] outline-0 placeholder:text-text-gray",
          "focus:border-transparent focus:ring-primary",
          "rounded-md",
          className,
          {
            "border-none  bg-[#F2F4F5] outline-gray-500": styleType === "shadow",
            "border-solid border-[#E3E5E5]": styleType === "outline",
          },
          props.disabled && "bg-none",
        ])}
        {...props}
        {...register}
        placeholder={placeholder}
        onClick={() => setIsPopup(true)}
      />
      {ReactDOM.createPortal(
        <NutPopup
          position="bottom"
          visible={isPopup}
          onClick={() => setIsPopup(false)}
          onOverlayClick={() => setIsPopup(false)}
          style={{ maxHeight: "80vh" }}
          overlayStyle={{ backgroundColor: "rgba(0,0,0,0.4)" }}
          round
        >
          <p className="p-5 pb-0 text-center text-base">{placeholder}</p>
          <div className="divide-x-0 divide-y divide-solid divide-[#E9EDEE] p-4" onClick={handleItemClick}>
            {options?.map((optionValue, index) => (
              <Fragment key={index}>
                {typeof optionValue === "string" ? (
                  <p data-option-value={optionValue} className="text-center text-base leading-12 text-text-gray">
                    {optionValue}
                  </p>
                ) : (
                  <p data-option-value={optionValue.value} className="text-center text-base leading-12 text-text-gray">
                    {optionValue.label}
                  </p>
                )}
              </Fragment>
            ))}
          </div>
        </NutPopup>,
        document.body
      )}
    </div>
  );
};
