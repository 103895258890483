import { cx } from "@emotion/css";
import { first } from "lodash-es";
import { memo } from "react";

import { DeliveriesCard, DeliveryItem } from "@/components";
import type { ProviderModel } from "@/types";

import { useOptions } from "../../hooks";

interface LiteUnlockDeliveriesProps {
  onProvidersSuccess: (_provider: ProviderModel | undefined) => void;
}
export const LiteUnlockDeliveries: React.FC<LiteUnlockDeliveriesProps> = memo(
  function ({ onProvidersSuccess }) {
    const {
      willShowProviders,
      isProvidersAllUnavailable,
      // onlyAmazon,
      isLoading,
      isSuccess,
      isError,
      refetch,
    } = useOptions();
    const eazyProvider: ProviderModel = {
      courier: "eazy",
      name: "ヤマト運輸（デジタルキー置き配）",
      testable: true,
    };

    if (isSuccess) {
      // const firstProvider = onlyAmazon
      //   ? eazyProvider
      //   : first(
      //       willShowProviders?.filter(
      //         (provider) =>
      //           provider.courier !== "amazon" && provider?.testable === true
      //       )
      //     ) ?? eazyProvider;
      const firstProvider =
        first(
          willShowProviders?.filter(
            (provider) =>
              provider.courier !== "amazon" && provider?.testable === true
          )
        ) ?? eazyProvider;
      onProvidersSuccess(firstProvider);
    }

    return (
      <div>
        <DeliveriesCard
          refresh={() => {
            refetch();
          }}
          isFreshing={isLoading}
        >
          {isLoading && (
            <DeliveryItem className="text-center">
              宅配業者が設定されていません
            </DeliveryItem>
          )}
          {isError && (
            <div className={cx("text-[15px]", "border-x-0 py-9 text-center")}>
              設定情報が失われています。
              <br />
              管理システム上の物件設定情報を
              <br />
              確認してください。
            </div>
          )}
          {isSuccess && (
            <>
              {willShowProviders.map((provider, index) => (
                <DeliveryItem key={index} testable={provider?.testable}>
                  {provider.name}
                </DeliveryItem>
              ))}
            </>
          )}
          {/* {isSuccess && onlyAmazon && (
            <>
              <DeliveryItem testable={eazyProvider.testable}>
                {eazyProvider.name}
              </DeliveryItem>
              <p className="border-0 pt-6  text-center">
                こちらの「ﾔﾏﾄ運輸(ﾃﾞｼﾞﾀﾙｷｰ置き配)」は、テストのために
                <br />
                クラウドからランダムに指定されました
              </p>
            </>
          )} */}
          {isSuccess && isProvidersAllUnavailable && (
            <>
              <DeliveryItem testable={eazyProvider.testable}>
                {eazyProvider.name}
              </DeliveryItem>
              <p className="border-0 pt-6 text-center">
                こちらの「ﾔﾏﾄ運輸(ﾃﾞｼﾞﾀﾙｷｰ置き配)」は、テストのために
                <br />
                クラウドからランダムに指定されました
              </p>
            </>
          )}
        </DeliveriesCard>
        <p className="text-sm text-[#909899]">
          「※」マークのついたサービス事業者は番号入力解錠テストを支えることができません。
        </p>
      </div>
    );
  }
);
