import { Card, Footer, Header, Main, Skeleton } from "@/components";

export function DearisAdapterSkeleton() {
  return (
    <>
      <Header></Header>
      <Main>
        <Card>
          <Skeleton />
        </Card>
      </Main>
      <Footer />
    </>
  );
}
