import { Card } from "@/components";

interface BasicInfoCardProps {
  children: React.ReactNode | React.ReactNode[];
}
export const BasicInfoCard: React.FC<BasicInfoCardProps> = ({ children }) => {
  return <Card className="flex flex-col gap-4">{children}</Card>;
};

interface BasicInfoItemProps {
  title: string;
  content?: string;
}
export const BasicInfoItem: React.FC<BasicInfoItemProps> = ({ title, content }) => {
  return (
    <div>
      <p className="text-neutral-600">{title}</p>
      <p className="font-medium text-neutral-800">{content || "-"}</p>
    </div>
  );
};
