import { Navigate } from "react-router-dom";

import { useHandleDeviceUnknown } from "@/hooks";
import { getStorage } from "@/shared/store";
import type { DeviceType } from "@/types";

import DearisAdapterRegisterLineSuccess from "./DearisAdapterRegisterLineSuccess";

const RegisterLineSuccessWrapper = () => {
  const deviceType = getStorage("deviceType") as DeviceType;
  const { toastAndToSelectDevice } = useHandleDeviceUnknown();
  switch (deviceType) {
    case "PabbitLite":
      return <Navigate to="/restart" />;
    case "PATMO":
      return <Navigate to="/restart" />;
    case "DearisAdapter":
      return <DearisAdapterRegisterLineSuccess />;
    default: {
      toastAndToSelectDevice();
    }
  }
};

export default RegisterLineSuccessWrapper;
