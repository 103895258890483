import { cx } from "@emotion/css";

interface HintProps {
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
}

export default function Hint({ children, className }: HintProps) {
  return (
    <div className={cx("flex items-start text-green-gray", className)}>
      <span className="text-error">※&nbsp;</span>
      <div> {children}</div>
    </div>
  );
}
