import { Footer, Header, Main } from "@/components";
import { clearDeviceInfo, getStorage } from "@/shared/store";
import type { DeviceType } from "@/types";

import { Hint, ManualButton, PatmoManualButton, RegisterMethodCard, ScanButton } from "./components";

export default function LoginDeviceWrapper() {
  return (
    <>
      <Header>情報の登録</Header>
      <DeviceRegisterWapper />
      <Footer />
    </>
  );
}

const DeviceRegisterWapper = () => {
  const deviceType = getStorage("deviceType") as DeviceType;
  switch (deviceType) {
    case "PabbitLite":
      return <LiteDeivceRegister />;
    case "PATMO":
      return <PATMODeviceRegister />;
    case "dearis":
      return <DearisInfoRegister />;
    // case "adapter": // adapter and dearisAdapter share common info register ui
    //   return <AdapterInfoRegister />;
    case "DearisAdapter":
      return <AdapterInfoRegister />;
    case "dearisController":
    case "dearisEntrance":
      return <DearisControllerInfoRegister />;
  }
};

const LiteDeivceRegister = () => (
  <Main className="text-[15px] leading-[22.5px]">
    <RegisterMethodCard>
      <p>デバイスに表示されたQRコードをスキャンするか、情報を入力してください</p>
      <ScanButton />
    </RegisterMethodCard>
    <RegisterMethodCard>
      <p>スキャンがうまく使用できない場合は下記ボタンをタップしてシリアル番号を入力してください</p>
      <ManualButton>デバイス情報を入力する</ManualButton>
    </RegisterMethodCard>
  </Main>
);
const PATMODeviceRegister = () => {
  clearDeviceInfo();
  return (
    <Main>
      <Hint>{"デバイスに表示されたQRコードをスキャンするか、情報を入力してください"}</Hint>
      <RegisterMethodCard>
        <p>デバイスのQRコードをスキャンします </p>
        <ScanButton />
      </RegisterMethodCard>
      <RegisterMethodCard>
        <p>スキャンがうまく使用できない場合は下記ボタンをタップしてシリアル番号を入力してください</p>
        <PatmoManualButton>デバイス情報を入力する</PatmoManualButton>
      </RegisterMethodCard>
    </Main>
  );
};
const AdapterInfoRegister = () => (
  <Main>
    <Hint>通信アダプターに表示されたQRコードをスキャンするか、IMEI番号を入力してください</Hint>
    <RegisterMethodCard>
      <p>デバイスのQRコードをスキャンします </p>
      <ScanButton />
    </RegisterMethodCard>
    <RegisterMethodCard>
      <p>スキャンがうまく使用できない場合は下記ボタンをタップしてシリアル番号を入力してください</p>
      <ManualButton>IMEI番号を入力する</ManualButton>
    </RegisterMethodCard>
  </Main>
);
const DearisInfoRegister = () => (
  <Main>
    <Hint>デバイスに表示されたQRコードをスキャンするか、デバイス情報を入力してください</Hint>
    <RegisterMethodCard>
      <p>デバイスのQRコードをスキャンします </p>
      <ScanButton />
    </RegisterMethodCard>
    <RegisterMethodCard>
      <p>スキャンがうまく使用できない場合は下記ボタンをタップしてデバイス情報を入力してください</p>
      <ManualButton>デバイス情報を入力する</ManualButton>
    </RegisterMethodCard>
  </Main>
);

const DearisControllerInfoRegister = () => (
  <Main>
    <Hint>デバイスに表示されたQRコードをスキャンするか、デバイス情報を入力してください</Hint>
    <RegisterMethodCard>
      <p>デバイスのQRコードをスキャンします </p>
      <ScanButton />
    </RegisterMethodCard>
    <RegisterMethodCard>
      <p>スキャンがうまく使用できない場合は下記ボタンをタップしてデバイス情報を入力してください</p>
      <ManualButton>デバイス情報を入力する</ManualButton>
    </RegisterMethodCard>
  </Main>
);
