import { lazy } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ArrowLeft from "@/assets/arrow-left.svg?react";
import { ErrorPopup } from "@/components";
import { useModal } from "@/hooks";
import { GeneralIMEIRegExp } from "@/shared/regexps";
import { checkVariableType, hasKey, matchesPattern } from "@/shared/scanners";

const Scanner = lazy(() => import("@/components/scanner/Scanner"));
const ScannerDeviceInfo = () => {
  const navigateRRD = useNavigate();
  function navigate(to: string | -1, options?: { state?: any }) {
    if (to === -1) {
      navigateRRD(to);
    } else {
      navigateRRD(to, { state: options?.state, replace: true });
    }
  }
  const { pathname } = useLocation();
  const { visible, toggle } = useModal();

  /**
   * @description 处理扫描成功后的回调函数
   * 参考: src/pages/scanner/index.tsx
   * @param result
   */
  const successFn = (result: string) => {
    let deviceData;

    try {
      // 二维码数据是JSON对象
      deviceData = JSON.parse(result);
      // 二维码数据有可能是字符串, 当字符串中全是数字, JSON.parse会将其解析成数字, 数字太多了JS处理精度不足
      // if (typeof deviceData === "number") {
      //   deviceData = { sn: result };
      // }
      console.debug("resolve succeed.");
    } catch (e) {
      // 二维码数据有可能是字符串
      // 如果扫描结果有误或JSON解析失败，直接将扫描结果作为sn, 在后续的进一步处理中会进行格式校验
      console.debug("[index] [Line 66]: e", e);
      deviceData = result;
    }

    try {
      hasKey(deviceData, "ifcSN");

      // imei
      hasKey(deviceData, "imei");
      checkVariableType(deviceData.imei, "string");
      matchesPattern(deviceData.imei, GeneralIMEIRegExp);

      const { imei } = deviceData;
      navigate("/imei-replace/manual", {
        state: {
          device: { imei },
          pathname,
        },
      });
    } catch (e) {
      console.log(e);
      toggle();
    }
  };

  return (
    <>
      <Scanner onSuccess={successFn}>
        <div
          className="inline-flex items-center text-white"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft />
          <span>キャンセル</span>
        </div>
      </Scanner>
      <ErrorPopup onClick={toggle} visible={visible}>
        要求に合致する情報がないか、情報フォーマットが不適切です。もう一度スキャンしてください。
      </ErrorPopup>
      {/* <Popup visible={visible}>
        <PopupMain>
          要求に合致する情報がないか、情報フォーマットが不適切です。もう一度スキャンしてください。
        </PopupMain>
        <PopupAction>
          <Button onClick={toggle}>OK</Button>
        </PopupAction>
      </Popup> */}
      {/* <Popup visible={visible}>
        <PopupMain>
          要求に合致する情報がないか、情報フォーマットが不適切です。もう一度スキャンしてください。
        </PopupMain>
        <PopupAction>
          <Button onClick={toggle}>OK</Button>
        </PopupAction>
      </Popup> */}
    </>
  );
};

export default ScannerDeviceInfo;
