import { useEffect, useState } from "react";

import { errorToast, FETCH_DATA_ERROR_HINT } from "@/shared";

export function useFetchDataError(isError: boolean, error: any) {
  const [online, setOnline] = useState(true);
  if (isError) {
    if (error?.status == 400) {
      errorToast(FETCH_DATA_ERROR_HINT);
    } else {
      errorToast(error?.message ?? error?.toString());
    }
  }
  useEffect(() => {
    if (!navigator.onLine) {
      setOnline(false);
      errorToast("ネットワークに接続されていません。");
    } else {
      setOnline(true);
    }
  });
  return { online };
}
export function FetchDataError(isError: boolean, error: any) {
  const [online, setOnline] = useState(true);
  if (isError) {
    if (error?.status == 400) {
      errorToast(FETCH_DATA_ERROR_HINT);
    } else {
      errorToast(error?.message ?? error?.toString());
    }
  }
  useEffect(() => {
    if (!navigator.onLine) {
      setOnline(false);
      errorToast("ネットワークに接続されていません。");
    } else {
      setOnline(true);
    }
  });
  return { online };
}
