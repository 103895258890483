import { cx } from "@emotion/css";
import { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Card, Footer, Header, Popup, PopupAction, PopupMain } from "@/components";
import { useGetScannerData, useModal } from "@/hooks";
import { useCreateLite } from "@/queries";
import { IMEIStatusError, SNIMEINotMatchError } from "@/queries/errors.type";
import { errorToast, REGISTER_DEVICE_FAILED_HINT } from "@/shared";
import { getStorage, setStorage } from "@/shared/store";

import { Warning } from "../components";

export default function LiteDataConfirm() {
  const navigate = useNavigate();

  const [serialNumber, setSerialNumber] = useState("");
  const [imei, setImei] = useState("");

  const { visible: imeiNotExistVisible, toggle: imeiNotExistToggle } = useModal();
  const { visible: imeiSnNotMatchVisible, toggle: imeiSnNotMatchToggle } = useModal();
  const { mutateAsync: checkAndCreateLite, isLoading } = useCreateLite();

  useGetScannerData();
  useLayoutEffect(() => {
    const serialNumber = getStorage("serialNumber");
    const imei = getStorage("imei");
    setSerialNumber(serialNumber);
    setImei(imei);
  }, []);

  const handleCheckAndRegister = async () => {
    const serialNumber = getStorage("serialNumber");
    const imei = getStorage("imei");
    if (!serialNumber.match(/^[a-zA-Z0-9]{8,50}$/gm)) {
      errorToast("シリアル番号の形式が正しくありません");
      return;
    }
    if (!imei.match(/^[0-9]{8,50}$/gm)) {
      errorToast("IMEIの形式が正しくありません");
      return;
    }

    await checkAndCreateLite()
      .then((device) => {
        setStorage("deviceId", device?.id || "");
        navigate("/status");
      })
      .catch((error: any) => {
        if (error instanceof IMEIStatusError) {
          imeiNotExistToggle();
        } else if (error instanceof SNIMEINotMatchError) {
          imeiSnNotMatchToggle();
        } else if (error?.status === 400) {
          errorToast(REGISTER_DEVICE_FAILED_HINT);
        } else {
          errorToast(error?.message ?? error.toString());
        }
      });
  };

  const handleIMEINotExistClick = () => {
    imeiNotExistToggle();
    navigate("/register");
  };

  return (
    <div className="contents">
      <Header>デバイス情報の確認</Header>
      <div className="m-4 flex-1">
        <Card>
          <Warning>{"ここに表示されている情報がデバイス側の情報と一致していることを確認してください。"}</Warning>
          <UniItem label="シリアル番号" content={serialNumber} className="mt-5" />
          <UniItem label="IMEI" content={imei} />
          <div className={cx("mb-4 mt-8 space-y-4", "text-center")}>
            <Button className="w-full" size="large" onClick={handleCheckAndRegister} loading={isLoading}>
              確認
            </Button>
          </div>
        </Card>
      </div>
      <Footer />
      <Popup visible={imeiNotExistVisible}>
        <PopupMain>{"このIMEIは存在しません。再度スキャンするか、入力してください。"}</PopupMain>
        <PopupAction>
          <Button onClick={handleIMEINotExistClick}>閉じる</Button>
        </PopupAction>
      </Popup>
      <Popup visible={imeiSnNotMatchVisible}>
        <PopupMain>{"このIMEI番号と登録したデバイスの情報が一致していません。 "}</PopupMain>
        <PopupAction>
          <Button onClick={imeiSnNotMatchToggle}>閉じる</Button>
        </PopupAction>
      </Popup>
    </div>
  );
}

const UniItem = ({ label, content, className }: any) => {
  if (!content) return null;
  return (
    <div className={cx("mt-4 text-base", className)}>
      <p className="text-neutral-600">{label}</p>
      {typeof content == "string" ? (
        <p className="mt-2 block break-all">{content}</p>
      ) : (
        <div className="mt-2">{content}</div>
      )}
    </div>
  );
};
