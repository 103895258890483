import {
  createBrowserRouter,
  Navigate,
  Outlet,
  type RouteObject,
} from "react-router-dom";

import { ScrollToTop } from "@/components";
import {
  AmazonUnlock,
  DataConfirm,
  Dearis,
  DearisDevicesSetting,
  DeliveriesConfirm,
  IMEIOpen,
  IMEIReplace,
  IMEIReplaceDearisControllerStatus,
  IMEIReplaceInfoRegister,
  IMEIReplaceManualRegister,
  IMEIReplaceRegsiterLine,
  IMEIReplaceRestart,
  IMEIReplaceScanner,
  InfoRegister,
  LoginPage,
  LoginWithCode,
  ManualRegister,
  ManualRegisterOptions,
  ModeToggle,
  ObjectConfigureOk,
  ObjectResult,
  ObjectSearch,
  PreSetting,
  PreSettingHardware,
  RegisterLine,
  RestartAdapter,
  Scanner,
  ScannerTest,
  SearchObjectResult,
  SelectDearisDevice,
  SelectDevice,
  ServiceConfigure,
  SettingMethods,
  SpotSetting,
  StaticSettingMethodPage,
  StatusChecking,
  UnlockOverview,
  UnlockTest} from "@/pages";

import { useCheckDeviceType, withGuard } from "./Guard";

/**
 * make router page async component for code splitting.
 *
 * - by splitting code we can have a smaller entry file
 *   smaller entry file means a faster loading speed
 *
 * - don't make all router async because we need to find a balance
 *   between the number of chunks and the size of entry file
 *
 * - more chunks means more http requests, and this is not good
 *   under poor network
 */

// for separating the @zxing/browser from the entry file. this is a huge lib.
const routes = [
  {
    path: "/",
    element: withGuard(
      <>
        <ScrollToTop />
        <Outlet />
      </>,
      useCheckDeviceType
    ),
    children: [
      { index: true, element: <Navigate to="/select-device" /> },
      { path: "login", element: <LoginPage /> },
      { path: "login-confirm", element: <LoginWithCode /> },
      {
        path: "select-device",
        element: <SelectDevice />,
      },
      { path: "select-device/dearis", element: <SelectDearisDevice /> },
      { path: "setting-methods", element: <SettingMethods /> },
      {
        path: "settings-methods/:deviceType",
        element: <StaticSettingMethodPage />,
      },

      {
        path: "pre-setting",
        element: <PreSetting />,
      },
      {
        path: "pre-setting/help",
        element: <PreSetting />,
      },
      { path: "hardware-setting", element: <PreSettingHardware /> },
      {
        path: "pre-setting/entrance",
        element: <PreSetting />,
      },
      {
        path: "register",
        element: <InfoRegister />,
      },
      {
        path: "register/options",
        element: <ManualRegisterOptions />,
      },
      {
        path: "register/manual",
        element: <ManualRegister />,
      },
      {
        path: "register/confirm",
        element: <DataConfirm />,
      },
      { path: "status", element: <StatusChecking /> },
      { path: "line-open", element: <IMEIOpen /> },

      { path: "imei-replace", element: <IMEIReplace /> },
      { path: "imei-replace/register", element: <IMEIReplaceInfoRegister /> },
      { path: "imei-replace/manual", element: <IMEIReplaceManualRegister /> },
      { path: "imei-replace/scan", element: <IMEIReplaceScanner /> },
      { path: "imei-replace/restart", element: <IMEIReplaceRestart /> },
      {
        path: "imei-replace/register-line",
        element: <IMEIReplaceRegsiterLine />,
      },
      {
        path: "imei-replace/status",
        element: <IMEIReplaceDearisControllerStatus />,
      },

      {
        path: "configure",
        element: <ObjectResult />,
      },
      { path: "configure/search", element: <ObjectSearch /> },
      {
        path: "configure/results",
        element: <SearchObjectResult />,
      },

      { path: "configure/success", element: <ObjectConfigureOk /> },
      { path: "register-line", element: <RegisterLine /> },

      { path: "restart", element: <RestartAdapter /> },
      { path: "service-configure", element: <ServiceConfigure /> },
      { path: "scanner-test", element: <ScannerTest /> },
      { path: "spot/:id", element: <SpotSetting /> },
      { path: "spot", element: <SpotSetting /> },
      { path: "deliveries", element: <DeliveriesConfirm /> },
      { path: "unlock-overview", element: <UnlockOverview /> },
      { path: "unlockTest/:deviceType", element: <UnlockTest /> },
      {
        path: "scan",
        element: <Scanner />,
      },

      // dearis
      { path: "/dearis", element: <Dearis /> },
      { path: "/cloud-unlock", element: <AmazonUnlock /> },
      { path: "/cloud-unlock/:deviceType", element: <AmazonUnlock /> },

      { path: "/mode-toggle", element: <ModeToggle /> },
      { path: "/dearis-building/:id", element: <DearisDevicesSetting /> },
    ],
  },
] satisfies RouteObject[];

export const router = createBrowserRouter(routes);
