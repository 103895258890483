const t = {
  save: "保存",
  confirm: "完了",
  cancel: "キャンセル",
  done: "完了",
  noData: "データなし",
  placeholder: "プレースホルダー",
  select: "選択",
  video: {
    errorTip: "エラーヒント",
    clickRetry: "タップして再試行",
  },
  fixednav: {
    activeText: "ナビゲーションを閉じる",
    inactiveText: "ナビゲーションを開く",
  },
  infiniteloading: {
    pullRefreshText: "プルリフレッシュ",
    loadText: "ロード中",
    loadMoreText: "おっと、ここが底です",
  },
  pagination: {
    prev: "前のページ",
    next: "次のページ",
  },
  range: { rangeText: "オーバーフロー" },
  calendaritem: {
    weekdays: ["日", "月", "火", "水", "木", "金", "土"],
    end: "終了",
    start: "開始",
    confirm: "確認",
    title: "カレンダー",
    monthTitle: (e, t) => `${e}/${Number(t) < 10 ? `0${Number(t)}` : t}`,
    today: "今日",
    loadPreviousMonth: "前月を読み込む",
    noEarlierMonth: "早い月はありません",
  },
  shortpassword: {
    title: "パスワードを入力してください",
    description: "確認",
    tips: "パスワードを忘れた",
  },
  uploader: {
    ready: "準備完了",
    readyUpload: "アップロードの準備ができました",
    waitingUpload: "アップロード待ち",
    uploading: "アップロード中...",
    success: "アップロード成功",
    error: "アップロードに失敗しました",
    deleteWord: "ユーザーが削除をブロックしました！",
  },
  countdown: {
    day: "日",
    hour: "時間",
    minute: "分",
    second: "秒",
  },
  address: {
    selectRegion: "住所を選択",
    deliveryTo: "配達先",
    chooseAnotherAddress: "別の住所を選択",
  },
  signature: {
    reSign: "再署名",
    unsupported:
      "申し訳ありませんが、現在のブラウザはキャンバスをサポートしていないため、このコントロールを使用できません！",
  },
  ecard: {
    chooseText: "選択",
    otherValueText: "その他の値",
    placeholder: "プレースホルダー",
  },
  timeselect: {
    pickupTime: "ピックアップ時間",
  },
  sku: {
    buyNow: "今すぐ購入",
    buyNumber: "購入数",
    addToCard: "カートに追加",
  },
  skuheader: {
    skuId: "SKU番号",
  },
  addresslist: {
    addAddress: "新しい住所を追加",
  },
  comment: {
    complaintsText: "苦情があります",
    additionalReview: (e) => `${e}日後にレビュー`,
    additionalImages: (e) => `${e}つの追加コメントがあります`,
  },
  searchbar: {
    basePlaceholder: "jd.comに行って良いものを買ってください",
    text: "テキスト",
    test: "テスト",
    title1: "基本的な使用法",
    title2: "検索ボックスの形状と最大長",
    title3: "検索ボックス内外の背景設定",
    title4: "検索ボックスのテキスト設定",
    title5: "カスタムアイコン設定",
    title6: "データ変更の監視",
  },
  audio: {
    back: "fastBack",
    forward: "forward",
    pause: "pause",
    start: "start",
    mute: "mute",
    tips: "loop = falseの場合、onplayendイベントはトリガーされません",
  },
  datepicker: {
    year: "年",
    month: "月",
    day: "日",
    hour: "時",
    min: "分",
    seconds: "秒",
  },
  pullToRefresh: {
    pullingText: "引っ張る",
    canReleaseText: "リリースして更新",
    refreshingText: "読み込み中...",
    completeText: "完了",
  },
  watermark: {
    errorCanvasTips: "現在の環境ではCanvasがサポートされていません",
  },
  tour: {
    prevStepText: "",
    completeText: "",
    nextStepText: "",
  },
};

export default t;
