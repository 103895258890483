import { useLayoutEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { LoadingPage } from "@/components";
import { infoToast } from "@/shared";
import { checkTokenExpiration } from "@/shared/requests/auth";
import { getStorage } from "@/shared/store";

export function withGuard(Component: any, useCheck: () => void) {
  return (
    <AuthGuard>
      <RouteGuard children={Component} useCheck={useCheck} />
    </AuthGuard>
  );
}
interface RouteGuardProps {
  children: React.ReactNode;
  useCheck: () => void;
}
export function RouteGuard({
  children,
  useCheck,
}: Readonly<RouteGuardProps>): JSX.Element {
  useCheck();
  return <>{children}</>;
}

function AuthGuard({ children }: { children: React.ReactNode }) {
  const { loading, authenticated } = useCheckAuth();
  if (loading) return <LoadingPage />;
  return authenticated ? <>{children}</> : <Navigate to="/login" />;
}

export const useCheckDeviceType = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useLayoutEffect(() => {
    if (
      location.pathname === "/" ||
      location.pathname === "/select-device" ||
      location.pathname === "/select-device/dearis" ||
      location.pathname === "/login" ||
      location.pathname === "/login-confirm" ||
      location.pathname === "/setting-methods"
    )
      return;
    if (!getStorage("deviceType")) {
      infoToast("デバイスを選択してください");
      navigate("/select-device");
    }
  });
};

function useCheckAuth() {
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const location = useLocation();
  useLayoutEffect(() => {
    if (
      location.pathname === "/" ||
      location.pathname === "/login" ||
      location.pathname === "/login-confirm" ||
      location.pathname === "/select-device"
    ) {
      setAuthenticated(true);
      setLoading(false);
      return;
    }
    checkTokenExpiration()
      .then(() => {
        setAuthenticated(true);
      })
      .catch(() => {
        // errorToast("まずログインしてください");
        setAuthenticated(false);
      })
      .finally(() => {
        setLoading(false);
      });
  });
  return { loading, authenticated };
}
