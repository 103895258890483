import { useUpdateUnlockTest } from "@/queries";
import { useReportTest } from "@/queries/useReportTest";

export function useUpdatePatmoTest() {
  // patmo 1.3.0 版本前的接口 用于更新 position.test_passed
  const { mutateAsync: updateTest, isLoading: isUpdateTestLoading } =
    useUpdateUnlockTest();

  // 对应 patmo 1.3.0 版本后的 unlock-overview
  const { mutateAsync: reportPatmoTest, isLoading: isReportTestLoading } =
    useReportTest();
  async function updatePatmoTests() {
    await updateTest();
    await reportPatmoTest({ step: "1" });
  }
  return {
    updatePatmoTests,
    isLoading: isUpdateTestLoading || isReportTestLoading,
  };
}
