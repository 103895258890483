import type { FC, MouseEventHandler, ReactElement } from "react";
import React from "react";

interface IDeviceCard {
  image: ReactElement;
  title: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

export const DeviceCard: FC<IDeviceCard> = React.memo(
  ({ image, title, onClick }) => {
    return (
      <div className="bg-bgGray h-[182px] flex flex-col p-6" onClick={onClick}>
        {/* <img src={image} alt="" /> */}
        <div className="flex-1 flex items-center justify-center bg-transparent">
          {image}
        </div>
        <p className="text-center text-base font-semibold text-neutral-800">
          {title}
        </p>
      </div>
    );
  },
);
