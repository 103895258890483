import type { Building, DeviceType, Property } from "@/types";

import ControllerBuildingCard from "./ControllerBuildingCard";
import LiteBuildingCard from "./LiteBuildingCard";
import PatmoBuildingCard from "./PatmoBuildingCard";

interface IBuildingCardProps {
  type: DeviceType;
  titleLighted?: boolean;
  buildingOrProperty: Building | Property;
  [key: string]: any;
}

export function BuildingCard({
  type,
  titleLighted,
  buildingOrProperty,
  ...rest
}: Readonly<IBuildingCardProps>) {
  switch (type) {
    case "dearisController":
      return (
        <ControllerBuildingCard
          titleLighted={titleLighted}
          className={rest.className}
          property={buildingOrProperty as Property}
          onClick={rest.onClick}
        />
      );
    case "PATMO":
      return (
        <PatmoBuildingCard
          titleLighted={titleLighted}
          className={rest.className}
          property={buildingOrProperty as Property}
          onClick={rest.onClick}
        />
      );
    case "PabbitLite":
      return (
        <LiteBuildingCard
          titleLighted={titleLighted}
          className={rest.className}
          building={buildingOrProperty as Building}
          onClick={rest.onClick}
        />
      );
  }
}
