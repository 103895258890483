import type { FC, PropsWithChildren } from "react";
import React from "react";

interface LinkButtonProps {
  onClick?: () => void,
  text: string,
  icon?: JSX.Element
  className?: string
}
export const LinkButton: FC<PropsWithChildren<LinkButtonProps>> = ({onClick, icon, text,className}) => {
  return (
    <span className={`flex text-sm gap-[6px] justify-center items-center py-0 my-0 hover:cursor-pointer ${className}`}>
      {icon}
      <a className="underline" onClick={onClick}>{text}</a>
    </span>
  );
};
