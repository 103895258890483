import { useNavigate } from "react-router-dom";

import {
  Button,
  Footer,
  Header,
  Main,
  Popup,
  PopupAction,
  PopupMain,
} from "@/components";
import { Step, StepContainer, StepsGenerator } from "@/components/step";
import { useModal } from "@/hooks";

import { liteStepsData } from "./lite-steps-data";

export const LiteModeToggle = () => {
  const navigate = useNavigate();
  const { visible, toggle } = useModal();
  return (
    <>
      <Header>モード切替</Header>
      <Main>
        <StepContainer>
          <StepsGenerator stepsData={liteStepsData} />
          <Step>
            <Button onClick={toggle}>モード切替を完了</Button>
          </Step>
        </StepContainer>
      </Main>
      <Footer />
      <Popup visible={visible}>
        <PopupMain>
          <p className="text-lg font-semibold mb-2">注意：</p>
          モードの更新には遅延が生じることがありますので、手動でページを更新する必要があります。
        </PopupMain>
        <PopupAction>
          <Button onClick={() => navigate(-1)}>確定</Button>
        </PopupAction>
      </Popup>
    </>
  );
};
