import type { AxiosResponse } from "axios";

import type { Device } from "@/types";

import type {
  GetDevicesParams,
  GetDevicesResponse,
  GetTheDeviceParams,
  RegisterDeviceData,
  RegisterDeviceResponse,
  UpdateDeviceData,
} from "./device.type";
import server from "./server";

export const registerDevice = (data: RegisterDeviceData) => {
  return server.post<unknown, AxiosResponse<RegisterDeviceResponse>>(
    "/devices",
    data
  );
};

export const updateDevice = ({ device_id, ...data }: UpdateDeviceData) => {
  return server.patch<unknown, AxiosResponse<RegisterDeviceResponse>>(
    `/devices/${device_id}`,
    data
  );
};

export const getDevices = (params: GetDevicesParams) => {
  return server.get<unknown, AxiosResponse<GetDevicesResponse>>("/devices", {
    params,
  });
};

export const getDeviceByID = (params: GetTheDeviceParams) => {
  return server.get<unknown, AxiosResponse<Device>>(
    `/devices/${params.device_id}?device_type=${params.device_type}`
  );
};

export const deleteDevice = ({ device_id }: { device_id: string }) => {
  return server.delete<unknown, AxiosResponse<unknown>>(
    `/devices/${device_id}`
  );
};

// 删除一批, 注意与删除单个进行区分
export const deleteBunchDevices = ({
  device_ids,
}: {
  device_ids: string[];
}) => {
  return server.post<unknown, AxiosResponse<unknown>>(
    "/devices/delete",
    device_ids
  );
};

export * from "./device.type";
