import { useNavigate, useSearchParams } from "react-router-dom";

import { Button, Footer, Header, Main, Skeleton } from "@/components";
import { useModal } from "@/hooks";
import { useGetDevicesOfProperty } from "@/queries";
import { setStorage } from "@/shared/store";

import { GateCard, GateUnlockModal } from "../components";

export function GateUnlock() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { visible, toggle } = useModal();
  const gate_number = searchParams.get("gate_number");
  const { data, isLoading } = useGetDevicesOfProperty("dearisGate");
  const dearisGate = data?.data?.find(
    (item) => item.position?.gate_number == gate_number
  );
  const handleUnlockTestClick = async (
    e: React.MouseEvent<HTMLSpanElement>
  ) => {
    const unlockEle = e.target as HTMLSpanElement;
    if (unlockEle?.dataset.deviceId) {
      const device_id = unlockEle.dataset.deviceId;
      setStorage("tobeUnlockId", device_id);
      !visible && toggle();
    }
  };

  const handleGateUnlockFinish = () => {
    navigate("/dearis");
  };

  if (isLoading) {
    return (
      <>
        <Header canBack onClick={() => navigate(-1)}>
          クラウド解錠テスト
        </Header>
        <Main>
          <Skeleton />
        </Main>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header canBack onClick={() => navigate(-1)}>
        クラウド解錠テスト
      </Header>
      <Main>
        <div onClick={handleUnlockTestClick}>
          <GateCard
            device={{
              ...dearisGate,
              position: { ...dearisGate?.position, build_id: 0 },
            }}
          />
        </div>
      </Main>
      <div className="px-4 pb-3">
        <Button
          disabled={!dearisGate?.position?.test_passed}
          onClick={handleGateUnlockFinish}
        >
          設定リストに戻る
        </Button>
      </div>
      <Footer />
      <GateUnlockModal visible={visible} toggle={toggle} />
    </>
  );
}
