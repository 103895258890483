import SolidCloseIcon from "@/assets/solid-close.svg?react";
import { OverlayLoading } from "@/components";
import { useAuthImgAuto } from "@/queries";
import { errorToast } from "@/shared";

interface ImageControlImageShowerProps {
  uri: string;
  disabled?: boolean;
  clearImage: () => void;
}

export const ImageControlImageShower: React.FC<
  ImageControlImageShowerProps
> = ({ uri, disabled, clearImage }) => {
  const { data, isLoading, isError } = useAuthImgAuto(uri);
  const handleClearImageClick = () => {
    clearImage();
  };
  if (!uri) return null;
  if (isError) {
    errorToast("写真のアップロードに失敗しました。");
  }
  return (
    <div className="relative">
      <img
        src={data?.data.uri}
        className="aspect-video w-[calc(100%-0.5rem)]"
        alt="写真"
      />
      {disabled || (
        <SolidCloseIcon
          className="absolute right-0 top-0 h-4 w-4 -translate-y-1/2"
          onClick={handleClearImageClick}
        />
      )}
      <OverlayLoading visible={isLoading} />
    </div>
  );
};
