import { cx } from "@emotion/css";

export default function LineStatusStatement({
  children,
  className,
}: Readonly<{
  children: React.ReactNode[] | React.ReactNode;
  className?: string;
}>) {
  return (
    <div
      className={cx(
        "flex-1",
        "text-lg text-neutral-800 text-center font-medium",
        className,
      )}
    >
      {children}
    </div>
  );
}
