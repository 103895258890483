import { cx } from "@emotion/css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Card, Footer, Header, Main, Skeleton } from "@/components";
import { getStorage, setStorage } from "@/shared/store";
import type { Property } from "@/types";

import { BindedTag, InfoItem, UnlockTestedTag } from "../components";
import { useGetControllerStatus } from "../hooks/use-get-controller-status";

export default function DearisControllerStatus() {
  const sn = getStorage("serialNumber");
  const navigate = useNavigate();
  const { device, deviceStatus, isLoading, isError, isStatusAllFinish } =
    useGetControllerStatus();
  const deviceId = getStorage("deviceId") || device?.id;

  const handleNextClick = () => {
    setStorage("deviceId", device?.id || "");

    const property: Partial<Property> = {
      id: device?.property_id || "",
      name: device?.position?.property_name,
    };
    setStorage("selectedBuildingOrProperty", property as Property);
    setStorage("propertyId", device?.property_id || "");

    if (!deviceStatus.binded) {
      navigate("/configure");
    } else if (!deviceStatus.lockTested) {
      navigate("/deliveries");
    } else {
      navigate("/dearis");
    }
  };

  useEffect(() => {
    if (!deviceId) {
      navigate("/register", { replace: true });
    }
  }, [deviceId]);

  if (isLoading) {
    return <ControllerSkeleton />;
  }

  return (
    <>
      <Header>基本情報</Header>
      <Main className="flex flex-col gap-3">
        <InfoItem title="シリアル番号" content={sn}></InfoItem>
        <InfoItem title="物件設定" content={device?.position?.property_name}>
          {!!deviceStatus.binded || <BindedTag binded={deviceStatus.binded} />}
        </InfoItem>
        <Card className="!p-3">
          <div
            className={cx(
              "flex justify-between ",
              "break-all text-base font-medium"
            )}
          >
            <span>番号入力解錠テスト</span>
            {deviceStatus.lockTested || (
              <UnlockTestedTag lockTested={deviceStatus.lockTested} />
            )}
          </div>
        </Card>
        <div className="mb-2 mt-7 text-center">
          <Button onClick={handleNextClick} disabled={isError}>
            {isStatusAllFinish ? "確認" : "次へ"}
          </Button>
        </div>
      </Main>
      <Footer />
    </>
  );
}

const ControllerSkeleton = () => (
  <>
    <Header>基本情報</Header>
    <Main className="flex flex-col gap-3">
      <Card>
        <Skeleton />
      </Card>
    </Main>
    <Footer />
  </>
);
