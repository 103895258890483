import { cx } from "@emotion/css";

export const BuildingSettingTitle = ({
  children,
  show,
}: {
  children: string;
  show: boolean;
}) => {
  if (!show) return null;
  return (
    <div
      className={cx(
        "mb-[10px] mt-5",
        "text-base font-semibold text-neutral-800"
      )}
    >
      <span
        className={cx(
          "mr-[6px] inline-block h-[14px] w-[3px] bg-primary",
          "leading-6 "
        )}
      ></span>
      {children}
    </div>
  );
};
