import { cx } from "@emotion/css";
import { Link as RRLink } from "react-router-dom";

interface ILinkProps {
  children?: React.ReactNode;
  className?: string;
  to: string;
}

export default function Link({ children, className, to }: ILinkProps) {
  return (
    <RRLink to={to} className={cx("underline text-primary", className)}>
      {children}
    </RRLink>
  );
}
