import { useHandleDeviceUnknown } from "@/hooks";
import { getStorage } from "@/shared/store";
import type { DeviceType } from "@/types";

import { DearisIMEIReplaceRestart } from "./DearisIMEIReplaceRestart";
import { PATMOIMEIReplaceRestart } from "./PATMOIMEIReplaceRestart";

export default function () {
  const deviceType = getStorage("deviceType") as DeviceType;
  const { toastAndToSelectDevice } = useHandleDeviceUnknown();
  switch (deviceType) {
    case "PATMO":
      return <PATMOIMEIReplaceRestart />;
    case "dearisController":
      return <DearisIMEIReplaceRestart />;
    default: {
      toastAndToSelectDevice();
    }
  }
}
