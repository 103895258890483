import { type FC } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import {
  BuildingCard,
  Button,
  Footer,
  Header,
  Main,
  Popup,
  PopupAction,
  PopupMain,
} from "@/components";
import { useModal } from "@/hooks";
import { useBindDevice } from "@/queries";
import { errorToast, setLiteBindInfo } from "@/shared";
import { getStorage } from "@/shared/store";
import type { Building, DeviceType } from "@/types";

import {
  BusinessObjectResultWrapper,
  ObjectEmpty,
  ObjectSearchButton,
} from "./components";

const ObjectResult: FC = () => {
  const navigate = useNavigate();

  const { visible: confirmBindVisible, toggle: confirmBindToggle } = useModal();
  const { mutateAsync: bindDeviceAsync, isLoading: isAddDeviceLoading } =
    useBindDevice();

  const deviceType = getStorage("deviceType") as DeviceType;
  const isDeviceBindedObject = getStorage("isDeviceBindedObject");
  const selectedBuildingOrProperty = getStorage("selectedBuildingOrProperty");

  const handleConfirm = async () => {
    await bindDeviceAsync()
      .then((data) => {
        if (deviceType === "PabbitLite") {
          setLiteBindInfo(data.data.id, {
            build_id: (selectedBuildingOrProperty as Building).build_id,
          });
        }
        navigate("/configure/success");
      })
      .catch((error) => {
        if (error?.status === 400) {
          errorToast("デバイスのバインドに失敗しました。");
        } else {
          errorToast(error?.message ?? error.toString());
        }
      });
  };

  if (isDeviceBindedObject && selectedBuildingOrProperty) {
    return (
      <BusinessObjectResultWrapper
        type={deviceType}
        buildingOrProperty={selectedBuildingOrProperty}
      />
    );
  }

  if (!isDeviceBindedObject && !selectedBuildingOrProperty?.id) {
    return <Navigate to="/configure/search" replace />;
  }

  return (
    <>
      <Header>物件設定</Header>
      <Main>
        <ObjectSearchButton />
        {selectedBuildingOrProperty ? (
          <BuildingCard
            type={deviceType}
            buildingOrProperty={selectedBuildingOrProperty}
          />
        ) : (
          <ObjectEmpty />
        )}
        <div className="mt-5 px-4">
          <Button
            onClick={confirmBindToggle}
            className="w-full"
            disabled={!selectedBuildingOrProperty}
          >
            確認
          </Button>
        </div>
      </Main>
      <Footer />
      <Popup visible={confirmBindVisible}>
        <PopupMain>
          <p className="mb-2 text-lg font-semibold leading-[22px]">注意：</p>
          <p className="text-sm leading-[22px]">
            選択した物件の変更は次のステップ以降できません。
          </p>
        </PopupMain>
        <PopupAction className="flex gap-[22px]">
          <div className="flex-1">
            <Button type="default" onClick={confirmBindToggle}>
              キャンセル
            </Button>
          </div>
          <div className="flex-1">
            <Button onClick={handleConfirm} loading={isAddDeviceLoading}>
              確定
            </Button>
          </div>
        </PopupAction>
      </Popup>
    </>
  );
};

export default ObjectResult;
