import { useMutation } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";
import { useLocation } from "react-router-dom";

import { NECESSARY_INFO_LACKING_HINT } from "@/shared";
import server from "@/shared/requests/server";

// import { getStorage } from "@/shared/store";
import { NecessaryInfoLackingError } from "./errors.type";

interface IssueCredentialParams {
  device_id: string;
  entry_code_length: number;
  room_number: string;
  build_id: number;
  service: {
    service_type: "delivery";
    service_option: string;
  };
}
const issueCredential = (data: IssueCredentialParams) => {
  return server.post<IssueCredentialParams, AxiosResponse<string>>(
    "/credentials",
    data
  );
};
/**
 * @description Lite 专用
 * @returns
 */
export const useIssueCredential = () => {
  const locationState = useLocation().state;
  const { build_id, device_id } = locationState;
  const mutationFn = (service_option: string) => {
    // const sn = getStorage("serialNumber");
    // const device_type = getStorage("deviceType");
    // const property_id = getStorage("propertyId");
    if (build_id === undefined)
      throw new NecessaryInfoLackingError(NECESSARY_INFO_LACKING_HINT);
    return issueCredential({
      device_id,
      build_id,
      room_number: "101",
      entry_code_length: 8,
      service: { service_type: "delivery", service_option },
    });
  };
  const mutation = useMutation(mutationFn);
  return mutation;
};
