import { useCheckLineExistMutation, useCreatePatmo } from "@/queries";
import { getStorage } from "@/shared/store";

export function useCheckAndCreatePatmo() {
  const { mutateAsync: addPatmo, isLoading: isAdding } = useCreatePatmo();
  const { mutateAsync: checkIMEI, isLoading: isChecking } =
    useCheckLineExistMutation();

  async function checkAndCreatePatmo() {
    const imei = getStorage("imei");
    try {
      imei && (await checkIMEI());
    } catch (e) {
      throw new IMEIStatusError(e?.toString());
    }
    return await addPatmo();
  }
  return { checkAndCreatePatmo, isLoading: isAdding || isChecking };
}

export class IMEIStatusError extends Error {}
export { GateNumberNotMatchError, SNIMEINotMatchError } from "@/queries";
