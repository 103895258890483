import { cx } from "@emotion/css";
import { useEffect, useLayoutEffect } from "react";
import { type FieldErrors, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { elevatorSpotOptions } from "@/app.constant";
import {
  Button,
  Card,
  Footer,
  Header,
  Main,
  Popup,
  PopupAction,
  PopupMain,
} from "@/components";
import { Label } from "@/components/form";
import {
  useFetchDataError,
  useFormErrorsToast,
  useModal,
  useVisibilityToggle,
} from "@/hooks";
import {
  BuildIdInput,
  GateNumberInput,
} from "@/pages/registerDevice/components";
import { useDeleteDevice } from "@/queries";
import {
  DELETE_DEVICE_FAILED_HINT,
  errorToast,
  REGISTER_DEVICE_FAILED_HINT,
} from "@/shared";
import { getStorage, setStorage } from "@/shared/store";

import {
  ElevatorSkeleton,
  ElevatorSpotInput,
  ImageControl,
} from "../components";
import { useCreateElevator, useDearisSpotValue } from "../hooks";

interface ElevatorSpotFormField {
  buildId: number;
  gateNumber: number;
  location: string;
  customLocation: string;
  uri: string;
}
export function ElevatorSpot() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { visible, toggle } = useModal();
  const { watch, register, setValue, getValues, handleSubmit, reset, control } =
    useForm<ElevatorSpotFormField>({
      defaultValues: {
        buildId: getStorage("configureDearisDevicesBuildId") ?? 0,
      },
    });
  const [isAllFieldFilled] = useVisibilityToggle(
    watch("buildId"),
    watch("gateNumber"),
    watch("location"),
    watch("location") === "カスタム" ? watch("customLocation") : "permanent"
  );
  const { toastFormValueError } = useFormErrorsToast();

  const { isLoading, isError, error } = useDearisSpotValue(
    id,
    reset,
    elevatorSpotOptions
  );
  useFetchDataError(!!isError, error);
  const { createElevator, isCreating } = useCreateElevator();
  const { mutateAsync: deleteDevice, isLoading: isDeleting } =
    useDeleteDevice();

  const handleRegister = async (values: ElevatorSpotFormField) => {
    if (values.location === "カスタム") {
      if (!values.customLocation.trim())
        return errorToast("設置場所(識別名)は必須です。");
      else {
        setStorage("spotName", values.customLocation.trim());
      }
    } else {
      setStorage("spotName", values.location);
    }
    setStorage("uri", values.uri);
    setStorage("buildId", values.buildId);
    setStorage("gateNumber", values.gateNumber);
    setStorage("serialNumber", getStorage("dearisController")?.sn); // TODO

    createElevator({
      buildId: values.buildId,
      gateNumber: values.gateNumber,
    })
      .then(() => navigate(-1))
      .catch((error) => {
        if (error?.status === 400) {
          errorToast(REGISTER_DEVICE_FAILED_HINT);
        } else {
          errorToast(error?.message ?? error.toString());
        }
      });
  };

  const handleDeleteDevice = () => {
    id &&
      deleteDevice(id)
        .then(() => navigate(-1))
        .catch((error) => {
          if (error?.status === 400) {
            errorToast(DELETE_DEVICE_FAILED_HINT);
          } else {
            errorToast(error?.message ?? error.toString());
          }
        });
  };

  useLayoutEffect(() => {
    setStorage("buildId", 0);
  }, []);

  useEffect(() => {
    return () => {
      setStorage("buildId", undefined);
      setStorage("gateNumber", undefined);
      setStorage("spotName", "");
    };
  }, []);

  if (isLoading) return <ElevatorSkeleton />;
  return (
    <>
      <Header>エレベーターを設定</Header>
      <Main>
        <Card>
          <Label htmlFor="buildId" required className="mt-5">
            棟番号
          </Label>
          <BuildIdInput
            register={register}
            setValue={setValue}
            getValues={getValues}
            disabled
          />
          <Label htmlFor="gateNumber" required className="mt-5">
            ゲート番号
          </Label>
          <GateNumberInput
            register={register}
            setValue={setValue}
            getValues={getValues}
            disabled={Boolean(id)}
          />
          <Label htmlFor="spot" required className="mt-5">
            設置場所（識別名）
          </Label>
          <ElevatorSpotInput
            watch={watch}
            register={register}
            setValue={setValue}
            getValues={getValues}
            options={elevatorSpotOptions}
            disabled={id}
          />
          <Label htmlFor="uri" className="mt-5">
            写真
          </Label>
          {!!id || (
            <p
              className={cx(
                "mb-3 text-xs font-normal leading-[18px] text-neutral-400"
              )}
            >
              画像は最大16MBです。横向きの写真を撮ってください。
              <br />
              下部に写真の注意事項があります。
            </p>
          )}
          <ImageControl name="uri" control={control} disabled={Boolean(id)} />
          <div className="mt-5">
            {id ? (
              <>
                <Button type="default" onClick={() => navigate(-1)}>
                  設定リストに戻る
                </Button>
                <button className="mt-[10px] text-error" onClick={toggle}>
                  デバイスを削除
                </button>
              </>
            ) : (
              <Button
                disabled={!isAllFieldFilled}
                loading={isCreating}
                onClick={handleSubmit(handleRegister, (errors: FieldErrors) =>
                  toastFormValueError(errors)
                )}
              >
                確認して提出
              </Button>
            )}
          </div>
        </Card>
      </Main>
      <Footer />
      <Popup visible={visible}>
        <PopupMain>このデバイスを削除してもよろしいですか？</PopupMain>
        <PopupAction className="flex gap-2">
          <div className="flex-1">
            <Button className="w-full" type="default" onClick={toggle}>
              キャンセル
            </Button>
          </div>
          <div className="flex-1">
            <Button
              className="w-full"
              onClick={handleDeleteDevice}
              loading={isDeleting}
              disabled={isDeleting}
            >
              確定
            </Button>
          </div>
        </PopupAction>
      </Popup>
    </>
  );
}
