import {
  useCheckLineStatus,
  useGetDeviceById,
  useGetDevicesOfProperty,
} from "@/queries";
import { getStorage } from "@/shared/store";

import { useCountBuildingDevices } from "./use-count-building-devices";

export function useDearisMultiData() {
  const imei = getStorage("imei");
  const deviceId = getStorage("deviceId");

  const { entrances, elevators } = useCountBuildingDevices();
  const {
    data: lineInfo,
    isLoading: isLineInfoLoading,
    isSuccess: isLineInfoSuccess,
    isError: isLineInfoError,
    error: lineInfoError,
  } = useCheckLineStatus(Boolean(imei));
  const {
    data,
    isLoading: isDeviceLoading,
    isSuccess: isDeviceSuccess,
    isError: isDeviceError,
    error: deviceError,
  } = useGetDeviceById();
  const { data: dearisGateData } = useGetDevicesOfProperty("dearisGate");
  const device = data?.data;
  const dearisGates = uniqueByKey(
    dearisGateData?.data?.map((item) => ({
      ...item,
      position: {
        ...item.position,
        build_id: 0,
      },
      gate_number: item?.position?.gate_number,
    })) ?? [],
    "gate_number"
  );
  const isControllerAdded = Boolean(device?.sn);
  const isIMEIMatch = Boolean(device?.sn && device?.imei === imei);
  const isStillLocked =
    entrances?.some((entrance) => !entrance?.position?.test_passed) ||
    elevators?.some((elevator) => !elevator?.position?.test_passed) ||
    dearisGates?.some((gate) => !gate?.position?.test_passed);

  return {
    imei,
    lineInfo,
    isControllerAdded,
    isIMEIMatch,
    device,
    dearisGates,
    isStillLocked,
    isLoading: isDeviceLoading || Boolean(imei && isLineInfoLoading),
    isSuccess: isDeviceSuccess || isLineInfoSuccess,
    isError: Boolean(deviceId && (isDeviceError || isLineInfoError)),
    error: deviceError || (imei && lineInfoError),
  };
}

function uniqueByKey(array: any[], key: string) {
  const map = new Map();
  array.forEach((item) => {
    if (!map.has(item[key])) {
      map.set(item[key], item);
    }
  });
  return Array.from(map.values());
}
