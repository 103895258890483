import { useMemo } from "react";

import { useListOption } from "@/queries";
import type { ProviderModel } from "@/types";

export function useProvidersForDearis() {
  const { data, isLoading, isSuccess, isError, refetch } = useListOption();
  const availableProviders = useMemo(() => {
    const providers = data?.data;

    const filteredProviders: Array<ProviderModel> =
      providers?.map((provider) => ({
        courier: provider.option,
        name: provider.title,
        testable: provider.testable,
      })) ?? [];
    return filteredProviders;
  }, [data]);

  const isProvidersAllUnavailable = !availableProviders.length;
  const onlyAmazon =
    availableProviders.length === 1 &&
    availableProviders[0].courier === "amazon";

  const isSomeTestable =
    availableProviders?.findIndex((provider) => provider?.testable) !== -1;
  return {
    availableProviders,
    isProvidersAllUnavailable,
    isSomeTestable,
    onlyAmazon,
    isLoading,
    isSuccess,
    isError,
    refetch,
  };
}
