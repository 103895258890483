export const UNKNOWN_DEVICE_HINT = "デバイスタイプが不明です。";
export const FETCH_DATA_ERROR_HINT = "デバイス情報の取得に失敗しました。";
export const NECESSARY_INFO_LACKING_HINT = "必要な情報が不足しています。";
export const REGISTER_DEVICE_FAILED_HINT = "デバイスの登録に失敗しました。";
export const UNKNOWEN_ERROR_HINT = "不明なエラーが発生しました。";
export const DELETE_DEVICE_FAILED_HINT = "デバイスの削除に失敗しました。";
export const DELETE_IMEI_FAILED_HINT = "IMEIの削除に失敗しました。";
export const SAVE_SPOT_NAME_FAILED_HINT = "場所名の設定保存に失敗しました。";
export const UPDATE_UNLOCK_STATE_FAILED = "テストの状態更新に失敗しました。";
export const GATE_NOT_MATCH_HINT =
  "ゲート番号と登録したデバイスの情報が一致していません";
