import { cx } from "@emotion/css";
import type { Dispatch, SetStateAction } from "react";

import ErrorIcon from "@/assets/error.svg?react";
import ReturnIcon from "@/assets/return.svg?react";
import {
  Button,
  Footer,
  Header,
  Hint,
  LineStatusAction,
  LineStatusCard,
  LineStatusStatement,
  Link,
} from "@/components";
import { getStorage } from "@/shared/store";
import type { DeviceType } from "@/types";

const RegisterFailedWrapper = ({
  setKey,
}: {
  setKey: Dispatch<SetStateAction<number>>;
}) => {
  const deviceType = getStorage("deviceType") as DeviceType;
  switch (deviceType) {
    case "PabbitLite":
    case "PATMO":
      return <StatusRegisterFailed setKey={setKey} />;
    default:
      return <RegisterFailed setKey={setKey} />;
  }
};

export default RegisterFailedWrapper;

/*
 * 设定工具改版后的回线开通失败页面
 * 可以一直开通, 返回只返回到imei开通页面
 * Lite 和 PATMO 完成了改版, DearisController 及其他则维持之前的回线开通失败页面
 */
const StatusRegisterFailed = ({
  setKey,
}: {
  setKey: Dispatch<SetStateAction<number>>;
}) => {
  return (
    <div className="contents">
      <Header>回線開通</Header>
      <div className="flex-1 bg-[#f6f6f6] p-4">
        <LineStatusCard>
          <ErrorIcon className={cx("m-auto")} />
          <LineStatusStatement>回線開通に失敗しました</LineStatusStatement>
          <LineStatusAction>
            <Button
              className="w-full"
              type="default"
              onClick={() => setKey((prev) => prev + 1)}
            >
              もう一度行う
            </Button>
          </LineStatusAction>
        </LineStatusCard>
        <Hint className="m-2 py-2 text-xs">
          何度も開通に失敗した場合は、デバイス管理者に連絡してください。
        </Hint>
      </div>
      <Link className="m-3 block text-center" to="/line-open">
        <ReturnIcon className="mb-0.5 mr-1.5 inline" />
        戻る
      </Link>
      <Footer />
    </div>
  );
};

const RegisterFailed = ({
  setKey,
}: {
  setKey: Dispatch<SetStateAction<number>>;
}) => {
  return (
    <div className="contents">
      <Header>回線開通</Header>
      <div className="flex-1 bg-[#f6f6f6] p-4">
        <LineStatusCard>
          <ErrorIcon className={cx("m-auto")} />
          <LineStatusStatement>回線開通に失敗しました</LineStatusStatement>
          <LineStatusAction>
            <Button
              className="w-full"
              type="default"
              onClick={() => setKey((prev) => prev + 1)}
            >
              もう一度行う
            </Button>
          </LineStatusAction>
        </LineStatusCard>
        <Hint className="m-2 py-2 text-xs">
          何度も開通に失敗した場合は、デバイス管理者に連絡してください。
        </Hint>
      </div>
      <Link className="m-3 block text-center" to="/">
        <ReturnIcon className="inline" />
        トップページに戻る
      </Link>
      <Footer />
    </div>
  );
};
