import { useHandleDeviceUnknown } from "@/hooks";
import { useInfiniteListBuildings, useInfiniteListProperties } from "@/queries";
import { getStorage } from "@/shared/store";
import type { DeviceType } from "@/types";

import { DefaultSearchResults } from "./search-object-results/DefaultSearchResults";
import { LiteSearchResults } from "./search-object-results/LiteSearchResults";
import { PatmoSearchResults } from "./search-object-results/PatmoSearchResults";

export default function SearchObjectResultWrapper() {
  const deviceType = getStorage("deviceType") as DeviceType;
  const { toastAndToSelectDevice } = useHandleDeviceUnknown();
  switch (deviceType) {
    case "PabbitLite":
      return (
        <LiteSearchResults
          deviceType={deviceType}
          infiniteQuery={useInfiniteListBuildings}
          dataKey="buildings"
        />
      );
    case "PATMO":
      return (
        <PatmoSearchResults
          deviceType={deviceType}
          infiniteQuery={useInfiniteListProperties}
          dataKey="properties"
        />
      );
    case "dearisController":
      return (
        <DefaultSearchResults
          deviceType={deviceType}
          infiniteQuery={useInfiniteListProperties}
          dataKey="properties"
        />
      );
    default: {
      toastAndToSelectDevice();
    }
  }
}
