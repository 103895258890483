import { cx } from "@emotion/css";

import { Card, Tag } from "@/components";
import type { LineStatus } from "@/types";

export function IMEIStatus({
  imei,
  lineStatus,
}: Readonly<{ imei: string; lineStatus: LineStatus }>) {
  if (!imei) return null;
  return (
    <Card className={cx("min-h-18", "mb-[10px]")}>
      <div className="flex justify-between">
        <p className="text-sm font-normal leading-[21px] text-green-gray">
          回線開通（IMEI）
        </p>
        <LineOpenedTag lineStatus={lineStatus} />
      </div>
      <p
        className={cx(
          "mt-[10px] break-all",
          "text-base font-medium text-[#303333]"
        )}
      >
        {imei}
      </p>
    </Card>
  );
}
const LineOpenedTag = ({ lineStatus }: { lineStatus: LineStatus }) => {
  if (lineStatus === "active")
    return (
      <Tag background="#E4FFE6" color="#52C41A">
        開通中
      </Tag>
    );
  if (lineStatus === "suspend")
    return (
      <Tag background="#FFF2EB" color="#FD8140">
        一時停止
      </Tag>
    );
  if (lineStatus === "dead") return <Tag>解約された</Tag>;
  return <Tag>未開通</Tag>;
};
