export const removeUnexpectedChar = (
  text: string | number,
  regexp: RegExp
): string | number => {
  if (typeof text === "string") {
    return text.replace(regexp, "");
  } else {
    return parseInt(text.toString().replace(regexp, ""));
  }
};

export const NotSpecialCharRegExp = new RegExp(/\W/, "g");
export const OnlyNumberRegExp = new RegExp(/\D/, "g");
export const LiteSNRegExp = new RegExp(/^[a-zA-Z0-9]{8,50}$/gm);
export const PATMOSNRegExp = new RegExp(/^[a-zA-Z0-9]{4,46}[0-9]{4}$/gm);
export const GeneralIMEIRegExp = new RegExp(/^[0-9]{8,50}$/gm);
export const OneTwoDigitRegExp = new RegExp(/^[0-9]{1,2}$/gm);
