import type { AxiosResponse } from "axios";

import type { GetEventsParams, GetEventsResponse } from "./events.type";
import server from "./server";

export const getEvents = (params: GetEventsParams) => {
  return server.get<unknown, AxiosResponse<GetEventsResponse>>("/events", {
    params,
  });
};
