import { useMutation, useQueryClient } from "@tanstack/react-query";

import { NECESSARY_INFO_LACKING_HINT } from "@/shared";
import { reportTestResult } from "@/shared/requests/property";
import { getStorage } from "@/shared/store";
import type { ReportTestOption } from "@/types";

import { NecessaryInfoLackingError } from "./errors.type";
import { UNLOCK_QUERY_KEY } from "./queryKey";

export function useReportTest() {
  const queryClient = useQueryClient();
  const mutationFn = ({
    step,
    failed = false,
  }: {
    step: ReportTestOption;
    failed?: boolean;
  }) => {
    const property_id = getStorage("propertyId");
    const device_id = getStorage("deviceId");
    if (!property_id || !device_id)
      throw new NecessaryInfoLackingError(NECESSARY_INFO_LACKING_HINT);

    if (failed) {
      const message = {
        type: "INVALID_VALUE",
        message: "The value is invalid",
      };
      return reportTestResult({ property_id, device_id, step, ...message });
    } else {
      return reportTestResult({ property_id, device_id, step });
    }
  };
  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [UNLOCK_QUERY_KEY] });
    },
  });
}
