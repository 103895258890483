import IndexIMG1 from "@/assets/lite-index-unlock-uncomplete.png";
import MainIMG from "@/assets/lite-relay-unlock.png";
// import IndexIMG2 from "@/assets/lite-relay-unlock-index-2.png";
import type { StepsDataModal } from "@/components/step";

export const relayStepsData: StepsDataModal = {
  steps: [
    [
      {
        type: "image",
        content: { src: IndexIMG1, caption: "" },
      },
      {
        type: "text",
        content:
          "トップページでタップしてから、次の解錠テストページで「次へ」ボタンを押してください。",
        indicator: 1,
      },
    ],
    [
      { type: "image", content: MainIMG },
      {
        type: "text",
        content:
          "「解錠」ボタンをタップし、オートドアが開くかどうかを確認します。信号が正常に送信されると、「次へ」ボタンが有効になります。",
        indicator: 2,
      },
      {
        type: "text",
        content:
          "オートドアが開くことを確認した後、「リレー解錠を完了」ボタンをタップしてください。",
      },
    ],
  ],
};
