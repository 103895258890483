import { useMutation } from "@tanstack/react-query";

import { deleteBunchDevices } from "@/shared/requests/device";

export function useDeleteBunchDevices() {
  const mutationFn = async (device_ids: string[]) => {
    return deleteBunchDevices({ device_ids });
  };
  return useMutation(mutationFn);
}
