import React from "react";

export default function ConsentTitle({
  children,
}: Readonly<{
  children: React.ReactNode | React.ReactNode[];
}>) {
  return (
    <h4 className="text-lg leading-4 my-2  text-xs text-neutral-400 font-normal">
      {children}
    </h4>
  );
}
