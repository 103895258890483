import { useMutation, useQueryClient } from "@tanstack/react-query";

import { NECESSARY_INFO_LACKING_HINT } from "@/shared";
import { updateDevice } from "@/shared/requests/device";
import { getStorage } from "@/shared/store";

import { NecessaryInfoLackingError } from "./errors.type";
import { DEVICE_QUERY_KEY } from "./queryKey";

export const useUpdateIMEI = () => {
  const queryClient = useQueryClient();
  const mutationFn = async () => {
    const device_id = getStorage("deviceId");
    const imei = getStorage("imei");
    if (!device_id || !imei)
      throw new NecessaryInfoLackingError(NECESSARY_INFO_LACKING_HINT);
    return updateDevice({ device_id, imei });
  };

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [DEVICE_QUERY_KEY] });
    },
  });
};
