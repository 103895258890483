import { type FieldErrors, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import { Button, Card, Footer, Header, Main } from "@/components";
import { Label } from "@/components/form";
import { useFormErrorsToast, useVisibilityToggle } from "@/hooks";
import { OnlyNumberRegExp, removeUnexpectedChar } from "@/shared/regexps";
import { setStorage } from "@/shared/store";

import { GateNumberSelectInput, IMEIInput, PatmoSNInput } from "../components";

interface PATMORegisterFormField {
  serialNumber: string;
  imei: string;
  gateNumber: number;
}
const PATMOManualRegister = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const hasImei = state.hasImei;
  const { watch, register, setValue, getValues, handleSubmit } =
    useForm<PATMORegisterFormField>();
  const [isAllThreeFieldFilled] = useVisibilityToggle(
    watch("gateNumber"),
    watch("serialNumber"),
    watch("imei")
  );
  const [isAllTwoFieldFilled] = useVisibilityToggle(
    watch("gateNumber"),
    watch("serialNumber")
  );
  watch("imei");
  const { toastFormValueError } = useFormErrorsToast();
  const confirmRegister = (values: PATMORegisterFormField) => {
    const { imei } = values;

    const cleaned = removeUnexpectedChar(imei, OnlyNumberRegExp).substring(
      0,
      50
    );
    setStorage("imei", cleaned);
    setStorage("gateNumberPATMO", values.gateNumber);
    navigate("/register/confirm");
  };
  const confirmCommonRegister = (values: PATMORegisterFormField) => {
    setStorage("imei", "");
    setStorage("gateNumberPATMO", values.gateNumber);
    navigate("/register/confirm");
  };

  return (
    <>
      <Header>情報の登録</Header>
      <Main>
        {hasImei ? (
          <>
            <Card className="mt-4">
              <Label htmlFor="serialNumber" required>
                シリアル番号
              </Label>
              <PatmoSNInput
                register={register}
                setValue={setValue}
                getValues={getValues}
              />
              <div className="h-6"></div>
              <Label htmlFor="imei" required>
                IMEI
              </Label>
              <IMEIInput
                register={register}
                setValue={setValue}
                getValues={getValues}
              />
              <div className="h-6"></div>
              <Label htmlFor="gateNumber" required>
                ゲート番号
              </Label>
              <GateNumberSelectInput
                register={register}
                setValue={setValue}
                getValues={getValues}
                defaultValue={1}
              />
              <div className="mt-10">
                <Button
                  className="w-full"
                  size="large"
                  onClick={handleSubmit(
                    confirmRegister,
                    (errors: FieldErrors) => toastFormValueError(errors)
                  )}
                  disabled={!isAllThreeFieldFilled}
                >
                  確認
                </Button>
              </div>
            </Card>
            <p className="mt-3 text-xs text-green-gray">
              <span className="text-error">※</span>
              &nbsp;{"注意:IMEI番号がある場合は、必ず入力してください。"}
            </p>
          </>
        ) : (
          <>
            <Card className="mt-4">
              <Label htmlFor="serialNumber" required>
                シリアル番号
              </Label>
              <PatmoSNInput
                register={register}
                setValue={setValue}
                getValues={getValues}
              />
              <div className="h-6"></div>
              <Label htmlFor="gateNumber" required>
                ゲート番号
              </Label>
              <GateNumberSelectInput
                register={register}
                setValue={setValue}
                getValues={getValues}
                defaultValue={1}
              />
              <div className="mt-10">
                <Button
                  className="w-full"
                  size="large"
                  onClick={handleSubmit(
                    confirmCommonRegister,
                    (errors: FieldErrors) => toastFormValueError(errors)
                  )}
                  disabled={!isAllTwoFieldFilled}
                >
                  確認
                </Button>
              </div>
            </Card>
          </>
        )}
      </Main>
      <Footer />
    </>
  );
};

export default PATMOManualRegister;
