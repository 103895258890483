import StepOneImg from "@/assets/patmo-pre-setting-step-three.png";
import SucceedStartImg from "@/assets/patmo-restart-step-two.png";
import FailedStartImg from "@/assets/patmo-restart-step-two-failed.png";
import type { StepsDataModal } from "@/components/step";

export const patmoStepsData: StepsDataModal = {
  steps: [
    // step 1
    [
      { type: "title", content: "操作手順" },
      {
        type: "text",
        content:
          "設定ページに「通信アダプターリセット」を押して、次に出てきたポップアップに「はい」を押してください。",
        indicator: 1,
      },
      { type: "image", content: StepOneImg },
    ],
    // step 2
    [
      {
        type: "text",
        content:
          "成功したら以下の画面が表示されます。「戻る」をタップして設定ページに戻ります。",
        indicator: 2,
      },
      { type: "image", content: SucceedStartImg },
    ],
    // step 2 failed
    [
      {
        type: "alert",
        content:
          "以下の画面が表示された場合は、少し待ってから「戻る」をタップし、再度操作してリセットしてください。",
      },
      { type: "image", content: FailedStartImg },
    ],
  ],
};
