import { cx } from "@emotion/css";
import { FC, MouseEventHandler, PropsWithChildren } from "react";

interface ICard {
  className?: string;
  onClick?: MouseEventHandler;
}
const Card: FC<PropsWithChildren<ICard>> = ({ className, children, onClick }) => {
  return (
    <div className={cx("shadow-card bg-white rounded-md", "p-4", className)} onClick={onClick}>
      {children}
    </div>
  );
};

export default Card;
