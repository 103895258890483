import { Footer, Header, Main, PlainSkeleton } from "@/components";
import { StepContainer } from "@/components/step";

export function UnlockedSkeleton() {
  return (
    <>
      <Header>解錠テスト</Header>
      <Main>
        <StepContainer>
          <PlainSkeleton />
        </StepContainer>
      </Main>
      <Footer />
    </>
  );
}
