import { cx } from "@emotion/css";
import { Checkbox, type CheckboxProps } from "@nutui/nutui-react";

import type { Building } from "@/types";

import CheckboxIconChecked from "../assets/checkbox-icon-checked.svg?react";
import CheckboxIconDisabled from "../assets/checkbox-icon-disabled.svg?react";
import CheckboxIconUnChoosen from "../assets/checkbox-icon-unchoose.svg?react";

interface BuildCheckboxProps {
  building: Pick<Building, "id" | "name" | "build_id">;
}
export const BuildCheckbox: React.FC<
  BuildCheckboxProps &
    Partial<Pick<CheckboxProps, "defaultChecked" | "checked" | "disabled">>
> = ({ building, ...props }) => {
  return (
    <div
      className={cx(
        "my-[10px] h-12 bg-[#F7F9FA] p-4",
        "rounded-md border border-solid border-[#F7F9FA]",
        props.checked && "!border-primary !bg-[#C7EFF5]/[0.3] "
      )}
      id={building?.id}
    >
      <Checkbox
        icon={
          props.disabled ? <CheckboxIconDisabled /> : <CheckboxIconUnChoosen />
        }
        activeIcon={<CheckboxIconChecked />}
        label={building?.name}
        value={building?.build_id}
        {...props}
      />
      <p className="text-xs font-normal text-green-gray">
        棟番号：{building?.build_id}
      </p>
    </div>
  );
};
