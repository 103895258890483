import { cx } from "@emotion/css";

const DataItem = ({
  label,
  content,
  className,
}: Readonly<{
  label: string;
  content: string | number;
  className?: string;
}>) => {
  return (
    <div
      className={cx(
        "flex flex-wrap justify-between text-base text-[#303333]",
        className
      )}
    >
      <span>{label}</span>
      <span>
        <span className="block break-all font-bold text-primary underline">
          {content}
        </span>
      </span>
    </div>
  );
};

export default DataItem;
