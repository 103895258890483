import { cx } from "@emotion/css";

export default function LineStatusAction({
  children,
  className,
}: Readonly<{
  children: React.ReactNode[] | React.ReactNode;
  className?: string;
}>) {
  return <div className={cx("px-3 mb-8", className)}>{children}</div>;
}
