import { useNavigate } from "react-router-dom";

import SuccessIcon from "@/assets/success.svg?react";
import {
  Button,
  Footer,
  Header,
  Hint,
  LineStatusAction,
  LineStatusCard,
  LineStatusStatement,
  Main,
} from "@/components";
import { useUpdateIMEI } from "@/queries";
import { errorToast } from "@/shared";
import { setStorage } from "@/shared/store";

export const DearisControllerSuccess = () => {
  const navigate = useNavigate();
  const { mutateAsync: replaceIMEI, isLoading } = useUpdateIMEI();
  const handleClick = () => {
    replaceIMEI()
      .then(() => {
        navigate("/imei-replace/restart");
        setStorage("DearisReplaceIMEI", false);
      })
      .catch(() => {
        errorToast("IMEIの変更に失敗しました。");
      });
  };
  return (
    <>
      <Header>回線開通</Header>
      <Main>
        <LineStatusCard>
          <SuccessIcon className="mx-auto" />
          <LineStatusStatement className="!font-bold !text-black/70">
            開通に成功しました
          </LineStatusStatement>
          <p className="mt-5 text-xs font-medium">
            2、３分を待って、アダプターをオフにしてから、再起動してください
          </p>
          <LineStatusAction className="mt-18">
            <Button
              className="w-full"
              onClick={handleClick}
              loading={isLoading}
            >
              通信確認
            </Button>
          </LineStatusAction>
        </LineStatusCard>
        <Hint className="p-2 text-xs">
          再起動後、このボタンを押してください。
        </Hint>
      </Main>
      <Footer />
    </>
  );
};
