import { useNavigate } from "react-router-dom";

import { infoToast, UNKNOWN_DEVICE_HINT } from "@/shared";

export function useHandleDeviceUnknown() {
  const navigate = useNavigate();
  function toastAndToSelectDevice() {
    infoToast(UNKNOWN_DEVICE_HINT);
    navigate("/select-device");
  }
  return { toastAndToSelectDevice };
}
