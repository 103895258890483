import { cx } from "@emotion/css";
import { type FieldErrors, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { spotOptions } from "@/app.constant";
import {
  Button,
  Card,
  Footer,
  Header,
  Main,
  Popup,
  PopupAction,
  PopupMain,
} from "@/components";
import { Label } from "@/components/form";
import {
  useFetchDataError,
  useFormErrorsToast,
  useModal,
  useVisibilityToggle,
} from "@/hooks";
import { useCreateDearis, useDeleteDevice } from "@/queries";
import {
  DELETE_DEVICE_FAILED_HINT,
  errorToast,
  REGISTER_DEVICE_FAILED_HINT,
} from "@/shared";
import { getStorage, setStorage } from "@/shared/store";

import {
  EntranceSkeleton,
  ImageControl,
  SpotInput,
  XiezhenCaption,
} from "../components";
import { useDearisSpotValue } from "../hooks";

export interface EntranceSpotFormField {
  location: string;
  customLocation: string;
  uri: string;
}

export function EntranceSpot() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { visible, toggle } = useModal();

  const { watch, register, setValue, getValues, handleSubmit, reset, control } =
    useForm<EntranceSpotFormField>();
  const [isAllFieldFilled] = useVisibilityToggle(
    watch("location"),
    watch("location") === "カスタム" ? watch("customLocation") : "permanent"
  );
  const { toastFormValueError } = useFormErrorsToast();

  const {
    isError,
    isLoading: isDataLoading,
    error,
  } = useDearisSpotValue(id, reset, spotOptions);
  useFetchDataError(!!(id && isError), error);
  const { mutateAsync: createEntrance, isLoading: isCreating } =
    useCreateDearis();
  const { mutateAsync: deleteDevice, isLoading: isDeleting } =
    useDeleteDevice();

  const handleRegister = (value: EntranceSpotFormField) => {
    if (value.location === "カスタム") {
      if (!value.customLocation.trim())
        return errorToast("設置場所(識別名)は必須です。");
      setStorage("spotName", value.customLocation.trim());
    } else {
      setStorage("spotName", value.location);
    }
    setStorage("uri", value.uri);
    const dearisBuildingDetail = getStorage("dearisBuildingDetail");
    createEntrance()
      .then(() =>
        navigate(`/dearis-building/${dearisBuildingDetail?.id}`, {
          replace: true,
        })
      )
      .catch((error) => {
        if (error?.status === 400) {
          errorToast(REGISTER_DEVICE_FAILED_HINT);
        } else {
          errorToast(error?.message ?? error.toString());
        }
      });
  };

  const handleDeleteDevice = () => {
    id &&
      deleteDevice(id)
        .then(() => navigate(-1))
        .catch((error) => {
          if (error?.status === 400) {
            errorToast(DELETE_DEVICE_FAILED_HINT);
          } else {
            errorToast(error?.message ?? error.toString());
          }
        });
  };

  if (isDataLoading) {
    return <EntranceSkeleton />;
  }

  return (
    <>
      <Header>設置場所（識別名）設定</Header>
      <Main>
        <Card>
          <Label htmlFor="location" required>
            設置場所（識別名）
          </Label>
          <SpotInput
            watch={watch}
            register={register}
            setValue={setValue}
            getValues={getValues}
            options={spotOptions}
            disabled={id}
          />
          <Label htmlFor="uri" className="mt-5">
            写真
          </Label>
          {!!id || (
            <p
              className={cx(
                "mb-3 text-xs font-normal leading-[18px] text-neutral-400"
              )}
            >
              画像は最大16MBです。横向きの写真を撮ってください。
              <br />
              下部に写真の注意事項があります。
            </p>
          )}
          <ImageControl name="uri" control={control} disabled={Boolean(id)} />
          <div className="mt-5">
            {id ? (
              <>
                <Button type="default" onClick={() => navigate(-1)}>
                  設定リストに戻る
                </Button>
                <button className="mt-[10px] text-error" onClick={toggle}>
                  デバイスを削除
                </button>
              </>
            ) : (
              <>
                <Button
                  disabled={!isAllFieldFilled}
                  loading={isCreating}
                  onClick={handleSubmit(handleRegister, (errors: FieldErrors) =>
                    toastFormValueError(errors)
                  )}
                >
                  確認して提出
                </Button>

                <XiezhenCaption />
              </>
            )}
          </div>
        </Card>
      </Main>
      <Footer />
      <Popup visible={visible}>
        <PopupMain>このデバイスを削除してもよろしいですか？</PopupMain>
        <PopupAction className="flex gap-2">
          <div className="flex-1">
            <Button className="w-full" type="default" onClick={toggle}>
              キャンセル
            </Button>
          </div>
          <div className="flex-1">
            <Button
              className="w-full"
              onClick={handleDeleteDevice}
              loading={isDeleting}
              disabled={isDeleting}
            >
              確定
            </Button>
          </div>
        </PopupAction>
      </Popup>
    </>
  );
}
