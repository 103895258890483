import { useQueries } from "@tanstack/react-query";

import { NECESSARY_INFO_LACKING_HINT } from "@/shared";
import { getDevicesOfProperty } from "@/shared/requests/property";
import { getStorage } from "@/shared/store";
import type { DeviceType } from "@/types";

import { NecessaryInfoLackingError } from "./errors.type";
import { ELEVATOR_QUERY_KEY, ENTRANCESE_QUERY_KEY } from "./queryKey";

export function useListDearis(building_id: string) {
  const queryDearisFn = async (device_type: DeviceType) => {
    const property_id = getStorage("propertyId");
    if (!property_id) {
      throw new NecessaryInfoLackingError(NECESSARY_INFO_LACKING_HINT);
    }
    return getDevicesOfProperty({ property_id, device_type, building_id });
  };
  const deviceId = getStorage("deviceId");
  return useQueries({
    queries: [
      {
        queryKey: [ENTRANCESE_QUERY_KEY],
        queryFn: () => queryDearisFn("dearisEntrance"),
        enabled: !!deviceId,
      },
      {
        queryKey: [ELEVATOR_QUERY_KEY],
        queryFn: () => queryDearisFn("dearisElevator"),
        enabled: !!deviceId,
      },
    ],
  });
}
