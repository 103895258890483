import { useQuery } from "@tanstack/react-query";

import { NECESSARY_INFO_LACKING_HINT } from "@/shared";
import { getDeviceTestResults } from "@/shared/requests/property";
import { getStorage } from "@/shared/store";
import type { ReportTestOption } from "@/types";

import { NecessaryInfoLackingError } from "./errors.type";
import { UNLOCK_QUERY_KEY } from "./queryKey";

export function useGetTestResultsInterval(key: ReportTestOption) {
  const device_id = getStorage("deviceId");
  const property_id = getStorage("propertyId");
  const queryFn = async () => {
    if (!device_id || !property_id) {
      throw new NecessaryInfoLackingError(NECESSARY_INFO_LACKING_HINT);
    }
    return getDeviceTestResults({ property_id, device_id });
  };
  return useQuery([UNLOCK_QUERY_KEY], queryFn, {
    refetchInterval: (data) => {
      if (data?.data?.[key]) {
        return false;
      } else {
        return 3000;
      }
    },
    enabled: !!(device_id && property_id),
  });
}
