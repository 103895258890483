import { cx } from "@emotion/css";

interface IMainProps {
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
  [key: string]: any;
}

export default function Main({ children, className, ...rest }: IMainProps) {
  return (
    <main className={cx("p-4 bg-[#f6f6f6] flex-1", className)} {...rest}>
      {children}
    </main>
  );
}
