import { useMutation } from "@tanstack/react-query";

import { getBuildsOfProperty } from "@/shared/requests/property";
import { getStorage } from "@/shared/store";

export const useGetBuildsByProperty = () => {
  const mutationFn = async () => {
    const property_id = getStorage("propertyId");
    return getBuildsOfProperty({ property_id });
  };
  return useMutation(mutationFn);
};
