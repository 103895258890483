import "./index.css";

import { cx } from "@emotion/css";
import React from "react";

interface StepImageProps {
  image?: string;
  children?: React.ReactNode | React.ReactNode[];
}

export default function StepGraph({
  image,
  children,
}: Readonly<StepImageProps>) {
  return (
    <div>
      {image && (
        <img src={image} className={cx("step-image-bg", "mx-auto h-[334px]")} />
      )}
      {children && (
        <p className="mt-[14px] text-center text-[15px] text-neutral-800">
          {children}
        </p>
      )}
    </div>
  );
}
