import { cx } from "@emotion/css";

export default function StepParagraph({
  children,
  className,
}: Readonly<{
  children?: React.ReactNode;
  className?: string;
}>) {
  return <div className={cx("text-[15px]", className)}>{children}</div>;
}
