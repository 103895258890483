import type {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";

import { InputWithClear } from "@/components/form";
import { NotSpecialCharRegExp, removeUnexpectedChar } from "@/shared/regexps";
import { setStorage } from "@/shared/store";

export default function LiteSNInput({
  register,
  setValue,
  getValues,
}: Readonly<{
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
}>) {
  const handleInputSerialNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cleaned = removeUnexpectedChar(e.target.value, NotSpecialCharRegExp)
      .toString()
      .substring(0, 50);
    setStorage("serialNumber", cleaned);
    setValue("serialNumber", cleaned);
  };

  return (
    <InputWithClear
      id="serialNumber"
      register={register("serialNumber", {
        onChange: handleInputSerialNumber,
        required: true,
        pattern: {
          value: /^[a-zA-Z0-9]{8,50}$/gm,
          message: "シリアル番号の形式が正しくありません",
        },
      })}
      getValues={getValues}
      setValue={setValue}
      placeholder="シリアル番号を入力してください"
    />
  );
}
