import type { FC, PropsWithChildren } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ImgH1 from "@/assets/patmo-pre-setting-help-1.png";
import ImgH2 from "@/assets/patmo-pre-setting-help-2.png";
import ImgH3 from "@/assets/patmo-pre-setting-help-3.png";
import ImgH4 from "@/assets/patmo-pre-setting-help-4.png";
import ImgH5 from "@/assets/patmo-pre-setting-help-5.png";
import ImgH6 from "@/assets/patmo-pre-setting-help-6.png";
import ImgH7 from "@/assets/patmo-pre-setting-help-7.png";
import ImgS1 from "@/assets/patmo-pre-setting-step-1.png";
import ImgS2 from "@/assets/patmo-pre-setting-step-2.png";
import ImgS3 from "@/assets/patmo-pre-setting-step-3.png";
import { Button, Footer, Header, LinkButton, Main } from "@/components";
import {
  Step,
  StepContainer,
  StepParagraph,
  StepsGenerator,
} from "@/components/step";
import { usePreloadImages } from "@/hooks/usePreloadImages";

import {
  patmoHelpData,
  patmoHelpExtra,
  patmoStepsData,
  patmoStepsExtra,
} from "./patmo-steps-data";

const preload = [
  ImgH1,
  ImgH2,
  ImgH3,
  ImgH4,
  ImgH5,
  ImgH6,
  ImgH7,
  ImgS1,
  ImgS2,
  ImgS3,
];
export default function PatmoPreSetting() {
  const navigate = useNavigate();
  const path = useLocation().pathname;
  const handleClick = () => {
    navigate("/pre-setting/help");
  };
  usePreloadImages(preload);
  switch (path) {
    case "/pre-setting":
      return (
        <PatmoPreSettingPage title="設定の準備">
          <StepsGenerator stepsData={patmoStepsData} />
          <StepsGenerator stepsData={patmoStepsExtra} />
          <Step className="-mt-3 gap-[26px]">
            <Button onClick={() => navigate("/register", { replace: true })}>
              次へ
            </Button>
            <StepParagraph className="!text-[13px]">
              もしQRコードが表示されておらず、QRコードの表示の仕方が分からない場合は「ヘルプ」をタップしてください。
            </StepParagraph>
            <LinkButton text="ヘルプ" onClick={handleClick} />
          </Step>
        </PatmoPreSettingPage>
      );
    case "/pre-setting/help":
      return (
        <PatmoPreSettingPage title="ヘルプ">
          <StepsGenerator stepsData={patmoHelpExtra} />
          <div className="text-[15px]">
            <span className="my-0 mr-1 py-0 text-primary">❷</span>
            <p className="inline">
              部屋呼出画面で呼出ボタンを押して、
              <br />
              「　*　1　⁨⁩⁨⁩⁨⁩⁨⁩⁨⁩⁨⁩⁨⁩⁨⁩⁨⁩*　」を入力してください。
            </p>
          </div>
          <StepsGenerator stepsData={patmoHelpData} />
          <Step className="gap-[26px]">
            <Button onClick={() => navigate("/pre-setting")}>戻る</Button>
          </Step>
        </PatmoPreSettingPage>
      );
  }
}

interface PatmoPreSettingProps {
  title: string;
}
const PatmoPreSettingPage: FC<PropsWithChildren<PatmoPreSettingProps>> = ({
  title,
  children,
}) => {
  return (
    <>
      <Header>{title}</Header>
      <Main className="pb-7 pt-5">
        <StepContainer>{children}</StepContainer>
      </Main>
      <Footer />
    </>
  );
};
