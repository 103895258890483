import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  updateUnlockTest,
  type UpdateUnlockTestData,
} from "@/shared/requests/property";
import { getStorage } from "@/shared/store";
import type { DeviceType } from "@/types";

import {
  DEVICE_QUERY_KEY,
  ELEVATOR_QUERY_KEY,
  ENTRANCESE_QUERY_KEY,
} from "./queryKey";

export function useUpdateCloudUnlock(
  device_type: DeviceType = getStorage("deviceType") as DeviceType
) {
  const queryClient = useQueryClient();
  const property_id = getStorage("propertyId");
  const mutateFn = (device_id = getStorage("deviceId")) => {
    const test_passed = getStorage("cloudUnlockTest");
    if (!device_id || !property_id || test_passed === undefined)
      throw new Error("デバイスIDまたは物件IDが存在しません");
    const data: UpdateUnlockTestData = {
      device_id,
      property_id,
      test_passed,
    };
    return updateUnlockTest(data);
  };
  return useMutation(mutateFn, {
    onSuccess: () => {
      device_type === "dearisEntrance" &&
        queryClient.invalidateQueries({
          queryKey: [ENTRANCESE_QUERY_KEY],
        });
      device_type === "dearisElevator" &&
        queryClient.invalidateQueries({
          queryKey: [ELEVATOR_QUERY_KEY],
        });
      device_type === "dearisGate" &&
        queryClient.invalidateQueries({
          queryKey: [DEVICE_QUERY_KEY, property_id, device_type],
        });
    },
  });
}
