import { Navigate } from "react-router-dom";

import { useHandleDeviceUnknown } from "@/hooks";
import { getStorage } from "@/shared/store";

import { DearisControllerSuccess } from "./DearisControllerSuccess";

export default function Success() {
  const deviceType = getStorage("deviceType");
  const { toastAndToSelectDevice } = useHandleDeviceUnknown();
  switch (deviceType) {
    case "PATMO":
      return <Navigate to="/imei-replace/restart" />;
    case "dearisController":
      return <DearisControllerSuccess />;
    default: {
      toastAndToSelectDevice();
    }
  }
}
