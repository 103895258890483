import { cx } from "@emotion/css";
import { barcode } from "pure-svg-code";

import ImageScannerError from "@/assets/patmo-scanner-test-error.png";
import ImageStepStart from "@/assets/patmo-scanner-test-step-one.png";
import ImageScannerSuccess from "@/assets/patmo-scanner-test-success.png";
import ImageStepEnd from "@/assets/service-configure-patmo-with-signal.png";
import { Footer, Header, Main, ToggleFullScreen } from "@/components";
import {
  Step,
  StepContainer,
  StepGraph,
  StepParagraph,
  StepTitle,
} from "@/components/step";
import { getStorage } from "@/shared/store";

import { ServiceConfigDoneBTN } from "../serviceConfigure/components";

export default function ScannerTest() {
  const code = getStorage("serialNumber").slice(-4);
  const barCodeString = barcode(code, "codabar", {
    bgColor: "white",
    barHeight: 40,
    width: 142,
  });

  return (
    <>
      <Header>スキャナーテスト</Header>
      <Main>
        <StepContainer>
          <Step>
            <StepTitle>操作手順</StepTitle>
            <StepGraph image={ImageStepStart} />
            <StepParagraph>
              この画面になったら、以下のバーコードをスキャンし、スキャンした数字がバーコードの数字と一致していることを確認してください。
            </StepParagraph>
            <StepGraph>
              <div
                className={cx("rounded-md bg-bgGray", "px-4 py-11", "relative")}
              >
                <ToggleFullScreen>
                  <>
                    {barCodeString && (
                      <div
                        dangerouslySetInnerHTML={{ __html: barCodeString }}
                      ></div>
                    )}
                    <p
                      className={cx("mt-4 text-center text-primary")}
                    >{`「${code}」`}</p>
                  </>
                </ToggleFullScreen>
              </div>
            </StepGraph>
            <StepGraph>
              <p className="text-left">例: </p>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="my-2 text-center">成功したページ</p>
                  <img src={ImageScannerSuccess} className="w-full" />
                </div>
                <div>
                  <p className="my-2 text-center">失敗したページ</p>
                  <img src={ImageScannerError} className="w-full" />
                </div>
              </div>
            </StepGraph>
            <StepParagraph>
              エラーができた場合に再度スキャンしてください。
            </StepParagraph>
            <StepParagraph>
              スキャンが成功したら、デバイス側で「戻る」をタッチし、設定ページに戻ります。
            </StepParagraph>
          </Step>

          <Step>
            <StepGraph image={ImageStepEnd}>PATMOαの設定ページ</StepGraph>
            <StepParagraph>
              設定ページの終了ボタンを押してください。
            </StepParagraph>
          </Step>

          <Step>
            <StepParagraph>ここで「完了」をタップしてください。</StepParagraph>
          </Step>

          <Step>
            <ServiceConfigDoneBTN />
          </Step>
        </StepContainer>
      </Main>
      <Footer />
    </>
  );
}
