import { type Dispatch, type SetStateAction, useState } from "react";
import { Navigate } from "react-router-dom";
import { useEffectOnce } from "react-use";

import { useLineOpenIMEIReplace } from "@/queries";
import { getStorage } from "@/shared/store";

import RegisterFailed from "./RegisterFailed";
import RegisterProcessing from "./RegisterProcessing";
import RegisterLineSuccessWrapper from "./success";

export const RegisterLine = ({
  setKey,
}: {
  setKey: Dispatch<SetStateAction<number>>;
}) => {
  const {
    mutate: openLine,
    isLoading,
    isSuccess,
    isError,
  } = useLineOpenIMEIReplace();
  useEffectOnce(() => {
    if (!isLoading) {
      openLine();
    }
  });

  if (isLoading) {
    return <RegisterProcessing />;
  } else if (isError) {
    return <RegisterFailed setKey={setKey} />;
  } else if (isSuccess) {
    const PATMOReplaceIMEI = getStorage("PATMOReplaceIMEI");
    if (PATMOReplaceIMEI) {
      return <Navigate to="/imei-replace/restart" />;
    }
    return <RegisterLineSuccessWrapper />;
  }
  return null;
};

const RegisterLineWrapper = () => {
  const [key, setKey] = useState(0);
  return <RegisterLine key={key} setKey={setKey} />;
};

export default RegisterLineWrapper;
