import { cx } from "@emotion/css";
import { useNavigate } from "react-router-dom";

import SuccessIcon from "@/assets/success.svg?react";
import { Button, Card, Footer, Header } from "@/components";
import { getStorage } from "@/shared/store";
import type { DeviceType } from "@/types";

const ObjectNotActivatedWrapper = () => {
  const navigate = useNavigate();
  const device_type = getStorage("deviceType") as DeviceType;

  const setService = () => {
    navigate("/service-configure");
  };

  const reback = () => {
    navigate("/status");
  };

  switch (device_type) {
    case "PATMO":
      return <PATMOObjectNotActivated handleClick={reback} />;
    case "dearisController": {
      return <ControllerObjectNotActivated handleClick={setService} />;
    }
    default:
      return <ObjectNotActivated handleClick={reback} />;
  }
};

export default ObjectNotActivatedWrapper;

const ObjectNotActivated = ({ handleClick }: { handleClick: () => void }) => {
  return (
    <div className="contents">
      <Header>物件設定</Header>
      <div className="flex-1 p-4">
        <Card
          className={cx(
            "px-8 py-12",
            "flex flex-col items-center justify-center"
          )}
        >
          <SuccessIcon />
          <p className={cx("my-3", "text-lg font-medium text-neutral-800")}>
            物件設定が完了
          </p>
          <p
            className={cx("mb-12 mt-4", "text-center text-sm text-neutral-600")}
          >
            {"「完了」をタップし、設定手順に従って 続けてください。"}
          </p>
          <Button className="w-full" onClick={handleClick}>
            完了
          </Button>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

const ControllerObjectNotActivated = ({
  handleClick,
}: {
  handleClick: () => void;
}) => {
  return (
    <div className="contents">
      <Header>物件設定</Header>
      <div className="flex-1 p-4">
        <Card
          className={cx(
            "px-8 py-12",
            "flex flex-col items-center justify-center"
          )}
        >
          <SuccessIcon />
          <p className={cx("my-3", "text-lg font-medium text-neutral-800")}>
            物件設定が完了
          </p>
          <p
            className={cx("mb-12 mt-4", "text-center text-sm text-neutral-600")}
          >
            物件設定が完了しました。
            <br />
            サービス設定を行ってください。
          </p>
          <Button className="w-full" onClick={handleClick}>
            サービス設定
          </Button>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

const PATMOObjectNotActivated = ({
  handleClick,
}: {
  handleClick: () => void;
}) => {
  return (
    <div className="contents">
      <Header>物件設定</Header>
      <div className="flex-1 p-4">
        <Card
          className={cx(
            "px-8 py-12",
            "flex flex-col items-center justify-center"
          )}
        >
          <SuccessIcon />
          <p className={cx("my-3", "text-lg font-medium text-neutral-800")}>
            物件設定が完了
          </p>
          <p
            className={cx("mb-12 mt-4", "text-center text-sm text-neutral-600")}
          >
            {"「完了」をタップし、設定手順に従って続けてください。"}
          </p>
          <Button className="w-full" onClick={handleClick}>
            完了
          </Button>
        </Card>
      </div>
      <Footer />
    </div>
  );
};
