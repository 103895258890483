import type { NavigateOptions, To } from "react-router-dom";
import { useNavigate } from "react-router-dom";

interface SearchParamsModel {
  [key: string]: any;
}
export function useNavigateWithSearchParams() {
  const navigateRRD = useNavigate();
  function navigate(
    to: To | -1 | 0,
    options?: NavigateOptions,
    searchParams?: SearchParamsModel
  ) {
    if (to === -1 || to === 0) {
      navigateRRD(to);
    } else {
      const searchParamsString = new URLSearchParams(searchParams).toString();
      const fullPath = searchParamsString ? `${to}?${searchParamsString}` : to;
      navigateRRD(fullPath, options);
    }
  }
  return navigate;
}
