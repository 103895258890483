import { useMutation, useQuery } from "@tanstack/react-query";

import { NECESSARY_INFO_LACKING_HINT } from "@/shared";
import { getDevicesOfProperty } from "@/shared/requests/property";
import { getStorage } from "@/shared/store";
import type { DeviceType } from "@/types";

import { NecessaryInfoLackingError } from "./errors.type";
import { DEVICE_QUERY_KEY } from "./queryKey";

export function useGetDevicesOfProperty(
  device_type = getStorage("deviceType") as DeviceType,
  enabled = true
) {
  const property_id = getStorage("propertyId");
  const queryFn = () => {
    if (!property_id) {
      throw new NecessaryInfoLackingError(NECESSARY_INFO_LACKING_HINT);
    }
    return getDevicesOfProperty({ property_id, device_type });
  };
  return useQuery([DEVICE_QUERY_KEY, property_id, device_type], queryFn, {
    enabled: enabled && !!property_id,
  });
}

export function useGetDevicesOfPropertyMutation(
  device_type = getStorage("deviceType") as DeviceType
) {
  const property_id = getStorage("propertyId");
  const mutationFn = () => {
    if (!property_id) {
      throw new NecessaryInfoLackingError(NECESSARY_INFO_LACKING_HINT);
    }
    return getDevicesOfProperty({ property_id, device_type });
  };
  return useMutation(mutationFn);
}
