import type { FC } from "react";
import { useEffect, useState } from "react";

import type { ButtonProps } from "@/components";
import { Button } from "@/components";
import { getStorage, setStorage } from "@/shared/store";

interface IResendCode extends Omit<ButtonProps, "onClick"> {
  onResend?: ButtonProps["onClick"];
}
export const ResendCode: FC<IResendCode> = ({ onResend, ...props }) => {
  const [enabled, setEnabled] = useState(true);
  const [seconds, setSeconds] = useState(60);

  const onClick = () => {
    onResend?.();
    setEnabled(false);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setEnabled((enabled) => {
        if (!enabled) {
          setSeconds((prevSeconds) => prevSeconds - 1);
        }
        return enabled;
      });
    }, 1000);

    // 清理定时器
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const isAutoSended = getStorage("isAutoSendedVerifyCode");
    isAutoSended || onClick();
    setStorage("isAutoSendedVerifyCode", true);
  });

  useEffect(() => {
    if (seconds < 0) {
      setSeconds(60);
      setEnabled(true);
    }
  }, [seconds]);

  if (enabled) {
    return (
      <Button
        {...props}
        className="w-full"
        fill="outline"
        size="large"
        onClick={onClick}
      >
        ワンタイムパスワードの再度送信
      </Button>
    );
  }

  return (
    <p className="text-center text-text-gray">
      <span>ワンタイムパスワードの再度送信</span>
      {!enabled && <span className="text-primary">&nbsp;{seconds}</span>}
    </p>
  );
};
